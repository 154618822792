import {Observable, combineLatest, of} from 'rxjs';
import {TreeItem, findChildRecursiveByPredicate} from '../../../../../../../../../../support/generic_tree';
import {V3SetDefinition, V3SetDefinitionsProvider} from './set_definitions_provider';
import {map, shareReplay, startWith, switchMap} from 'rxjs/operators';

import {AnswerOption} from '../../../../../../../../../models/answer_option';
import {BuildYearProvider} from '../../../../../../../../../business/build_year_provider';
import {EnergyLabelProvider} from '../../../../../../../../../business/energy_label_provider';
import {GebruiksOppervlakteBuitenruimteProvider} from '../../../../../../../../../business/bbmi_areas/gebruiks_oppervlakte_buitenruimte_provider';
import {GebruiksOppervlakteExterneBergruimteProvider} from '../../../../../../../../../business/bbmi_areas/gebruiks_oppervlakte_externe_bergruimte_provider';
import {OverigeInpandigeRuimteProvider} from '../../../../../../../../../business/bbmi_areas/overige_inpandige_ruimte';
import {PlotAreaProvider} from '../../../../../../../../../business/plot_area_provider';
import {QuestionAnswerPair} from '../../../../../../../../../../support/question_answer_tree';
import {ReferenceSetFilters} from '../../../../../../../../../models/reference_set/reference_set_filters';
import {SetType} from '../../../../../../../../../models/reference_set/set_type';
import {SurfaceAreaProvider} from '../../../../../../../../../business/support/surface_area_provider';
import {TechnicalReference} from '../../../../../../../../../enum/technical_reference';
import {ValuationDateProvider} from '../../../../../../../../../business/valuation_date_provider';
import {VolumeProvider} from '../../../../../../../../../business/volume_provider';
import {ObjectType} from '../../../../../../../../../enum/object_type';
import {Appraisal} from '../../../../../../../../../models/appraisal';
import {AnswerController} from '../../../../../../../../../business/answering/answer_controller';
import {QuestionSet} from '../../../../../../../../../models/question_set';
import {QuestionEffectInteractor} from '../../../../../../../../../business/conditions/question_effects_interactor';
import {getMultipleChoiceAnswerStreamByTechnicalReference} from '../../../../../../../../../../support/answer_stream';

export interface V3ReferenceSet<T = TreeItem<QuestionAnswerPair>> extends V3SetDefinition<T> {
    objectType: ObjectType | null;
    buildYear: number | null;
    plotArea: number | null; //perceelOppervlakte
    surfaceArea: number | null; //gebruiksoppervlakteWonen
    gebruiksOppervlakteBuitenruimte: number | null;
    overigeInpandigeRuimte: number | null;
    gebruiksOppervlakteExterneBergruimte: number | null;
    onderhoudssituatie: string | null;
    volume: number | null; //brutoInhoud
    energyLabel: string | null;
    valuationDate: Date | null;
    filters?: ReferenceSetFilters;
}

export interface V3ReferenceSetsProvider {
    referenceSets(): Observable<V3ReferenceSet[] | null>;
}

export class DefaultV3ReferenceSetsProvider implements V3ReferenceSetsProvider {
    constructor(
        private appraisal: Appraisal,
        private questionSet: QuestionSet,
        private setDefinitionsProvider: V3SetDefinitionsProvider,
        private buildYearProvider: BuildYearProvider,
        private surfaceAreaProvider: SurfaceAreaProvider,
        private plotAreaProvider: PlotAreaProvider,
        private overigeInpandigeRuimteProvider: OverigeInpandigeRuimteProvider,
        private gebruiksOppervlakteBuitenruimteProvider: GebruiksOppervlakteBuitenruimteProvider,
        private gebruiksOppervlakteExterneBergruimteProvider: GebruiksOppervlakteExterneBergruimteProvider,
        private volumeProvider: VolumeProvider,
        private energyLabelProvider: EnergyLabelProvider,
        private valuationDateProvider: ValuationDateProvider,
        private answerController: AnswerController,
        private questionEffectInteractor: QuestionEffectInteractor
    ) {}

    private extractSpecialValues(
        setDefinitions: V3SetDefinition[],
        buildYear: number | null,
        surfaceArea: number | null,
        plotArea: number | null,
        overigeInpandigeRuimte: number | null,
        gebruiksOppervlakteBuitenruimte: number | null,
        gebruiksOppervlakteExterneBergruimte: number | null,
        volume: number | null,
        energyLabel: string | null,
        valuationDate: Date | null,
        objectType: ObjectType | null,
        onderhoudssituatie: string | null
    ): V3ReferenceSet[] {
        return setDefinitions.map((setDefinition): V3ReferenceSet => {
            const specialValueArgumentGroup = findChildRecursiveByPredicate(
                setDefinition.groupTree,
                (item) =>
                    item.question.technicalReference ===
                    (setDefinition.type === SetType.SOLD
                        ? TechnicalReference.SPECIAL_VALUE_ARGUMENT_GROUP
                        : TechnicalReference.SPECIAL_VALUE_ARGUMENT_GROUP_RENT)
            );

            if (specialValueArgumentGroup) {
                const buildYearItem = findChildRecursiveByPredicate(
                    specialValueArgumentGroup,
                    (i) =>
                        i.question.technicalReference ===
                        (setDefinition.isDemolishedBuilding
                            ? TechnicalReference.MARKET_VALUE_ARGUMENT_BUILD_YEAR
                            : TechnicalReference.SPECIAL_VALUE_ARGUMENT_BUILD_YEAR)
                );
                const surfaceAreaItem = findChildRecursiveByPredicate(
                    specialValueArgumentGroup,
                    (i) =>
                        i.question.technicalReference ===
                        (setDefinition.isDemolishedBuilding
                            ? TechnicalReference.MARKET_VALUE_ARGUMENT_GEBRUIKSOPPERVLAKTE_WONEN
                            : TechnicalReference.SPECIAL_VALUE_ARGUMENT_GEBRUIKSOPPERVLAKTE_WONEN)
                );
                const plotAreaItem = findChildRecursiveByPredicate(
                    specialValueArgumentGroup,
                    (i) =>
                        i.question.technicalReference ===
                        (setDefinition.isDemolishedBuilding
                            ? TechnicalReference.MARKET_VALUE_ARGUMENT_PERCEEL_OPPERVLAKTE
                            : TechnicalReference.SPECIAL_VALUE_ARGUMENT_PERCEEL_OPPERVLAKTE)
                );

                const overigeInpandigeRuimteItem = findChildRecursiveByPredicate(
                    specialValueArgumentGroup,
                    (i) =>
                        i.question.technicalReference ===
                        (setDefinition.isDemolishedBuilding
                            ? TechnicalReference.MARKET_VALUE_ARGUMENT_OVERIGE_INPANDIGE_RUIMTE
                            : TechnicalReference.SPECIAL_VALUE_ARGUMENT_OVERIGE_INPANDIGE_RUIMTE)
                );
                const gebruiksOppervlakteBuitenruimteItem = findChildRecursiveByPredicate(
                    specialValueArgumentGroup,
                    (i) =>
                        i.question.technicalReference ===
                        (setDefinition.isDemolishedBuilding
                            ? TechnicalReference.MARKET_VALUE_ARGUMENT_GEBOUWGEBONDEN_BUITENRUIMTE
                            : TechnicalReference.SPECIAL_VALUE_ARGUMENT_GEBOUWGEBONDEN_BUITENRUIMTE)
                );
                const gebruiksOppervlakteExterneBergruimteItem = findChildRecursiveByPredicate(
                    specialValueArgumentGroup,
                    (i) =>
                        i.question.technicalReference ===
                        (setDefinition.isDemolishedBuilding
                            ? TechnicalReference.MARKET_VALUE_ARGUMENT_EXTERNE_BERGRUIMTE
                            : TechnicalReference.SPECIAL_VALUE_ARGUMENT_EXTERNE_BERGRUIMTE)
                );
                const volumeItem = findChildRecursiveByPredicate(
                    specialValueArgumentGroup,
                    (i) =>
                        i.question.technicalReference ===
                        (setDefinition.isDemolishedBuilding
                            ? TechnicalReference.MARKET_VALUE_ARGUMENT_BRUTO_INHOUD
                            : TechnicalReference.SPECIAL_VALUE_ARGUMENT_BRUTO_INHOUD)
                );
                const energyLabelItem = findChildRecursiveByPredicate(
                    specialValueArgumentGroup,
                    (i) =>
                        i.question.technicalReference ===
                        (setDefinition.isDemolishedBuilding
                            ? TechnicalReference.MARKET_VALUE_ARGUMENT_ENERGY_LABEL
                            : TechnicalReference.SPECIAL_VALUE_ARGUMENT_ENERGY_LABEL)
                );
                const objectTypeItem = findChildRecursiveByPredicate(
                    specialValueArgumentGroup,
                    (i) =>
                        i.question.technicalReference ===
                        (setDefinition.isDemolishedBuilding
                            ? TechnicalReference.MARKET_VALUE_ARGUMENT_OBJECT_TYPE
                            : TechnicalReference.SPECIAL_VALUE_ARGUMENT_OBJECT_TYPE)
                );
                const onderhoudssituatieItem = findChildRecursiveByPredicate(
                    specialValueArgumentGroup,
                    (i) => i.question.technicalReference === TechnicalReference.ADAPTED_VALUE_ONDERHOUDSITUATIE
                );

                const specialBuildYear = parseInt(String(buildYearItem?.item.answer?.contents), 10);
                const specialPlotArea = parseFloat(String(plotAreaItem?.item.answer?.contents));
                const specialSurfaceArea = parseFloat(String(surfaceAreaItem?.item.answer?.contents));
                const specialOverigeInpandigeRuimte = parseFloat(
                    String(overigeInpandigeRuimteItem?.item.answer?.contents)
                );
                const specialGebruiksOppervlakteBuitenruimte = parseFloat(
                    String(gebruiksOppervlakteBuitenruimteItem?.item.answer?.contents)
                );
                const specialGebruiksOppervlakteExterneBergruimte = parseFloat(
                    String(gebruiksOppervlakteExterneBergruimteItem?.item.answer?.contents)
                );
                const specialVolume = parseFloat(String(volumeItem?.item.answer?.contents));
                const specialEnergyLabel = energyLabelItem?.item.question?.answerOptions.find(
                    (a: AnswerOption) => a.id === energyLabelItem?.item.answer?.answerOptionId
                )?.contents;
                const specialObjectType = objectTypeItem?.item.question?.answerOptions.find(
                    (a: AnswerOption) => a.id === objectTypeItem?.item.answer?.answerOptionId
                )?.contents;
                const specialOnderhoudssituatie = onderhoudssituatieItem?.item.question?.answerOptions.find(
                    (a: AnswerOption) => a.id === onderhoudssituatieItem?.item.answer?.answerOptionId
                )?.contents;

                return {
                    ...setDefinition,
                    buildYear: !isNaN(specialBuildYear) ? specialBuildYear : buildYear,
                    plotArea: !isNaN(specialPlotArea) ? specialPlotArea : plotArea,
                    surfaceArea: !isNaN(specialSurfaceArea) ? specialSurfaceArea : surfaceArea,
                    overigeInpandigeRuimte: !isNaN(specialOverigeInpandigeRuimte)
                        ? specialOverigeInpandigeRuimte
                        : overigeInpandigeRuimte,
                    gebruiksOppervlakteBuitenruimte: !isNaN(specialGebruiksOppervlakteBuitenruimte)
                        ? specialGebruiksOppervlakteBuitenruimte
                        : gebruiksOppervlakteBuitenruimte,
                    gebruiksOppervlakteExterneBergruimte: !isNaN(specialGebruiksOppervlakteExterneBergruimte)
                        ? specialGebruiksOppervlakteExterneBergruimte
                        : gebruiksOppervlakteExterneBergruimte,
                    volume: !isNaN(specialVolume) ? specialVolume : volume,
                    energyLabel: specialEnergyLabel ? specialEnergyLabel : energyLabel,
                    objectType:
                        specialObjectType && Object.values<string>(ObjectType).includes(specialObjectType)
                            ? (specialObjectType as ObjectType)
                            : objectType,
                    onderhoudssituatie: specialOnderhoudssituatie ?? onderhoudssituatie,
                    valuationDate,
                };
            }

            const forcedSaleFiltersGroup = findChildRecursiveByPredicate(
                setDefinition.groupTree,
                (item) => item.question.technicalReference === TechnicalReference.VALUATION_FORCED_SALE_FILTERS
            );
            if (forcedSaleFiltersGroup) {
                const address = findChildRecursiveByPredicate(
                    forcedSaleFiltersGroup,
                    (i) => i.question.technicalReference === TechnicalReference.VALUATION_FORCED_SALE_FILTER_ADDRESS
                );
                const objectTypeFilter = findChildRecursiveByPredicate(
                    forcedSaleFiltersGroup,
                    (i) => i.question.technicalReference === TechnicalReference.VALUATION_FORCED_SALE_FILTER_OBJECT_TYPE
                );

                const objectTypeValue = objectTypeFilter?.item.question?.answerOptions.find(
                    (a: AnswerOption) => a.id === objectTypeFilter?.item.answer?.answerOptionId
                )?.contents;

                const ranges = findChildRecursiveByPredicate(
                    forcedSaleFiltersGroup,
                    (i) => i.question.technicalReference === TechnicalReference.VALUATION_FORCED_SALE_FILTER_RANGES
                );

                const rangesAnswer = ranges?.item.answer?.contents;

                return {
                    ...setDefinition,
                    buildYear,
                    plotArea,
                    surfaceArea,
                    overigeInpandigeRuimte,
                    gebruiksOppervlakteBuitenruimte,
                    gebruiksOppervlakteExterneBergruimte,
                    onderhoudssituatie,
                    volume,
                    energyLabel,
                    valuationDate,
                    objectType,
                    filters: {
                        address: address?.item.answer?.contents ?? null,
                        objectType: objectTypeValue ?? null,
                        ranges: rangesAnswer ? JSON.parse(rangesAnswer) : null,
                    },
                };
            }

            return {
                ...setDefinition,
                buildYear,
                plotArea,
                surfaceArea,
                overigeInpandigeRuimte,
                gebruiksOppervlakteBuitenruimte,
                gebruiksOppervlakteExterneBergruimte,
                onderhoudssituatie,
                volume,
                energyLabel,
                valuationDate,
                objectType,
            };
        });
    }

    private observableCache: Observable<V3ReferenceSet[] | null> | null = null;
    public referenceSets(): Observable<V3ReferenceSet[] | null> {
        if (this.observableCache !== null) {
            return this.observableCache;
        }
        this.observableCache = this.setDefinitionsProvider.setDefinitions().pipe(
            switchMap((tree) => {
                return combineLatest([
                    of(tree),
                    this.buildYearProvider.stream(0),
                    this.surfaceAreaProvider.surfaceArea(),
                    this.plotAreaProvider.plotArea(0),
                    this.overigeInpandigeRuimteProvider.stream().pipe(startWith(0)),
                    this.gebruiksOppervlakteBuitenruimteProvider.stream().pipe(startWith(0)),
                    this.gebruiksOppervlakteExterneBergruimteProvider.stream().pipe(startWith(0)),
                    this.volumeProvider.stream(),
                    this.energyLabelProvider.stream(),
                    of(this.valuationDateProvider.date),
                    of(this.appraisal.objectType),
                    this.onderhoudsSituatieStream(),
                ]) as Observable<
                    [
                        typeof tree,
                        number | null,
                        number | null,
                        number | null,
                        number | null,
                        number | null,
                        number | null,
                        number | null,
                        string | null,
                        Date | null,
                        ObjectType | null,
                        string | null
                    ]
                >;
            }),
            map(([tree, ...rest]) => {
                if (!tree) {
                    return null;
                }

                return this.extractSpecialValues(tree, ...rest);
            }),
            shareReplay(1)
        );

        return this.observableCache;
    }

    private onderhoudsSituatieStream(): Observable<string | null> {
        return getMultipleChoiceAnswerStreamByTechnicalReference(
            this.questionSet,
            this.answerController,
            this.questionEffectInteractor,
            TechnicalReference.OBJECT_ALGEHELE_INDRUK
        );
    }
}
