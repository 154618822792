import {ApiAnswerOption, apiAnswerOptionToAnswerOption} from './api_answer_option';
import {ApiConditionGroup, apiConditionGroupToConditionGroup} from './api_condition_group';
import {ApiMacro, apiMacroToMacro} from './api_macro';

import {CompareSelectorType} from '../../enum/compare_selector_type';
import {DetailName} from '../../enum/detail_name';
import {GroupType} from '../../enum/group_type';
import {NormalQuestionType} from '../../enum/question_type';
import {Question} from '../../models/question';
import {ReportSelectorType} from '../../enum/report_selector_type';
import {TechnicalReference} from '../../enum/technical_reference';
import {CopyReferenceType} from '../../enum/copy_reference_type';
import {ApiQuestionExtension, apiQuestionExtensionToQuestionExtension} from './api_question_extension';
import {QuestionExtension} from '../../models/question_extension';

export interface ApiQuestion {
    uuid: string;
    parent_uuid: string | null;
    detail_name: DetailName | null;
    type: NormalQuestionType;
    contents: string;
    image_url: string | null;
    report_value: string | null;
    max_characters: number;
    min_characters: number;
    title: string | null;
    is_required: boolean;
    is_visited_required: boolean;
    is_appraiser_only: boolean;
    is_duplicable: boolean;
    should_create_first_iteration: boolean;
    should_stub_children: boolean;
    freezes: boolean;
    is_always_editable: boolean;
    default_value: string | null;
    group: GroupType[] | null;
    read_more_url: string | null;
    order: number;
    technical_reference: TechnicalReference | null;
    automation_reference: string | null;
    report_field_reference: string | null;
    labels: string | null;
    building_costs_reference: string | null;
    report_paragraph_references: string[] | null;
    answer_options: ApiAnswerOption[];
    macros: ApiMacro[];
    condition_groups: ApiConditionGroup[];
    report_selector: ReportSelectorType | null;
    compare_selector: CompareSelectorType | null;
    copy_reference: CopyReferenceType | null;
    tooltip: string | null;
    show_label: boolean;
    extensions: ApiQuestionExtension[];
}

export function apiQuestionToQuestion(apiQuestion: ApiQuestion): Question {
    return {
        uuid: apiQuestion.uuid,
        parentUuid: apiQuestion.parent_uuid,
        detailName: apiQuestion.detail_name,
        type: apiQuestion.type,
        contents: apiQuestion.contents,
        imageUrl: apiQuestion.image_url,
        reportValue: apiQuestion.report_value,
        maxCharacters: apiQuestion.max_characters,
        minCharacters: apiQuestion.min_characters,
        title: apiQuestion.title,
        isRequired: apiQuestion.is_required,
        isVisitedRequired: apiQuestion.is_visited_required,
        isAppraiserOnly: apiQuestion.is_appraiser_only,
        isDuplicable: apiQuestion.is_duplicable,
        shouldCreateFirstIteration: apiQuestion.should_create_first_iteration,
        shouldStubChildren: apiQuestion.should_stub_children,
        freezes: apiQuestion.freezes,
        isAlwaysEditable: apiQuestion.is_always_editable,
        defaultValue: apiQuestion.default_value,
        group: apiQuestion.group,
        readMoreUrl: apiQuestion.read_more_url,
        order: apiQuestion.order,
        technicalReference: apiQuestion.technical_reference,
        automationReference: apiQuestion.automation_reference,
        labels: apiQuestion.labels,
        buildingCostsReference: apiQuestion.building_costs_reference,
        reportParagraphReferences: apiQuestion.report_paragraph_references,
        reportFieldReference: apiQuestion.report_field_reference,
        answerOptions: apiQuestion.answer_options.map((apiAnswerOption) =>
            apiAnswerOptionToAnswerOption(apiAnswerOption)
        ),
        macros: apiQuestion.macros.map((apiMacro) => apiMacroToMacro(apiMacro)),
        conditionGroups: apiQuestion.condition_groups.map((apiConditionGroup) =>
            apiConditionGroupToConditionGroup(apiConditionGroup)
        ),
        reportSelector: apiQuestion.report_selector,
        compareSelector: apiQuestion.compare_selector,
        copyReference: apiQuestion.copy_reference,
        tooltip: apiQuestion.tooltip,
        showLabel: apiQuestion.show_label,
        extensions:
            apiQuestion.extensions
                ?.map((apiExtension) => apiQuestionExtensionToQuestionExtension(apiExtension))
                .filter((e): e is QuestionExtension => e !== null) ?? [],
    };
}
