import * as React from 'react';

import {Loader} from '../components/loader';
import {Modal} from '../content/questions/components/modal';
import {UserWidget} from '../../../enum/user_widget';

interface OwnProps {
    initialUserWidgets: UserWidget[];
    onHide: () => void;
    onSave: (userWidgets: UserWidget[]) => void;
    isSaving: boolean;
}

interface OwnState {
    userWidgets: UserWidget[];
}

export class UserWidgetsModal extends React.Component<OwnProps, OwnState> {
    public state: OwnState = {
        userWidgets: this.props.initialUserWidgets,
    };

    private toggleUserWidget(userWidget: UserWidget) {
        this.setState((state) => ({
            userWidgets: state.userWidgets.includes(userWidget)
                ? state.userWidgets.filter((uw) => uw !== userWidget)
                : [...state.userWidgets, userWidget],
        }));
    }

    public render() {
        return (
            <Modal visible={true} onHide={() => this.props.onHide()}>
                <div className="modal-header">
                    <h2>Widgets bewerken</h2>
                </div>
                <div className="modal-body">
                    <div className="text-center">
                        <p className="leading">Selecteer de widgets die actief moeten worden in het rechter paneel.</p>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <div className="form-group check-block">
                                <input
                                    type="checkbox"
                                    id="widget-appraisal-nwwi-control-report"
                                    checked={this.state.userWidgets.includes(UserWidget.APPRAISE_NWWI_CONTROL_REPORT)}
                                    onChange={() => this.toggleUserWidget(UserWidget.APPRAISE_NWWI_CONTROL_REPORT)}
                                />
                                <label htmlFor="widget-appraisal-nwwi-control-report">
                                    <span className="check-block-icon ion-md-today"></span>
                                    <small>NWWI controleverslag</small>
                                </label>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="form-group check-block">
                                <input
                                    type="checkbox"
                                    id="widget-appraisal-object-details"
                                    checked={this.state.userWidgets.includes(UserWidget.APPRAISE_OBJECT_DETAILS)}
                                    onChange={() => this.toggleUserWidget(UserWidget.APPRAISE_OBJECT_DETAILS)}
                                />
                                <label htmlFor="widget-appraisal-object-details">
                                    <span className="check-block-icon ion-md-home"></span>
                                    <small>Kerngegevens taxatieobject</small>
                                </label>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="form-group check-block">
                                <input
                                    type="checkbox"
                                    id="widget-appraisal-core-tasks"
                                    checked={this.state.userWidgets.includes(UserWidget.APPRAISE_CORE_TASKS)}
                                    onChange={() => this.toggleUserWidget(UserWidget.APPRAISE_CORE_TASKS)}
                                />
                                <label htmlFor="widget-appraisal-core-tasks">
                                    <span className="check-block-icon ion-md-checkmark-circle-outline"></span>
                                    <small>Kerntaken</small>
                                </label>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="form-group check-block">
                                <input
                                    type="checkbox"
                                    id="widget-appraisal-notes"
                                    checked={this.state.userWidgets.includes(UserWidget.APPRAISE_NOTES)}
                                    onChange={() => this.toggleUserWidget(UserWidget.APPRAISE_NOTES)}
                                />
                                <label htmlFor="widget-appraisal-notes">
                                    <span className="check-block-icon ion-md-clipboard"></span>
                                    <small>Notities</small>
                                </label>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="form-group check-block">
                                <input
                                    type="checkbox"
                                    id="widget-appraisal-attachments"
                                    checked={this.state.userWidgets.includes(UserWidget.APPRAISE_FILES)}
                                    onChange={() => this.toggleUserWidget(UserWidget.APPRAISE_FILES)}
                                />
                                <label htmlFor="widget-appraisal-attachments">
                                    <span className="check-block-icon ion-md-document"></span>
                                    <small>Bijlagen</small>
                                </label>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="form-group check-block">
                                <input
                                    type="checkbox"
                                    id="widget-appraisal-photos"
                                    checked={this.state.userWidgets.includes(UserWidget.APPRAISE_PHOTOS)}
                                    onChange={() => this.toggleUserWidget(UserWidget.APPRAISE_PHOTOS)}
                                />
                                <label htmlFor="widget-appraisal-photos">
                                    <span className="check-block-icon ion-md-image"></span>
                                    <small>Foto&apos;s</small>
                                </label>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="form-group check-block">
                                <input
                                    type="checkbox"
                                    id="widget-appraisal-reference-objects"
                                    checked={this.state.userWidgets.includes(UserWidget.APPRAISE_REFERENCE_OBJECTS)}
                                    onChange={() => this.toggleUserWidget(UserWidget.APPRAISE_REFERENCE_OBJECTS)}
                                />
                                <label htmlFor="widget-appraisal-reference-objects">
                                    <span className="check-block-icon c-icon-pin"></span>
                                    <small>Referenties</small>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button className="btn btn-secondary" onClick={() => this.props.onHide()}>
                        Sluiten
                    </button>
                    <button className="btn btn-primary" onClick={() => this.props.onSave(this.state.userWidgets)}>
                        {this.props.isSaving ? <Loader /> : 'Opslaan'}
                    </button>
                </div>
            </Modal>
        );
    }
}
