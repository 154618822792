import {Observable, combineLatest, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {IteratorQuestionType, NormalQuestionType} from '../../enum/question_type';
import {PagePart} from '../../models/page_part';
import {QuestionSet} from '../../models/question_set';
import {
    ValidationMessage,
    ValidationMessageErrorType,
    ValidationMessageImportance,
    ValidationMessageType,
} from './validation_message';
import {FloorInfo, FloorInteractor} from '../floor_interactor';
import {QuestionValidationMessagesProvider} from './question_validation_messages_provider';
import {AnswerController} from '../answering/answer_controller';
import {isIteratorQuestionType} from '../../../support/is_iterator_question_type';
import {Question} from '../../models/question';

export class PagePartValidationProvider {
    constructor(
        private questionSet: QuestionSet,
        private floorInteractor: FloorInteractor,
        private questionValidationMessagesProvider: QuestionValidationMessagesProvider,
        private answerController: AnswerController
    ) {}

    public getPagePartsValidationsStream(pageParts: PagePart[], debounceTimeMs = 100): Observable<ValidationMessage[]> {
        return this.floorInteractor.getFloorsStream().pipe(
            switchMap((floors) => {
                return combineLatest(
                    pageParts.map((pagePart) => {
                        if (pagePart.questionUuid === null) {
                            return of([]);
                        }
                        const question = this.questionSet.findQuestionByUuid(pagePart.questionUuid);
                        if (question === undefined) {
                            return of([]);
                        }

                        if (question.type === IteratorQuestionType.PAGE_PART_ITERATOR) {
                            if (floors.length === 0) {
                                return of([]);
                            }
                            return combineLatest([
                                this.getEmptyPagePartValidationStream(pagePart),
                                ...floors.map((floor) =>
                                    this.getPagePartValidationStream(pagePart, floor, debounceTimeMs)
                                ),
                            ]).pipe(map((messages) => messages.flat()));
                        } else {
                            return combineLatest([
                                this.getEmptyPagePartValidationStream(pagePart),
                                this.getPagePartValidationStream(pagePart, null, debounceTimeMs),
                            ]).pipe(map((messages) => messages.flat()));
                        }
                    })
                ).pipe(map((messages) => messages.flat()));
            })
        );
    }

    private getEmptyPagePartValidationStream(pagePart: PagePart): Observable<ValidationMessage[]> {
        if (pagePart.questionUuid === null) {
            return of([]);
        }
        const question = this.questionSet.findQuestionByUuid(pagePart.questionUuid);
        if (question === undefined) {
            return of([]);
        }

        const alwaysVisibleChildren = this.questionSet.flattenChildrenRecursively(
            question.uuid,
            (q) =>
                !q.conditionGroups.some((cg) => cg.effects.some((e) => e.task === 'show')) &&
                !isIteratorQuestionType(q.type) &&
                q.type !== NormalQuestionType.HIDDEN
        );
        const alwaysRequiredChildren = alwaysVisibleChildren.filter((q) => q.isRequired);
        if (alwaysRequiredChildren.length === 0) {
            return of([]);
        }

        return combineLatest(
            alwaysRequiredChildren.map((child) => {
                return this.answerController.answersForQuestionUuidStream(child.uuid).pipe(
                    map((answers) => {
                        if (answers.filter((a) => a.isDeleted !== true).length === 0) {
                            return child;
                        }
                        return null;
                    })
                );
            })
        ).pipe(
            map((nullableQuestions) => {
                const questions = nullableQuestions.filter((q): q is Question => q !== null);
                return questions.map((q) => ({
                    type: ValidationMessageType.TAXAPI,
                    importance: ValidationMessageImportance.ERROR,
                    question: question,
                    answer: null,
                    messages: [],
                    fallbackMessage: `Het veld "${q.contents}" is verplicht om in te vullen.`,
                    floorInfo: null,
                    pagePart: pagePart,
                    errorType: ValidationMessageErrorType.MISSING_REQUIRED_ANSWER,
                }));
            })
        );
    }

    private getPagePartValidationStream(
        pagePart: PagePart,
        floor: FloorInfo | null,
        debounceTimeMs = 100
    ): Observable<ValidationMessage[]> {
        if (pagePart.questionUuid === null) {
            return of([]);
        }
        const question = this.questionSet.findQuestionByUuid(pagePart.questionUuid);
        if (question === undefined) {
            return of([]);
        }

        if (question.type === IteratorQuestionType.PAGE_PART_ITERATOR) {
            return this.questionValidationMessagesProvider
                .getStream(question, floor?.iteration ?? null, debounceTimeMs)
                .pipe(map((messages) => messages.map((message) => ({...message, floorInfo: floor}))));
        } else {
            return this.questionValidationMessagesProvider.getStream(question, null, debounceTimeMs);
        }
    }
}
