import {ValidationMessage, ValidationMessageImportance, ValidationMessageType} from '../validation_message';

import {Answer} from '../../../models/answer';
import {AnswerValidator} from '../answer_validator';
import {Appraisal} from '../../../models/appraisal';
import {AppraisalState} from '../../../enum/appraisal_state';
import {Question} from '../../../models/question';
import {TechnicalReference} from '../../../enum/technical_reference';
import {isEmpty} from '../../../../support/util';

export class GeneralCommentsValidator implements AnswerValidator {
    constructor(private appraisal: Appraisal) {}

    public validate(question: Question, answer: Answer | null): ValidationMessage[] {
        if (question.technicalReference !== TechnicalReference.GENERAL_REMARKS) {
            return [];
        }

        if (this.appraisal.status !== AppraisalState.REQUESTED_CORRECTION_AFTER_APPROVAL) {
            return [];
        }

        if (answer !== null && this.hasAnswer(answer)) {
            return [];
        }

        return [
            {
                type: ValidationMessageType.TAXAPI,
                importance: ValidationMessageImportance.ERROR,
                question: question,
                answer: answer,
                messages: [],
                fallbackMessage:
                    'Het veld "' +
                    question.contents +
                    '" is verplicht om in te vullen wanneer het rapport ' +
                    'is opengesteld na goedkeuring. Bekijk de instructies bij de nadere mededelingen.',
                floorInfo: null,
                errorType: null,
            },
        ];
    }

    private hasAnswer(answer: Answer) {
        return !isEmpty(answer.contents) || !isEmpty(answer.answerOptionId) || !isEmpty(answer.file);
    }
}
