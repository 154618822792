import * as React from 'react';
import {createRoot} from 'react-dom/client';

import {Root} from '../root';
import {Decide} from './decide';

export function renderPrecheckDecideEnvironmentInto(element: HTMLElement) {
    const appraisalId = element.getAttribute('data-appraisal-id');
    if (appraisalId === null) {
        throw new Error('Trying to render pre-check environment without appraisal ID');
    }

    const questionSetId = element.getAttribute('data-question-set-id');
    if (questionSetId === null) {
        throw new Error('Trying to render pre-check environment without Question Set ID');
    }

    createRoot(element).render(
        <Root>
            <Decide questionSetId={parseInt(questionSetId, 10)} />
        </Root>
    );
}
