import {AppraisalObject, AppraisalObjectAdres} from '../../../create_appraisal/pre_check/map/appraisal_object';

export interface AppraisalObjectApi {
    id: number;
    adres: AppraisalObjectAdres;

    living_area: number | null;
    plot_area: number | null;
    object_type: string | null;
    build_year: number | null;
    volume: number | null;
    front_url: string | null;
}

export function apiAppraisalObjectToAppraisalObject(apiAppraisalObject: AppraisalObjectApi): AppraisalObject {
    return {
        id: apiAppraisalObject.id,
        adres: apiAppraisalObject.adres,
        livingSurface: apiAppraisalObject.living_area,
        parcelSurface: apiAppraisalObject.plot_area,
        volume: apiAppraisalObject.volume,
        objectType: apiAppraisalObject.object_type,
        buildYear: apiAppraisalObject.build_year,
        frontUrl: apiAppraisalObject.front_url,
    };
}
