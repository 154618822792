import {format} from 'date-fns';
import {observer} from 'mobx-react';
import React, {useEffect} from 'react';
import {formatMoney} from '../../../appraising/appraise/ui/support/format_money';
import {usePresenter} from '../../../support/presenter/use_presenter';
import {LineChart} from './line_chart';
import {ValuationLine} from './valuation_line';
import {WidgetPresenter} from './widget_presenter';
import {classNames} from '../../../support/classnames';
import {PaymentBlur} from '../components/payment_blur';

const daysToDuration = (days: number): string => {
    if (days < 7) {
        return `${days} ${days === 1 ? 'dag' : 'dagen'}`;
    } else if (days < 30) {
        return `${Math.floor(days / 7)} ${Math.floor(days / 7) === 1 ? 'week' : 'weken'}`;
    } else if (days < 365) {
        return `${Math.floor(days / 30)} ${Math.floor(days / 30) === 1 ? 'maand' : 'maanden'}`;
    } else {
        return `${Math.floor(days / 365)} ${Math.floor(days / 365) === 1 ? 'jaar' : 'jaren'} en ${Math.floor(
            (days % 365) / 30
        )} ${Math.floor((days % 365) / 30) === 1 ? 'maand' : 'maanden'}`;
    }
};

export interface ValuationObject {
    source: ValuationSource;
    value: number;
}

export enum ValuationSource {
    WOZ = 'geindexeerde woz',
    ALTUM = 'altum AVM',
    ORTEC = 'verkooptransactie',
    MATRIXIAN = 'matrixian AVM',
    ORTEC_AVM = 'ortec AVM',
}

export const ValuationOverview: React.FC<{setIsLoading?: (loading: boolean) => void; forReport?: boolean}> = observer(
    ({setIsLoading, forReport}) => {
        const presenter = usePresenter(
            (component) => new WidgetPresenter(component.business.appraisalProvider, component.network.appraisalApi)
        );

        useEffect(() => {
            setIsLoading?.(presenter.valuationOverview === undefined);
        }, [presenter.valuationOverview]);

        const hasEstimatedWoz = presenter.valuationOverview?.wozValues.find((wozWaarde) => wozWaarde.is_estimated);

        if (!presenter.valuationOverview) {
            return null;
        }

        return (
            <>
                <PaymentBlur
                    active={!presenter.hasActivatedAvms}
                    overlay={
                        presenter.valuationOverview.avmCosts !== null ? (
                            <>
                                <span className="payment-required-icon ion-md-stats"></span>
                                <button
                                    className="btn btn-primary btn-sm ion-md-code-download"
                                    onClick={() => presenter.requestValuation(true)}
                                >
                                    AVMs ophalen voor {formatMoney(presenter.valuationOverview.avmCosts / 100)}
                                </button>
                            </>
                        ) : undefined
                    }
                >
                    <div className="row">
                        {presenter.valuationLineValuesWithFake.length > 1 ? (
                            <div
                                className={classNames('col-12 pt-1 pb-3', {
                                    'pl-5 pr-5': !forReport,
                                })}
                            >
                                <ValuationLine values={presenter.valuationLineValuesWithFake} />
                            </div>
                        ) : null}
                        {presenter.chartValuationDataWithFake &&
                        (presenter.chartValuationDataWithFake.wozValues.length > 0 ||
                            presenter.chartValuationDataWithFake.altumTransactions.length > 0 ||
                            presenter.chartValuationDataWithFake.ortecTransaction) ? (
                            <div
                                className={classNames('col-12 pt-1 pb-3', {
                                    'pl-5 pr-5': !forReport,
                                })}
                            >
                                <h3>Historische gegevens</h3>
                                <LineChart data={presenter.chartValuationDataWithFake} />
                            </div>
                        ) : null}
                    </div>
                </PaymentBlur>
                <div className="row">
                    {presenter.wozValues.length > 0 ? (
                        <div
                            className={classNames('col-12', {
                                'pl-5 pr-5': !forReport,
                            })}
                        >
                            <h3>WOZ-gegevens</h3>
                            <table className="table table-sm table-striped">
                                <thead>
                                    <tr>
                                        <th>Peildatum</th>
                                        <th>WOZ-waarde</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {presenter.wozValues.map((value) => (
                                        <tr key={value.referenceDate.toString()}>
                                            <td>
                                                {format(value.referenceDate, 'dd-MM-yyyy')}
                                                {value.is_estimated ? '*' : null}
                                            </td>
                                            <td>{formatMoney(value.value)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            {hasEstimatedWoz ? <p>* Schatting op basis van indexatie</p> : null}
                        </div>
                    ) : null}
                    {presenter.hasActivatedAvms &&
                    (presenter.valuationOverview.altumTransactions.length > 0 ||
                        presenter.valuationOverview.ortecTransaction !== null) ? (
                        <div
                            className={classNames('col-12', {
                                'pl-5 pr-5': !forReport,
                            })}
                        >
                            <h3>Verkoopgegevens</h3>
                            {presenter.valuationOverview.ortecTransaction !== null ? (
                                <table className="table table-sm table-striped">
                                    <thead>
                                        <tr>
                                            <th>Verkocht op</th>
                                            <th>Originele verkoopprijs</th>
                                            <th>Geïndexeerde verkoopprijs</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                {format(
                                                    presenter.valuationOverview.ortecTransaction.saleDate,
                                                    'dd-MM-yyyy'
                                                )}
                                            </td>
                                            <td>
                                                {formatMoney(
                                                    presenter.valuationOverview.ortecTransaction.purchasePrice
                                                )}
                                            </td>
                                            <td>
                                                {formatMoney(
                                                    presenter.valuationOverview.ortecTransaction.indexedPurchasePrice
                                                )}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            ) : null}
                            {presenter.valuationOverview.altumTransactions.length > 0 ? (
                                <table className="table table-sm table-striped">
                                    <thead>
                                        <tr>
                                            <th>Te koop van</th>
                                            <th>Te koop tot</th>
                                            <th>Laatst bekende vraagprijs</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {presenter.valuationOverview.altumTransactions.map((value) => (
                                            <tr key={value.id}>
                                                <td>{format(value.offeredSinceDate, 'dd-MM-yyyy')}</td>
                                                <td>
                                                    {format(value.saleDate, 'dd-MM-yyyy')} (
                                                    {daysToDuration(value.nrOfDaysOffered)})
                                                </td>
                                                <td>{formatMoney(value.askingPrice)}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ) : null}
                        </div>
                    ) : null}
                    {presenter.valuationLineValuesWithFake.length <= 1 &&
                    presenter.chartValuationDataWithFake?.wozValues.length === 0 &&
                    presenter.chartValuationDataWithFake?.altumTransactions.length === 0 &&
                    presenter.chartValuationDataWithFake?.ortecTransaction === null ? (
                        <div
                            className={classNames('col-12 mt-3 mb-3', {
                                'pl-5 pr-5': !forReport,
                            })}
                        >
                            <div className="empty-message appraise mt-0">
                                <h3>Er zijn geen gegevens gevonden.</h3>
                                <span className="spacer-gradient">&nbsp;</span>
                            </div>
                        </div>
                    ) : null}
                </div>
            </>
        );
    }
);
