import {observer} from 'mobx-react';
import React from 'react';
import {PrecheckWidget} from '../components/widget';
import {ValuationOverview} from './overview';

export const Widget: React.FC = observer(() => {
    const [isLoading, setIsLoading] = React.useState(true);

    return (
        <PrecheckWidget
            id="valuation"
            title="Waarderingen"
            icon="ion-logo-euro"
            description="Bekijk de geschatte waarde van het object samen met historische data."
            loading={isLoading}
            forceRender
        >
            <div className="widget-blocks">
                <ValuationOverview setIsLoading={setIsLoading} />
            </div>
        </PrecheckWidget>
    );
});
