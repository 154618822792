import {findChildRecursiveByPredicate, findChildrenRecursiveByPredicate} from '../../../../support/generic_tree';
import {
    createReferenceObjectData,
    ReferenceObjectData,
} from '../../../appraise/ui/content/questions/advanced/reference_objects_question/v3/internal/create_reference_object_data';
import {IteratorQuestionType, NormalQuestionType} from '../../../enum/question_type';
import {
    ValidationMessage,
    ValidationMessageErrorType,
    ValidationMessageImportance,
    ValidationMessageType,
} from '../validation_message';

import {Answer} from '../../../models/answer';
import {AnswerValidator} from '../answer_validator';
import {Appraisal} from '../../../models/appraisal';
import {Question} from '../../../models/question';
import {QuestionSet} from '../../../models/question_set';
import {ReferenceObjectsQuestionPresenter} from '../../../appraise/ui/content/questions/advanced/reference_objects_question/v3/reference_objects_question_presenter';
import {TechnicalReference} from '../../../enum/technical_reference';
import {V3SetDefinitionsProvider} from '../../../appraise/ui/content/questions/advanced/reference_objects_question/v3/internal/reference_sets/set_definitions_provider';
import {ValuationDateProvider} from '../../valuation_date_provider';

export class ReferenceObjectV3Validator implements AnswerValidator {
    constructor(
        private appraisal: Appraisal,
        private questionSet: QuestionSet,
        private setDefinitionsProvider: V3SetDefinitionsProvider,
        private valuationDateProvider: ValuationDateProvider
    ) {}

    public validate(question: Question, answer: Answer | null): ValidationMessage[] {
        if (question.type !== NormalQuestionType.REFERENCE_OBJECTS_V3) {
            return [];
        }

        const valuationGroupQuestion = this.questionSet.findQuestionByTechnicalReference(
            TechnicalReference.VALUATION_GROUP
        );
        if (valuationGroupQuestion === null) {
            return [];
        }
        const result = this.setDefinitionsProvider.getSetDefinitions(valuationGroupQuestion, true);
        if (result === null) {
            return [];
        }

        const messages: string[] = [];

        const appraiserExplanationByAddress: {[address: string]: string[]} = {};

        for (const setDefinition of result.setDefinitions) {
            const treeItems = findChildrenRecursiveByPredicate(
                setDefinition.groupTree,
                (item) => item.question.type === IteratorQuestionType.ITERATOR_REFERENCE_OBJECTS_V3
            );

            const referenceObjects = treeItems
                .map((treeItem) => {
                    const item = findChildRecursiveByPredicate(
                        treeItem,
                        (item) =>
                            item.question.technicalReference === TechnicalReference.REFERENCE_OBJECTS_V3_ITERATOR_GROUP
                    );

                    if (!item) {
                        return null;
                    }

                    return createReferenceObjectData(item);
                })
                .filter((item): item is ReferenceObjectData => item !== null);

            if (referenceObjects.length < ReferenceObjectsQuestionPresenter.minReferenceObjects) {
                const missing = ReferenceObjectsQuestionPresenter.minReferenceObjects - referenceObjects.length;
                messages.push(
                    `Er ${missing === 1 ? 'mist' : 'missen'} nog ${missing} ${
                        missing === 1 ? 'referentie' : 'referenties'
                    } in de referentievergelijker "${setDefinition.name} - ${setDefinition.description}".`
                );
            }

            for (const referenceObject of referenceObjects) {
                const {adres: address} = referenceObject.referenceObjectAnswer.referenceObject;
                const data = referenceObject.referenceObjectValues;
                const key = `${address.postcode}-${address.huisnummer}-${address.huisnummerToevoeging}`;

                if (
                    data.transportdatum &&
                    this.valuationDateProvider.date &&
                    Date.parse(data.transportdatum) > this.valuationDateProvider.date.getTime()
                ) {
                    messages.push(
                        `De transportdatum van ${address.straat} ${
                            address.huisnummer +
                            (address.huisnummerToevoeging ? '-' + address.huisnummerToevoeging : '')
                        } ${address.plaats} ligt na de opnamedatum.`
                    );
                }

                appraiserExplanationByAddress[key] ??= [];
                if (data.toelichtingGebruikReferentieObject !== null) {
                    if (appraiserExplanationByAddress[key].includes(data.toelichtingGebruikReferentieObject)) {
                        messages.push(
                            `Je hebt meerdere keren dezelfde toelichting gebruikt voor de referentie ${
                                address.straat
                            } ${
                                address.huisnummer +
                                (address.huisnummerToevoeging ? '-' + address.huisnummerToevoeging : '')
                            } ${address.plaats}.`
                        );
                    } else {
                        appraiserExplanationByAddress[key].push(data.toelichtingGebruikReferentieObject);
                    }
                }
            }
        }

        return messages.length > 0
            ? [
                  {
                      type: ValidationMessageType.TAXAPI,
                      importance: ValidationMessageImportance.ERROR,
                      question: question,
                      answer: answer,
                      messages,
                      fallbackMessage: null,
                      floorInfo: null,
                      errorType: ValidationMessageErrorType.REFERENCES_INVALID,
                  },
              ]
            : [];
    }
}
