import {computed, makeObservable, observable, runInAction} from 'mobx';
import {AppraisalProvider} from '../../../appraising/business/appraisal_provider';
import {Appraisal} from '../../../appraising/models/appraisal';
import {ValuationOverview, WozValue} from '../../../appraising/models/valuation_overview';
import {AppraisalApi} from '../../../appraising/network/appraisal_api';
import {CompositeSubscription} from '../../../support/composite_subscription';
import {Presenter} from '../../../support/presenter/presenter';
import {
    ApiValuationOverview,
    apiValuationOverviewToValuationOverview,
} from '../../../appraising/network/models/api_valuation_overview';
import {AppraisalCostType} from '../../preferences/appraisal_costs';
import {ValuationObject, ValuationSource} from './overview';
import moment from 'moment';

//This is only available during report rendering. Otherwise we need to fetch from API
declare let GLOBAL: {
    precheckValuationOverview?: ApiValuationOverview;
};

export class WidgetPresenter implements Presenter {
    @observable.ref public appraisal?: Appraisal;
    @observable.ref public valuationOverview?: ValuationOverview;
    @observable private forceActivedValuation = false;

    private subscriptions = new CompositeSubscription();

    constructor(private appraisalProvider: AppraisalProvider, private appraisalApi: AppraisalApi) {
        makeObservable(this);
    }

    public mount(): void {
        this.subscriptions.add(
            this.appraisalProvider.stream.subscribe((appraisal) => {
                runInAction(() => {
                    this.appraisal = appraisal;

                    if (GLOBAL.precheckValuationOverview) {
                        runInAction(() => {
                            this.valuationOverview = apiValuationOverviewToValuationOverview(
                                GLOBAL.precheckValuationOverview as ApiValuationOverview
                            );
                        });
                    } else {
                        this.requestValuation();
                    }
                });
            })
        );
    }

    public unmount(): void {
        this.subscriptions.clear();
    }

    @computed
    public get hasActivatedAvms(): boolean {
        return (
            this.forceActivedValuation ||
            (this.appraisal !== undefined &&
                this.appraisal.activatedOptionalCosts !== null &&
                this.appraisal.activatedOptionalCosts.includes(AppraisalCostType.APPRAISAL_AVMS))
        );
    }

    @computed
    public get wozValues(): WozValue[] {
        if (!this.valuationOverview) {
            return [];
        }

        const woz = [...(this.valuationOverview.wozValues ?? [])];
        woz.sort((a, b) => (a.referenceDate < b.referenceDate ? 1 : -1));
        return woz;
    }

    public requestValuation(withPaidAvms = this.hasActivatedAvms): void {
        if (withPaidAvms && !this.hasActivatedAvms) {
            this.forceActivedValuation = true;
        }

        if (!this.appraisal) {
            return;
        }

        runInAction(() => {
            this.valuationOverview = undefined;
        });

        this.appraisalApi
            .getValuationOverview(this.appraisal.id, withPaidAvms)
            .then((valuationOverview) => {
                runInAction(() => {
                    this.valuationOverview = valuationOverview;
                });
            })
            .catch((e) => {
                console.error(e);
            });
    }

    @computed
    public get valuationLineValuesWithFake() {
        if (!this.hasActivatedAvms) {
            // Return fake values for blur
            return [
                {
                    source: ValuationSource.WOZ,
                    value: 200000,
                },
                {
                    source: ValuationSource.ALTUM,
                    value: 250000,
                },
                {
                    source: ValuationSource.MATRIXIAN,
                    value: 220000,
                },
                {
                    source: ValuationSource.ORTEC,
                    value: 300000,
                },
            ];
        }
        const values: ValuationObject[] = [];

        if (!this.valuationOverview) {
            return values;
        }

        if (this.valuationOverview.wozValues && this.valuationOverview.wozValues.length > 0) {
            const sorted = this.valuationOverview.wozValues.slice().sort((a, b) => {
                return a.referenceDate < b.referenceDate ? 1 : -1;
            });
            values.push({source: ValuationSource.WOZ, value: sorted[0].value});
        }

        if (this.valuationOverview.altumAvm) {
            values.push({
                source: ValuationSource.ALTUM,
                value: this.valuationOverview.altumAvm.priceEstimation,
            });
        }
        if (this.valuationOverview.matrixianAvm) {
            values.push({
                source: ValuationSource.MATRIXIAN,
                value: this.valuationOverview.matrixianAvm.priceEstimation,
            });
        }

        if (this.valuationOverview.ortecAvm) {
            values.push({
                source: ValuationSource.ORTEC_AVM,
                value: this.valuationOverview.ortecAvm.priceEstimation,
            });
        }

        if (this.valuationOverview.ortecTransaction) {
            values.push({
                source: ValuationSource.ORTEC,
                value: this.valuationOverview.ortecTransaction.indexedPurchasePrice,
            });
        }

        return values;
    }

    @computed
    public get chartValuationDataWithFake(): ValuationOverview | undefined {
        if (!this.valuationOverview) {
            return undefined;
        }

        if (this.hasActivatedAvms) {
            return this.valuationOverview;
        }

        const data = {
            ...this.valuationOverview,
        };

        if (data.availableSaleTransactionDate && data.ortecTransaction === null) {
            data.ortecTransaction = {
                id: 'fake',
                purchasePrice: 300000,
                indexedPurchasePrice: 350000,
                saleDate: data.availableSaleTransactionDate,
                indexedPurchasePrices: [],
            };

            // Generate fake indexed purchase prices for each quarter up until now
            let date = moment(data.availableSaleTransactionDate).add(1, 'quarter').startOf('quarter');
            let lastValue = data.ortecTransaction.purchasePrice;
            while (date.isBefore(moment())) {
                lastValue = lastValue * 1.01;

                data.ortecTransaction.indexedPurchasePrices.push({
                    date: date.toDate(),
                    value: lastValue,
                });

                date = date.add(1, 'quarter');
            }
        }

        return data;
    }
}
