import {Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';

import {Answer} from '../../models/answer';
import {AnswerController} from '../answering/answer_controller';
import {GroupType} from '../../enum/group_type';
import {Question} from '../../models/question';
import {QuestionEffectInteractor} from '../conditions/question_effects_interactor';
import {QuestionSet} from '../../models/question_set';
import {TechnicalReference} from '../../enum/technical_reference';
import {isEmpty} from '../../../support/util';
import {normalizeNumber} from './normalize_number';

export interface SurfaceAreaProvider {
    surfaceArea(): Observable<number | null>;
    getForAreaAnswers(answers: Answer[]): number | null;
}

interface QuestionWithAnswer {
    question: Question;
    answer: Answer;
}

interface SurfaceQuestionsGroup {
    group: string;
    questionsWithAnswer: QuestionWithAnswer[];
}

export class DefaultSurfaceAreaProvider implements SurfaceAreaProvider {
    constructor(
        private questionSet: QuestionSet,
        private answerController: AnswerController,
        private questionEffectInteractor: QuestionEffectInteractor
    ) {}

    private observableCache: Observable<number | null> | null = null;

    public surfaceArea(): Observable<number | null> {
        if (this.observableCache !== null) {
            return this.observableCache;
        }

        const bbmiWonenQuestions = this.questionSet.findQuestionsByTechnicalReference(TechnicalReference.BBMI_WONEN);

        const bbmiMeasurementQuestions = this.questionSet.findQuestionsByTechnicalReference(
            TechnicalReference.BBMI_MEET_RAPPORT
        );

        this.observableCache = this.answerController
            .answersForQuestionUuidsStream(
                [...bbmiWonenQuestions, ...bbmiMeasurementQuestions].map((question) => question.uuid)
            )
            .pipe(
                //Filter deleted and hidden answers
                map((unfilteredAnswers) => this.answerController.filterDeleted(unfilteredAnswers)),
                map((unfilteredAnswers) =>
                    unfilteredAnswers.filter(
                        (answer) => !this.questionEffectInteractor.isHidden(answer.questionUuid, answer.uuid)
                    )
                ),

                //Find detailed construction cost questions
                switchMap((bbmiAnswers) => {
                    const hasMeasurementReport = this.hasMeasurementReport(bbmiAnswers, bbmiMeasurementQuestions);

                    if (hasMeasurementReport && bbmiAnswers.length > 0 && bbmiAnswers[0].contents) {
                        return of(parseFloat(bbmiAnswers[0].contents));
                    }

                    const surfaceQuestions = [
                        ...this.questionSet.findQuestionsByGroupType(GroupType.SURFACE_AREA_ADDITIVE),
                        ...this.questionSet.findQuestionsByGroupType(GroupType.SURFACE_AREA_DEDUCTIVE),
                    ];
                    return this.answerController
                        .answersForQuestionUuidsStream(surfaceQuestions.map((question) => question.uuid))
                        .pipe(map((answers) => this.getForAreaAnswers(answers)));
                })
            );
        return this.observableCache;
    }

    public getForAreaAnswers(answers: Answer[]) {
        const surfaceQuestions = [
            ...this.questionSet.findQuestionsByGroupType(GroupType.SURFACE_AREA_ADDITIVE),
            ...this.questionSet.findQuestionsByGroupType(GroupType.SURFACE_AREA_DEDUCTIVE),
        ];

        const filteredAnswers = this.answerController
            .filterDeleted(answers)
            .filter((a) => !this.questionEffectInteractor.isHidden(a.questionUuid, a.uuid));

        const questionAnswerPairs = filteredAnswers
            .map((answer) => ({
                question: surfaceQuestions.find((q) => q.uuid === answer.questionUuid) as Question,
                answer: answer,
            }))
            .filter((p) => p.question !== undefined);

        const grouped = this.groupQuestionsWithAnswer(questionAnswerPairs);

        let surface = 0.0;

        for (const surfaceQuestionsGroup of grouped) {
            surface += this.mapSurfaceGroup(surfaceQuestionsGroup);
        }

        if (surface === 0) {
            return null;
        }

        return normalizeNumber(surface, 0);
    }

    private hasMeasurementReport(bbmiAnswer: Answer[], bbmiMeasurementQuestions: Question[]): boolean {
        // No answers to check when there are no questions
        if (bbmiMeasurementQuestions.length === 0) {
            return true;
        }
        const answerOptionIds: number[] = bbmiMeasurementQuestions
            .map((q) => {
                return q.answerOptions.find((o) => o.contents.toLowerCase() === 'ja')?.id;
            })
            .filter((id): id is number => id !== undefined);

        return (
            bbmiAnswer
                .filter((answer) => bbmiMeasurementQuestions.map((q) => q.uuid).includes(answer.questionUuid))
                .find((a) => a.contents === '1' || (a.answerOptionId && answerOptionIds.includes(a.answerOptionId))) !==
            undefined
        );
    }

    /**
     * Calculate and round the surface for one group (floor)
     */
    private mapSurfaceGroup(surfaceQuestionsGroup: SurfaceQuestionsGroup): number {
        let surface = 0.0;

        for (const questionWithAnswer of surfaceQuestionsGroup.questionsWithAnswer) {
            if (isEmpty(questionWithAnswer.answer.contents)) {
                continue;
            }

            let jsonObject: {total?: number | null} | null = null;
            const value = parseFloat(questionWithAnswer.answer.contents);
            if (isNaN(value)) {
                try {
                    jsonObject = JSON.parse(questionWithAnswer.answer.contents);
                } catch (e) {
                    console.warn(e);
                }
            }

            if (questionWithAnswer.question.group?.toString().includes(GroupType.SURFACE_AREA_ADDITIVE.toString())) {
                if (
                    jsonObject !== null &&
                    'total' in jsonObject &&
                    jsonObject.total !== undefined &&
                    jsonObject.total !== null
                ) {
                    surface += jsonObject.total;
                } else {
                    surface += Math.abs(parseFloat(questionWithAnswer.answer.contents) || 0);
                }
            }
            if (questionWithAnswer.question.group?.toString().includes(GroupType.SURFACE_AREA_DEDUCTIVE.toString())) {
                if (
                    jsonObject !== null &&
                    'total' in jsonObject &&
                    jsonObject.total !== undefined &&
                    jsonObject.total !== null
                ) {
                    surface -= jsonObject.total;
                } else {
                    surface -= Math.abs(parseFloat(questionWithAnswer.answer.contents) || 0);
                }
            }
        }

        return surface;
    }

    private groupQuestionsWithAnswer(questionsWithAnswer: QuestionWithAnswer[]): SurfaceQuestionsGroup[] {
        const groupedQuestionsWithAnswer: {[reference: string]: SurfaceQuestionsGroup} = {};
        questionsWithAnswer.map((questionWithAnswer: QuestionWithAnswer) => {
            // Try to group by parentUuid
            if (questionWithAnswer.question.parentUuid !== null) {
                if (groupedQuestionsWithAnswer[questionWithAnswer.question.parentUuid] === undefined) {
                    groupedQuestionsWithAnswer[questionWithAnswer.question.parentUuid] = {
                        group: questionWithAnswer.question.parentUuid,
                        questionsWithAnswer: [],
                    };
                }
                groupedQuestionsWithAnswer[questionWithAnswer.question.parentUuid].questionsWithAnswer.push(
                    questionWithAnswer
                );
            } else {
                // If no parentUuid, just take the single answer
                groupedQuestionsWithAnswer[questionWithAnswer.question.uuid].questionsWithAnswer.push(
                    questionWithAnswer
                );
            }
            return groupedQuestionsWithAnswer;
        }, {});

        return Object.keys(groupedQuestionsWithAnswer).map((key) => groupedQuestionsWithAnswer[key]);
    }
}
