import * as React from 'react';
import {RootPresenter} from './root_presenter';
import {observer} from 'mobx-react';
import {usePresenter} from '../../support/presenter/use_presenter';
import {Loader} from '../../appraising/appraise/ui/components/loader';

interface OwnProps {
    children?: React.ReactNode;
}

export const Root: React.FC<OwnProps> = observer(({children}: OwnProps) => {
    const presenter = usePresenter((component) => new RootPresenter(component.business.appraisalProvider));

    if (presenter.loading) {
        return (
            <div className="empty-message">
                <Loader />
                <h3>Aan het laden...</h3>
            </div>
        );
    }

    return <>{children}</>;
});
