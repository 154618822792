export function translateRequiredImageTechnicalReference(technicalReference: TechnicalReference) {
    switch (technicalReference) {
        case TechnicalReference.IMAGE_REQUIRED_ACHTERGEVEL:
            return 'Achtergevel';
        case TechnicalReference.IMAGE_REQUIRED_BADKAMER:
            return 'Badkamer';
        case TechnicalReference.IMAGE_REQUIRED_KEUKEN:
            return 'Keuken';
        case TechnicalReference.IMAGE_REQUIRED_METERKAST:
            return 'Meterkast';
        case TechnicalReference.IMAGE_REQUIRED_SLAAPKAMER:
            return 'Slaapkamer';
        case TechnicalReference.IMAGE_REQUIRED_STRAATBEELD:
            return 'Straatbeeld';
        case TechnicalReference.IMAGE_REQUIRED_TOILET:
            return 'Toilet';
        case TechnicalReference.IMAGE_REQUIRED_VOORAANZICHT:
            return 'Vooraanzicht';
        case TechnicalReference.IMAGE_REQUIRED_WOONKAMER:
            return 'Woonkamer';
        case TechnicalReference.IMAGE_REQUIRED_CV:
            return 'CV ketel';
        case TechnicalReference.IMAGE_REQUIRED_BALKON:
            return 'Balkon';
        case TechnicalReference.IMAGE_REQUIRED_VERWARMING:
            return 'Verwarmingsinstallatie';
        case TechnicalReference.IMAGE_REQUIRED_BERGRUIMTE:
            return 'Bergruimte in-/aanpandig of vrijstaand';
        case TechnicalReference.IMAGE_REQUIRED_GEB_BUITENRUIMTE:
            return 'Gebouwgebonden buitenruimte';
        case TechnicalReference.IMAGE_REQUIRED_TUIN:
            return 'Tuin';

        default:
            return technicalReference;
    }
}

export enum TechnicalReference {
    VALUATION = 'valuation',

    VALUATION_GROUP = 'valuation-group',

    VALUATION_MARKET_VALUE_GROUP = 'valuation-market-value-group',
    VALUATION_MARKET_VALUE_AFTER_CONSTRUCTION_GROUP = 'valuation-market-value-after-construction-group',
    VALUATION_MARKET_VALUE_AFTER_REALISATION_GROUP = 'valuation-market-value-after-realisation-group',
    VALUATION_MARKET_VALUE_FORCED_SALE_GROUP = 'valuation-market-value-forced-sale-group',
    VALUATION_SPECIAL_VALUE_FORCED_SALE_GROUP = 'valuation-special-value-forced-sale-group',
    VALUATION_FORCED_SALE_GROUP = 'valuation-forced-sale-group',

    PAGE_PART_CONCEPT_REPORT = 'page-part-concept-report',

    SYMLINK_NAME = 'symlink-name',
    SYMLINK_VVE = 'symlink-vve',
    SYMLINK_BUILDING_COSTS = 'symlink-building-costs',
    SYMLINK_AANDACHTSPUNT = 'symlink-aandachtspunt',
    SYMLINK_ERFPACHT = 'symlink-erfpacht',
    SYMLINK_GEBRUIK_BEWONING = 'symlink-gebruik-bewoning',
    SYMLINK_REFERENTIES = 'symlink-referenties',
    SYMLINK_OPINIE = 'symlink-opinie',
    SYMLINK_RISK_BUILDING_COSTS = 'symlink-risk-building-costs',

    VALUATION_SPECIAL_EXPLANATION_MC = 'valuation-special-explanation-mc',
    VALUATION_SPECIAL_EXPLANATION_OPEN = 'valuation-special-explanation-open',
    VALUATION_SPECIAL_VALUE_GROUP = 'valuation-special-value-group',
    VALUATION_SPECIAL_VALUE_AFTER_CONSTRUCTION_GROUP = 'valuation-special-value-after-construction-group',
    VALUATION_SPECIAL_VALUE_AFTER_REALISATION_GROUP = 'valuation-special-value-after-realisation-group',

    USER_SETTINGS = 'user-settings',
    PRICE_DATE = 'price-date',
    OBJECT_BUILD_YEAR = 'object-build-year',
    OBJECT_BUILD_YEAR_DESTRUCTION = 'object-build-year-destruction',
    HAS_TO_BE_DESTRUCTED_OBJECT = 'has-to-be-destructed-object',

    OBJECT_LIVING_AREA = 'object-living-area',
    OBJECT_WOZ = 'object-woz',
    MODEL_VALUE_DETAILS = 'model-value-details',
    REFERENCE_OBJECTS = 'reference-objects',
    REFERENCE_OBJECTS_GROUP = 'reference-objects-group',
    REFERENCE_OBJECTS_METADATA = 'reference-objects-metadata',
    REFERENCE_OBJECTS_V3_ITERATOR_GROUP = 'reference-objects-v3-iterator-group',
    OBJECT_VOLUME = 'object-volume',
    OBJECT_PLOT_AREA = 'object-plot-area',
    OBJECT_PLOT_AREA_MANDELIG = 'object-plot-area-mandelig',
    ENERGY_LABEL = 'energy-label',
    CLIENT_FILES = 'client-files',
    FILES = 'files',
    EXTRA_FILES_GROUP = 'extra-files-group',
    GENERAL_REMARKS = 'general-remarks',
    OBJECT_ALGEHELE_INDRUK = 'object-algehele-indruk',

    BBMI_MEET_RAPPORT = 'bbmi-meet-rapport',
    BBMI_WONEN = 'bbmi-wonen',
    BBMI_OVERIGE_INPANDIGE_RUIMTE = 'bbmi-overige-inpandige-ruimte',
    BBMI_GEBOUWGEBONDEN_BUITENRUIMTE = 'bbmi-gebouwgebonden-buitenruimte',
    BBMI_EXTERNE_BERGRUIMTE = 'bbmi-externe-bergruimte',

    AREA_GENERIC = 'area-generic',
    AREA_OTHER_SPACE = 'area-other-space',
    AREA_CORRECTION = 'area-correction',
    AREA_EXTERNAL_STORAGE_SPACE = 'area-external-storage-space',
    AREA_USABLE_OUTDOOR = 'area-usable-outdoor',
    AREA_GROUP = 'area-group',

    CADASTRAL_GROUP = 'cadastral-group',
    CADASTRAL_MUNICIPAL = 'cadastral-municipal',
    CADASTRAL_SECTION = 'cadastral-section',
    CADASTRAL_NUMBER = 'cadastral-number',
    CADASTRAL_INDEX = 'cadastral-index',
    CADASTRAL_COMPLEX = 'cadastral-complex',
    CADASTRAL_APPARTEMENTSRECHT = 'cadastral-appartementsrecht',
    CADASTRAL_GRONDGEBONDEN = 'cadastral-grondgebonden',
    CADASTRAL_APPARTEMENTSRECHT_VVE_BIJDRAGE_NODIG = 'cadastral-appartementsrecht-vve-bijdrage-nodig',
    CADASTRAL_APPARTEMENTSRECHT_VVE_BIJDRAGE = 'cadastral-appartementsrecht-vve-bijdrage',

    CONSTRUCTION_TYPE = 'construction-type',
    CONSTRUCTION_WORK = 'construction-work',
    CONSTRUCTION_EXECUTED_BY_CLIENT = 'construction-executed-by-client',
    CONSTRUCTION_REPARATIONS_REQUIRED = 'construction-reparations-required',
    CONSTRUCTION_COSTS = 'construction-costs',

    HAS_PLANNED_CONSTRUCTION = 'planned-construction',
    PLANNED_CONSTRUCTION_ITERATOR = 'planned-construction-iterator',
    HAS_PLANNED_RENOVATION = 'planned-renovation',
    PLANNED_RENOVATION_ITERATOR = 'planned-renovation-iterator',

    PHOTO_LABEL_CONSTRUCTION = 'photo-label-construction',
    PHOTO_ITERATOR_DEFAULT = 'photo-iterator-default',
    PHOTO_ITERATOR_CONSTRUCTION = 'photo-iterator-construction',

    VALUATION_REQUIRES_REFERENCE_OBJECTS = 'valuation-requires-reference-objects',
    SPECIAL_VALUE_ARGUMENT_ITERATOR = 'special-value-argument-iterator',
    SPECIAL_VALUE_ARGUMENT_GROUP = 'special-value-argument-group',
    SPECIAL_VALUE_ARGUMENT_GROUP_RENT = 'special-value-argument-group-rent',
    // @deprecated, use "special-value-argument-group-sale" instead
    SPECIAL_VALUE_ARGUMENT_GROUP_SOLD = 'special-value-argument-group-sold',
    SPECIAL_VALUE_ARGUMENT_GROUP_SALE = 'special-value-argument-group-sale',
    SPECIAL_VALUE_ARGUMENT_GROUP_MARKET = 'special-value-argument-group-market',
    SPECIAL_VALUE_ARGUMENT_PRICE = 'special-value-argument-price',
    SPECIAL_VALUE_ARGUMENT_REQUIRES_REFERENCE_OBJECTS = 'special-value-argument-requires-reference-objects',
    SPECIAL_VALUE_ARGUMENT_UITGANGSPUNT = 'special-value-argument-uitgangspunt',
    SPECIAL_VALUE_ARGUMENT_UITGANGSPUNT_VOOR_VERBOUWING_OF_REALISATIE = 'special-value-argument-uitgangspunt-voor-verbouwing-of-realisatie',
    SPECIAL_VALUE_ARGUMENT_GEBRUIKSOPPERVLAKTE_WONEN = 'special-value-argument-gebruiksoppervlakte-wonen',
    SPECIAL_VALUE_ARGUMENT_GEBOUWGEBONDEN_BUITENRUIMTE = 'special-value-argument-gebouwgebonden-buitenruimte',
    SPECIAL_VALUE_ARGUMENT_OVERIGE_INPANDIGE_RUIMTE = 'special-value-argument-overige-inpandige-ruimte',
    SPECIAL_VALUE_ARGUMENT_EXTERNE_BERGRUIMTE = 'special-value-argument-externe-bergruimte',
    SPECIAL_VALUE_ARGUMENT_PERCEEL_OPPERVLAKTE = 'special-value-argument-perceel-oppervlakte',
    SPECIAL_VALUE_ARGUMENT_BRUTO_INHOUD = 'special-value-argument-bruto-value',
    SPECIAL_VALUE_ARGUMENT_ENERGY_LABEL = 'special-value-argument-energy-label',
    SPECIAL_VALUE_ARGUMENT_BUILD_YEAR = 'special-value-argument-build-year',
    SPECIAL_VALUE_ARGUMENT_BUILDINGS = 'special-value-argument-buildings',
    SPECIAL_VALUE_ARGUMENT_OBJECT_TYPE = 'special-value-argument-object-type',
    MARKET_VALUE_ARGUMENT_GEBRUIKSOPPERVLAKTE_WONEN = 'market-value-argument-gebruiksoppervlakte-wonen',
    MARKET_VALUE_ARGUMENT_GEBOUWGEBONDEN_BUITENRUIMTE = 'market-value-argument-gebouwgebonden-buitenruimte',
    MARKET_VALUE_ARGUMENT_OVERIGE_INPANDIGE_RUIMTE = 'market-value-argument-overige-inpandige-ruimte',
    MARKET_VALUE_ARGUMENT_EXTERNE_BERGRUIMTE = 'market-value-argument-externe-bergruimte',
    MARKET_VALUE_ARGUMENT_PERCEEL_OPPERVLAKTE = 'market-value-argument-perceel-oppervlakte',
    MARKET_VALUE_ARGUMENT_BRUTO_INHOUD = 'market-value-argument-bruto-value',
    MARKET_VALUE_ARGUMENT_ENERGY_LABEL = 'market-value-argument-energy-label',
    MARKET_VALUE_ARGUMENT_BUILD_YEAR = 'market-value-argument-build-year',
    MARKET_VALUE_ARGUMENT_OBJECT_TYPE = 'market-value-argument-object-type',
    HAS_REFERENCE_OBJECTS_VALUATION = 'has-reference-objects-valuation',
    HAS_REFERENCE_OBJECTS_AFTER_CONSTRUCTION = 'has-reference-objects-after-construction',
    HAS_REFERENCE_OBJECTS_AFTER_REALISATION = 'has-reference-objects-after-realisation',
    HAS_REFERENCE_OBJECTS_SPECIAL_VALUE_ARGUMENT = 'has-reference-objects-special-value-argument',
    CONSULTED_KOOPOVEREENKOMST = 'consulted-koopovereenkomst',

    VALUATION_FORCED_SALE_FILTERS = 'valuation-forced-sale-filters',
    VALUATION_FORCED_SALE_FILTER_ADDRESS = 'valuation-forced-sale-filter-address',
    VALUATION_FORCED_SALE_FILTER_OBJECT_TYPE = 'valuation-forced-sale-filter-object-type',
    VALUATION_FORCED_SALE_FILTER_RANGES = 'valuation-forced-sale-filter-ranges',

    KITCHEN_LAYOUT = 'indeling-keuken',
    KITCHEN_STYLE = 'indeling-keuken-stijl',
    KITCHEN_EQUIPMENT = 'indeling-keuken-apparatuur',
    FLOOR_TYPE = 'verdieping-type',
    FLOOR_NUMBER = 'verdieping-nummer',
    FLOOR_LAYOUT_PARENT = 'verdieping-type-parent',
    FLOOR_BOUWDELEN_PARENT = 'verdieping-bouwdelen-parent',

    // Afbeeldingen
    VISUELE_OBJECT_REPRESENTATIE = 'visuele-object-presentaties',
    VISUELE_OBJECT_REPRESENTATIE_VOORAANZICHT = 'visuele-object-presentaties-vooraanzicht',

    // Objecten met verplichte afbeeldingen
    IMAGE_REQUIRED_ACHTERGEVEL = 'image-required-achtergevel',
    IMAGE_REQUIRED_BADKAMER = 'image-required-badkamer',
    IMAGE_REQUIRED_KEUKEN = 'image-required-keuken',
    IMAGE_REQUIRED_METERKAST = 'image-required-meterkast',
    IMAGE_REQUIRED_SLAAPKAMER = 'image-required-slaapkamer',
    IMAGE_REQUIRED_STRAATBEELD = 'image-required-straatbeeld',
    IMAGE_REQUIRED_TOILET = 'image-required-toilet',
    IMAGE_REQUIRED_VOORAANZICHT = 'image-required-vooraanzicht',
    IMAGE_REQUIRED_WOONKAMER = 'image-required-woonkamer',
    IMAGE_REQUIRED_CV = 'image-required-cv',
    IMAGE_REQUIRED_BALKON = 'image-required-balkon',
    IMAGE_REQUIRED_VERWARMING = 'image-required-verwarming',
    IMAGE_REQUIRED_BERGRUIMTE = 'image-required-bergruimte',
    IMAGE_REQUIRED_GEB_BUITENRUIMTE = 'image-required-geb-buitenruimte',
    IMAGE_REQUIRED_TUIN = 'image-required-tuin',

    //Required for homes
    ATTACHMENT_KADASTRAAL_UITTREKSEL = 'attachment-kadastraal-uittreksel',
    ATTACHMENT_KADASTRALE_KAART = 'attachment-kadastrale-kaart',
    ATTACHMENT_AKTE_VAN_LEVERING = 'attachment-akte-van-levering',
    ATTACHMENT_KOOPOVEREENKOMT = 'attachment-koopovereenkomst',
    ATTACHMENT_BESTEMMINGSPLANKAART = 'attachment-bestemmingsplankaart',
    ATTACHMENT_BODEMINFORMATIE = 'attachment-bodeminformatie',
    ATTACHMENT_FUNDERINGSVIEWER = 'attachment-funderingsviewer',

    //Optional attachments
    ATTACHMENT_ERFDIENSTBAARHEDEN = 'attachment-erfdienstbaarheden',
    ATTACHMENT_ERFPACHT = 'attachment-erfpacht',
    ATTACHMENT_SCHEIDING_EN_DELING = 'attachment-scheiding-en-deling',
    ATTACHMENT_PROCES_VERBAAL_VEILING = 'attachment-proces-verbaal-veiling',
    ATTACHMENT_HUUROVEREENKOMST = 'attachment-huurovereenkomst',

    //Required for appartments
    ATTACHMENT_SPLITSING = 'attachment-splitsing',
    ATTACHMENT_KVK_VVE = 'attachment-kvk-vve',
    ATTACHMENT_VVE_BALANS = 'attachment-vve-balans',
    ATTACHMENT_VVE_WINST_VERLIES_REKENING = 'attachment-vve-winst-verlies-rekening',
    ATTACHMENT_VVE_ONDERHOUDSBEGROTING = 'attachment-vve-onderhoudsbegroting',
    ATTACHMENT_VVE_NOTULEN = 'attachment-vve-notulen',
    ATTACHMENT_VVE_OPSTALVERZEKERING = 'attachment-vve-opstalverzekering',
    ATTACHMENT_OVERDRACHT_LIDMAATSCHAPSRECHT = 'attachment-overdracht-lidmaatschapsrecht',

    //Other
    ATTACHMENT_SPLITSINGSTEKENING = 'attachment-splitsings-rekening',
    ATTACHMENT_PARKREGLEMENT = 'attachment-parkreglement',
    ATTACHMENT_SUSTAINABILITY_REPORT = 'attachment-sustainability-report',
    ATTACHMENT_TRANSITIEVISIE_WARMTE = 'attachment-transitievisie-warmte',

    //Attachment options
    ATTACHMENT_IN_REPORT = 'attachment-in-report',

    //Vernieuwen verbeteren
    VERNIEUWEN_VERBETEREN_BADKAMER = 'vernieuwen-verbeteren-badkamer',
    VERNIEUWEN_VERBETEREN_KEUKEN = 'vernieuwen-verbeteren-keuken',
    VERNIEUWEN_VERBETEREN_TOILET = 'vernieuwen-verbeteren-toilet',
    VERNIEUWEN_VERBETEREN_VLOERENWANDENPLAFONDS = 'vernieuwen-verbeteren-vloerenwandenplafonds',
    VERNIEUWEN_VERBETEREN_SCHILDERWERKINWENDIG = 'vernieuwen-verbeteren-schilderwerkinwendig',
    VERNIEUWEN_VERBETEREN_SCHILDERWERKUITWENDIG = 'vernieuwen-verbeteren-schilderwerkuitwendig',
    VERNIEUWEN_VERBETEREN_CV = 'vernieuwen-verbeteren-cv',
    VERNIEUWEN_VERBETEREN_BUITENZONWERING = 'vernieuwen-verbeteren-buitenzonwering',
    VERNIEUWEN_VERBETEREN_ELECTRISCHEINSTALLATIE = 'vernieuwen-verbeteren-electrischeinstallatie',
    VERNIEUWEN_VERBETEREN_DAKDAKGOTEN = 'vernieuwen-verbeteren-dakdakgoten',
    VERNIEUWEN_VERBETEREN_REINIGENGEVEL = 'vernieuwen-verbeteren-reinigengevel',
    VERNIEUWEN_VERBETEREN_STUCWERK = 'vernieuwen-verbeteren-stucwerk',
    VERNIEUWEN_VERBETEREN_TUINOPRIT = 'vernieuwen-verbeteren-tuinoprit',
    VERNIEUWEN_VERBETEREN_OVERIG = 'vernieuwen-verbeteren-overig',

    //Bouwkundige aanpassing uitbreiding
    BOUWKUNDIGE_AANPASSING_UITBREIDING_DAKKAPEL = 'bouwkundige-aanpassing-uitbreiding-dakkapel',
    BOUWKUNDIGE_AANPASSING_UITBREIDING_GARAGE = 'bouwkundige-aanpassing-uitbreiding-garage',
    BOUWKUNDIGE_AANPASSING_UITBREIDING_UITBOUW = 'bouwkundige-aanpassing-uitbreiding-uitbouw',
    BOUWKUNDIGE_AANPASSING_UITBREIDING_SERRE = 'bouwkundige-aanpassing-uitbreiding-serre',
    BOUWKUNDIGE_AANPASSING_UITBREIDING_CARPORT = 'bouwkundige-aanpassing-uitbreiding-carport',
    BOUWKUNDIGE_AANPASSING_UITBREIDING_BERGINGSCHUUR = 'bouwkundige-aanpassing-uitbreiding-bergingschuur',
    BOUWKUNDIGE_AANPASSING_UITBREIDING_DAKOPBOUW = 'bouwkundige-aanpassing-uitbreiding-dakopbouw',
    BOUWKUNDIGE_AANPASSING_UITBREIDING_GEVELRENOVATIE = 'bouwkundige-aanpassing-uitbreiding-gevelrenovatie',
    BOUWKUNDIGE_AANPASSING_UITBREIDING_FUNDERING = 'bouwkundige-aanpassing-uitbreiding-fundering',
    BOUWKUNDIGE_AANPASSING_UITBREIDING_OVERIG = 'bouwkundige-aanpassing-uitbreiding-overig',

    //Energiebesparende voorzieningen
    ENERGIEBESPARENDE_VOORZIENINGEN_HOOGRENDEMENTSKETEL = 'energiebesparende-voorzieningen-hoogrendementsketel',
    ENERGIEBESPARENDE_VOORZIENINGEN_GEVELISOLATIE = 'energiebesparende-voorzieningen-gevelisolatie',
    ENERGIEBESPARENDE_VOORZIENINGEN_DAKISOLATIE = 'energiebesparende-voorzieningen-dakisolatie',
    ENERGIEBESPARENDE_VOORZIENINGEN_LEIDINGISOLATIE = 'energiebesparende-voorzieningen-leidingisolatie',
    ENERGIEBESPARENDE_VOORZIENINGEN_VLOERISOLATIE = 'energiebesparende-voorzieningen-vloerisolatie',
    ENERGIEBESPARENDE_VOORZIENINGEN_HOOGRENDEMENTSBEGLAZING = 'energiebesparende-voorzieningen-hoogrendementsbeglazing',
    ENERGIEBESPARENDE_VOORZIENINGEN_ENERGIEZUINIGECONSTRUCTIEONDERDELEN = 'energiebesparende-voorzieningen-energiezuinigeconstructieonderdelen',
    ENERGIEBESPARENDE_VOORZIENINGEN_ENERGIEZUINIGEVENTILATIE = 'energiebesparende-voorzieningen-energiezuinigeventilatie',
    ENERGIEBESPARENDE_VOORZIENINGEN_WARMTEPOMP = 'energiebesparende-voorzieningen-warmtepomp',
    ENERGIEBESPARENDE_VOORZIENINGEN_WARMTETERUGWINNING = 'energiebesparende-voorzieningen-warmteterugwinning',
    ENERGIEBESPARENDE_VOORZIENINGEN_ZONNEBOILER = 'energiebesparende-voorzieningen-zonneboiler',
    ENERGIEBESPARENDE_VOORZIENINGEN_ZONNECELLEN = 'energiebesparende-voorzieningen-zonnecellen',
    ENERGIEBESPARENDE_VOORZIENINGEN_OVERIG = 'energiebesparende-voorzieningen-overig',

    //Adapted values
    // @deprecated, use "object-omschrijving-bijgebouw" instead
    OBJECT_BIJGEBOUW = 'object-bijgebouw',
    OBJECT_LIGGING = 'object-ligging',
    OBJECT_ONDERHOUDSITUATIE = 'object-onderhoudsituatie',
    OBJECT_ONDERHOUDSITUATIE_TOELICHTING = 'object-onderhoudsituatie-toelichting',
    OBJECT_KWALITEIT_EN_CONTITIE = 'object-kwaliteit-en-conditie',
    OBJECT_LUXE_EN_DOELMATIGHEID = 'object-luxe-en-doelmatigheid',
    OBJECT_OMSCHRIJVING = 'object-omschrijving',
    OBJECT_OMSCHRIJVING_BIJGEBOUW = 'object-omschrijving-bijgebouw',
    OBJECT_OMSCHRIJVING_BIJGEBOUW_DAKKAPELLEN = 'object-omschrijving-bijgebouw-dakkapellen',
    OBJECT_OMSCHRIJVING_BIJGEBOUW_EXCLUDED = 'object-omschrijving-bijgebouw-excluded',
    OBJECT_OMSCHRIJVING_AANBOUW = 'object-omschrijving-aanbouw',
    OBJECT_OMSCHRIJING_PARKEERPLAATS = 'object-omschrijving-parkeerplaats',
    OBJECT_OMSCHRIJVING_AANBOUW_ANDERS = 'object-omschrijving-aanbouw-anders',
    OBJECT_OMSCHRIJVING_BIJGEBOUW_ANDERS = 'object-omschrijving-bijgebouw-anders',
    OBJECT_LOCATIE = 'object-locatie',
    OBJECT_OMGEVINGSFACTOREN_WAARDEONTWIKKELING = 'object-omgevingsfactoren-waardeontwikkeling',
    ADAPTED_VALUE_BIJGEBOUWEN = 'adapted-value-bijgebouwen',
    ADAPTED_VALUE_BIJGEBOUWEN_ANDERS = 'adapted-value-bijgebouwen-anders',
    ADAPTED_VALUE_LIGGING = 'adapted-value-ligging',
    ADAPTED_VALUE_ONDERHOUDSITUATIE = 'adapted-value-onderhoudsituatie',
    ADAPTED_VALUE_ONDERHOUDSITUATIE_TOELICHTING = 'adapted-value-onderhoudsituatie-toelichting',
    ADAPTED_VALUE_LUXE_EN_DOELMATIGHEID = 'adapted-value-luxe-en-doelmatigheid',
    ADAPTED_VALUE_KWALITEIT_EN_CONTITIE = 'adapted-value-kwaliteit-en-conditie',
    ADAPTED_VALUE_GETAXEERDE_OBJECT_TOELICHTING_TAXATEUR = 'adapted-value-getaxeerde-object-toelichting-taxateur',
    ADAPTED_VALUE_GETAXEERDE_OBJECT_AANBOUW_TOELICHTING_TAXATEUR = 'adapted-value-getaxeerde-object-aanbouw-toelichting-taxateur',

    // Building costs
    BUILDING_COSTS_GROUP_TYPE = 'building-costs-group-type',
    BUILDING_COSTS_GROUP_RISK = 'building-costs-group-risk',
    BUILDING_COSTS_GROUP_IMAGE = 'building-costs-group-image',
    BUILDING_COSTS_GROUP_OTHER_OWNERSHIP = 'building-costs-group-other-ownership',
    BUILDING_COSTS_GROUP_OWNERSHIP = 'building-costs-group-ownership',

    BUILDING_INSPECTION_REQUIRED = 'building-inspection-required',
    BUILDING_INSPECTION_APPOINTMENT = 'building-inspection-appointment',
    BUILDING_INSPECTION_APPOINTMENT_ATTACHMENTS = 'building-inspection-appointment-attachments',
    BUILDING_INSPECTION_APPOINTMENT_ATTACHMENT = 'building-inspection-appointment-attachment',
    BUILDING_INSPECTION_APPOINTMENT_PHOTOS = 'building-inspection-appointment-photos',

    // Photo viewer references
    PHOTO_KITCHEN = 'photo-keuken',
    PHOTO_BATHROOM = 'photo-badkamer',
    PHOTO_FUSE_BOX = 'photo-meterkast',

    // Referentie objecten
    REFERENCE_OBJECT_PHOTO = 'reference-object-photo',
    REFERENCE_OBJECT_ADRES = 'reference-object-adres',
    REFERENCE_OBJECT_BOUWJAAR = 'reference-object-bouwjaar',
    REFERENCE_OBJECT_BOUWJAAR_UITLEG = 'reference-object-bouwjaar-uitleg',
    REFERENCE_OBJECT_ENERGIELABEL = 'reference-object-energielabel',
    REFERENCE_OBJECT_ENERGIELABEL_GELDIG_TOT = 'reference-object-energielabel-geldig-tot',
    REFERENCE_OBJECT_ENERGIELABEL_VOORLOPIG = 'reference-object-energielabel-voorlopig',
    REFERENCE_OBJECT_WONING_TYPE = 'reference-object-woning-type',
    REFERENCE_OBJECT_BRON_GEGEVENS = 'reference-object-bron-gegevens',
    REFERENCE_OBJECT_BRON_GEGEVENS_TOELICHTING = 'reference-object-bron-gegevens-toelichting',
    REFERENCE_OBJECT_GEBRUIKS_OPPERVLAKTE = 'reference-object-gebruiks-oppervlakte',
    REFERENCE_OBJECT_GEBRUIKS_OPPERVLAKTE_UITLEG = 'reference-object-gebruiks-oppervlakte-uitleg',
    REFERENCE_OBJECT_INHOUD = 'reference-object-inhoud',
    REFERENCE_OBJECT_INHOUD_UITLEG = 'reference-object-inhoud-uitleg',
    REFERENCE_OBJECT_PERCEEL_OPPERVLAKTE = 'reference-object-perceel-oppervlakte',
    REFERENCE_OBJECT_PERCEEL_OPPERVLAKTE_UITLEG = 'reference-object-perceel-oppervlakte-uitleg',
    REFERENCE_OBJECT_AANVANGS_HUURPRIJS_PER_MAAND = 'reference-object-aanvangs-huurprijs-per-maand',
    REFERENCE_OBJECT_GECORRIGEERDE_HUURPRIJS_PER_MAAND = 'reference-object-gecorrigeerde-huurprijs-per-maand',
    REFERENCE_OBJECT_INGANGSDATUM_HUUR = 'reference-object-ingangsdatum-huur',
    REFERENCE_OBJECT_VERKOOPPRIJS = 'reference-object-verkoopprijs',
    REFERENCE_OBJECT_GECORRIGEERDE_VERKOOPPRIJS = 'reference-object-gecorrigeerde-verkoopprijs',
    REFERENCE_OBJECT_VERKOOPDATUM = 'reference-object-verkoopdatum',
    REFERENCE_OBJECT_TRANSPORTDATUM = 'reference-object-transportdatum',
    REFERENCE_OBJECT_VERKOCHT_BINNEN = 'reference-object-verkocht-binnen',
    REFERENCE_OBJECT_EIGENDOMSSITUATIE_VOLLE_EIGENDOM = 'reference-object-eigendomssituatie-volle-eigendom',
    REFERENCE_OBJECT_EIGENDOMSSITUATIE_RECHT_ERFPACHT = 'reference-object-eigendomssituatie-recht-erfpacht',
    REFERENCE_OBJECT_EIGENDOMSSITUATIE_RECHT_ONDERERFPACHT = 'reference-object-eigendomssituatie-recht-ondererfpacht',
    REFERENCE_OBJECT_EIGENDOMSSITUATIE_RECHT_OPSTAL = 'reference-object-eigendomssituatie-recht-opstal',
    REFERENCE_OBJECT_EIGENDOMSSITUATIE_RECHT_GEBRUIK_BEWONING = 'reference-object-eigendomssituatie-recht-gebruik-bewoning',
    REFERENCE_OBJECT_EIGENDOMSSITUATIE_RECHT_VRUCHTGEBRUIK = 'reference-object-eigendomssituatie-recht-vruchtgebruik',
    REFERENCE_OBJECT_EIGENDOMSSITUATIE_RECHT_ANDERS = 'reference-object-eigendomssituatie-recht-anders',
    REFERENCE_OBJECT_EIGENDOMSSITUATIE_TOELICHTING = 'reference-object-eigendomssituatie-toelichting',
    REFERENCE_OBJECT_EIGENDOMSSITUATIE_STATUS = 'reference-object-eigendomssituatie-status',
    REFERENCE_OBJECT_EIGENDOMSSITUATIE_UITLEG = 'reference-object-eigendomssituatie-uitleg',
    REFERENCE_OBJECT_ONDERHOUDSSITUATIE = 'reference-object-onderhoudssituatie',
    REFERENCE_OBJECT_ONDERHOUDS_SITUATIE_STATUS = 'reference-object-onderhouds-situatie-status',
    REFERENCE_OBJECT_ONDERHOUDS_SITUATIE_UITLEG = 'reference-object-onderhouds-situatie-uitleg',
    REFERENCE_OBJECT_WONING_TYPE_STATUS = 'reference-object-woning-type-status',
    REFERENCE_OBJECT_WONING_TYPE_UITLEG = 'reference-object-woning-type-uitleg',
    REFERENCE_OBJECT_MATE_VAN_LUXE_STATUS = 'reference-object-mate-van-luxe-status',
    REFERENCE_OBJECT_MATE_VAN_LUXE_UITLEG = 'reference-object-mate-van-luxe-uitleg',
    REFERENCE_OBJECT_MATE_VAN_DOELMATIGHEID_STATUS = 'reference-object-mate-van-doelmatigheid-status',
    REFERENCE_OBJECT_MATE_VAN_DOELMATIGHEID_UITLEG = 'reference-object-mate-van-doelmatigheid-uitleg',
    REFERENCE_OBJECT_LIGGING_STATUS = 'reference-object-ligging-status',
    REFERENCE_OBJECT_LIGGING_UITLEG = 'reference-object-ligging-uitleg',
    REFERENCE_OBJECT_AANBOUW = 'reference-object-aanbouw',
    REFERENCE_OBJECT_AANBOUW_CHOICE = 'reference-object-aanbouw-choice',
    REFERENCE_OBJECT_AANBOUW_STATUS = 'reference-object-aanbouw-status',
    REFERENCE_OBJECT_AANBOUW_UITLEG = 'reference-object-aanbouw-uitleg',
    REFERENCE_OBJECT_ANDERE_WEZENLIJKE_VERSCHILLEN = 'reference-object-andere-wezenlijke-verschillen',
    REFERENCE_OBJECT_ANDERE_WEZENLIJKE_VERSCHILLEN_UITLEG = 'reference-object-andere-wezenlijke-verschillen-uitleg',
    REFERENCE_OBJECT_TOELICHTING_GEBRUIK_REFERENTIE_OBJECT = 'reference-object-toelichting-gebruik-referentie-object',
    REFERENCE_OBJECT_OBJECT_DETAILS_GROUP = 'reference-object-object-details-group',
    REFERENCE_OBJECT_VERKOOP_DETAILS_GROUP = 'reference-object-verkoop-details-group',
    REFERENCE_OBJECT_VERHUUR_DETAILS_GROUP = 'reference-object-verhuur-details-group',
    REFERENCE_OBJECT_VERSCHILLEN_GROUP = 'reference-object-verschillen-group',

    // Referentie objecten te koop
    REFERENCE_OBJECT_TE_KOOP_VRAAGPRIJS = 'reference-object-te-koop-vraagprijs',
    REFERENCE_OBJECT_TE_KOOP_OORSPRONKELIJKE_VRAAGPRIJS = 'reference-object-te-koop-oorspronkelijke-vraagprijs',
    REFERENCE_OBJECT_TE_KOOP_DATUM = 'reference-object-te-koop-datum',

    // Energetic
    ENERGETIC_SOLAR_INPUT_METHOD = 'energetic-solar-input-method',
    ENERGETIC_SOLAR_AMOUNT = 'energetic-solar-amount',
    ENERGETIC_SOLAR_ORIENTATION = 'energetic-solar-orientation',
    ENERGETIC_HEAT_RECOVERING_SHOWER = 'energetic-heat-recovering-shower',
    ENERGETIC_VENTILATION = 'energetic-ventilation',
    ENERGETIC_INSTALLATION = 'energetic-installation',
    ENERGETIC_GLASS = 'energetic-glass',
    ENERGETIC_FLOOR_INSULATION = 'energetic-floor-insulation',
    ENERGETIC_WALL_INSULATION = 'energetic-wall-insulation',
    ENERGETIC_WALL_PANELS = 'energetic-wall-panels',
    ENERGETIC_FLAT_ROOF_INSULATION = 'energetic-flat-roof-insulation',
    ENERGETIC_ANGLED_ROOF_INSULATION = 'energetic-angled-roof-insulation',
    ENERGETIC_ROOF_TYPE = 'energetic-roof-type',
    ENERGETIC_PIPE_INSULATION = 'energetic-pipe-insulation',
    ENERGETIC_FRAMES = 'energetic-frames',
    ENERGETIC_HEATING_SYSTEM = 'energetic-heating-system',
    ENERGETIC_WARM_WATER_INSTALLATION = 'energetic-warm-water-installation',
    ENERGETIC_SOLAR_BOILER = 'energetic-solar-boiler',
    ENERGETIC_COOLING = 'energetic-cooling',
    ENERGETIC_SOLAR = 'energetic-solar',
    ENERGETIC_WIND = 'energetic-wind',
    ENERGETIC_OTHER_ENERGY = 'energetic-other-energy',

    ENERGETIC_IMPROVEMENTS_POSSIBLE = 'energetic-improvements-possible',
    ENERGETIC_IMPROVEMENTS_WALL = 'energetic-improvements-wall',
    ENERGETIC_IMPROVEMENTS_FLAT_ROOF = 'energetic-improvements-flat-roof',
    ENERGETIC_IMPROVEMENTS_ANGLED_ROOF = 'energetic-improvements-angled-roof',
    ENERGETIC_IMPROVEMENTS_FLOOR = 'energetic-improvements-floor',
    ENERGETIC_IMPROVEMENTS_GLASS_LIVING_ROOM = 'energetic-improvements-glass-living-room',
    ENERGETIC_IMPROVEMENTS_GLASS_BEDROOM = 'energetic-improvements-glass-bedroom',
    ENERGETIC_IMPROVEMENTS_HEAT_PUMP = 'energetic-improvements-heat-pump',
    ENERGETIC_IMPROVEMENTS_OTHER_HEATING = 'energetic-improvements-other-heating',
    ENERGETIC_IMPROVEMENTS_SHOWER = 'energetic-improvements-shower',
    ENERGETIC_IMPROVEMENTS_SOLAR_BOILER = 'energetic-improvements-solar-boiler',
    ENERGETIC_IMPROVEMENTS_VENTILATION = 'energetic-improvements-ventilation',
    ENERGETIC_IMPROVEMENTS_SOLAR = 'energetic-improvements-solar',

    ENERGETIC_INSTALL_MOMENT = 'energetic-install-moment',
    ENERGETIC_ENERGYLABEL_DATE = 'energetic-energylabel-date',
    ENERGETIC_INSTALL_YEAR = 'energetic-install-year',
    ENERGETIC_NOTES = 'energetic-notes',
    ENERGETIC_OWNERSHIP = 'energetic-ownership',
}
