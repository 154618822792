import {CbsData, NeighbourhoodDetailsData, ObjectSummary} from '../../models/object_summary';

export interface ApiObjectSummary {
    object_type: string | null;
    build_year: number | null;
    surface_area: number | null;
    plot_area: number | null;
    energy_label: string | null;
    keywords: string[] | null;
    neighbourhood_details: NeighbourhoodDetailsData;
    cbs_data: CbsData | null;
}

export function apiObjectSummaryToObjectSummary(apiObjectSummary: ApiObjectSummary): ObjectSummary {
    return {
        objectType: apiObjectSummary.object_type,
        buildYear: apiObjectSummary.build_year,
        surfaceArea: apiObjectSummary.surface_area,
        plotArea: apiObjectSummary.plot_area,
        energyLabel: apiObjectSummary.energy_label,
        keywords: apiObjectSummary.keywords,
        neighbourhoodDetails: apiObjectSummary.neighbourhood_details,
        cbsData: apiObjectSummary.cbs_data,
    };
}
