import React from 'react';
import {classNames} from '../../../../../../support/classnames';
import {TechnicalReference} from '../../../../../enum/technical_reference';
import {CoreTaskAttachment, CoreTasksData} from '../../../../../models/core_tasks';
import {observer} from 'mobx-react';
import {CoreTaskFileDisplayPresenter} from './core_task_file_display_presenter';
import {usePresenter} from '../../../../../../support/presenter/use_presenter';
import {Loader} from '../../../components/loader';
import {ALLOWED_DOCUMENT_TYPES, QuestionContainer} from '../../../content/questions/question_container';
import {Appraisal} from '../../../../../models/appraisal';
import {QuestionSet} from '../../../../../models/question_set';
import {SimpleTooltip} from '../../../../../components/tooltip';
import {NormalQuestionType} from '../../../../../enum/question_type';
import {RenderingContextType} from '../../../../../enum/rendering_context_type';
import {PagePartsSet} from '../../../../../models/page_parts_set';

export const CoreTaskFileDisplay = observer(function CoreTaskFileDisplay({
    name,
    description,
    technicalReference,
    parentAnswerUuid,
    data,
    type,
    questionSet,
    appraisal,
    pagePartsSet,
    renderingContext,
    attachment,
}: {
    name: string;
    description?: React.ReactNode;
    technicalReference?: TechnicalReference;
    parentAnswerUuid: string;
    data: CoreTasksData | null;
    questionSet: QuestionSet;
    appraisal: Appraisal;
    type?: 'file' | 'mail';
    pagePartsSet: PagePartsSet | null;
    renderingContext: RenderingContextType;
    attachment?: CoreTaskAttachment;
}) {
    const presenter = usePresenter(
        (container) =>
            new CoreTaskFileDisplayPresenter(
                ALLOWED_DOCUMENT_TYPES,
                technicalReference ?? null,
                type,
                parentAnswerUuid,
                appraisal,
                questionSet,
                container.business.answerController(appraisal, questionSet),
                container.business.attachmentUploadInteractor(appraisal, questionSet),
                container.business.blobCacheInteractor(),
                container.business.flashMessageBroadcaster,
                container.business.answerInteractor(appraisal, questionSet),
                container.network.appraisalApi,
                container.network.taskHelper,
                attachment ?? null
            ),
        [data, attachment ?? null]
    );

    function renderButtons() {
        if (presenter.loading) {
            return null;
        }

        return (
            <>
                <div className="list-item-attachment-buttons">
                    {presenter.url ? (
                        <>
                            {presenter.canUpload && (
                                <button
                                    className="btn btn-upload"
                                    onClick={(e) => {
                                        if (
                                            e.target instanceof HTMLElement &&
                                            e.target.firstChild instanceof HTMLInputElement &&
                                            confirm('Zeker dat je dit bestand wil vervangen?')
                                        ) {
                                            e.target.firstChild.click();
                                        }
                                    }}
                                >
                                    {renderFileUpload()}
                                    Vervang
                                    <br />
                                    bijlage
                                </button>
                            )}
                            {presenter.canClear && (
                                <button
                                    className="btn btn-clear ion-md-trash"
                                    onClick={() => presenter.onClearClick()}
                                />
                            )}
                        </>
                    ) : (
                        <>
                            {presenter.canUpload && (
                                <label className="btn btn-upload">
                                    {renderFileUpload()}
                                    Upload een
                                    <br />
                                    bijlage
                                </label>
                            )}
                        </>
                    )}
                </div>
            </>
        );
    }

    function renderFileData() {
        const avatar = renderAvatar();

        return (
            <>
                {presenter.url ? (
                    <a
                        className="list-item-attachment-download"
                        href={presenter.url}
                        target="_blank"
                        rel="noreferrer"
                    />
                ) : presenter.attachment ? (
                    <a className="list-item-attachment-download" onClick={() => presenter.openAttachment()} />
                ) : null}
                {avatar}
                <span
                    className={classNames('list-avatar', {
                        'list-avatar-second': avatar !== null,
                        'list-avatar-download': presenter.url !== null || presenter.attachment !== null,
                        'list-avatar-active': presenter.url !== null || presenter.attachment !== null,
                        'ion-md-cloud-download': presenter.url !== null || presenter.attachment !== null,
                        'ion-md-cloud-upload':
                            presenter.attachment === null && presenter.url === null && presenter.canUpload,
                        'ion-md-mail':
                            presenter.attachment === null &&
                            presenter.url === null &&
                            !presenter.canUpload &&
                            type === 'mail',
                        'ion-md-attach':
                            presenter.attachment === null &&
                            presenter.url === null &&
                            !presenter.canUpload &&
                            type !== 'mail',
                    })}
                >
                    {(presenter.loading || presenter.isGenerating) && (
                        <span className="list-avatar-loader">
                            <Loader addSpace={false} />
                        </span>
                    )}
                </span>
                <span
                    className={classNames('form-group', {
                        'list-title-download': presenter.url !== null || presenter.attachment !== null,
                    })}
                >
                    {name}
                </span>
                <small
                    className={classNames('list-details', {
                        'list-details-download': presenter.url !== null || presenter.attachment !== null,
                    })}
                >
                    {presenter.isGenerating
                        ? 'Bezig met genereren...'
                        : presenter.generatedUrl
                        ? 'Het bestand is beschikbaar. Klik om het te downloaden.'
                        : presenter.fileName
                        ? presenter.fileName
                        : description ??
                          (presenter.url !== null || presenter.attachment !== null
                              ? 'Download dit bestand'
                              : 'Nog geen bestand geüpload')}
                </small>
            </>
        );
    }
    function renderAvatar() {
        const avatarData = presenter.avatarData;

        if (!avatarData) {
            return null;
        }

        return (
            <SimpleTooltip content={avatarData.tooltip} placement="top">
                <span className={classNames('list-avatar', 'list-user-avatar', avatarData.iconClass)} data-type="info">
                    {avatarData.initials}
                </span>
            </SimpleTooltip>
        );
    }

    function renderFileUpload() {
        return (
            <input type="file" name="files[]" style={{display: 'none'}} onChange={(e) => presenter.onFileChange(e)} />
        );
    }

    function renderProgressBar() {
        const progress = presenter.uploadProgress;
        if (progress === 1 || progress == 0 || progress === null) {
            return null;
        }

        return (
            <span className="list-progress">
                <div className="uppy uppy-ProgressBar" style={{position: 'fixed'}}>
                    <div
                        className="uppy-ProgressBar-inner"
                        style={{
                            width: progress ? `${progress * 100}%` : '0%',
                            display: progress ? undefined : 'none',
                        }}
                    />
                    <div className="uppy-ProgressBar-percentage">0</div>
                </div>
            </span>
        );
    }

    function renderAdditionalQuestions() {
        if (presenter.question === null || presenter.question.type === NormalQuestionType.ATTACHMENT) {
            return null;
        }
        return (
            <div className="row ml-2">
                <div className="col-12">
                    <QuestionContainer
                        key={presenter.question.uuid}
                        appraisal={appraisal}
                        question={presenter.question}
                        questionSet={questionSet}
                        iteration={undefined}
                        parentAnswerUuid={parentAnswerUuid}
                        validationErrors={[]}
                        validationMessages={{}}
                        forceShowValidationMessages={false}
                        hiddenQuestionTypes={[]}
                        pagePartsSet={pagePartsSet}
                        activePagePart={null}
                        renderingContext={renderingContext}
                        questionRenderingData={null}
                    />
                </div>
            </div>
        );
    }

    return (
        <>
            <div
                className={classNames('list-item-attachment', {
                    'list-item-attachment-multiple-avatar': presenter.avatarData !== null,
                })}
            >
                {renderFileData()}
                {renderButtons()}
                {renderProgressBar()}
            </div>
            {renderAdditionalQuestions()}
        </>
    );
});
