import React, {useEffect, useRef} from 'react';
import {CbsBurgelijkeStaat, NeighbourhoodDetailsData} from '../../../appraising/models/object_summary';
import {Chart} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

interface OwnProps {
    neighbourhoodDetailsData: NeighbourhoodDetailsData | null;
    cbsData: CbsBurgelijkeStaat | null;
}

export const NeighbourhoodMarriageDetails: React.FC<OwnProps> = ({neighbourhoodDetailsData, cbsData}: OwnProps) => {
    if (
        !neighbourhoodDetailsData ||
        !cbsData ||
        !neighbourhoodDetailsData.neighbourhoodBevolking ||
        !neighbourhoodDetailsData.wijkenEnBuurten
    ) {
        return null;
    }

    const marriageDataTotal =
        neighbourhoodDetailsData.neighbourhoodBevolking.Ongehuwd.value +
        neighbourhoodDetailsData.neighbourhoodBevolking.Gehuwd.value +
        neighbourhoodDetailsData.neighbourhoodBevolking.Gescheiden.value +
        neighbourhoodDetailsData.neighbourhoodBevolking.Verweduwd.value;

    const marriageData = {
        Ongehuwd: +((neighbourhoodDetailsData.neighbourhoodBevolking.Ongehuwd.value / marriageDataTotal) * 100).toFixed(
            1
        ),
        Gehuwd: +((neighbourhoodDetailsData.neighbourhoodBevolking.Gehuwd.value / marriageDataTotal) * 100).toFixed(1),
        Gescheiden: +(
            (neighbourhoodDetailsData.neighbourhoodBevolking.Gescheiden.value / marriageDataTotal) *
            100
        ).toFixed(1),
        Verweduwd: +(
            (neighbourhoodDetailsData.neighbourhoodBevolking.Verweduwd.value / marriageDataTotal) *
            100
        ).toFixed(1),
    };

    const ref = useRef<HTMLCanvasElement>(null);
    const data = {
        labels: ['Ongehuwd', 'Gehuwd', 'Gescheiden', 'Verweduwd'],
        plugins: [ChartDataLabels],
        datasets: [
            {
                label: 'Nederland',
                data: [cbsData.Ongehuwd, cbsData.Gehuwd, cbsData.Gescheiden, cbsData.Verweduwd],
                backgroundColor: [
                    'rgba(54, 162, 235, 0.6)', // light blue
                    'rgba(75, 192, 192, 0.6)', // teal
                    'rgba(153, 102, 255, 0.6)', // purple
                    'rgba(255, 159, 64, 0.6)', // orange
                ],
            },
            {
                label: neighbourhoodDetailsData.wijkenEnBuurten,
                data: [marriageData.Gehuwd, marriageData.Ongehuwd, marriageData.Gescheiden, marriageData.Verweduwd],
                backgroundColor: [
                    'rgba(54, 162, 235, 1)', // light blue
                    'rgba(75, 192, 192, 1)', // teal
                    'rgba(153, 102, 255, 1)', // purple
                    'rgba(255, 159, 64, 1)', // orange
                ],
            },
        ],
    };

    useEffect(() => {
        if (!ref.current) {
            return;
        }

        const chart = new Chart(ref.current, {
            type: 'doughnut',
            data: data,
            options: {
                plugins: {
                    datalabels: {
                        display: false,
                    },
                    tooltip: {
                        callbacks: {
                            label: function (context) {
                                if (context.datasetIndex === 0) {
                                    return `${context.formattedValue} % (Nederland)`;
                                } else if (context.datasetIndex === 1) {
                                    return `${context.formattedValue} % (${neighbourhoodDetailsData.wijkenEnBuurten})`;
                                }
                                return context.formattedValue;
                            },
                        },
                    },
                    legend: {
                        position: 'right',
                    },
                },
            },
        });

        return () => {
            chart.destroy();
        };
    }, [data]);

    return neighbourhoodDetailsData ? (
        <>
            <h2>Verdeling burgerlijke staat</h2>
            <canvas id="marriage_doughnut" key="marriage_doughnut" ref={ref} />
        </>
    ) : null;
};
