import $ from 'jquery';

/**
 * Bit 'n pieces to change elements based on selection
 */
(() => {
    const cancellationCosts = $('#form-cancellation-costs');
    if (cancellationCosts.length > 0) {
        $('input[name="charge_cancellation_costs"]').on('change', function (source) {
            showOnValue(source, cancellationCosts, 'on');
        });
    }

    function showOnValue(input, target, val) {
        if ($(input.target ?? input).val() === val) {
            target.show();
            return true;
        } else {
            target.hide();
            return false;
        }
    }
})();
