import {makeObservable, observable, runInAction} from 'mobx';
import {CompositeSubscription} from '../support/composite_subscription';
import {Presenter} from '../support/presenter/presenter';
import {SearchAppraisal} from '../appraising/models/search_appraisal';
import {FlashMessageBroadcaster} from '../appraising/business/flash_message_broadcaster';
import {
    ApiSearchAppraisal,
    apiSearchAppraisalToSearchAppraisal,
} from '../appraising/network/models/api_search_appraisal';
import {TaskHelper} from '../appraising/business/task_helper';
import {catchError, concat, defer, EMPTY, raceWith} from 'rxjs';

declare const GLOBAL: {
    appraisals: ApiSearchAppraisal[];
    task_id: number;
};

interface StreamMessage {
    appraisal_id: number;
    status: 'processing' | 'processed';
}

interface AppraisalLiveStatus {
    appraisal: SearchAppraisal;
    status: 'processing' | 'processed';
}

export class AppraisalArchivesStreamingBatchPresenter implements Presenter {
    private subscriptions = new CompositeSubscription();

    public APPRAISAL_LIVE_STATUS_TOTAL_LIMIT = 10;

    @observable public batchAppraisals: SearchAppraisal[];
    @observable public taskId: number;
    @observable public appraisalLiveStatus: AppraisalLiveStatus[] = [];
    @observable public downloadUrl: string | null = null;
    @observable public appraisalsDoneCount: number = 0;

    constructor(private flashMessageBroadcaster: FlashMessageBroadcaster, private taskHelper: TaskHelper) {
        this.batchAppraisals = GLOBAL.appraisals.map(apiSearchAppraisalToSearchAppraisal);
        this.taskId = GLOBAL.task_id;

        makeObservable(this);
    }

    public mount(): void {
        this.subscriptions.add(
            this.taskHelper
                .stream<StreamMessage>(this.taskId)
                .pipe(
                    catchError((err) => {
                        console.error(
                            'Caught error while loading progress stream. Falling back to task polling. Error: ',
                            err
                        );
                        // Returning an empty observable will immediately continue to the concatted deferred taskHelper.poll call
                        return EMPTY;
                    }),
                    raceWith(this.taskHelper.poll<string>(this.taskId)),
                    (o) =>
                        concat(
                            o,
                            defer(() => this.taskHelper.poll<string>(this.taskId))
                        )
                )
                .subscribe((data) => {
                    if (data === null) {
                        return;
                    }

                    if (typeof data === 'string') {
                        runInAction(() => {
                            this.downloadUrl = data;
                            this.appraisalsDoneCount = this.batchAppraisals.length;
                            this.appraisalLiveStatus = [...this.batchAppraisals]
                                .reverse()
                                .slice(0, this.APPRAISAL_LIVE_STATUS_TOTAL_LIMIT)
                                .map((appraisal) => ({
                                    appraisal,
                                    status: 'processed',
                                }));
                        });
                        return;
                    }

                    runInAction(() => {
                        const appraisalIndex = this.batchAppraisals.findIndex(
                            (appraisal) => appraisal.id === data.appraisal_id
                        );
                        if (appraisalIndex !== -1) {
                            const appraisal = this.batchAppraisals[appraisalIndex];

                            this.appraisalLiveStatus = this.appraisalLiveStatus.filter(
                                (status) => status.appraisal.id !== data.appraisal_id
                            );
                            this.appraisalLiveStatus.unshift({
                                appraisal: appraisal,
                                status: data.status,
                            });

                            this.appraisalLiveStatus = this.appraisalLiveStatus.slice(
                                0,
                                this.APPRAISAL_LIVE_STATUS_TOTAL_LIMIT
                            );
                            this.appraisalsDoneCount = appraisalIndex + 1 - (data.status === 'processed' ? 0 : 1);
                        }
                    });
                })
        );
    }

    public unmount(): void {
        this.subscriptions.clear();
    }
}
