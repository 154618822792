import {makeObservable, observable, runInAction} from 'mobx';

import {Appraisal} from '../../../../../../models/appraisal';
import {Presenter} from '../../../../../../../support/presenter/presenter';
import {TaskHelper} from '../../../../../../business/task_helper';
import {AppraisalApi} from '../../../../../../network/appraisal_api';
import {GeneratedReportFormat, GeneratedReportType} from '../../../../../../enum/generated_report';

export class ReportDownloaderPresenter implements Presenter {
    @observable public loadingFile = false;
    @observable public fileUrl: string | null = null;

    constructor(
        private reportType: GeneratedReportType,
        private reportFormat: GeneratedReportFormat,
        private appraisal: Appraisal,
        private appraisalApi: AppraisalApi,
        private taskHelper: TaskHelper
    ) {
        makeObservable(this);
    }

    public async mount() {
        await this.downloadFile();
    }

    public async unmount() {
        // No action
    }

    public async downloadFile() {
        runInAction(() => {
            // Trigger loading screen
            this.loadingFile = true;
        });

        const pdf = await this.appraisalApi.generateReport(this.appraisal.id, this.reportType, this.reportFormat);
        if (!TaskHelper.isTaskReference(pdf)) {
            runInAction(() => {
                this.fileUrl = pdf;
                this.loadingFile = false;
            });
            return;
        }

        const result = await this.taskHelper.poll<string>(pdf.taskId);
        if (result !== null) {
            runInAction(() => {
                this.fileUrl = result;
                this.loadingFile = false;
            });
            return;
        }

        throw new Error('No result');
    }
}
