import React, {useMemo} from 'react';
import {formatMoney} from '../../../appraising/appraise/ui/support/format_money';
import {SimpleTooltip} from '../../../appraising/components/tooltip';
import {ValuationObject} from './overview';

interface OwnProps {
    values: ValuationObject[];
}

const getPinPosition = (value: number, barStart: number, barSize: number) => {
    return ((value - barStart) / barSize) * 100;
};

const getPinMargin = (values: ValuationObject[], index: number, barStart: number, barSize: number): number => {
    if (index === 0) {
        return -5;
    }

    const pos = getPinPosition(values[index].value, barStart, barSize);
    const prevPos = getPinPosition(values[index - 1].value, barStart, barSize);

    if (pos - prevPos < 10) {
        return 15 + getPinMargin(values, index - 1, barStart, barSize);
    }

    return -5;
};

export const ValuationLine: React.FC<OwnProps> = ({values}) => {
    const [lower, upper, barStart, barEnd, barSize, sortedValues] = useMemo(() => {
        const Z = 1.65; // Z-score for 90% confidence interval

        // Calculate confidence interval based on Z and values
        const mean = values.reduce((a, b) => a + b.value, 0) / values.length;
        const std = Math.sqrt(
            values.map((x) => Math.pow(x.value - mean, 2)).reduce((a, b) => a + b, 0) / values.length
        );
        const lower = Math.round(mean - Z * std);
        const upper = Math.round(mean + Z * std);
        const size = upper - lower;

        const barStart = Math.floor((lower - size * 0.3) / 10000) * 10000;
        const barEnd = Math.ceil((upper + size * 0.3) / 10000) * 10000;
        const barSize = barEnd - barStart;

        return [lower, upper, barStart, barEnd, barSize, values.sort((a, b) => a.value - b.value)];
    }, [values]);

    function uppercaseFirst(string: string) {
        return string[0].toUpperCase() + string.slice(1);
    }

    return (
        <>
            <h3>Geschatte waarde</h3>
            <div className="valuation-bar">
                <div style={{width: `${((lower - barStart) / barSize) * 100}%`}} />
                <div className="valuation-bar-interval" style={{width: `${((upper - lower) / barSize) * 100}%`}} />
                <div style={{width: `${((barEnd - upper) / barSize) * 100}%`}} />
                {sortedValues.map((value, index) => (
                    <div
                        key={index}
                        className="valuation-bar-marker"
                        style={{left: `${getPinPosition(value.value, barStart, barSize)}%`}}
                    >
                        <div className="valuation-bar-marker-container">
                            <SimpleTooltip content={uppercaseFirst(value.source)} placement="top">
                                <span
                                    className="valuation-bar-value"
                                    style={{
                                        marginBottom: getPinMargin(sortedValues, index, barStart, barSize),
                                    }}
                                >
                                    {formatMoney(value.value)}
                                </span>
                            </SimpleTooltip>
                            <span className="ion-md-pin" />
                        </div>
                    </div>
                ))}
                <div className="valuation-bar-labels">
                    <span className="valuation-bar-label">{formatMoney(barStart)}</span>
                    <span className="valuation-bar-label">{formatMoney(barEnd)}</span>
                </div>
            </div>
        </>
    );
};
