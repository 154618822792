import {ValidationMessage, ValidationMessageImportance, ValidationMessageType} from '../validation_message';

import {Answer} from '../../../models/answer';
import {AnswerController} from '../../answering/answer_controller';
import {AnswerValidator} from '../answer_validator';
import {Question} from '../../../models/question';
import {isIteratorQuestionType} from '../../../../support/is_iterator_question_type';

export class IteratorValidator implements AnswerValidator {
    constructor(private answerController: AnswerController) {}

    public validate(question: Question, answer: Answer | null): ValidationMessage[] {
        if (!isIteratorQuestionType(question.type)) {
            return [];
        }

        if (
            !question.isRequired ||
            answer === null ||
            this.answerController.answersByParentAnswerUuid(answer.uuid).length > 0
        ) {
            return [];
        }

        return [
            {
                type: ValidationMessageType.TAXAPI,
                importance: ValidationMessageImportance.ERROR,
                question: question,
                answer: answer,
                messages: [],
                fallbackMessage: '"' + question.contents + '"',
                floorInfo: null,
                errorType: null,
            },
        ];
    }
}
