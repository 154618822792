import {
    Chart,
    ChartDataset,
    Decimation,
    Filler,
    Legend,
    LinearScale,
    LineController,
    LineElement,
    PointElement,
    TimeScale,
    TimeSeriesScale,
    Title,
    Tooltip,
} from 'chart.js';
import React, {useEffect, useRef} from 'react';
import {formatMoney} from '../../../appraising/appraise/ui/support/format_money';
import {ValuationOverview} from '../../../appraising/models/valuation_overview';
import 'chartjs-adapter-moment';

Chart.register(
    TimeScale,
    TimeSeriesScale,
    LinearScale,
    LineElement,
    PointElement,
    LineController,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip
);

interface OwnProps {
    data?: ValuationOverview;
}

const isAllDataSameYear = (data?: ValuationOverview): boolean => {
    if (!data) {
        return false;
    }

    let date: Date | null = null;

    const checkDate = (toCheck: Date) => {
        if (!date) {
            date = toCheck;
        } else if (date.getFullYear() !== toCheck.getFullYear()) {
            return false;
        }

        return true;
    };

    if (data.wozValues) {
        for (const value of data.wozValues) {
            if (!checkDate(value.referenceDate)) {
                return false;
            }
        }
    }

    if (data.altumTransactions) {
        for (const value of data.altumTransactions) {
            if (!checkDate(value.saleDate)) {
                return false;
            }
        }
    }

    if (data.ortecTransaction) {
        if (!checkDate(data.ortecTransaction.saleDate)) {
            return false;
        }
    }

    return true;
};

export const LineChart: React.FC<OwnProps> = ({data}: OwnProps) => {
    const ref = useRef<HTMLCanvasElement>(null);

    useEffect(() => {
        if (!ref.current) {
            return;
        }

        const datasets: ChartDataset<
            'line',
            {
                x: Date;
                y: number;
            }[]
        >[] = [];

        if (data?.wozValues && data.wozValues.length > 0) {
            datasets.push({
                label: 'WOZ-waarde',
                data:
                    data?.wozValues.map((value) => ({
                        x: value.referenceDate,
                        y: value.value,
                        is_estimated: value.is_estimated,
                    })) ?? [],
                segment: {
                    borderDash: (context) => {
                        const p0Id = (context.p0 as unknown as {raw: {is_estimated: boolean}}).raw.is_estimated;

                        return !p0Id ? [] : [5, 5];
                    },
                },
                borderColor: `#f47f26`,
            });
        }

        if (data?.altumTransactions && data.altumTransactions.length > 0) {
            datasets.push({
                label: 'Verkoopgegevens',
                data:
                    data?.altumTransactions.flatMap((value) => {
                        const points = [
                            {
                                x: value.saleDate,
                                y: value.askingPrice,
                                id: value.id,
                            },
                        ];

                        if (value.originalAskingPrices.length === 0) {
                            points.push({
                                x: value.offeredSinceDate,
                                y: value.askingPrice,
                                id: value.id,
                            });
                        } else {
                            value.originalAskingPrices.forEach((originalAskingPrice) => {
                                points.push({
                                    x: originalAskingPrice.date,
                                    y: originalAskingPrice.value,
                                    id: value.id,
                                });
                            });
                        }

                        return points;
                    }) ?? [],
                borderColor: `#30e8db`,
                segment: {
                    borderDash: (context) => {
                        const p0Id = (context.p0 as unknown as {raw: {id: string}}).raw.id;
                        const p1Id = (context.p1 as unknown as {raw: {id: string}}).raw.id;

                        return p0Id === p1Id ? [] : [5, 5];
                    },
                    borderColor: (context) => {
                        const p0Id = (context.p0 as unknown as {raw: {id: string}}).raw.id;
                        const p1Id = (context.p1 as unknown as {raw: {id: string}}).raw.id;

                        return p0Id === p1Id ? undefined : `rgba(0, 0, 0, 0.2)`;
                    },
                },
            });
        }

        if (data?.ortecTransaction) {
            datasets.push({
                label: 'Geïndexeerde verkooptransacties',
                data: data?.ortecTransaction
                    ? [
                          {
                              x: data.ortecTransaction.saleDate,
                              y: data.ortecTransaction.purchasePrice,
                          },
                          ...data.ortecTransaction.indexedPurchasePrices.map((value) => ({
                              x: value.date,
                              y: value.value,
                          })),
                      ]
                    : [],
                borderColor: '#E0B0FF',
            });
        }

        const chart = new Chart(ref.current, {
            type: 'line',
            data: {
                datasets,
            },
            options: {
                scales: {
                    x: {
                        type: 'time',
                        time: {
                            unit: isAllDataSameYear(data) ? 'month' : 'year',
                            tooltipFormat: 'DD-MM-YYYY',
                            round: 'day',
                        },
                    },
                    y: {
                        beginAtZero: true,
                        ticks: {
                            callback: (value) => formatMoney(Number(value)),
                        },
                    },
                },
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: (context) => formatMoney(Number(context.parsed.y)),
                        },
                    },
                },
            },
        });

        return () => {
            chart.destroy();
        };
    }, [data]);

    return <canvas ref={ref} />;
};
