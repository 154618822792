import {action, computed, makeObservable, observable, runInAction} from 'mobx';
import {AppraisalProvider} from '../../../appraising/business/appraisal_provider';
import {Appraisal} from '../../../appraising/models/appraisal';
import {AppraiserStatistic} from '../../../appraising/models/appraiser_statistic';
import {ObjectSummary} from '../../../appraising/models/object_summary';
import {AppraisalApi} from '../../../appraising/network/appraisal_api';
import {CompositeSubscription} from '../../../support/composite_subscription';
import {Presenter} from '../../../support/presenter/presenter';
import {AppraisalImage, AppraisalObject} from '../map/appraisal_object';
import {VisibleAppraisalObject} from '../map/appraisal_objects_map';

export class WidgetPresenter implements Presenter {
    @observable.ref public appraisal?: Appraisal;
    @observable.ref public appraiserStatistics?: AppraiserStatistic[];
    @observable.ref public objectSummary?: ObjectSummary;
    @observable.ref public visibleAppraisalObjects: VisibleAppraisalObject | null = null;
    @observable.ref public clickedAppraisalObject: AppraisalObject | null = null;
    @observable.ref public hoveringAppraisalObjectId: number | null = null;
    @observable.ref public reloadMapValue: boolean = false;
    @observable.ref public clickedAppraisalImageUrls: AppraisalImage[] = [];

    @computed
    public get appraiserStatisticsByKey(): Record<string, AppraiserStatistic> {
        const result: Record<string, AppraiserStatistic> = {};

        for (const appraiserStatistic of this.appraiserStatistics ?? []) {
            result[appraiserStatistic.key] = appraiserStatistic;
        }

        return result;
    }

    private subscriptions = new CompositeSubscription();

    constructor(private appraisalProvider: AppraisalProvider, private appraisalApi: AppraisalApi) {
        makeObservable(this);
    }

    @action
    public setClickedAppraisalObject = (appraisalObject: AppraisalObject | null) => {
        this.clickedAppraisalObject = appraisalObject;
        if (appraisalObject) {
            this.appraisalApi.getAppraisalImages(appraisalObject.id).then((appraisalImages) => {
                runInAction(() => {
                    this.clickedAppraisalImageUrls = appraisalImages;
                });
            });
        }
    };

    @action
    public setHoveringAppraisalObject = (appraisalObjectId: number | null) => {
        this.hoveringAppraisalObjectId = appraisalObjectId;
    };

    @action
    public setReloadMap = (value: boolean): void => {
        this.reloadMapValue = value;
    };

    public mount(): void {
        this.subscriptions.add(
            this.appraisalProvider.stream.subscribe((appraisal) => {
                runInAction(() => {
                    this.appraisal = appraisal;

                    this.appraisalApi.getAppraiserStatistics(appraisal.id).then((appraiserStatistics) => {
                        runInAction(() => {
                            this.appraiserStatistics = appraiserStatistics;
                        });
                    });

                    this.appraisalApi.getObjectSummary(appraisal.id).then((summary) => {
                        runInAction(() => {
                            this.objectSummary = summary;
                        });
                    });

                    this.appraisalApi.getAppraisalsForAppraiser().then((appraisals) => {
                        runInAction(() => {
                            this.visibleAppraisalObjects = {
                                count: appraisals.length,
                                visibleObjects: appraisals.filter(
                                    (appraisal) =>
                                        appraisal.adres.latitude &&
                                        appraisal.adres.longitude &&
                                        appraisal.adres.latitude !== this.appraisal?.latitude &&
                                        appraisal.adres.longitude !== this.appraisal?.longitude
                                ),
                            };
                        });
                    });
                });
            })
        );
    }

    public unmount(): void {
        this.subscriptions.clear();
    }
}
