import $ from 'jquery';

(() => {
    $('[name="invoice_flow_client"], [name="invoicing_invoice_flow_client"]').on('change', function (item) {
        $('[name="invoice_flow_client"], [name="invoicing_invoice_flow_client"]').prop(
            'checked',
            $(item.target).is(':checked')
        );
    });
})();

(() => {
    /**
     * Add VAT on the account rates page.
     */
    const groups = $('[data-rate-group]');

    if (groups.length > 0) {
        groups.on('change', function (input) {
            const name = $(input.target).attr('name');
            const fieldHasVat = name.includes('_inc_vat');
            setNewValue(input, fieldHasVat ? name.replace('_inc_vat', '') : name + '_inc_vat', !fieldHasVat);
            if (
                [
                    'rate_base_total',
                    'rate_base_total_inc_vat',
                    'rate_base_reduction_total',
                    'rate_base_reduction_total_inc_vat',
                ].includes(name)
            ) {
                calculateBase(name.replace('_total_inc_vat', '').replace('_total', ''));
            }
        });
    }

    function setNewValue(input, field, addVat) {
        const value = $(input.target).val();
        const newValue = addVat ? normalizeValue(value * 1.21) : normalizeValue(value / 1.21);
        $(`[name="${field}"]`).val(parseFloat(newValue.toString(10)).toFixed(2));
    }

    function calculateBase(name) {
        if (!['rate_base', 'rate_base_reduction'].includes(name)) {
            return;
        }

        const total = $(`[name="${name}_total"]`)?.val() ?? 0;
        const totalInclVAT = $(`[name="${name}_total_inc_vat"]`)?.val() ?? 0;

        const validation = $(`[name="rate_validation"]`)?.val() ?? 0;
        const validationInclVAT = $(`[name="rate_validation_inc_vat"]`)?.val() ?? 0;

        const baseField = $(`[name="${name}"]`);
        if (baseField.length > 0) {
            baseField.val(normalizeValue(total - validation));
        }
        const baseInclVATField = $(`[name="${name}_inc_vat"]`);
        if (baseInclVATField.length > 0) {
            baseInclVATField.val(normalizeValue(totalInclVAT - validationInclVAT));
        }
    }

    function normalizeValue(value) {
        if (value !== null) {
            return parseInt(Math.round(parseFloat(value) * 100).toString(10), 10) / 100;
        }
        return 0;
    }
})();

(() => {
    /**
     * Toggle buttons for credit page
     */
    $('input[name="credit_package_id"]').on('change', function (input) {
        if ($(input.target).data('credit-recurring-enabled') === 1) {
            $('[data-credit-button-recurring-payments-submit]')?.removeClass('d-none');
            $('[data-credit-button-submit]')?.addClass('d-none');
        } else {
            $('[data-credit-button-recurring-payments-submit]')?.addClass('d-none');
            $('[data-credit-button-submit]')?.removeClass('d-none');
        }
    });
})();

(() => {
    const customThemeDetails = document.getElementById('custom-theme-details');
    if (customThemeDetails) {
        $('select[name="color_theme"]').on('change', function (input) {
            const submitButton = $('button[type="submit"].btn-primary');

            if (input.target.value === 'custom') {
                customThemeDetails.style.display = 'block';
                submitButton.text('Opslaan & naar betaling');
            } else {
                customThemeDetails.style.display = 'none';
                submitButton.text('Opslaan');
            }
        });
    }
})();
