import {AnswerTrailWidgetProps} from '../appraise/ui/appraise_secondary/answer_trail_widget/answer_trail_widget';
import {Appraisal} from './appraisal';
import {ConstructionalDefectsProps} from '../appraise/ui/appraise_secondary/construction_defects/constructional_defects';
import {QuestionSet} from './question_set';
import {ReferenceObjectMapProps as ReferenceObjectV1MapProps} from '../appraise/ui/content/questions/advanced/reference_objects_question/v1/map/reference_object_map';
import {ReferenceObjectMapProps as ReferenceObjectV2MapProps} from '../appraise/ui/content/questions/advanced/reference_objects_question/v2/components/map/reference_object_map';
import {ReferenceObjectMapProps as ReferenceObjectV3MapProps} from '../appraise/ui/content/questions/advanced/reference_objects_question/v3/components/map/reference_object_map';
import {ReportReplacementsProps} from '../appraise/ui/appraise_secondary/report_replacements/report_replacements_widget';
import {WidgetsGroupQuestionWidgetProps} from '../appraise/ui/appraise_secondary/widgets_group_question/widgets_group_question_widget';
import {MacroSettingsProps} from '../appraise/ui/appraise_secondary/macro_settings/macro_settings_widget';
import {MacroSettingsPayload} from '../appraise/ui/appraise_secondary/macro_settings/macro_settings_presenter';
import {EnergeticSheetWidgetProps} from '../appraise/ui/appraise_secondary/energetic_sheet/energetic_sheet_widget';
import {TextAIChatWidgetProps} from '../appraise/ui/appraise_secondary/textai_chat/textai_chat_widget';
import {AttachmentPreviewWidgetProps} from '../appraise/ui/appraise_secondary/attachments_widget/attachment_preview_widget';
import {ReportDownloaderWidgetProps} from '../appraise/ui/content/questions/advanced/report_viewer/report_downloader_widget';

export enum AppraiseSecondaryType {
    WIDGETS = 'widgets',
    REFERENCE_MAP = 'reference-map',
    REFERENCE_V2_MAP = 'reference-v2-map',
    REFERENCE_V3_MAP = 'reference-v3-map',
    DEFECTS = 'defects',
    WIDGET_GROUP = 'widget-group',
    REPORT_REPLACEMENTS = 'report-replacements',
    ANSWER_TRAIL = 'answer-trail',
    MACRO_SETTINGS = 'macro-settings',
    ENERGETIC_SHEET = 'energetic-sheet',
    TEXTAI_CHAT = 'textai-chat',
    ATTACHMENT_PREVIEW_WIDGET = 'attachment-widget-preview',
    APPRAISAL_REPORT_RENDERER = 'appraisal-report-renderer',
    MATRIXIAN_BUURTINFO = 'matrixian-buurtinfo',
    VALUATION_INFO = 'valuation-info',
    REPORT_DOWNLOADER = 'report-downloader',
}

export interface AppraiseSecondaryConfigBase {
    id: string | number;
    type: AppraiseSecondaryType;
    onClose: () => void;
}
interface WidgetsConfig extends AppraiseSecondaryConfigBase {
    type: AppraiseSecondaryType.WIDGETS;
    appraisal: Appraisal;
    questionSet: QuestionSet;
}

interface WidgetGroupConfig
    extends AppraiseSecondaryConfigBase,
        Omit<WidgetsGroupQuestionWidgetProps, 'renderingContext'> {
    type: AppraiseSecondaryType.WIDGET_GROUP;
}

interface ReferenceMapConfig extends AppraiseSecondaryConfigBase, ReferenceObjectV1MapProps {
    type: AppraiseSecondaryType.REFERENCE_MAP;
}

interface ReferenceV2MapConfig extends AppraiseSecondaryConfigBase, ReferenceObjectV2MapProps {
    type: AppraiseSecondaryType.REFERENCE_V2_MAP;
}

interface ReferenceV3MapConfig extends AppraiseSecondaryConfigBase, ReferenceObjectV3MapProps {
    type: AppraiseSecondaryType.REFERENCE_V3_MAP;
}

interface DefectsConfig extends AppraiseSecondaryConfigBase, Omit<ConstructionalDefectsProps, 'renderingContext'> {
    type: AppraiseSecondaryType.DEFECTS;
}

interface MacroSettingsConfig extends AppraiseSecondaryConfigBase, Omit<MacroSettingsProps, 'renderingContext'> {
    type: AppraiseSecondaryType.MACRO_SETTINGS;
    payload: MacroSettingsPayload;
    appraisal: Appraisal;
    questionSet: QuestionSet;
}

interface MatrixianBuurtinfoConfig extends AppraiseSecondaryConfigBase {
    type: AppraiseSecondaryType.MATRIXIAN_BUURTINFO;
    appraisal: Appraisal;
}

interface ValuationInfoConfig extends AppraiseSecondaryConfigBase {
    type: AppraiseSecondaryType.VALUATION_INFO;
    appraisal: Appraisal;
}

interface ReportReplacementsConfig
    extends AppraiseSecondaryConfigBase,
        Omit<ReportReplacementsProps, 'renderingContext'> {
    type: AppraiseSecondaryType.REPORT_REPLACEMENTS;
}

interface AnswerTrailConfig extends AppraiseSecondaryConfigBase, AnswerTrailWidgetProps {
    type: AppraiseSecondaryType.ANSWER_TRAIL;
}

interface EnergeticSheetConfig extends AppraiseSecondaryConfigBase, EnergeticSheetWidgetProps {
    type: AppraiseSecondaryType.ENERGETIC_SHEET;
}

interface TextAIChatConfig extends AppraiseSecondaryConfigBase, TextAIChatWidgetProps {
    type: AppraiseSecondaryType.TEXTAI_CHAT;
}

interface AttachmentPreviewWidgetConfig extends AppraiseSecondaryConfigBase, AttachmentPreviewWidgetProps {
    type: AppraiseSecondaryType.ATTACHMENT_PREVIEW_WIDGET;
}

interface AppraisalReportRendererConfig extends AppraiseSecondaryConfigBase {
    type: AppraiseSecondaryType.APPRAISAL_REPORT_RENDERER;
    appraisal: Appraisal;
}

interface ReportDownloaderConfig extends AppraiseSecondaryConfigBase, ReportDownloaderWidgetProps {
    type: AppraiseSecondaryType.REPORT_DOWNLOADER;
}

export type AppraiseSecondaryConfig =
    | WidgetsConfig
    | ReferenceMapConfig
    | ReferenceV2MapConfig
    | ReferenceV3MapConfig
    | DefectsConfig
    | WidgetGroupConfig
    | ReportReplacementsConfig
    | AnswerTrailConfig
    | MacroSettingsConfig
    | EnergeticSheetConfig
    | TextAIChatConfig
    | AttachmentPreviewWidgetConfig
    | AppraisalReportRendererConfig
    | MatrixianBuurtinfoConfig
    | ValuationInfoConfig
    | ReportDownloaderConfig;
