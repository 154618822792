import React from 'react';
import {Loader} from '../../../appraising/appraise/ui/components/loader';
import GoogleMapReact from 'google-map-react';
import {getCenterLocation} from '../../../appraising/appraise/ui/content/questions/advanced/reference_objects_question/v3/components/map/center_location';
import {mapStyles} from '../../../appraising/appraise/ui/content/questions/advanced/reference_objects_question/v3/components/map/map_styles';
import {ApiGlobal} from '../../../business/global_provider';
import {Coordinate, NeighbourhoodDetailsData} from '../../../appraising/models/object_summary';
import {Appraisal} from '../../../appraising/models/appraisal';

declare let GLOBAL: ApiGlobal;

interface OwnProps {
    neighbourhoodDetails: NeighbourhoodDetailsData | null;
    appraisal?: Appraisal;
}

export const NeighbourhoodMap: React.FC<OwnProps> = ({neighbourhoodDetails, appraisal}: OwnProps) => {
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'map' implicitly has an 'any' type.
    const handleApiLoaded = (map, maps) => {
        if (neighbourhoodDetails?.neighbourhoodGeometry?.coordinates) {
            const triangleCoords2 = neighbourhoodDetails.neighbourhoodGeometry.coordinates.map(
                (coordinate: Coordinate) => {
                    return {lat: coordinate.lat, lng: coordinate.lng};
                }
            );

            const neighbourhood = new maps.Polygon({
                paths: triangleCoords2,
                strokeColor: '#00FF00',
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: '#00FF00',
                fillOpacity: 0.35,
            });
            neighbourhood.setMap(map);
        }
    };

    if (
        GLOBAL.google_maps_api_key === undefined ||
        GLOBAL.google_maps_api_key === null ||
        !appraisal ||
        !appraisal.latitude ||
        !appraisal.longitude
    ) {
        return null;
    }

    const centerLocation = getCenterLocation(
        {
            lat: appraisal.latitude,
            lng: appraisal.longitude,
        },
        []
    );

    const createMapOptions = (maps: GoogleMapReact.Maps) => {
        return {
            styles: mapStyles,
            zoomControlOptions: {
                position: maps.ControlPosition.RIGHT_CENTER,
                style: maps.ZoomControlStyle.SMALL,
            },
            mapTypeControl: false,
            panControl: true,
            disableDefaultUI: false,
            draggable: true,
            fullscreenControl: false,
            gestureHandling: 'greedy',
        };
    };

    return (
        <div className="pre-check-widget">
            <div className="object-details">
                {neighbourhoodDetails ? (
                    <div className="neighbourhood-map">
                        <GoogleMapReact
                            bootstrapURLKeys={{
                                key: GLOBAL.google_maps_api_key,
                                language: 'nl',
                            }}
                            options={createMapOptions}
                            defaultZoom={centerLocation.zoom}
                            defaultCenter={centerLocation.center}
                            yesIWantToUseGoogleMapApiInternals={true}
                            shouldUnregisterMapOnUnmount={true}
                            onGoogleApiLoaded={({map, maps}) => handleApiLoaded(map, maps)}
                        />
                        <p>* De definitie van een buurt is landelijk geregeld, hierop heeft taXapi geen invloed. </p>
                    </div>
                ) : (
                    <div className="d-flex justify-content-center">
                        <Loader isSmall />
                    </div>
                )}
            </div>
        </div>
    );
};
