import * as React from 'react';
import {createPortal} from 'react-dom';

import {AppraiseSecondaryConfig, AppraiseSecondaryType} from '../../../models/appraise_secondary_config';

import {AnswerTrailWidget} from './answer_trail_widget/answer_trail_widget';
import {AppraiseSecondaryPresenter} from './appraise_secondary_presenter';
import {ConstructionDefectsWidgetContext} from './construction_defects/construction_defects_widget_context';
import {ConstructionalDefectsWidget} from './construction_defects/constructional_defects_widget';
import {PagePartsSet} from '../../../models/page_parts_set';
import {ReferenceObjectMap as ReferenceObjectV1Map} from '../content/questions/advanced/reference_objects_question/v1/map/reference_object_map';
import {ReferenceObjectMap as ReferenceObjectV2Map} from '../content/questions/advanced/reference_objects_question/v2/components/map/reference_object_map';
import {ReferenceObjectMap as ReferenceObjectV3Map} from '../content/questions/advanced/reference_objects_question/v3/components/map/reference_object_map';
import {RenderingContextType} from '../../../enum/rendering_context_type';
import {ReportReplacementsWidget} from './report_replacements/report_replacements_widget';
import {ValidationError} from '../../../models/validation_error';
import {WidgetsContainer} from './widgets_container';
import {WidgetsGroupQuestionWidget} from './widgets_group_question/widgets_group_question_widget';
import {observer} from 'mobx-react';
import {usePresenter} from '../../../../support/presenter/use_presenter';
import {MacroSettingsWidget} from './macro_settings/macro_settings_widget';
import {EnergeticSheetWidget} from './energetic_sheet/energetic_sheet_widget';
import {QuestionRenderingData} from '../../../models/question_rendering_data';
import {TextAIChatWidget} from './textai_chat/textai_chat_widget';
import {AttachmentPreviewWidget} from './attachments_widget/attachment_preview_widget';
import {ConfigWithCallbacks} from '../../../business/generic_config_stack_interactor';
import {classNames} from '../../../../support/classnames';
import {ReportRenderer} from '../components/report_renderer/report_renderer';
import {Appraisal} from '../../../models/appraisal';
import {AppraisalMatrixianBuurtinfoWidget} from './appraisal_matrixian_buurtinfo_widget/appraisal_matrixian_buurtinfo_widget';
import {AppraisalValuationInfoWidget} from './appraisal_valuation_info_widget/appraisal_valuation_info_widget';
import {ReportDownloaderWidget} from '../content/questions/advanced/report_viewer/report_downloader_widget';

interface OwnProps {
    appraisal: Appraisal;
    pagePartsSet: PagePartsSet | null;
    validationErrors: ValidationError[];
    renderingContext: RenderingContextType;
    questionRenderingData: QuestionRenderingData | null;
}

export const AppraiseSecondary: React.FC<OwnProps> = observer(function AppraiseSecondary(props) {
    const presenter = usePresenter(
        (container) => new AppraiseSecondaryPresenter(container.business.appraiseSecondaryConfigStackInteractor)
    );

    const [isContainerDisplayed, setContainerDisplayed] = React.useState(false);

    const readContainerState = React.useCallback(() => {
        //If the container is hidden, dont render the secondary
        const appraiseSecondaryContainer = document.getElementById('appraise-secondary-container');
        const newIsContainerDisplayed = !(
            appraiseSecondaryContainer && window.getComputedStyle(appraiseSecondaryContainer, null).display === 'none'
        );

        if (isContainerDisplayed !== newIsContainerDisplayed) {
            setContainerDisplayed(newIsContainerDisplayed);
        }
    }, [isContainerDisplayed, setContainerDisplayed]);

    React.useEffect(() => {
        window.addEventListener('resize', readContainerState);
        readContainerState();
        return () => window.removeEventListener('resize', readContainerState);
    });

    function renderSecondary(
        currentActiveSecondary: ConfigWithCallbacks<AppraiseSecondaryConfig, unknown>
    ): JSX.Element {
        switch (currentActiveSecondary.type) {
            case AppraiseSecondaryType.WIDGETS:
                return (
                    <WidgetsContainer
                        appraisal={currentActiveSecondary.appraisal}
                        questionSet={currentActiveSecondary.questionSet}
                        pagePartsSet={props.pagePartsSet}
                        validationErrors={props.validationErrors}
                        renderingContext={props.renderingContext}
                    />
                );
            case AppraiseSecondaryType.WIDGET_GROUP:
                return (
                    <WidgetsGroupQuestionWidget
                        appraisal={currentActiveSecondary.appraisal}
                        questionSet={currentActiveSecondary.questionSet}
                        onClose={currentActiveSecondary.onClose}
                        question={currentActiveSecondary.question}
                        iteration={currentActiveSecondary.iteration}
                        parentAnswerUuid={currentActiveSecondary.parentAnswerUuid}
                        pagePartsSet={currentActiveSecondary.pagePartsSet}
                        activePagePart={currentActiveSecondary.activePagePart}
                        validationErrors={currentActiveSecondary.validationErrors}
                        validationMessages={currentActiveSecondary.validationMessages}
                        forceShowValidationMessages={currentActiveSecondary.forceShowValidationMessages}
                        adaptedDefaultValues={currentActiveSecondary.adaptedDefaultValues}
                        renderingContext={props.renderingContext}
                        questionRenderingData={props.questionRenderingData}
                    />
                );
            case AppraiseSecondaryType.MACRO_SETTINGS:
                return (
                    <MacroSettingsWidget
                        payload={currentActiveSecondary.payload}
                        questionSet={currentActiveSecondary.questionSet}
                        appraisal={currentActiveSecondary.appraisal}
                        onClose={currentActiveSecondary.onClose}
                    />
                );
            case AppraiseSecondaryType.REFERENCE_MAP:
                return (
                    <ReferenceObjectV1Map
                        onClickChange={currentActiveSecondary.onClickChange}
                        onHoverChange={currentActiveSecondary.onHoverChange}
                        clickedReferenceSale={currentActiveSecondary.clickedReferenceSale}
                        hoveringReferenceSale={currentActiveSecondary.hoveringReferenceSale}
                        appraisal={currentActiveSecondary.appraisal}
                        visibleReferenceSales={currentActiveSecondary.visibleReferenceSales}
                        showDetailsModal={currentActiveSecondary.showDetailsModal}
                    />
                );
            case AppraiseSecondaryType.REFERENCE_V2_MAP:
                return (
                    <ReferenceObjectV2Map
                        onClickChange={currentActiveSecondary.onClickChange}
                        onHoverChange={currentActiveSecondary.onHoverChange}
                        clickedReferenceObject={currentActiveSecondary.clickedReferenceObject}
                        hoveringReferenceObject={currentActiveSecondary.hoveringReferenceObject}
                        appraisal={currentActiveSecondary.appraisal}
                        visibleReferenceObjects={currentActiveSecondary.visibleReferenceObjects}
                        showDetailsModal={currentActiveSecondary.showDetailsModal}
                    />
                );
            case AppraiseSecondaryType.REFERENCE_V3_MAP:
                return (
                    <ReferenceObjectV3Map
                        setType={currentActiveSecondary.setType}
                        onClickChange={currentActiveSecondary.onClickChange}
                        onHoverChange={currentActiveSecondary.onHoverChange}
                        clickedReferenceObject={currentActiveSecondary.clickedReferenceObject}
                        hoveringReferenceObjectId={currentActiveSecondary.hoveringReferenceObjectId}
                        appraisal={currentActiveSecondary.appraisal}
                        questionSet={currentActiveSecondary.questionSet}
                        visibleReferenceObjects={currentActiveSecondary.visibleReferenceObjects}
                        showDetailsModal={currentActiveSecondary.showDetailsModal}
                        subscriptions={currentActiveSecondary.subscriptions}
                    />
                );
            case AppraiseSecondaryType.MATRIXIAN_BUURTINFO:
                return (
                    <AppraisalMatrixianBuurtinfoWidget
                        appraisal={currentActiveSecondary.appraisal}
                        onDiscard={currentActiveSecondary.onDiscard}
                    />
                );
            case AppraiseSecondaryType.VALUATION_INFO:
                return (
                    <AppraisalValuationInfoWidget
                        appraisal={currentActiveSecondary.appraisal}
                        onDiscard={currentActiveSecondary.onDiscard}
                    />
                );
            case AppraiseSecondaryType.DEFECTS:
                return (
                    <ConstructionalDefectsWidget
                        appraisal={currentActiveSecondary.appraisal}
                        questionSet={currentActiveSecondary.questionSet}
                        onClose={currentActiveSecondary.onClose}
                        question={currentActiveSecondary.question}
                        parentAnswerUuid={currentActiveSecondary.parentAnswerUuid}
                        validationInstituteConstructionalDefect={
                            currentActiveSecondary.validationInstituteConstructionalDefect
                        }
                        constructionDefectLabel={currentActiveSecondary.constructionDefectLabel}
                        validationErrors={currentActiveSecondary.validationErrors}
                        validationMessages={currentActiveSecondary.validationMessages}
                        forceShowValidationMessages={currentActiveSecondary.forceShowValidationMessages}
                        constructionDefectPhotoAnswerUuid={currentActiveSecondary.constructionDefectPhotoAnswerUuid}
                        constructionCostsWidgetContext={currentActiveSecondary.constructionCostsWidgetContext}
                        onChangeConstructionCostsWidgetContext={(c: ConstructionDefectsWidgetContext) =>
                            currentActiveSecondary.onChangeConstructionCostsWidgetContext(c)
                        }
                        pagePartsSet={currentActiveSecondary.pagePartsSet}
                        activePagePart={currentActiveSecondary.activePagePart}
                        renderingContext={props.renderingContext}
                        questionRenderingData={props.questionRenderingData}
                    />
                );
            case AppraiseSecondaryType.REPORT_REPLACEMENTS:
                return (
                    <ReportReplacementsWidget
                        appraisal={currentActiveSecondary.appraisal}
                        questionSet={currentActiveSecondary.questionSet}
                        onClose={currentActiveSecondary.onClose}
                        lastFocusedReference={currentActiveSecondary.lastFocusedReference}
                        onReferenceFocus={currentActiveSecondary.onReferenceFocus}
                        renderingContext={props.renderingContext}
                        questionRenderingData={props.questionRenderingData}
                    />
                );
            case AppraiseSecondaryType.ANSWER_TRAIL:
                return (
                    <AnswerTrailWidget
                        auditTrailItems={currentActiveSecondary.auditTrailItems}
                        isLoading={currentActiveSecondary.isLoading}
                        isDisabled={currentActiveSecondary.isDisabled}
                        questionType={currentActiveSecondary.questionType}
                        onHistoricAnswerClick={currentActiveSecondary.onHistoricAnswerClick}
                        getAnswerContents={currentActiveSecondary.getAnswerContents}
                        onClose={currentActiveSecondary.onClose}
                    />
                );
            case AppraiseSecondaryType.ENERGETIC_SHEET:
                return (
                    <EnergeticSheetWidget
                        onClose={currentActiveSecondary.onClose}
                        onOpenExplanation={currentActiveSecondary.onOpenExplanation}
                        renderingContext={currentActiveSecondary.renderingContext}
                        answerUuid={currentActiveSecondary.answerUuid}
                        questionSet={currentActiveSecondary.questionSet}
                        pagePartsSet={currentActiveSecondary.pagePartsSet}
                        appraisal={currentActiveSecondary.appraisal}
                    />
                );
            case AppraiseSecondaryType.TEXTAI_CHAT:
                return (
                    <TextAIChatWidget
                        onClose={currentActiveSecondary.onClose}
                        answerUuid={currentActiveSecondary.answerUuid}
                        appraisal={currentActiveSecondary.appraisal}
                        questionSet={currentActiveSecondary.questionSet}
                    />
                );
            case AppraiseSecondaryType.ATTACHMENT_PREVIEW_WIDGET:
                return (
                    <AttachmentPreviewWidget
                        onDiscard={currentActiveSecondary.onDiscard}
                        displayName={currentActiveSecondary.displayName}
                        url={currentActiveSecondary.url}
                    />
                );
            case AppraiseSecondaryType.APPRAISAL_REPORT_RENDERER:
                if (props.renderingContext === RenderingContextType.PLAUSIBILITY_CHECK) {
                    return <ReportRenderer appraisal={props.appraisal} renderingContext={props.renderingContext} />;
                }
                return <></>;
            case AppraiseSecondaryType.REPORT_DOWNLOADER:
                return (
                    <ReportDownloaderWidget
                        appraisal={currentActiveSecondary.appraisal}
                        questionSet={currentActiveSecondary.questionSet}
                        onClose={currentActiveSecondary.onClose}
                        reportFormat={currentActiveSecondary.reportFormat}
                        reportType={currentActiveSecondary.reportType}
                    />
                );
        }
    }

    const stack = presenter.stack;
    if (!stack || stack.length === 0) {
        return null;
    }

    const appraiseSecondary = document.getElementById('appraise-secondary');
    if (!appraiseSecondary) {
        return null;
    }

    if (isContainerDisplayed === false) {
        return null;
    }

    return createPortal(
        <div className="secondaries-container">
            {stack.map((config) => {
                return (
                    <div
                        key={config.id ?? config.type}
                        className={classNames('secondary-container', {
                            transparent: [AppraiseSecondaryType.ATTACHMENT_PREVIEW_WIDGET].includes(config.type),
                        })}
                    >
                        {renderSecondary(config)}
                    </div>
                );
            })}
        </div>,
        appraiseSecondary
    );
});
