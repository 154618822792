import * as React from 'react';

import {Appraisal} from '../../../../models/appraisal';
import {QuestionSet} from '../../../../models/question_set';
import {observer} from 'mobx-react';
import {usePresenter} from '../../../../../support/presenter/use_presenter';
import {EnergeticSheetPresenter} from './energetic_sheet_presenter';
import {DefaultAnswerContentBuilder} from '../../../../business/adapted_values/internal/answer_content_builder';
import {Collapsible} from '../../components/collapsible';
import {EnergeticSheetSettings} from './energetic_sheet_settings';
import {Loader} from '../../components/loader';
import {classNames} from '../../../../../support/classnames';
import {EnergeticSheetResult} from './energetic_sheet_result';
import {RenderingContextType} from '../../../../enum/rendering_context_type';
import {PagePartsSet} from '../../../../models/page_parts_set';
import {SustainabilitySubscriptionFrequency} from '../../../../network/sustainability_api';
import {formatMoney} from '../../support/format_money';
import {Select} from '../../components/select';

export interface EnergeticSheetProps {
    appraisal: Appraisal;
    questionSet: QuestionSet;
    pagePartsSet: PagePartsSet | null;
    renderingContext: RenderingContextType;
    answerUuid: string;
}

const valueToDisplayValue = (value: unknown) => {
    if (typeof value === 'boolean') {
        return value ? 'Ja' : 'Nee';
    }

    if (value === null) {
        return 'Niet ingevuld';
    }

    return String(value);
};

// Also kept track in backend in SubscriptionForSustainabilityCostCalculator
export const FREE_COUNT = 10;

export const EnergeticSheet: React.FC<EnergeticSheetProps> = observer((props) => {
    const presenter = usePresenter(
        (component) =>
            new EnergeticSheetPresenter(
                props.appraisal,
                props.questionSet,
                props.answerUuid,
                props.pagePartsSet,
                component.globalProvider,
                component.business.surfaceAreaProvider(props.appraisal, props.questionSet),
                component.business.buildYearProvider(props.appraisal, props.questionSet),
                component.business.answerController(props.appraisal, props.questionSet),
                component.business.questionEffectsInteractor(props.appraisal, props.questionSet),
                new DefaultAnswerContentBuilder(),
                component.network.sustainabilityApi,
                component.business.flashMessageBroadcaster,
                component.business.answerPathStubber(props.appraisal, props.questionSet, props.renderingContext),
                component.business.floorInteractor(props.appraisal, props.questionSet, props.renderingContext),
                component.business.energyLabelProvider(props.appraisal, props.questionSet),
                component.business.userInteractor
            )
    );

    if (presenter.subscriptionInfo.activeSubscriptionFrequency === null) {
        return (
            <div>
                <div className="d-flex justify-content-center">
                    <span className="ion-md-leaf" style={{fontSize: 60}}></span>
                </div>
                <h2>Duurzaamheidsadvies niet actief</h2>
                <p>
                    Het energetisch duurzaamheidsadvies-abonnement is niet actief. Activeer dit abonnement via{' '}
                    <a href="/account/me/financial/subscriptions">je accountinstellingen</a>.{' '}
                    {presenter.subscriptionInfo.totalUsageCount < FREE_COUNT
                        ? `Je mag het advies nog voor ${
                              FREE_COUNT - presenter.subscriptionInfo.totalUsageCount
                          } taxaties kosteloos uitproberen.`
                        : null}
                </p>
            </div>
        );
    }

    return (
        <div>
            <Collapsible title="Basisgegevens voor advies">
                <table className="table table-sm">
                    <thead>
                        <tr>
                            <th>Huidige situatie</th>
                            <th>Waarde</th>
                        </tr>
                    </thead>
                    <tbody>
                        {presenter.inputs.map((input) => (
                            <tr key={input.key}>
                                <td className="align-middle">{input.name}</td>
                                <td>
                                    {input.customOptions ? (
                                        <Select
                                            id={`energetic-input-${input.key}`}
                                            isDisabled={false}
                                            options={Object.entries(input.customOptions).map(([label, value]) => ({
                                                label,
                                                value,
                                            }))}
                                            value={
                                                input.storedInput && presenter.storedInputs[input.storedInput]
                                                    ? presenter.storedInputs[input.storedInput]
                                                    : input.value
                                            }
                                            onChange={(value) => presenter.setStoredInput(input.key, value)}
                                        />
                                    ) : (
                                        input.displayValue ?? valueToDisplayValue(input.value)
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Collapsible>
            <Collapsible title="Instellingen" defaultShown>
                <EnergeticSheetSettings
                    settings={presenter.settings}
                    updateSettings={(settings) => presenter.setSettings(settings)}
                />
            </Collapsible>
            {presenter.sustainability !== null || presenter.notFound ? (
                <div className="row">
                    <div className="col-12">
                        <h2 className="w-50 mt-4">Verbeteradvies</h2>
                        <a
                            className={classNames('btn btn-sm btn-primary float-right ion-md-download mt-3', {
                                disabled: presenter.loading,
                            })}
                            onClick={() => presenter.sendRequest()}
                        >
                            {presenter.loading ? <Loader isSmall /> : null}
                            Opnieuw ophalen
                        </a>
                        <p>
                            Hieronder vind je het verbeteradvies voor de huidige situatie en de gekozen instellingen. Je
                            kunt dit advies zo vaak ophalen als je wilt <strong>zonder extra kosten</strong>. Met de
                            knop &quot;Invullen in opname&quot; neem je de verbetermaatregelen over in de
                            opnameomgeving.{' '}
                            {presenter.subscriptionInfo.activeSubscriptionFrequency ===
                                SustainabilitySubscriptionFrequency.SINGLE &&
                            presenter.subscriptionInfo.totalUsageCount < FREE_COUNT
                                ? `Je kunt het duurzaamheidsadvies nog gratis uitproberen voor ${
                                      FREE_COUNT - presenter.subscriptionInfo.totalUsageCount
                                  } ${
                                      FREE_COUNT - presenter.subscriptionInfo.totalUsageCount === 1
                                          ? 'andere taxatie'
                                          : 'andere taxaties'
                                  }.`
                                : null}
                        </p>
                    </div>
                    <div className="col-12">
                        {presenter.sustainability !== null ? (
                            <EnergeticSheetResult
                                data={presenter.sustainability}
                                measures={presenter.measures}
                                fillMeasures={() => presenter.fillMeasures()}
                                labelDiffers={presenter.labelDiffers}
                            />
                        ) : (
                            <div className="alert-inline alert-danger ion-md-close-circle mt-3">
                                <p>
                                    Er is geen verbeteradvies gevonden voor deze combinatie aan basisgegevens en
                                    instellingen. Probeer het opnieuw door bijvoorbeeld een ander doel te kiezen of het
                                    zoekgedrag aan te passen.
                                </p>
                            </div>
                        )}
                    </div>
                    {presenter.sustainability !== null && (
                        <>
                            <div className="col-12">
                                <h2>Rapport aanmaken</h2>
                                <p>
                                    Zet dit verbeteradvies, samen met een aantal standaard-scenarios om in een
                                    energetisch duurzaamheidsrapport. Dit rapport zal worden toegevoegd aan de extra
                                    bijlagen en zichtbaar worden in de omgeving van de opdrachtgever, maar zal niet
                                    worden verstuurd naar het validatie-instituut.
                                </p>
                                {presenter.subscriptionInfo.activeSubscriptionFrequency ===
                                    SustainabilitySubscriptionFrequency.SINGLE && (
                                    <p>
                                        Dit rapport kan <strong>eenmalig zonder extra kosten</strong> worden
                                        gegenereerd. Voor alle volgende pogingen wordt{' '}
                                        <strong>
                                            {formatMoney(presenter.subscriptionInfo.singleCostsCents / 100)}
                                        </strong>{' '}
                                        in rekening gebracht.
                                    </p>
                                )}
                                {presenter.subscriptionInfo.activeSubscriptionFrequency ===
                                    SustainabilitySubscriptionFrequency.MONTHLY && (
                                    <p>Dit rapport kan kosteloos worden gegenereerd.</p>
                                )}
                            </div>
                            <div className="col-12">
                                <div className="spacer" />
                                {presenter.report === null ? (
                                    <a
                                        className={classNames('btn btn-sm btn-primary float-right ion-md-refresh', {
                                            disabled: presenter.isDownloadingReport,
                                        })}
                                        onClick={() => presenter.downloadReport()}
                                    >
                                        {presenter.isDownloadingReport ? <Loader isSmall /> : null}
                                        Genereer rapport
                                    </a>
                                ) : (
                                    <>
                                        <a
                                            className="btn btn-sm btn-primary float-right ion-md-cloud-download"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href={presenter.report.url ?? presenter.report.uncompressedUrl}
                                        >
                                            Download rapport
                                        </a>
                                        <a
                                            className={classNames(
                                                'btn btn-sm btn-primary float-right ion-md-refresh mr-3',
                                                {
                                                    disabled: presenter.isDownloadingReport,
                                                }
                                            )}
                                            onClick={() => presenter.downloadReport()}
                                        >
                                            {presenter.isDownloadingReport ? <Loader isSmall /> : null}
                                            Genereer opnieuw
                                        </a>
                                    </>
                                )}
                                <div className="spacer" />
                            </div>
                        </>
                    )}
                </div>
            ) : (
                <div className="row">
                    <div className="col-12">
                        <h2>Verbeteradvies ophalen</h2>
                        <p>
                            Haal op basis van de opgegeven basisgegevens en de gekozen instellingen een verbeteradvies
                            op voor dit adres.{' '}
                            {presenter.subscriptionInfo.activeSubscriptionFrequency ===
                                SustainabilitySubscriptionFrequency.SINGLE &&
                            presenter.subscriptionInfo.appraisalUsageCount === 0 ? (
                                presenter.subscriptionInfo.totalUsageCount < FREE_COUNT ? (
                                    `Je kunt het duurzaamheidsadvies nog gratis uitproberen voor ${
                                        FREE_COUNT - presenter.subscriptionInfo.totalUsageCount
                                    } ${
                                        FREE_COUNT - presenter.subscriptionInfo.totalUsageCount === 1
                                            ? 'taxatie'
                                            : 'taxaties'
                                    }.`
                                ) : (
                                    <>
                                        Voor het ophalen van een duurzaamheidsadvies wordt per taxatie eenmalig{' '}
                                        <strong>
                                            {formatMoney(presenter.subscriptionInfo.singleCostsCents / 100)}
                                        </strong>{' '}
                                        in rekening gebracht.
                                    </>
                                )
                            ) : null}
                        </p>
                    </div>
                    <div className="col-12">
                        <a
                            className={classNames('btn btn-sm btn-primary float-right ion-md-download', {
                                disabled: presenter.loading,
                            })}
                            onClick={() => presenter.sendRequest()}
                        >
                            {presenter.loading ? <Loader isSmall /> : null}
                            Ophalen
                        </a>
                    </div>
                </div>
            )}
        </div>
    );
});
