import 'react-day-picker/style.css';

import * as React from 'react';

import {ChangeEventHandler, FunctionComponent, useEffect, useState} from 'react';
import {DateFormat, formatDate, parseDate, parseDateToFormat} from '../../../support/format_date';

import {DayPicker} from 'react-day-picker';
import {Popper} from '../../../../../components/popper';
import {nl} from 'date-fns/locale';

interface OwnProps {
    value: string;
    onChange: (date: string | undefined) => void;
}

export const EditableDayPickerInput: FunctionComponent<OwnProps> = ({value, onChange}) => {
    const [dialogMonth, setDialogMonth] = useState(parseDate(value, DateFormat.TAXAPI) ?? new Date());
    const [propsValue, setPropsValue] = useState<string>(value);
    const [inputValue, setInputValue] = useState(parseDateToFormat(value, DateFormat.TAXAPI, DateFormat.PICKER));
    const [isPopperOpen, setIsPopperOpen] = useState(false);
    useEffect(() => {
        if (value !== propsValue) {
            setInputValue(parseDateToFormat(value, DateFormat.TAXAPI, DateFormat.PICKER));
            setPropsValue(value);
            setDialogMonth(parseDate(value, DateFormat.TAXAPI) ?? new Date());
        }
    }, [value, propsValue]);

    const selected = inputValue ? parseDate(inputValue, DateFormat.PICKER) : undefined;

    const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        if (e.target instanceof HTMLInputElement) {
            const dateStr = e.target.value;
            setInputValue(dateStr);

            if (dateStr === '') {
                setPropsValue('');
                onChange('');
            } else {
                const date = parseDate(dateStr, DateFormat.PICKER);
                if (date) {
                    const taxapiDateStr = formatDate(date, DateFormat.TAXAPI);
                    setDialogMonth(date);
                    setPropsValue(taxapiDateStr);
                    onChange(taxapiDateStr);
                }
            }
        }
    };

    const handleInputFocus = () => {
        setIsPopperOpen(true);
    };

    const handleDaySelect = (date: Date | undefined) => {
        if (date) {
            const taxapiDateStr = formatDate(date, DateFormat.TAXAPI);
            setInputValue(formatDate(date, DateFormat.PICKER));
            setPropsValue(taxapiDateStr);
            setDialogMonth(date);
            onChange(taxapiDateStr);
        }
    };

    const handleOnMonthChange = (date: Date) => {
        setDialogMonth(date);
    };

    return (
        <Popper
            isPopperOpen={isPopperOpen}
            setIsPopperOpen={setIsPopperOpen}
            toggle={
                <input
                    type="text"
                    placeholder="DD-MM-YYYY"
                    value={inputValue}
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                    className="form-control"
                />
            }
            popperClassName="date-picker__dialog-sheet"
            popper={
                <DayPicker
                    initialFocus={isPopperOpen}
                    mode="single"
                    onMonthChange={handleOnMonthChange}
                    month={dialogMonth}
                    selected={selected}
                    onSelect={handleDaySelect}
                    weekStartsOn={1}
                    locale={nl}
                />
            }
        />
    );
};
