import {Coords, NESWBounds, fitBounds} from 'google-map-react';

interface MapBounds {
    nw: {
        lat: number | null;
        lng: number | null;
    };
    se: {
        lat: number | null;
        lng: number | null;
    };
}

export interface Location {
    center: Coords;
    zoom: number;
}
export function getCenterLocation(mainLocation: Coords, locations: Coords[]): Location {
    if (locations.length === 0) {
        return {
            center: mainLocation,
            zoom: 13,
        };
    }

    const bounds: MapBounds = {
        nw: {
            lat: null,
            lng: null,
        },
        se: {
            lat: null,
            lng: null,
        },
    };

    locations.forEach((location) => {
        if (!bounds.nw.lat || bounds.nw.lat < location.lat) {
            bounds.nw.lat = location.lat;
        }
        if (!bounds.nw.lng || bounds.nw.lng > location.lng) {
            bounds.nw.lng = location.lng;
        }
        if (!bounds.se.lat || bounds.se.lat > location.lat) {
            bounds.se.lat = location.lat;
        }
        if (!bounds.se.lng || bounds.se.lng < location.lng) {
            bounds.se.lng = location.lng;
        }
    });

    // Due to a bug in the fitBounds method the nw and se cant be equal
    if (bounds.nw.lat === bounds.se.lat && bounds.se.lat && bounds.nw.lat) {
        bounds.se.lat -= 0.1;
        bounds.nw.lat += 0.1;
    }
    if (bounds.nw.lng === bounds.se.lng && bounds.se.lng && bounds.nw.lng) {
        bounds.se.lng += 0.1;
        bounds.nw.lng -= 0.1;
    }

    //TODO Check if we can get the size of the map
    const size = {
        width: 700, // Map width in pixels
        height: 800, // Map height in pixels
    };

    //There is a typo in the types of NESWBounds, so just cast it
    return fitBounds(bounds as unknown as NESWBounds, size);
}
