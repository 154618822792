import * as React from 'react';
import {classNames} from '../../../support/classnames';

interface OwnProps {
    lng: number;
    lat: number;
    pointCount: number;
    onClick?: () => void;
    isHovering: boolean;
}

export class Cluster extends React.Component<OwnProps> {
    public render() {
        return (
            <div
                className={classNames('marker-container', {
                    hovering: this.props.isHovering,
                })}
            >
                <div className="cluster" onClick={this.props.onClick}>
                    {this.props.pointCount}
                </div>
            </div>
        );
    }
}
