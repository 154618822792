import {action, computed, makeObservable, observable, runInAction} from 'mobx';
import {Presenter} from '../../../../../../support/presenter/presenter';
import {CoreTaskAttachment, CoreTaskAttachmentType, CoreTasksData} from '../../../../../models/core_tasks';
import {TechnicalReference} from '../../../../../enum/technical_reference';
import {QuestionSet} from '../../../../../models/question_set';
import {Question} from '../../../../../models/question';
import {CompositeSubscription} from '../../../../../../support/composite_subscription';
import {AnswerController} from '../../../../../business/answering/answer_controller';
import {Answer} from '../../../../../models/answer';
import {AttachmentUploadInteractor} from '../../../../../business/attachments/attachment_upload_interactor';
import {BlobCacheInteractor} from '../../../../../business/attachments/blob_cache_interactor';
import {mergeMap} from 'rxjs';
import {FlashMessageBroadcaster, Type} from '../../../../../business/flash_message_broadcaster';
import {AttachmentQuestionAvatarData} from '../../../content/questions/advanced/attachment_question';
import {UserRole} from '../../../../../enum/user_role';
import {UserType} from '../../../../../enum/user_type';
import {AnswerInteractor} from '../../../../../business/answering/answer_interactor';
import {Appraisal} from '../../../../../models/appraisal';
import {AppraisalApi} from '../../../../../network/appraisal_api';
import {TaskHelper} from '../../../../../business/task_helper';
import {NormalQuestionType} from '../../../../../enum/question_type';

export class CoreTaskFileDisplayPresenter implements Presenter {
    @observable public coreTasksData: CoreTasksData | null = null;
    @observable public uploadProgress: number | null = null;
    @observable public isGenerating = false;
    @observable public generatedUrl: string | null = null;

    public question: Question | null;
    @observable
    private attachmentAnswer: Answer | null = null;

    @observable.ref protected file: File | null = null;
    protected objectUrl: string | null = null;

    @observable.ref
    protected propsAttachment: CoreTaskAttachment | null = null;

    private readonly subscriptions = new CompositeSubscription();

    constructor(
        private readonly fileTypes: string[],
        private readonly techicalReference: TechnicalReference | null,
        private readonly type: 'file' | 'mail' | undefined,
        private readonly parentAnswerUuid: string,
        private readonly appraisal: Appraisal,
        private readonly questionSet: QuestionSet,
        private readonly answerController: AnswerController,
        private readonly attachmentUploadInteractor: AttachmentUploadInteractor,
        private readonly blobCacheInteractor: BlobCacheInteractor,
        private readonly flashMessageBroadcaster: FlashMessageBroadcaster,
        private readonly answerInteractor: AnswerInteractor,
        private readonly appraisalApi: AppraisalApi,
        private readonly taskHelper: TaskHelper,

        propsAttachment: CoreTaskAttachment | null
    ) {
        this.propsAttachment = propsAttachment;

        makeObservable(this);

        this.question = techicalReference ? this.questionSet.findQuestionByTechnicalReference(techicalReference) : null;
    }

    public mount(): void {
        if (this.question && this.question.type === NormalQuestionType.ATTACHMENT) {
            this.subscriptions.add(
                this.answerController
                    .answerByIdentifiersStream(this.question.uuid, this.parentAnswerUuid, null)
                    .subscribe((answer) => {
                        runInAction(() => {
                            this.attachmentAnswer = answer;
                        });
                    })
            );

            this.subscriptions.add(
                this.answerController
                    .answerByIdentifiersStream(this.question.uuid, this.parentAnswerUuid, null)
                    .pipe(mergeMap((answer) => this.blobCacheInteractor.find(answer.uuid)))
                    .subscribe((file) => {
                        runInAction(() => {
                            this.file = file;
                        });
                    })
            );
        }
    }

    public unmount(): void {
        this.subscriptions.clear();
    }

    @action
    public onUpdatedProps(coreTasksData: CoreTasksData | null, propsAttachment: CoreTaskAttachment | null): void {
        this.coreTasksData = coreTasksData;
        this.propsAttachment = propsAttachment;
    }

    @computed
    public get loading(): boolean {
        return this.coreTasksData === null;
    }

    @computed
    public get attachment(): CoreTaskAttachment | null {
        return (
            this.propsAttachment ??
            this.coreTasksData?.attachments.find(
                (attachment) => attachment.technicalReference === this.techicalReference
            ) ??
            null
        );
    }

    @computed
    public get url(): string | null {
        if (this.generatedUrl !== null) {
            return this.generatedUrl;
        }

        if (this.attachmentAnswer !== null && this.attachmentAnswer.file !== null) {
            return this.attachmentAnswer.file.url;
        }

        if (this.objectUrl === null && this.file !== null && this.file.size > 0) {
            this.createObjectUrl();
        }

        if (this.objectUrl !== null) {
            return this.objectUrl;
        }

        if (
            this.attachment?.type === CoreTaskAttachmentType.FILE_REFERENCE ||
            this.attachment?.type === CoreTaskAttachmentType.URL
        ) {
            return this.attachment.url;
        }

        return null;
    }

    @computed
    public get fileName() {
        if (this.attachmentAnswer !== null && this.attachmentAnswer.file !== null) {
            return this.attachmentAnswer.file.originalFilename;
        }

        if (this.file !== null) {
            return this.file.name;
        }

        if (
            this.attachment?.type === CoreTaskAttachmentType.FILE_REFERENCE ||
            this.attachment?.type === CoreTaskAttachmentType.URL
        ) {
            return this.attachment.fileName;
        }

        return null;
    }

    @computed
    public get canClear(): boolean {
        return this.attachmentAnswer !== null && this.attachmentAnswer.file !== null;
    }

    @computed
    public get canUpload(): boolean {
        return this.attachmentAnswer !== null;
    }

    public async openAttachment() {
        const attachment = this.attachment;
        if (!attachment || attachment.type !== CoreTaskAttachmentType.GENERATOR) {
            return;
        }

        runInAction(() => {
            this.isGenerating = true;
        });
        try {
            // Ensure all answers are synced with backend before generating report
            await this.answerInteractor.submit();

            const result = await this.appraisalApi.generateReport(
                this.appraisal.id,
                attachment.generatedReportType,
                attachment.generatedReportFormat
            );
            if (!TaskHelper.isTaskReference(result)) {
                runInAction(() => {
                    this.generatedUrl = result;
                });
            } else {
                const taskResult = await this.taskHelper.poll<string>(result.taskId);
                if (taskResult !== null) {
                    runInAction(() => {
                        this.generatedUrl = taskResult;
                    });
                } else {
                    this.flashMessageBroadcaster.broadcast(
                        'Er is een fout opgetreden bij het genereren van het rapport',
                        Type.Danger
                    );
                }
            }
        } catch (e) {
            console.error(e);
            this.flashMessageBroadcaster.broadcast(
                'Er is een fout opgetreden bij het genereren van het rapport',
                Type.Danger
            );
        } finally {
            runInAction(() => {
                this.isGenerating = false;
            });
        }
    }

    public async onClearClick() {
        if (this.attachmentAnswer !== null) {
            this.answerController.clearFileAndContents(this.attachmentAnswer.uuid);
            await this.answerInteractor.submit();
        }
    }

    public async onFileChange(e: React.ChangeEvent<HTMLInputElement>) {
        if (e.target.files === null || e.target.files.length === 0 || !this.canUpload) {
            return;
        }
        const files = e.target.files;
        const file = files[0];

        await this.onChangeFile(file);
    }

    public async onChangeFile(file: File) {
        if (this.attachmentAnswer === null) {
            return;
        }

        const result = await this.attachmentUploadInteractor.uploadForAnswer(this.attachmentAnswer.uuid, file, {
            fileTypes: this.fileTypes,
            progressCallback: (progress) => this.updateProgress(progress),
        });

        // Reset progress bar when done
        this.updateProgress(null);

        if (!result.succeeded) {
            await this.clearAnswer(this.attachmentAnswer);
            this.throwAlert(result.message);
        } else {
            runInAction(() => {
                this.objectUrl = null;
            });
        }
    }

    @action
    protected updateProgress(progress: number | null) {
        this.uploadProgress = progress;
    }

    @action
    protected createObjectUrl() {
        if (this.file !== null) {
            this.objectUrl = URL.createObjectURL(this.file);
        }
    }

    protected async clearAnswer(answer: Answer) {
        await this.blobCacheInteractor.remove(answer.uuid);
        this.answerController.clearFileAndContents(answer.uuid);
    }

    protected throwAlert(error?: string) {
        if (error) {
            this.flashMessageBroadcaster.broadcast(error, Type.Danger);
        } else if (this.file !== null) {
            if (this.file.size > 15728640) {
                // 15 * 1,048,576 bytes
                this.alertIncorrectSize();
            } else {
                this.alertIncorrectType();
            }
        }
    }

    public get avatarData(): AttachmentQuestionAvatarData | null {
        if (this.attachmentAnswer && this.attachmentAnswer.file !== null) {
            if (
                this.attachmentAnswer.filledByAutomator ||
                !this.attachmentAnswer.createdByUserType ||
                !this.attachmentAnswer.createdByUserRole
            ) {
                // Icon for taXapi AI
                return {
                    iconClass: 'c-icon-system list-avatar-active',
                    tooltip: 'Deze bijlage is toegevoegd door taXapi AI',
                    initials: null,
                };
            } else if (
                this.attachmentAnswer.createdByUserRole !== UserRole.USER ||
                [UserType.APPRAISER, UserType.EMPLOYEE, UserType.JUNIOR_APPRAISER].includes(
                    this.attachmentAnswer.createdByUserType
                )
            ) {
                // Icon for taXapi admin users, appraisers and employees
                return {
                    iconClass: 'list-avatar-primary-color',
                    tooltip: 'Deze bijlage is toegevoegd door ' + this.attachmentAnswer.createdByUserName,
                    initials: this.attachmentAnswer.createdByUserInitials ?? null,
                };
            } else if (this.attachmentAnswer.createdByUserName) {
                //  Icon for normal users (e.g. client)
                return {
                    iconClass: 'list-avatar-gray ion-md-person',
                    tooltip: 'Deze bijlage is toegevoegd door ' + this.attachmentAnswer.createdByUserName,
                    initials: null,
                };
            }
        }

        if (!this.attachment) {
            return null;
        }

        if (this.attachment.type === CoreTaskAttachmentType.GENERATOR) {
            return {
                iconClass: 'list-avatar-gray ion-md-cog',
                tooltip: 'Deze bijlage wordt automatisch gegenereerd',
                initials: null,
            };
        }

        if (this.type === 'mail') {
            return {
                iconClass: 'list-avatar-gray ion-md-mail',
                tooltip: null,
                initials: null,
            };
        }

        return {
            iconClass: 'list-avatar-gray ion-md-attach',
            tooltip: null,
            initials: null,
        };
    }

    @action
    private alertIncorrectSize() {
        this.flashMessageBroadcaster.broadcast(
            'Fout bij het uploaden. De maximaal toegestane grote voor een bestand is 15 Mb.',
            Type.Danger
        );
    }

    @action
    private alertIncorrectType() {
        this.flashMessageBroadcaster.broadcast(
            'Fout bij het uploaden. Is het een correct formaat? Voor afbeeldingen kun je .jpg en .png uploaden en voor bijlagen .pdf.',
            Type.Danger
        );
    }
}
