import * as React from 'react';

import GoogleMapReact, {Bounds} from 'google-map-react';

import {AppraisalObjectsMapDetails} from './appraisal_objects_map_details';
import {Appraisal} from '../../../appraising/models/appraisal';
import {AppraisalImage, AppraisalObject} from './appraisal_object';
import {ApiGlobal} from '../../../business/global_provider';
import Supercluster from 'supercluster';
import {mapStyles} from '../../../appraising/appraise/ui/content/questions/advanced/reference_objects_question/v3/components/map/map_styles';
import {Pointer} from './appraisal_objects_map_pointer';
import {Cluster} from './appraisal_objects_map_cluster';
import {Location, getCenterLocation} from './center_location';

declare let GLOBAL: ApiGlobal;

export interface VisibleAppraisalObject {
    count: number;
    visibleObjects: AppraisalObject[];
}

export interface AppraisalObjectsMapProps {
    appraisal: Appraisal;
    visibleAppraisalObjects: VisibleAppraisalObject;
    onHoverChange: (id: number | null) => void;
    onClickChange: (referenceObject: AppraisalObject | null) => void;
    forceReload: boolean;
    disableForceReload: () => void;
    clickedAppraisalObject: AppraisalObject | null;
    hoveringAppraisalObjectId: number | null;
    clickedAppraisalImageUrls: AppraisalImage[];
}

interface OwnState {
    centerLocation: Location;
    initialCenterLocation: Location;
    bounds: null | Bounds;
    reloaded: boolean;
}

interface GloogleMap {
    setZoom(zoom: number): void;
    panTo(coords: {lat: number; lng: number}): void;
}

export class AppraisalObjectsMap extends React.Component<AppraisalObjectsMapProps, OwnState> {
    private mapRef: null | GloogleMap = null;

    public state: OwnState = {
        centerLocation: getCenterLocation(
            {
                lat: this.props.appraisal.latitude || 0,
                lng: this.props.appraisal.longitude || 0,
            },
            [
                ...this.props.visibleAppraisalObjects.visibleObjects.map((appraisal: AppraisalObject) => ({
                    lat: appraisal.adres.latitude,
                    lng: appraisal.adres.longitude,
                })),
            ]
        ),
        initialCenterLocation: getCenterLocation(
            {
                lat: this.props.appraisal.latitude || 0,
                lng: this.props.appraisal.longitude || 0,
            },
            [
                ...this.props.visibleAppraisalObjects.visibleObjects.map((appraisal: AppraisalObject) => ({
                    lat: appraisal.adres.latitude,
                    lng: appraisal.adres.longitude,
                })),
            ]
        ),
        bounds: null,
        reloaded: false,
    };

    public componentDidUpdate(prevProps: AppraisalObjectsMapProps) {
        if (this.props.forceReload && !this.state.reloaded) {
            this.props.disableForceReload();
            this.setState({reloaded: true});
        }
        const clickedAppraisalObject = this.props.clickedAppraisalObject;
        const previousClickedId = prevProps.clickedAppraisalObject ? prevProps.clickedAppraisalObject.id : null;

        if (
            clickedAppraisalObject !== null &&
            clickedAppraisalObject.id !== previousClickedId &&
            this.mapRef !== null
        ) {
            this.mapRef.setZoom(19);
            this.mapRef.panTo({
                lat: clickedAppraisalObject.adres.latitude,
                lng: clickedAppraisalObject.adres.longitude,
            });
        }
        if (this.mapRef && this.props.appraisal.latitude && this.props.appraisal.longitude) {
            const initialCenterLocation = getCenterLocation(
                {
                    lat: this.props.appraisal.latitude,
                    lng: this.props.appraisal.longitude,
                },
                [
                    ...this.props.visibleAppraisalObjects.visibleObjects.map((appraisal: AppraisalObject) => ({
                        lat: appraisal.adres.latitude,
                        lng: appraisal.adres.longitude,
                    })),
                ]
            );
            if (this.props.forceReload) {
                this.props.disableForceReload();
                this.setState({reloaded: false});
                const centerLocation = getCenterLocation(
                    {
                        lat: this.props.appraisal.latitude,
                        lng: this.props.appraisal.longitude,
                    },
                    []
                );
                this.mapRef.setZoom(centerLocation.zoom);
                this.mapRef.panTo(centerLocation.center);
            }

            if (
                initialCenterLocation.zoom !== this.state.initialCenterLocation.zoom ||
                initialCenterLocation.center.lat !== this.state.initialCenterLocation.center.lat ||
                initialCenterLocation.center.lng !== this.state.initialCenterLocation.center.lng
            ) {
                this.setState({initialCenterLocation});
                this.mapRef.setZoom(initialCenterLocation.zoom);
                this.mapRef.panTo(initialCenterLocation.center);
            }
        }
    }

    private zoomToCluster(
        superCluster: Supercluster,
        cluster: Supercluster.ClusterFeature<Supercluster.AnyProps> | Supercluster.PointFeature<Supercluster.AnyProps>
    ) {
        if (this.mapRef === null) {
            return;
        }

        if (cluster.id) {
            const expansionZoom = Math.min(superCluster.getClusterExpansionZoom(cluster.id as number), 22);
            const [longitude, latitude] = cluster.geometry.coordinates;
            this.mapRef.setZoom(expansionZoom);
            this.mapRef.panTo({lat: latitude, lng: longitude});
        }
    }

    private createMapOptions(maps: GoogleMapReact.Maps) {
        return {
            styles: mapStyles,
            zoomControlOptions: {
                position: maps.ControlPosition.RIGHT_CENTER,
                style: maps.ZoomControlStyle.SMALL,
            },
            mapTypeControl: false,
            panControl: true,
            disableDefaultUI: false,
            draggable: true,
            fullscreenControl: false,
            gestureHandling: 'greedy',
        };
    }

    private calculateClusters(
        superCluster: Supercluster<Supercluster.ClusterProperties | AppraisalObject>,
        visibleAppraisalObjects: VisibleAppraisalObject
    ) {
        superCluster.load([
            ...visibleAppraisalObjects.visibleObjects.map((referenceObjectData) => {
                return {
                    type: 'Feature' as const,
                    geometry: {
                        type: 'Point' as const,
                        coordinates: [referenceObjectData.adres.longitude, referenceObjectData.adres.latitude],
                    },
                    properties: referenceObjectData,
                };
            }),
        ]);

        return superCluster.getClusters(
            this.state.bounds
                ? [
                      this.state.bounds.nw.lng,
                      this.state.bounds.se.lat,
                      this.state.bounds.se.lng,
                      this.state.bounds.nw.lat,
                  ]
                : [0, 0, 0, 0],
            Math.round(this.state.centerLocation.zoom)
        );
    }

    public render() {
        if (
            GLOBAL.google_maps_api_key === undefined ||
            GLOBAL.google_maps_api_key === null ||
            !this.props.appraisal.latitude ||
            !this.props.appraisal.longitude
        ) {
            return null;
        }

        const centerLocation = getCenterLocation(
            {
                lat: this.props.appraisal.latitude,
                lng: this.props.appraisal.longitude,
            },
            []
        );

        const superCluster = new Supercluster<Supercluster.ClusterProperties | AppraisalObject>({
            radius: 180,
            maxZoom: 19,
        });

        const clusters = this.calculateClusters(superCluster, this.props.visibleAppraisalObjects);

        const {clickedAppraisalObject} = this.props;

        return (
            <div className="appraisal-objects-map-container">
                <div className="appraisal-objects-map-title">
                    <h2>Eerder getaxeerd in deze omgeving</h2>
                </div>

                <GoogleMapReact
                    bootstrapURLKeys={{
                        key: GLOBAL.google_maps_api_key,
                        language: 'nl',
                    }}
                    options={this.createMapOptions}
                    defaultZoom={centerLocation.zoom}
                    defaultCenter={centerLocation.center}
                    onChange={({zoom, bounds}) => {
                        this.setState((oldState) => ({
                            centerLocation: {
                                center: oldState.centerLocation.center,
                                zoom,
                            },
                            bounds,
                        }));
                    }}
                    yesIWantToUseGoogleMapApiInternals={true}
                    shouldUnregisterMapOnUnmount={true}
                    onGoogleApiLoaded={({map}) => {
                        this.mapRef = map;
                    }}
                >
                    <Pointer
                        lat={this.props.appraisal.latitude}
                        lng={this.props.appraisal.longitude}
                        isHovering={false}
                        isBaseObject={true}
                    />

                    {clusters.map((cluster) => {
                        if ('cluster' in cluster.properties && cluster.properties.cluster && cluster.id) {
                            const childIds = superCluster
                                .getLeaves(cluster.id as number, Infinity)
                                .filter(
                                    (point): point is Supercluster.PointFeature<AppraisalObject> =>
                                        'id' in point.properties
                                )
                                .map((point) => point.properties.id);

                            const isHoveringChild =
                                this.props.hoveringAppraisalObjectId !== null &&
                                childIds.includes(this.props.hoveringAppraisalObjectId);

                            return (
                                <Cluster
                                    key={cluster.id}
                                    lng={cluster.geometry.coordinates[0]}
                                    lat={cluster.geometry.coordinates[1]}
                                    pointCount={cluster.properties.point_count}
                                    isHovering={isHoveringChild}
                                    onClick={() => this.zoomToCluster(superCluster, cluster)}
                                />
                            );
                        } else {
                            const appraisalObject = cluster.properties as AppraisalObject;
                            return (
                                <Pointer
                                    key={appraisalObject.id}
                                    lng={appraisalObject.adres.longitude}
                                    lat={appraisalObject.adres.latitude}
                                    isHovering={this.props.hoveringAppraisalObjectId === appraisalObject.id}
                                    onClick={() => this.props.onClickChange(appraisalObject)}
                                    onMouseEnter={() => this.props.onHoverChange(appraisalObject.id)}
                                    onMouseLeave={() => this.props.onHoverChange(null)}
                                    className="pointer-selected"
                                    isBaseObject={false}
                                />
                            );
                        }
                    })}
                </GoogleMapReact>

                {clickedAppraisalObject !== null && (
                    <AppraisalObjectsMapDetails
                        isSelected={true}
                        appraisalObject={clickedAppraisalObject}
                        onClose={() => this.props.onClickChange(null)}
                        onClick={() => this.props.onClickChange(clickedAppraisalObject)}
                        appraisal={this.props.appraisal}
                        clickedAppraisalImageUrls={this.props.clickedAppraisalImageUrls}
                    />
                )}
            </div>
        );
    }
}
