import * as React from 'react';

import {Appraisal} from '../../../../../../models/appraisal';
import {Loader} from '../../../../components/loader';
import {TaskHelper} from '../../../../../../business/task_helper';
import {ReportDownloaderPresenter} from './report_downloader_presenter';
import {QuestionSet} from '../../../../../../models/question_set';
import {usePresenter} from '../../../../../../../support/presenter/use_presenter';
import {observer} from 'mobx-react';
import {GeneratedReportFormat, GeneratedReportType} from '../../../../../../enum/generated_report';

export interface ReportDownloaderProps {
    appraisal: Appraisal;
    questionSet: QuestionSet;
    reportType: GeneratedReportType;
    reportFormat: GeneratedReportFormat;
}

export const ReportDownloader: React.FunctionComponent<ReportDownloaderProps> = observer((props) => {
    const presenter = usePresenter(
        (container) =>
            new ReportDownloaderPresenter(
                props.reportType,
                props.reportFormat,
                props.appraisal,
                container.network.appraisalApi,
                new TaskHelper(container.network.ajaxDriver)
            )
    );

    if (presenter.fileUrl) {
        return (
            <div className="row">
                <div className="col-12 d-flex justify-content-center mt-5">
                    <a className="btn btn-sm btn-primary float-right ion-md-cloud-download" href={presenter.fileUrl}>
                        Download bijlage
                    </a>
                </div>
            </div>
        );
    }

    return (
        <div className="row">
            <div className="col-12 text-center">
                <Loader icon="c-icon c-icon-building-costs-sheet" addSpace />
                <h3>Bijlage aan het genereren...</h3>
            </div>
        </div>
    );
});
