import React, {useEffect, useRef} from 'react';
import {NeighbourhoodDetailsData} from '../../../appraising/models/object_summary';
import {Chart, LegendItem} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

interface OwnProps {
    neighbourhoodDetailsData: NeighbourhoodDetailsData | null;
}

export const NeighbourhoodAgeDetails: React.FC<OwnProps> = ({neighbourhoodDetailsData}: OwnProps) => {
    if (!neighbourhoodDetailsData?.neighbourhoodBevolking || !neighbourhoodDetailsData?.wijkenEnBuurten) {
        return null;
    }

    const ageDataTotal =
        neighbourhoodDetailsData.neighbourhoodBevolking.k0Tot15Jaar.value +
        neighbourhoodDetailsData.neighbourhoodBevolking.k15Tot25Jaar.value +
        neighbourhoodDetailsData.neighbourhoodBevolking.k25Tot45Jaar.value +
        neighbourhoodDetailsData.neighbourhoodBevolking.k45Tot65Jaar.value +
        neighbourhoodDetailsData.neighbourhoodBevolking.k65JaarOfOuder.value;

    const ageData = {
        k0Tot15Jaar: +(
            (neighbourhoodDetailsData.neighbourhoodBevolking.k0Tot15Jaar.value / ageDataTotal) *
            100
        ).toFixed(1),
        k15Tot25Jaar: +(
            (neighbourhoodDetailsData.neighbourhoodBevolking.k15Tot25Jaar.value / ageDataTotal) *
            100
        ).toFixed(1),
        k25Tot45Jaar: +(
            (neighbourhoodDetailsData.neighbourhoodBevolking.k25Tot45Jaar.value / ageDataTotal) *
            100
        ).toFixed(1),
        k45Tot65Jaar: +(
            (neighbourhoodDetailsData.neighbourhoodBevolking.k45Tot65Jaar.value / ageDataTotal) *
            100
        ).toFixed(1),
        k65JaarOfOuder: +(
            (neighbourhoodDetailsData.neighbourhoodBevolking.k65JaarOfOuder.value / ageDataTotal) *
            100
        ).toFixed(1),
    };

    const shortendLabels = ['0-15 jaar', '15-25 jaar', '25-45 jaar', '45-65 jaar', '65+ jaar'];

    const ref = useRef<HTMLCanvasElement>(null);
    const data = {
        labels: ['0 tot 15 jaar', '15 tot 25 jaar', '25 tot 45 jaar', '45 tot 65 jaar', '65 jaar of ouder'],
        datasets: [
            {
                label: neighbourhoodDetailsData.wijkenEnBuurten,
                data: [
                    ageData.k0Tot15Jaar,
                    ageData.k15Tot25Jaar,
                    ageData.k25Tot45Jaar,
                    ageData.k45Tot65Jaar,
                    ageData.k65JaarOfOuder,
                ],
                backgroundColor: [
                    'rgba(54, 162, 235, 1)', // light blue
                    'rgba(75, 192, 192, 1)', // teal
                    'rgba(153, 102, 255, 1)', // purple
                    'rgba(255, 159, 64, 1)', // orange
                    'rgba(255, 99, 132, 1)', // red
                ],
            },
        ],
    };

    useEffect(() => {
        if (!ref.current) {
            return;
        }

        const chart = new Chart(ref.current, {
            plugins: [ChartDataLabels],
            type: 'doughnut',
            data: data,
            options: {
                plugins: {
                    datalabels: {
                        display: false,
                    },
                    tooltip: {
                        callbacks: {
                            label: function (context) {
                                if (!context.chart.data.labels) {
                                    return '';
                                }
                                const label = context.chart.data.labels[context.dataIndex];
                                const value = context.chart.data.datasets[0].data[context.dataIndex];
                                return `${label}: ${value}%`;
                            },
                        },
                    },
                    legend: {
                        position: 'right',
                        labels: {
                            generateLabels(chart: Chart): LegendItem[] {
                                return chart.data.datasets[0].data.map((value, index) => {
                                    if (typeof value !== 'number') {
                                        return {
                                            text: '',
                                            hidden: true,
                                            index: index,
                                            datasetIndex: 0,
                                        };
                                    }

                                    if (!chart.data.labels) {
                                        return {
                                            text: '',
                                            hidden: true,
                                            index: index,
                                            datasetIndex: 0,
                                        };
                                    }
                                    return {
                                        text: `${shortendLabels[index]}`,
                                        hidden: isNaN(value),
                                        fillStyle: JSON.parse(JSON.stringify(chart.data.datasets[0].backgroundColor))[
                                            index
                                        ],
                                        index: index,
                                        lineWidth: 0,
                                        datasetIndex: 0,
                                    };
                                });
                            },
                        },
                    },
                },
            },
        });

        return () => {
            chart.destroy();
        };
    }, [data]);

    return neighbourhoodDetailsData ? (
        <>
            <h2>Verdeling leeftijden</h2>
            <canvas id="age_doughnut" key="age_doughnut" ref={ref} />
        </>
    ) : null;
};
