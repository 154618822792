import React from 'react';
import {ReportDownloader, ReportDownloaderProps} from './report_downloader';

export interface ReportDownloaderWidgetProps extends ReportDownloaderProps {
    onClose: () => void;
}

export function ReportDownloaderWidget({onClose, ...restProps}: ReportDownloaderWidgetProps) {
    return (
        <div className="widget-overlay">
            <div className="widget-overlay-container">
                <div className="widget-overlay-title">
                    <span className="widget-overlay-title-icon ion-md-cloud-download">&nbsp;</span>
                    <div className="d-flex">
                        <h2 className="w-auto">Bijlage downloaden</h2>
                    </div>
                    <button className="widget-overlay-close" onClick={() => onClose()}>
                        <span className="ion-md-close">&nbsp;</span>
                    </button>
                </div>
                <ReportDownloader {...restProps} />
            </div>
        </div>
    );
}
