import {action, computed, makeObservable, observable, override, runInAction} from 'mobx';
import {AttachmentProps, AttachmentQuestionPresenter} from '../attachment_question_presenter';
import {Question} from '../../../../../../models/question';
import {Answer} from '../../../../../../models/answer';
import {IteratorFilesAnswerData, IteratorFilesAttachmentProvider} from './iterator_files_attachment_provider';
import {IteratorFilesAttachmentInteractor, IteratorFilesListType} from './iterator_files_attachment_interactor';
import {mergeMap} from 'rxjs/operators';
import {UserType} from '../../../../../../enum/user_type';
import {AttachmentQuestionAvatarData} from '../attachment_question';
import {UserRole} from '../../../../../../enum/user_role';
import {getFileName} from '../../../../../../../support/file_name';

export class IteratorFilesAttachmentPresenter extends AttachmentQuestionPresenter {
    @observable.ref public iterationData: IteratorFilesAnswerData | null = null;
    @observable public willChangeFromList = false;
    @observable public didChangeFromList = false;
    @observable public hasOpenField = false;
    @observable public isEditing = false;

    private ANSWER_OPTION_OTHER = 'Anders';
    private CHECKED_VALUE = '1';

    @computed
    public get isEditable(): boolean {
        // For non appraisers, editing is only possible for own created files when the file is in upload context.
        const global = this.globalProvider.global;
        if (
            global.userType === null ||
            ![UserType.APPRAISER, UserType.EMPLOYEE, UserType.JUNIOR_APPRAISER].includes(global.userType)
        ) {
            return (
                this.answer?.createdByUserId === global.userId &&
                this.iteratorFilesListType === IteratorFilesListType.UPLOAD_LIST
            );
        }
        return true;
    }

    @override
    public override get loading() {
        return this.answer === undefined || this.iterationData?.fileTree.answer === undefined;
    }

    public get openQuestion(): Question | null {
        return this.iterationData?.openTree.question ?? null;
    }

    public get openAnswer(): Answer | null {
        return this.iterationData?.openTree.answer ?? null;
    }

    public get mcQuestion(): Question | null {
        return this.iterationData?.mcTree?.question ?? null;
    }

    public get mcAnswer(): Answer | null {
        return this.iterationData?.mcTree?.answer ?? null;
    }

    public get checkQuestion(): Question | null {
        return this.iterationData?.checkTree?.question ?? null;
    }

    public get checkAnswer(): Answer | null {
        return this.iterationData?.checkTree?.answer ?? null;
    }

    public get fileAnswer(): Answer | null {
        return this.iterationData?.fileTree.answer ?? null;
    }

    constructor(
        private iteratorFilesListType: IteratorFilesListType,
        private iteratorFilesAttachmentProvider: IteratorFilesAttachmentProvider,
        private iteratorFilesAttachmentInteractor: IteratorFilesAttachmentInteractor,
        ...attachmentQuestionPresenterParameters: ConstructorParameters<typeof AttachmentQuestionPresenter>
    ) {
        super(...attachmentQuestionPresenterParameters);
        makeObservable(this);
    }

    public mount(): void {
        super.mount();
        if (this.answer !== undefined && this.answer.iteration !== null) {
            this._subscriptions.add(
                this.iteratorFilesAttachmentProvider
                    .getIterationData(this.answer.iteration)
                    .pipe(
                        mergeMap(async (data) => {
                            const file = data?.fileTree?.answer
                                ? await this.blobCacheInteractor.find(data.fileTree.answer.uuid)
                                : null;
                            return [data, file] as const;
                        })
                    )
                    .subscribe(([data, file]) => {
                        runInAction(() => {
                            this.iterationData = data;
                            this.file = file;
                            this.willChangeFromList =
                                data !== null &&
                                this.iteratorFilesListType === IteratorFilesListType.UPLOAD_LIST &&
                                this.iteratorFilesAttachmentInteractor.willChangeFromList(data);
                            this.didChangeFromList =
                                data !== null &&
                                this.iteratorFilesListType === IteratorFilesListType.FILE_LIST &&
                                this.iteratorFilesAttachmentInteractor.didChangeFromList(data);
                            this.hasOpenField =
                                data !== null &&
                                this.iteratorFilesListType === IteratorFilesListType.UPLOAD_LIST &&
                                this.iteratorFilesAttachmentInteractor.hasOpenField(data);
                        });
                    })
            );
        }
    }

    public unmount(): void {
        super.unmount();

        this._subscriptions.clear();
    }

    // --------------------
    // Functions for attachment answer
    // --------------------

    public async onOpenFile(event: React.MouseEvent) {
        await this.markVisited();

        if (!this.attachmentQuestionsInteractor.isAttachmentModalAvailable()) {
            return; // Default behaviour of link click
        }

        event.preventDefault();

        this.attachmentQuestionsInteractor.showAttachmentModal({
            displayName: this.filename,
            url: this.url,
        });
    }

    public get attachmentTitle(): string | null {
        if (this.mcAnswer !== null && this.mcAnswer.answerOptionId) {
            const answerOption = this.mcQuestion?.answerOptions.find((o) => o.id === this.mcAnswer?.answerOptionId);
            if (this.openAnswer !== null && answerOption?.contents === this.ANSWER_OPTION_OTHER) {
                return this.openAnswer.contents;
            } else if (answerOption !== undefined) {
                return answerOption.contents ?? null;
            }
        } else if (this.openAnswer !== null) {
            return this.openAnswer.contents;
        }
        return this.question.contents;
    }

    public get hasUserAvatar() {
        return this.fileAnswer !== null && this.fileAnswer.file !== null;
    }

    public get shouldShowUnreadIndicator() {
        const userType = this.globalProvider.global?.userType;
        return (
            userType !== null && [UserType.APPRAISER, UserType.EMPLOYEE, UserType.JUNIOR_APPRAISER].includes(userType)
        );
    }

    public get isActive() {
        if (this.checkAnswer && this.checkAnswer.contents !== this.CHECKED_VALUE) {
            return false;
        }
        return (
            this.fileAnswer !== null &&
            this.fileAnswer.file !== null &&
            this.iteratorFilesListType === IteratorFilesListType.FILE_LIST
        );
    }

    public override async markVisited() {
        if (this.fileAnswer !== null) {
            this.answerController.markVisited(this.fileAnswer.uuid);
            await this.answerInteractor.submit();
        }
    }

    public override get avatarData(): AttachmentQuestionAvatarData {
        if (this.fileAnswer) {
            if (
                this.fileAnswer.filledByAutomator ||
                !this.fileAnswer.createdByUserType ||
                !this.fileAnswer.createdByUserRole
            ) {
                // Icon for taXapi AI
                return {
                    iconClass: 'c-icon-system',
                    tooltip: 'Deze bijlage is toegevoegd door taXapi AI',
                    initials: null,
                };
            } else if (
                this.fileAnswer.createdByUserRole !== UserRole.USER ||
                [UserType.APPRAISER, UserType.EMPLOYEE, UserType.JUNIOR_APPRAISER].includes(
                    this.fileAnswer.createdByUserType
                )
            ) {
                // Icon for taXapi admin users, appraisers and employees
                return {
                    iconClass: 'list-avatar-primary-color',
                    tooltip: 'Deze bijlage is toegevoegd door ' + this.fileAnswer.createdByUserName,
                    initials: this.fileAnswer.createdByUserInitials ?? null,
                };
            } else if (this.fileAnswer.createdByUserName) {
                //  Icon for normal users (e.g. client)
                return {
                    iconClass: 'list-avatar-gray ion-md-person',
                    tooltip: 'Deze bijlage is toegevoegd door ' + this.fileAnswer.createdByUserName,
                    initials: null,
                };
            }
        }

        // Fallback
        return {
            iconClass: 'list-avatar-gray ion-md-person',
            tooltip: 'Deze bijlage is toegevoegd door de opdrachtgever',
            initials: null,
        };
    }

    // --------------------
    // Functions for file
    // --------------------

    public get filename(): string | null {
        if (this.file !== null && this.file.name !== null) {
            return getFileName(this.file);
        }
        if (this.fileAnswer !== null) {
            if (
                this.fileAnswer.file !== null &&
                (this.fileAnswer.file.compressedFilename || this.fileAnswer.file.originalFilename)
            ) {
                return this.fileAnswer.file.compressedFilename ?? this.fileAnswer.file.originalFilename;
            }
            const contents = this.fileAnswer.contents
                ? JSON.parse(this.fileAnswer.contents)
                : (null as AttachmentProps | null);
            if (contents !== null) {
                return contents.name;
            }
        }

        return null;
    }

    public get url(): string | null {
        if (this.fileAnswer && this.fileAnswer.file) {
            return this.fileAnswer.file.url;
        }
        if (this.objectUrl === null && this.file !== null && this.file.size > 0) {
            this.createObjectUrl();
        }
        return this.objectUrl;
    }

    public get uncompressedUrl(): string | null {
        if (this.fileAnswer && this.fileAnswer.file) {
            return this.fileAnswer.file.uncompressedUrl;
        }
        if (this.objectUrl === null && this.file !== null && this.file.size > 0) {
            this.createObjectUrl();
        }
        return this.objectUrl;
    }

    public async onFileChange(e: React.ChangeEvent<HTMLInputElement>) {
        if (e.target.files === null || e.target.files.length === 0 || this.fileAnswer === null) {
            return;
        }
        await this.onChangeFile(e.target.files[0], this.fileAnswer);
    }

    // --------------------
    // Functions for iteration
    // --------------------

    public onDeleteClick() {
        if (this.answer) {
            this.answerController.delete(this.answer.uuid);
        }
    }

    @action
    public onToggleEdit() {
        this.isEditing = !this.isEditing;
    }

    public onAcceptOpenField() {
        if (this.answer) {
            this.iteratorFilesAttachmentInteractor.acceptOpenField(this.answer);
        }
    }
}
