import React, {useEffect, useRef} from 'react';
import {NeighbourhoodDetailsData} from '../../../appraising/models/object_summary';
import {Chart} from 'chart.js';
import 'chartjs-gauge-v3';
import ChartDataLabels from 'chartjs-plugin-datalabels';

interface OwnProps {
    neighbourhoodDetailsData: NeighbourhoodDetailsData | null;
}

export const NeighbourhoodHuishoudensGauge: React.FC<OwnProps> = ({neighbourhoodDetailsData}: OwnProps) => {
    if (!neighbourhoodDetailsData?.neighbourhoodBevolking) {
        return null;
    }

    const totalHuishoudens =
        neighbourhoodDetailsData.neighbourhoodBevolking.Eenpersoonshuishoudens.value +
        neighbourhoodDetailsData.neighbourhoodBevolking.HuishoudensMetKinderen.value +
        neighbourhoodDetailsData.neighbourhoodBevolking.HuishoudensZonderKinderen.value;

    const eenpersoonsHuishoudens = +(
        (neighbourhoodDetailsData.neighbourhoodBevolking.Eenpersoonshuishoudens.value / totalHuishoudens) *
        100
    ).toFixed(1);
    const huishoudensMetKinderen = +(
        (neighbourhoodDetailsData.neighbourhoodBevolking.HuishoudensMetKinderen.value / totalHuishoudens) *
        100
    ).toFixed(1);
    const huishoudensZonderKinderen = +(
        (neighbourhoodDetailsData.neighbourhoodBevolking.HuishoudensZonderKinderen.value / totalHuishoudens) *
        100
    ).toFixed(1);

    const showLegend = eenpersoonsHuishoudens > 15 && huishoudensMetKinderen > 15 && huishoudensZonderKinderen > 15;

    const ref = useRef<HTMLCanvasElement>(null);

    useEffect(() => {
        if (!ref.current) {
            return;
        }

        const chart = new Chart(ref.current, {
            plugins: [ChartDataLabels],
            type: 'doughnut',
            data: {
                labels: ['% Eenpersoons', '% Met kinderen', '% Zonder kinderen'],
                datasets: [
                    {
                        data: [eenpersoonsHuishoudens, huishoudensMetKinderen, huishoudensZonderKinderen],
                        backgroundColor: [
                            'rgba(255, 99, 132, 1)', // red
                            'rgba(255, 205, 86, 1)', // yellow
                            'rgb(75,192,134)', // green
                        ],
                        datalabels: {
                            display: showLegend,
                            formatter: function (value, context) {
                                if (context.chart.data.labels) {
                                    return context.chart.data.labels[context.dataIndex];
                                }
                            },
                            color: 'rgba(255, 255, 255, 1)',
                            backgroundColor: 'rgba(0,0,0,0.75)',
                            font: {
                                size: 8,
                                weight: 'bold',
                            },
                        },
                    },
                ],
            },
            options: {
                //@ts-expect-error - chartjs-gauge-v3 types are not up to date
                cutout: '50%',
                rotation: -90,
                circumference: 180,
                plugins: {
                    legend: {
                        display: !showLegend,
                        position: 'bottom',
                        align: 'start',
                    },
                    tooltip: {
                        callbacks: {
                            label: function (context) {
                                if (context.dataIndex === 1) {
                                    return huishoudensMetKinderen.toString(10) + '%';
                                } else if (context.dataIndex === 2) {
                                    return huishoudensZonderKinderen.toString(10) + '%';
                                }
                                return context.formattedValue + '%';
                            },
                        },
                    },
                },
                responsive: true,
                layout: {
                    padding: {
                        left: 30,
                        right: 30,
                        bottom: 140,
                    },
                },
            },
        });

        return () => {
            chart.destroy();
        };
    }, []);

    return neighbourhoodDetailsData ? (
        <>
            <h2>Verdeling huishoudens</h2>
            <div>
                <canvas key="ownership_gauge" id="ownership_gauge" ref={ref} />
            </div>
        </>
    ) : null;
};
