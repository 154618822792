import * as React from 'react';
import {createRoot} from 'react-dom/client';

import {Root} from '../root';
import {Widget} from './widget';

export function renderPrecheckStatisticsEnvironmentInto(element: HTMLElement) {
    createRoot(element).render(
        <Root>
            <Widget />
        </Root>
    );
}
