import {action, computed, makeObservable, observable, runInAction} from 'mobx';
import {CompositeSubscription} from '../support/composite_subscription';
import {Presenter} from '../support/presenter/presenter';
import {SearchAppraisal} from '../appraising/models/search_appraisal';
import {FlashMessageBroadcaster, Type} from '../appraising/business/flash_message_broadcaster';
import {AppraisalApi} from '../appraising/network/appraisal_api';

export class AddresSearchModalPresenter implements Presenter {
    private _subscriptions = new CompositeSubscription();

    @observable public appraisalSearchQuery: string | null = null;
    @observable public searchAppraisals: SearchAppraisal[] | null = null;
    @observable public selectedAppraisals: SearchAppraisal[] = [];
    @observable public isSearchingAppraisals = false;

    @observable
    private appraisalSearchTimeout?: NodeJS.Timeout;

    @observable
    public spaceLeft: number;
    @observable
    private addedAppraisals: SearchAppraisal[];

    constructor(
        private appraisalApi: AppraisalApi,
        private flashMessageBroadcaster: FlashMessageBroadcaster,
        spaceLeft: number,
        addedAppraisals: SearchAppraisal[]
    ) {
        this.spaceLeft = spaceLeft;
        this.addedAppraisals = addedAppraisals;
        makeObservable(this);
    }

    public mount(): void {
        // Unused
    }

    public unmount(): void {
        this._subscriptions.clear();
    }

    @action
    public onUpdatedProps(spaceLeft: number, addedAppraisals: SearchAppraisal[]): void {
        this.spaceLeft = spaceLeft;
        this.addedAppraisals = addedAppraisals;
    }

    @computed
    public get isFull() {
        return this.spaceLeft - this.selectedAppraisals.length <= 0;
    }

    @computed
    public get isAppraisalSearchPending() {
        return this.appraisalSearchTimeout !== undefined;
    }

    public isAlreadyAdded(id: number) {
        return this.addedAppraisals.some((a) => a.id === id);
    }

    @action
    public onSearchAppraisal(query?: string, force = false) {
        if (query !== undefined) {
            this.appraisalSearchQuery = query;
        }

        const search = () => {
            runInAction(() => {
                this.isSearchingAppraisals = true;
                clearTimeout(this.appraisalSearchTimeout);
                this.appraisalSearchTimeout = undefined;
            });
            this.appraisalApi
                .search(this.appraisalSearchQuery ?? '')
                .then((appraisals) => {
                    runInAction(() => {
                        this.searchAppraisals = appraisals;
                    });
                })
                .catch((error) => {
                    console.warn(error);
                    this.flashMessageBroadcaster.broadcast(
                        'Er is een fout opgetreden bij het zoeken naar taxaties.',
                        Type.Danger
                    );
                })
                .finally(() => {
                    runInAction(() => {
                        this.isSearchingAppraisals = false;
                    });
                });
        };

        if (this.appraisalSearchTimeout) {
            clearTimeout(this.appraisalSearchTimeout);
            this.appraisalSearchTimeout = undefined;
        }

        if (force) {
            search();
        } else {
            this.appraisalSearchTimeout = setTimeout(search, 500);
        }
    }

    @action
    public toggleAppraisal(appraisal: SearchAppraisal) {
        if (this.selectedAppraisals.some((a) => a.id === appraisal.id)) {
            this.selectedAppraisals = this.selectedAppraisals.filter((a) => a.id !== appraisal.id);
        } else {
            this.selectedAppraisals.push(appraisal);
        }
    }
}
