import React, {useMemo} from 'react';
import {CoreTaskType} from '../../../../../enum/core_tasks';
import {SimpleTooltip} from '../../../../../components/tooltip';
import {observer} from 'mobx-react';
import {hasCompletedTask, TaskCompletionInfo} from '../appraisal_core_tasks_widget_presenter';
import {classNames} from '../../../../../../support/classnames';

export const CoreTaskItem = observer(function CoreTaskItem({
    task,
    taskIndex,
    itemIndex,
    name,
    description,
    taskCompletion,
    checkboxTooltip,
    disabled,
    children,
}: {
    task: CoreTaskType;
    taskIndex: number;
    itemIndex: number;
    name: string;
    description: React.ReactNode;
    taskCompletion: TaskCompletionInfo;
    checkboxTooltip: string;
    disabled?: boolean;
    children?: React.ReactNode;
}) {
    const {checked, tooltipContent} = useMemo(() => {
        const checked = hasCompletedTask(taskCompletion, task);

        const completionData = taskCompletion.get(task);
        const checklist = Array.isArray(completionData) ? completionData : null;

        let tooltipContent: React.ReactNode = null;

        if (checkboxTooltip && checklist) {
            tooltipContent = (
                <>
                    <p>{checkboxTooltip}</p>
                    <ul className="list-checkmarks list-checkmarks-small">
                        {checklist.map((item, index) => (
                            <li
                                className={classNames({
                                    'ion-md-checkmark': item.completed,
                                    'ion-md-close': !item.completed,
                                })}
                                key={index}
                            >
                                {item.label}
                            </li>
                        ))}
                    </ul>
                </>
            );
        } else {
            tooltipContent = checkboxTooltip;
        }

        return {checked, tooltipContent};
    }, [taskCompletion, checkboxTooltip]);

    return (
        <div className="core-task-item-container">
            <div
                className={classNames('core-task-item', {
                    'core-task-item-disabled': disabled,
                })}
            >
                <div className="core-task-item-step">
                    {taskIndex}.{itemIndex}
                </div>
                <div className="d-inline-block w-100">
                    <div className="form-group mb-0 core-task-item-checkbox">
                        {disabled === true ? null : (
                            <input type="checkbox" id={`core-tasks-${task}`} name={task} checked={checked} readOnly />
                        )}
                        {tooltipContent && disabled !== true ? (
                            <SimpleTooltip content={tooltipContent} placement="top-end" arrowAlignment="end">
                                <label htmlFor={`core-tasks-${task}`}>{name}</label>
                            </SimpleTooltip>
                        ) : (
                            <>
                                <label htmlFor={`core-tasks-${task}`}>{name}</label>
                            </>
                        )}
                    </div>
                    {description && <small className="core-task-item-description">{description}</small>}
                </div>
            </div>
            {children}
        </div>
    );
});
