import React, {useEffect, useState} from 'react';
import {classNames} from '../../../../support/classnames';

export function Collapsible({
    title,
    children,
    light,
    defaultShown,
    onCollapse,
    onOpen,
    className,
}: {
    title?: React.ReactNode;
    children?: React.ReactNode;
    light?: boolean;
    defaultShown?: boolean;
    onCollapse?: () => void;
    onOpen?: (value: boolean) => void;
    className?: string;
}) {
    const [collapsed, setCollapsed] = useState(defaultShown !== true);
    const innerRef = React.useRef<HTMLDivElement>(null);
    const [childHeight, setChildHeight] = useState<string | null>(collapsed ? '0' : '100vh');

    useEffect(() => {
        if (!innerRef.current) {
            return;
        }

        if (collapsed) {
            if (childHeight === null) {
                // First set max height back to actual value for smooth transition
                setChildHeight(`${innerRef.current.scrollHeight + 100}px`);
            } else {
                const timeout = setTimeout(() => {
                    setChildHeight('0');
                }, 10);
                return () => clearTimeout(timeout);
            }
        } else if (childHeight !== null) {
            setChildHeight(`${innerRef.current.scrollHeight + 100}px`);
            const timeout = setTimeout(() => {
                setChildHeight(null);
            }, 500);
            return () => clearTimeout(timeout);
        }
    }, [collapsed, childHeight]);

    return (
        <div
            className={classNames(
                'group-collapsible',
                {
                    'group-collapsible-visible': !collapsed,
                    'group-collapsible-light': light,
                },
                className
            )}
        >
            <a
                className={classNames('group-collapsible-header', {
                    'ion-ios-arrow-down': collapsed,
                    'ion-ios-arrow-up': !collapsed,
                })}
                onClick={() => {
                    if (onCollapse && !collapsed) {
                        onCollapse();
                    }
                    if (collapsed && onOpen) {
                        onOpen(collapsed);
                    }
                    setCollapsed(!collapsed);
                }}
            >
                {title}
            </a>
            <div
                className="group-collapsible-children controlled"
                style={childHeight ? {maxHeight: childHeight} : undefined}
            >
                <div className="group-collapsible-inner" ref={innerRef}>
                    {children}
                </div>
            </div>
        </div>
    );
}
