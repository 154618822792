import {ValidationMessage, ValidationMessageImportance, ValidationMessageType} from '../validation_message';

import {Answer} from '../../../models/answer';
import {AnswerController} from '../../answering/answer_controller';
import {AnswerValidator} from '../answer_validator';
import {Question} from '../../../models/question';
import {TechnicalReference} from '../../../enum/technical_reference';
import {QuestionSet} from '../../../models/question_set';

export class GrondgebondenAkteValidator implements AnswerValidator {
    constructor(private answerController: AnswerController, private questionSet: QuestionSet) {}

    public validate(question: Question, answer: Answer | null): ValidationMessage[] {
        if (question.technicalReference === TechnicalReference.ATTACHMENT_AKTE_VAN_LEVERING) {
            const grondGebondenQuestion = this.questionSet.findQuestionByTechnicalReference(
                TechnicalReference.CADASTRAL_GRONDGEBONDEN
            );

            if (grondGebondenQuestion === undefined || grondGebondenQuestion === null) {
                return [];
            }

            const grondGebondenAnswers = this.answerController
                .answersForQuestionUuid(grondGebondenQuestion.uuid)
                .filter((answer) => {
                    return !answer.isDeleted;
                });

            const AkteVanLeveringAnswers = this.answerController.answersForQuestionUuid(question.uuid);

            const AkteVanLeveringAnswersWithFileReference = AkteVanLeveringAnswers.filter(
                (answer) => answer.file !== null && !answer.isDeleted
            );

            if (grondGebondenAnswers.length === 0 && AkteVanLeveringAnswersWithFileReference.length === 0) {
                return [];
            }
            if (grondGebondenAnswers.length > AkteVanLeveringAnswersWithFileReference.length) {
                return [
                    {
                        type: ValidationMessageType.TAXAPI,
                        importance: ValidationMessageImportance.ERROR,
                        question: question,
                        answer: answer,
                        messages: [],
                        fallbackMessage: `Het aantal aktes moet gelijk zijn aan het aantal grondgebonden percelen. Op dit moment ${
                            grondGebondenAnswers.length > 1 ? 'zijn' : 'is'
                        } er ${grondGebondenAnswers.length} grondgebonden ${
                            grondGebondenAnswers.length > 1 ? 'percelen' : 'perceel'
                        } toegevoegd en ${grondGebondenAnswers.length > 1 ? 'zijn' : 'is'} er ${
                            AkteVanLeveringAnswersWithFileReference.length === 0
                                ? 'geen'
                                : AkteVanLeveringAnswersWithFileReference.length
                        } ${grondGebondenAnswers.length > 1 ? 'aktes' : 'akte'} toegevoegd.`,
                        floorInfo: null,
                        errorType: null,
                    },
                ];
            } else {
                return [];
            }
        } else {
            return [];
        }
    }
}
