import React from 'react';
import {classNames} from '../../../support/classnames';

export function PaymentBlur({
    children,
    active,
    overlay,
}: {
    children: React.ReactNode;
    active?: boolean;
    overlay?: React.ReactNode;
}) {
    return (
        <div
            className={classNames({
                'payment-required-blur': active !== false,
            })}
        >
            <div className="payment-required-blur-container">{children}</div>
            {active !== false && (
                <div className="payment-required-blur-overlay">
                    <div className="d-flex flex-column align-items-center justify-content-center h-100">{overlay}</div>
                </div>
            )}
        </div>
    );
}
