import * as Uuid from 'uuid';

import {action, makeObservable, observable, runInAction} from 'mobx';

import {IteratorPresenter} from './iterator_presenter';
import {Uppy} from '@uppy/core';
import {
    AnswerFilePair,
    AttachmentUploadInteractor,
} from '../../../../../business/attachments/attachment_upload_interactor';
import {NormalQuestionType} from '../../../../../enum/question_type';
import {Question} from '../../../../../models/question';
import {
    IteratorFilesAnswerData,
    IteratorFilesAttachmentProvider,
} from './iterator_files/iterator_files_attachment_provider';
import {IteratorFilesAttachmentInteractor} from './iterator_files/iterator_files_attachment_interactor';
import {combineLatest, of, timer} from 'rxjs';
import {distinctUntilChanged, map} from 'rxjs/operators';
import {partition} from '../../../../../../support/partition_array';
import {UserType} from '../../../../../enum/user_type';
import {GlobalProvider} from '../../../../../../business/global_provider';
import {FlashMessageBroadcaster} from '../../../../../business/flash_message_broadcaster';
import {PagePartsSet} from '../../../../../models/page_parts_set';
import {IteratorFilesCustomizationType} from './iterator_files_customizer_presenter';

export class IteratorFilesPresenter extends IteratorPresenter {
    @observable.ref public completeIterations: string[] = [];
    @observable.ref public incompleteIterations: string[] = [];
    @observable public canAddFiles = true;
    @observable public iteratorProgress: Record<string, number> = {};
    @observable public uppy: Uppy;
    @observable public isAvailable = true;
    @observable public errorMessage: string | null = null;
    private clientMaxFileCount = 10;

    constructor(
        private fileTypes: string[],
        private attachmentUploadInteractor: AttachmentUploadInteractor,
        private iteratorFilesAttachmentProvider: IteratorFilesAttachmentProvider,
        private iteratorFilesAttachmentInteractor: IteratorFilesAttachmentInteractor,
        private globalProvider: GlobalProvider,
        private flashMessageBroadcaster: FlashMessageBroadcaster,
        private pagePartsSet: PagePartsSet | null,
        ...iteratorPresenterParameters: ConstructorParameters<typeof IteratorPresenter>
    ) {
        super(...iteratorPresenterParameters);
        makeObservable(this);

        const uppy = new Uppy();
        uppy.on('files-added', (uppyFiles) => {
            const attachmentQuestion = this.questionSet.findChildByPredicateRecursive(
                this.question,
                (q) => q.type === NormalQuestionType.ATTACHMENT
            );
            if (attachmentQuestion === null) {
                return;
            }

            const currentFileCount = this.completeIterations.length + this.incompleteIterations.length;
            const totalFileCount = currentFileCount + uppyFiles.length;

            runInAction(() => {
                this.canAddFiles = this.isAppraiser || totalFileCount <= this.clientMaxFileCount;
            });

            let files = uppyFiles.map((uppyFile) => uppyFile.data).filter((file): file is File => 'name' in file);
            if (!this.isAppraiser) {
                // Non appraisers can only upload 10 files, so remove t files above 10.
                files = files.slice(0, Math.max(0, this.clientMaxFileCount - currentFileCount));
            }

            if (files.length > 0) {
                if (this.errorMessage != null) {
                    this.clearError();
                }

                const answerFilePairs = files.map((file) => {
                    return this.createAnswerFilePair(file, attachmentQuestion);
                });

                this.attachmentUploadInteractor.uploadIteratedFilesForAnswers(
                    answerFilePairs,
                    {fileTypes: this.fileTypes},
                    {
                        updateAnswerFilePairsProgress: (iteration: string, progress: number) => {
                            runInAction(() => {
                                this.iteratorProgress[iteration] = progress;
                            });
                        },
                        alertNotAvailable: () => {
                            for (const pair of answerFilePairs) {
                                this.answerController.delete(pair.iteratorAnswerUuid);
                            }
                            this.alertNotAvailable();
                        },
                        alertIncorrectType: (file, pair) => {
                            this.answerController.delete(pair.iteratorAnswerUuid);
                            this.alertIncorrectType(file);
                        },
                        handleError: (iteration, error) => {
                            this.alertError(error);
                            this.removeIteration(iteration);
                        },
                    }
                );
                this.uppy.reset();

                // 4. Add iterations if needed
                for (const answerFilePair of answerFilePairs) {
                    this.addIteration(answerFilePair.iteration);
                }
            }
        });

        this.uppy = uppy;
    }

    public async mount(): Promise<void> {
        await super.mount();

        combineLatest([
            timer(500, 2000),
            this.iteratorFilesAttachmentProvider.stream(),
            this.pagePartsSet
                ? this.pagePartsSet.customizations.streamByQuestionUuid<IteratorFilesCustomizationType>(
                      this.question.uuid
                  )
                : of(null),
        ])
            .pipe(
                map(([, iterratorDatas, customizations]) => {
                    const {matched: complete, unmatched: incomplete} = partition(
                        iterratorDatas.filter((item) => item.iteration.iteration !== null),
                        (item) => this.iteratorFilesAttachmentInteractor.isFinished(item)
                    );

                    if (customizations) {
                        return {
                            complete: complete.sort((a, b) => this.sortIterations(a, b, customizations.order)),
                            incomplete,
                        };
                    }

                    return {complete, incomplete};
                }),
                map(({complete, incomplete}) => {
                    const completeIterations = complete
                        .filter((item) => this.isIterationVisibleForUser(item))
                        .map((item) => item.iteration.iteration)
                        .filter((iteration): iteration is string => iteration !== null);
                    const incompleteIterations = incomplete
                        .filter((item) => this.isIterationVisibleForUser(item))
                        .map((item) => item.iteration.iteration)
                        .filter((iteration): iteration is string => iteration !== null);

                    return {completeIterations, incompleteIterations};
                }),
                distinctUntilChanged((a, b) => {
                    if (
                        a.completeIterations.length !== b.completeIterations.length ||
                        a.incompleteIterations.length !== b.incompleteIterations.length
                    ) {
                        return false;
                    }

                    return (
                        a.completeIterations.join(',') + a.incompleteIterations.join(',') ===
                        b.completeIterations.join(',') + b.incompleteIterations.join(',')
                    );
                })
            )
            .subscribe(({completeIterations, incompleteIterations}) => {
                runInAction(() => {
                    this.completeIterations = completeIterations;
                    this.incompleteIterations = incompleteIterations;
                    this.canAddFiles =
                        completeIterations.length + incompleteIterations.length < this.clientMaxFileCount;
                });
            });
    }

    private isIterationVisibleForUser(data: IteratorFilesAnswerData): boolean {
        const global = this.globalProvider.global;

        // The client can see the attachment when one of the answers is created by the client.
        if (!this.isAppraiser) {
            for (const answer of [data.iteration, data.fileTree.answer, data.openTree.answer, data.mcTree?.answer]) {
                if (answer?.createdByUserId === global.userId) {
                    return true;
                }
            }
        }

        // The iteration is always visible for the appraiser. Only when the iteration is created by the user, it is
        // visible for the client. Also directly after creating, when createdByUserId is undefined this means the
        // iteration was created by the current user and should be visible.
        return (
            this.isAppraiser ||
            data.iteration.createdByUserId === global.userId ||
            data.iteration.createdByUserId === undefined ||
            data.iteration.createdByUserId === null
        );
    }

    private sortIterations(a: IteratorFilesAnswerData, b: IteratorFilesAnswerData, order: string[]) {
        if (!a.mcTree?.answer?.answerOptionId || !b.mcTree?.answer?.answerOptionId) {
            return 0;
        }

        const aAnswerOption = a.mcTree.question.answerOptions.find(
            (answerOption) => answerOption.id === a.mcTree?.answer?.answerOptionId
        );
        const bAnswerOption = b.mcTree.question.answerOptions.find(
            (answerOption) => answerOption.id === b.mcTree?.answer?.answerOptionId
        );

        if (!aAnswerOption || !bAnswerOption) {
            return 0;
        }

        const aIndex = order.indexOf(aAnswerOption.contents);
        const bIndex = order.indexOf(bAnswerOption.contents);

        if (aIndex === -1 || bIndex === -1) {
            return 0;
        }

        return aIndex - bIndex;
    }

    private get isAppraiser(): boolean {
        const userType = this.globalProvider.global?.userType;
        return (
            userType !== null && [UserType.APPRAISER, UserType.EMPLOYEE, UserType.JUNIOR_APPRAISER].includes(userType)
        );
    }

    public get isUploaderVisible(): boolean {
        const global = this.globalProvider.global;
        if (this.isAppraiser) {
            return true;
        }
        return global.clientExtraFilesEnabled ?? true;
    }

    public get canAddIteration(): boolean {
        if (this.isAppraiser) {
            return true;
        }
        return this.canAddFiles;
    }

    public override addIteration(iteration?: string) {
        runInAction(() => {
            if (this.iterations === undefined) {
                this.iterations = [];
            }
            if (iteration === undefined) {
                iteration = Uuid.v4();
            }
            if (!this.iterations.includes(iteration)) {
                this.iterations = [...this.iterations, iteration];
            }
        });
    }

    public removeIteration(iteration: string) {
        const answer = this.answerController.answerByIdentifiers(
            this.question.uuid,
            this.parentAnswerUuid ?? null,
            iteration
        );
        if (answer) {
            this.answerController.delete(answer.uuid);
        }
    }

    private createAnswerFilePair(file: File, attachmentQuestion: Question): AnswerFilePair {
        const iteration = Uuid.v4();
        const iteratorAnswer = this.answerController.answerByIdentifiersOrStub(
            this.question.uuid,
            this.parentAnswerUuid ?? null,
            iteration
        );
        const attachmentAnswer = this.answerController.answerByIdentifiersOrStub(
            attachmentQuestion.uuid,
            iteratorAnswer.uuid,
            null
        );
        return {
            iteratorAnswerUuid: iteratorAnswer.uuid,
            answerUuid: attachmentAnswer.uuid,
            iteration: iteration,
            file: file,
        };
    }

    @action
    private clearError() {
        this.errorMessage = null;
    }

    @action
    private alertError(error: string) {
        this.errorMessage = error;
    }

    @action
    private alertIncorrectType(file: File) {
        this.errorMessage = `Fout bij het uploaden van "${file.name}". Is het een correct formaat? Voor bijlagen kun je .pdf bestanden uploaden.`;
    }

    @action
    private alertNotAvailable() {
        this.errorMessage = 'Offline opslag niet beschikbaar op dit apparaat.';
        this.isAvailable = false;
    }
}
