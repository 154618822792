import React, {useMemo} from 'react';
import {Collapsible} from '../../../components/collapsible';
import {CoreTaskType} from '../../../../../enum/core_tasks';
import {observer} from 'mobx-react';
import {hasCompletedTask, TaskCompletionInfo} from '../appraisal_core_tasks_widget_presenter';

export const CoreTask = observer(function CoreTask({
    index,
    name,
    children,
    taskCompletion,
    subtasks,
}: {
    index: number;
    name: string;
    children?: React.ReactNode;
    taskCompletion: TaskCompletionInfo;
    subtasks: Map<number, CoreTaskType[]>;
}) {
    const subtasksCount = useMemo(() => {
        return subtasks.get(index)?.length ?? 0;
    }, [subtasks, index]);

    const completedTasksCount = useMemo(() => {
        return subtasks.get(index)?.filter((subtask) => hasCompletedTask(taskCompletion, subtask)).length ?? 0;
    }, [subtasks, taskCompletion, index]);

    return (
        <Collapsible
            className="core-task"
            light
            title={
                <div className="d-flex flex-row align-items-center w-100 pr-3">
                    <h2 className="m-0">
                        <span className="core-task-step">{index}</span> {name}{' '}
                    </h2>
                    {subtasksCount === completedTasksCount ? (
                        <span className="text-nowrap text-right mr-2 ion-md-checkmark-circle" />
                    ) : (
                        <span className="text-nowrap text-right mr-2" style={{minWidth: 50}}>
                            <strong>{completedTasksCount}</strong> / {subtasksCount}
                        </span>
                    )}
                </div>
            }
        >
            {children}
        </Collapsible>
    );
});
