import {IteratorQuestionType, NormalQuestionType} from '../../../enum/question_type';
import {ValidationMessage, ValidationMessageImportance, ValidationMessageType} from '../validation_message';

import {Answer} from '../../../models/answer';
import {AnswerController} from '../../answering/answer_controller';
import {AppraisalProvider} from '../../appraisal_provider';
import {AppraiseModel, isAppraiseModel, isAppraiseModelOrNewer} from '../../../enum/appraise_model';
import {Question} from '../../../models/question';
import {QuestionEffectInteractor} from '../../conditions/question_effects_interactor';
import {QuestionSet} from '../../../models/question_set';
import {QuestionValidator} from '../question_validator';
import {TechnicalReference} from '../../../enum/technical_reference';
import {findAllChildrenForQuestionUuid} from '../../../appraise/ui/support/question_filtering';

export class PhotoIteratorValidator implements QuestionValidator {
    constructor(
        private questionSet: QuestionSet,
        private answerController: AnswerController,
        private questionEffectInteractor: QuestionEffectInteractor,
        private appraisalProvider: AppraisalProvider
    ) {}

    public validate(questionUuid: string): ValidationMessage[] {
        const question = this.questionSet.findQuestionByUuid(questionUuid);
        if (question === undefined) {
            return [];
        }

        if (question.type !== IteratorQuestionType.PHOTO_ITERATOR) {
            return this.questionSet
                .findChildQuestionsByParentUuid(questionUuid)
                .reduce((p: ValidationMessage[], c: Question) => {
                    return [...p, ...this.validate(c.uuid)];
                }, []);
        }

        const answers = this.answerController.answersForQuestionUuid(question.uuid).filter((answer) => {
            return !this.questionEffectInteractor.isHidden(answer.questionUuid, answer.parentUuid) && !answer.isDeleted;
        });

        const messages: ValidationMessage[] = [];
        this.validateRequiredIterator(question, answers).forEach((m) => {
            messages.push(m);
        });
        this.validateMaxAnswers(question, answers).forEach((m) => {
            messages.push(m);
        });
        this.validateLabelConstruction(question).forEach((m) => {
            messages.push(m);
        });
        return messages;
    }

    private validateMaxAnswers(question: Question, answers: Answer[]): ValidationMessage[] {
        const appraisal = this.appraisalProvider.appraisal;
        let maxLength = 30;
        if (isAppraiseModelOrNewer(appraisal.appraiseModel, AppraiseModel.MODEL2021)) {
            maxLength = 50;
        }

        if (answers.length <= maxLength) {
            return [];
        }
        return [
            {
                type: ValidationMessageType.TAXAPI,
                importance: ValidationMessageImportance.ERROR,
                question: question,
                answer: null,
                messages: [],
                fallbackMessage:
                    'Er zijn ' +
                    answers.length +
                    " foto's toegevoegd. Het maximum aantal is 50 foto's. Archiveer enkele foto's en probeer het opnieuw.",
                floorInfo: null,
                errorType: null,
            },
        ];
    }

    private validateRequiredIterator(question: Question, answers: Answer[]): ValidationMessage[] {
        if (answers.length > 0) {
            return [];
        }
        if (!question.isRequired) {
            return [];
        }
        return [
            {
                type: ValidationMessageType.TAXAPI,
                importance: ValidationMessageImportance.ERROR,
                question: question,
                answer: null,
                messages: [],
                fallbackMessage: 'Voeg in tenminste één foto toe.',
                floorInfo: null,
                errorType: null,
            },
        ];
    }

    private validateLabelConstruction(question: Question): ValidationMessage[] {
        const appraisal = this.appraisalProvider.appraisal;

        if (!appraisal.improvementsPlanned) {
            return [];
        }
        if (isAppraiseModel(appraisal, [AppraiseModel.WOCO2016, AppraiseModel.MODEL2018])) {
            return [];
        }

        const labelQuestion = findAllChildrenForQuestionUuid(question.uuid, this.questionSet).find((q) =>
            [NormalQuestionType.MC_SELECT, NormalQuestionType.MC_SELECT_OPTIONAL].some((qt) => qt === q.type)
        );

        if (labelQuestion === undefined) {
            return [];
        }

        const constructionAnswerOption = labelQuestion.answerOptions.find((answerOption) =>
            answerOption.technicalReference.includes(TechnicalReference.PHOTO_LABEL_CONSTRUCTION)
        );

        if (constructionAnswerOption === undefined) {
            return [];
        }

        const labelAnswers = this.answerController.answersForQuestionUuid(labelQuestion.uuid).filter((answer) => {
            return !this.questionEffectInteractor.isHidden(answer.questionUuid, answer.parentUuid) && !answer.isDeleted;
        });

        if (labelAnswers.some((answer) => answer.answerOptionId === constructionAnswerOption.id)) {
            return [];
        }

        return [
            {
                type: ValidationMessageType.TAXAPI,
                importance: ValidationMessageImportance.WARNING,
                question: question,
                answer: null,
                messages: [],
                fallbackMessage:
                    'Er is sprake van een verbouwing, maar er is geen aparte foto van een verbouwing toegevoegd. Zijn alle ruimtes welke een verbouwing betreffen toegevoegd als foto?',
                floorInfo: null,
                errorType: null,
            },
        ];
    }
}
