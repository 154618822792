import {BehaviorSubject, Observable} from 'rxjs';

import {partition} from '../../support/partition_array';

export interface BaseStackConfig {
    id: string | number;
    onClose?: () => void;
}

export enum ResultType {
    SUCCEEDED = 'succeeded',
    DISCARDED = 'discarded',
}

interface BaseResult {
    type: ResultType;
}

interface SucceededResult<T> extends BaseResult {
    type: ResultType.SUCCEEDED;
    result: T;
}

interface DiscardedResult extends BaseResult {
    type: ResultType.DISCARDED;
    reason?: string;
}

export type ResultPromise<T> = SucceededResult<T> | DiscardedResult;

export interface ConfigStackInteractor<TConfig extends BaseStackConfig> {
    stream(): Observable<Array<ConfigWithCallbacks<TConfig, unknown>>>;
    insert<T>(config: TConfig): Promise<ResultPromise<T>>;
    upsert(config: TConfig): void;
    remove(predicate: (config: string | number | TConfig) => boolean): void;
}

interface Callbacks<TResult> {
    onAbort: (errorMessage: string) => void;
    onSucceed: (result: TResult) => void;
    onDiscard: (reason?: string) => void;
}

export type ConfigWithCallbacks<TConfig extends BaseStackConfig, TResult> = Callbacks<TResult> & TConfig;

export class DefaultConfigStackInteractor<TConfig extends BaseStackConfig> implements ConfigStackInteractor<TConfig> {
    private observable = new BehaviorSubject<Array<ConfigWithCallbacks<TConfig, unknown>>>([]);

    public stream(): Observable<Array<ConfigWithCallbacks<TConfig, unknown>>> {
        return this.observable.asObservable();
    }

    public insert<T>(config: TConfig): Promise<ResultPromise<T>> {
        return new Promise((resolve, reject) => {
            const newConfig: ConfigWithCallbacks<typeof config, T> = {
                ...config,
                onAbort: (errorMessage: string) => {
                    reject(errorMessage);
                    const newStack = this.observable.getValue().filter((stack) => stack.id !== newConfig.id);
                    this.observable.next(newStack);
                    if (config.onClose !== undefined && 'onClose' in config) {
                        config.onClose();
                    }
                },
                onSucceed: (result: T) => {
                    resolve({type: ResultType.SUCCEEDED, result});
                    const newStack = this.observable.getValue().filter((stack) => stack.id !== newConfig.id);
                    this.observable.next(newStack);
                    if (config.onClose !== undefined && 'onClose' in config) {
                        config.onClose();
                    }
                },
                onDiscard: (reason?: string) => {
                    resolve({type: ResultType.DISCARDED, reason});
                    const newStack = this.observable.getValue().filter((stack) => stack.id !== newConfig.id);
                    this.observable.next(newStack);
                    if (config.onClose !== undefined && 'onClose' in config) {
                        config.onClose();
                    }
                },
            };

            const newStack = [...this.observable.getValue(), newConfig as ConfigWithCallbacks<TConfig, unknown>];
            this.observable.next(newStack);
        });
    }

    public upsert(config: TConfig) {
        const stackCopy = [...this.observable.getValue()];
        const oldConfigIndex = stackCopy.findIndex((c) => c.id === config.id);
        if (oldConfigIndex !== -1) {
            const oldConfig = stackCopy[oldConfigIndex];
            const newConfig = {
                ...oldConfig,
                ...config,
            };
            stackCopy[oldConfigIndex] = newConfig;
            this.observable.next(stackCopy);
        } else {
            this.insert(config);
        }
    }

    public remove(predicate: string | number | ((config: TConfig) => boolean)) {
        const currentStack = this.observable.getValue();
        const {unmatched, matched} = partition(currentStack, (i) => {
            return typeof predicate === 'function' ? predicate(i) : predicate === i.id;
        });

        for (const removed of matched) {
            removed.onDiscard('closed');
        }

        //Only update if we actually removed something
        if (unmatched.length > 0 && matched.length > 0) {
            this.observable.next(unmatched);
        }
    }
}
