import {Answer} from '../../models/answer';
import {Question} from '../../models/question';
import {FloorInfo} from '../floor_interactor';

export enum ValidationMessageType {
    TAXAPI = 'taxapi',
    VALIDATION_INSTITUTE = 'validation-institute',
}

export enum ValidationMessageImportance {
    ERROR = 'error',
    WARNING = 'warning',
    INFO = 'info',
}

export enum ValidationMessageErrorType {
    NOT_VISITED = 'not-visited',
    REFERENCES_INVALID = 'references-invalid',
    MISSING_FLOOR = 'missing-floor',
    MISSING_REQUIRED_ANSWER = 'missing-required-answer',
}

export interface ValidationMessage {
    type: ValidationMessageType;
    importance: ValidationMessageImportance;
    question: Question | null;
    answer: Answer | null;
    title?: string; //By default a validation message will be rendered using the question content
    messages: string[];
    fallbackMessage: string | null;
    floorInfo: FloorInfo | null;
    errorType: ValidationMessageErrorType | null;
}

export type ValidationMessageMap = Partial<Record<string, ValidationMessage[]>>;
