import {Answer} from '../../../../../models/answer';
import {NormalQuestionType} from '../../../../../enum/question_type';
import {Question} from '../../../../../models/question';
import {isEmpty} from '../../../../../../support/util';

interface Pair {
    question: Question;
    answer: Answer;
}

export function getAttachmentDisplayName(attachmentPair: Pair, siblingPairs: Pair[]): string | null {
    let result: string | null = null;
    const mcSelectPair = siblingPairs.find(
        (p) =>
            p.question.type === NormalQuestionType.MC_SELECT ||
            p.question.type === NormalQuestionType.MC_SELECT_OPTIONAL ||
            p.question.type === NormalQuestionType.MC
    );

    // Get title from MC pair (attachments question with MC question to set title)
    if (mcSelectPair) {
        const openQuestionPair = siblingPairs.find(
            (p) => p.question.type === NormalQuestionType.OPEN || p.question.type === NormalQuestionType.OPEN_COMPACT
        );
        const shouldUseOpenAnswer = openQuestionPair?.question.conditionGroups.some((conditionGroup) => {
            return conditionGroup.conditions.some((condition) => {
                return (
                    condition.questionUuid === mcSelectPair.question.uuid &&
                    condition.condition === '==' &&
                    condition.value === String(mcSelectPair.answer.answerOptionId)
                );
            });
        });

        if (shouldUseOpenAnswer === true && openQuestionPair) {
            result = openQuestionPair.answer.contents;
        } else {
            const selectedAnswerOption = mcSelectPair.question.answerOptions.find(
                (answerOption) => answerOption.id === mcSelectPair.answer.answerOptionId
            );
            result = selectedAnswerOption ? selectedAnswerOption.contents : null;
        }

        // If there is no title, use the file name
        if (result === null) {
            result = attachmentPair.answer.file
                ? attachmentPair.answer.file.compressedFilename ?? attachmentPair.answer.file.originalFilename
                : null;
        }
    } else {
        // Default for attachments without MC options to set title
        result = attachmentPair.question.contents;

        // When only an open question is specified in the attachment iteration, use this contents.
        const openQuestionPair = siblingPairs.find((p) => p.question.type === NormalQuestionType.OPEN_COMPACT);
        if (openQuestionPair && !isEmpty(openQuestionPair.answer.contents)) {
            result = openQuestionPair.answer.contents;
        }
    }

    return result;
}
