import {ValidationMessage, ValidationMessageImportance, ValidationMessageType} from '../validation_message';

import {Answer} from '../../../models/answer';
import {AnswerController} from '../../answering/answer_controller';
import {AnswerValidator} from '../answer_validator';
import {IsRequiredCalculator} from '../internal/is_required_calculator';
import {NormalQuestionType} from '../../../enum/question_type';
import {Question} from '../../../models/question';
import {QuestionSet} from '../../../models/question_set';
import {TechnicalReference} from '../../../enum/technical_reference';

export class ReferenceObjectValidator implements AnswerValidator {
    constructor(
        private questionSet: QuestionSet,
        private isRequiredCalculator: IsRequiredCalculator,
        private answerController: AnswerController
    ) {}

    public validate(question: Question, answer: Answer | null): ValidationMessage[] {
        if (question.type !== NormalQuestionType.REFERENCE_OBJECTS) {
            return [];
        }

        if (!this.isRequiredCalculator.isRequired(question, answer)) {
            return [];
        }

        if (answer !== null && answer.contents !== null && answer.contents.length > 0) {
            if (this.getObjects(answer).length > 0) {
                return [];
            }
        }

        const validationRequiredQuestion = this.questionSet.findQuestionByTechnicalReference(
            TechnicalReference.VALUATION_REQUIRES_REFERENCE_OBJECTS
        );
        const valuationRequiredAnswer = validationRequiredQuestion
            ? this.answerController.answersForQuestionUuid(validationRequiredQuestion.uuid)[0]
            : null;

        if (
            valuationRequiredAnswer !== undefined &&
            valuationRequiredAnswer !== null &&
            valuationRequiredAnswer.contents === '0'
        ) {
            return [];
        }

        return [
            {
                type: ValidationMessageType.TAXAPI,
                importance: ValidationMessageImportance.ERROR,
                question: question,
                answer: answer,
                messages: [],
                fallbackMessage: 'Het is verplicht om 3 of meer referenties op te voeren.',
                floorInfo: null,
                errorType: null,
            },
        ];
    }

    private getObjects(answer: Answer) {
        try {
            if (answer.contents === null) {
                return [];
            }
            return JSON.parse(answer.contents);
        } catch (e) {
            //Corrupt?
            return [];
        }
    }
}
