import React from 'react';
import {createRoot} from 'react-dom/client';

import {observer} from 'mobx-react';
import {usePresenter} from '../support/presenter/use_presenter';
import {FlashMessage} from '../appraising/appraise/ui/container/flash_message';
import {AppraisalArchivesStreamingBatchPresenter} from './streaming_batch_presenter';
import {classNames} from '../support/classnames';

const AppraisalArchivesStreamingBatch: React.FC = observer(() => {
    const presenter = usePresenter(
        (component) =>
            new AppraisalArchivesStreamingBatchPresenter(
                component.business.flashMessageBroadcaster,
                component.network.taskHelper
            )
    );

    return (
        <>
            <div className="card-body">
                <div className="messages-stack">
                    <FlashMessage />
                </div>

                <div className="row">
                    <div className="col-12">
                        {presenter.downloadUrl === null ? (
                            <div className="empty-message">
                                <h3>Back-up samenstellen</h3>
                                <div className="loader-logo-container">
                                    <div className="loader-logos loader-fade-in-out mt-0">
                                        <img
                                            src="/images/icon-taxapi.svg"
                                            className={classNames({
                                                'loader-fade-out': presenter.appraisalLiveStatus.length > 0,
                                            })}
                                        />
                                        {presenter.appraisalLiveStatus
                                            .map((status, i) => (
                                                <img
                                                    src={status.appraisal.streetviewUrl}
                                                    key={status.appraisal.id}
                                                    className={classNames('loader-logo-full-size', {
                                                        'loader-fade-out': i > 0,
                                                    })}
                                                />
                                            ))
                                            .reverse()}
                                    </div>
                                    <div className="loader">
                                        <div className="loader-progress-overlay">
                                            <div id="loader-total-progress"></div>
                                        </div>
                                        <span>&nbsp;</span>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-center mt-3 mb-3">
                                    {presenter.appraisalLiveStatus.length > 0 && (
                                        <strong className="btn-circle" style={{fontSize: 14}}>
                                            {presenter.appraisalsDoneCount}/{presenter.batchAppraisals.length}
                                        </strong>
                                    )}
                                </div>
                                <div
                                    className="d-flex justify-content-center position-relative"
                                    style={{minHeight: 250}}
                                >
                                    <div className="text-left">
                                        {presenter.appraisalLiveStatus.map(({appraisal, status}) => (
                                            <div className="row" key={appraisal.id}>
                                                <div className="col-2">
                                                    <span
                                                        className={classNames({
                                                            'ion-md-checkmark-circle': status === 'processed',
                                                            'ion-md-code-working': status === 'processing',
                                                        })}
                                                    />
                                                </div>
                                                <div className="col-8">
                                                    <small className="text-nowrap">
                                                        {appraisal.address} {appraisal.houseNumber} {appraisal.letter}
                                                    </small>
                                                </div>
                                            </div>
                                        ))}
                                        <div className="loader-overflow-gradient" />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div style={{marginTop: 90}}>
                                <div className="card-icon-container card-icon-container-bg">
                                    <div className="card-icon card-icon-animated card-icon-green ion-md-cloud-download"></div>
                                </div>

                                <div className="col-12 text-center">
                                    <strong>De back-up is klaar om te downloaden.</strong>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <footer className="d-flex justify-content-end">
                <a className="btn btn-secondary" href="/account/me/appraisal-archive">
                    Terug
                </a>
                {presenter.downloadUrl ? (
                    <a className="btn btn-primary" href={presenter.downloadUrl}>
                        Download
                    </a>
                ) : (
                    <button className="btn btn-primary" disabled>
                        Download
                    </button>
                )}
            </footer>
        </>
    );
});

export function renderAppraisalArchivesStreamingBatchInto(element: HTMLElement) {
    createRoot(element).render(<AppraisalArchivesStreamingBatch />);
}
