import {ValuationOverview} from '../../models/valuation_overview';

export interface ApiValuationOverview {
    woz_values: {
        reference_date: string;
        value: number;
        is_estimated: boolean;
    }[];
    altum_transactions: {
        id: string;
        asking_price: number;
        nr_of_days_offered: number;
        offered_since_date: string;
        sale_date: string;
        original_asking_prices: {
            [date: string]: number;
        };
    }[];
    ortec_transaction: {
        id: string;
        purchase_price: number;
        indexed_purchase_price: number;
        sale_date: string;
        indexed_purchase_prices: {
            [date: string]: number;
        };
    } | null;
    altum_avm: {
        price_estimation: number;
    } | null;
    matrixian_avm: {
        price_estimation: number;
    } | null;
    ortec_avm: {
        price_estimation: number;
    } | null;
    available_sale_transaction_date: string | null;

    /** Cost for fetching paid avms */
    avm_costs: number | null;
}

export function apiValuationOverviewToValuationOverview(apiValuationOverview: ApiValuationOverview): ValuationOverview {
    return {
        wozValues: apiValuationOverview.woz_values.map((apiValue) => ({
            referenceDate: new Date(apiValue.reference_date),
            value: apiValue.value,
            is_estimated: apiValue.is_estimated,
        })),
        altumTransactions: apiValuationOverview.altum_transactions.map((apiTransaction) => ({
            id: apiTransaction.id,
            askingPrice: apiTransaction.asking_price,
            nrOfDaysOffered: apiTransaction.nr_of_days_offered,
            offeredSinceDate: new Date(apiTransaction.offered_since_date),
            saleDate: new Date(apiTransaction.sale_date),
            originalAskingPrices: Object.entries(apiTransaction.original_asking_prices).map(([date, value]) => ({
                date: new Date(date),
                value: value,
            })),
        })),
        ortecTransaction: apiValuationOverview.ortec_transaction
            ? {
                  id: apiValuationOverview.ortec_transaction.id,
                  purchasePrice: apiValuationOverview.ortec_transaction.purchase_price,
                  indexedPurchasePrice: apiValuationOverview.ortec_transaction.indexed_purchase_price,
                  saleDate: new Date(apiValuationOverview.ortec_transaction.sale_date),
                  indexedPurchasePrices: Object.entries(
                      apiValuationOverview.ortec_transaction.indexed_purchase_prices
                  ).map(([date, value]) => ({
                      date: new Date(date),
                      value: value,
                  })),
              }
            : null,
        altumAvm: apiValuationOverview.altum_avm
            ? {
                  priceEstimation: apiValuationOverview.altum_avm.price_estimation,
              }
            : null,
        matrixianAvm: apiValuationOverview.matrixian_avm
            ? {
                  priceEstimation: apiValuationOverview.matrixian_avm.price_estimation,
              }
            : null,
        ortecAvm: apiValuationOverview.ortec_avm
            ? {
                  priceEstimation: apiValuationOverview.ortec_avm.price_estimation,
              }
            : null,
        availableSaleTransactionDate: apiValuationOverview.available_sale_transaction_date
            ? new Date(apiValuationOverview.available_sale_transaction_date)
            : null,
        avmCosts: apiValuationOverview.avm_costs ?? null,
    };
}
