import {
    ValidationMessage,
    ValidationMessageErrorType,
    ValidationMessageImportance,
    ValidationMessageType,
} from '../validation_message';

import {Answer} from '../../../models/answer';
import {AnswerValidator} from '../answer_validator';
import {Question} from '../../../models/question';
import {RootGroupQuestionType} from '../../../enum/question_type';
import {
    compactHouseGroupConfigurationFromAnswer,
    getDefaultCompactHouseGroupConfiguration,
} from '../../../appraise/ui/content/questions/support/house_group_question/compact_house_group_default_configuration';
import {AnswerController} from '../../answering/answer_controller';
import {QuestionSet} from '../../../models/question_set';

export class FloorValidator implements AnswerValidator {
    constructor(private questionSet: QuestionSet, private answerController: AnswerController) {}

    public validate(question: Question, answer: Answer | null): ValidationMessage[] {
        if (question.type !== RootGroupQuestionType.HOUSE_INSIDE) {
            return [];
        }

        const houseGroupCompactQuestion = this.questionSet
            .findChildQuestionsByParentUuid(question.uuid)
            .filter((q) => q.type === RootGroupQuestionType.HOUSE_GROUP_COMPACT)[0];

        if (!houseGroupCompactQuestion) {
            return [];
        }

        const answers = this.answerController.answersForQuestionUuid(houseGroupCompactQuestion.uuid);

        if (!answers.some((answer) => this.hasFloors(answer))) {
            return [
                {
                    type: ValidationMessageType.TAXAPI,
                    importance: ValidationMessageImportance.ERROR,
                    question: question,
                    answer: answer,
                    messages: [],
                    fallbackMessage: 'Het is verplicht om 1 of meer woonlagen in te voeren.',
                    floorInfo: null,
                    errorType: ValidationMessageErrorType.MISSING_FLOOR,
                },
            ];
        }

        return [];
    }

    private hasFloors(answer: Answer | null): boolean {
        if (answer === null || (answer !== null && answer.contents === null)) {
            return false;
        }

        if (answer !== null && answer.contents !== null) {
            const houseGroupConfiguration =
                answer.contents === null
                    ? getDefaultCompactHouseGroupConfiguration()
                    : compactHouseGroupConfigurationFromAnswer(answer);

            if (houseGroupConfiguration.numberOfFloors === 0) {
                return false;
            }
        }

        return true;
    }
}
