import {action, computed, makeObservable, observable, runInAction} from 'mobx';
import {CompositeSubscription} from '../support/composite_subscription';
import {Presenter} from '../support/presenter/presenter';
import {SearchAppraisal} from '../appraising/models/search_appraisal';
import {FlashMessageBroadcaster, Type} from '../appraising/business/flash_message_broadcaster';
import {AppraisalApi} from '../appraising/network/appraisal_api';
import {DateRange} from 'react-day-picker';
import {AppraisalState} from '../appraising/enum/appraisal_state';
import moment from 'moment';

export class RangeModalPresenter implements Presenter {
    private _subscriptions = new CompositeSubscription();

    @observable public range: DateRange | null = null;
    @observable public onlyApprovedAppraisals = true;
    @observable private searchedAppraisals: SearchAppraisal[] | null = null;
    @observable public isSearchingAppraisals = false;

    @observable
    private appraisalSearchTimeout?: NodeJS.Timeout;

    @observable
    private addedAppraisals: SearchAppraisal[];
    @observable
    private spaceLeft: number;

    constructor(
        private appraisalApi: AppraisalApi,
        private flashMessageBroadcaster: FlashMessageBroadcaster,
        addedAppraisals: SearchAppraisal[],
        spaceLeft: number
    ) {
        this.addedAppraisals = addedAppraisals;
        this.spaceLeft = spaceLeft;

        makeObservable(this);
    }

    public mount(): void {
        // Unused
    }

    public unmount(): void {
        this._subscriptions.clear();
    }

    @action
    public onUpdatedProps(addedAppraisals: SearchAppraisal[], spaceLeft: number): void {
        this.addedAppraisals = addedAppraisals;
        this.spaceLeft = spaceLeft;
    }

    @computed
    public get isAppraisalSearchPending() {
        return this.appraisalSearchTimeout !== undefined;
    }

    @computed
    public get returnedTooManyAppraisals() {
        return this.searchedAppraisals !== null && this.searchedAppraisals.length > this.spaceLeft;
    }

    @computed
    public get availableAppraisals() {
        return (
            this.searchedAppraisals?.filter((appraisal) => !this.addedAppraisals.some((a) => a.id === appraisal.id)) ??
            null
        );
    }

    @action
    public updateRange(range?: DateRange, onlyApprovedAppraisals?: boolean): void {
        this.range = range ?? null;
        if (this.range?.to) {
            this.range.to = moment(this.range.to).endOf('day').toDate();
        }

        this.onlyApprovedAppraisals = onlyApprovedAppraisals ?? this.onlyApprovedAppraisals;

        const search = () => {
            runInAction(() => {
                this.isSearchingAppraisals = true;
                clearTimeout(this.appraisalSearchTimeout);
                this.appraisalSearchTimeout = undefined;
            });
            this.appraisalApi
                .search('', {
                    // Fetch one more than backup limit such that we can detect when there are too many appraisals
                    limit: this.spaceLeft + 1,
                    from: this.range?.from,
                    to: this.range?.to,
                    statusses: this.onlyApprovedAppraisals ? [AppraisalState.APPROVED] : undefined,
                })
                .then((appraisals) => {
                    runInAction(() => {
                        this.searchedAppraisals = appraisals;
                    });
                })
                .catch((error) => {
                    console.warn(error);
                    this.flashMessageBroadcaster.broadcast(
                        'Er is een fout opgetreden bij het zoeken naar taxaties.',
                        Type.Danger
                    );
                })
                .finally(() => {
                    runInAction(() => {
                        this.isSearchingAppraisals = false;
                    });
                });
        };

        if (this.appraisalSearchTimeout) {
            clearTimeout(this.appraisalSearchTimeout);
            this.appraisalSearchTimeout = undefined;
        }

        if (this.range !== null && this.range.from && this.range.to) {
            this.appraisalSearchTimeout = setTimeout(search, 500);
        }

        this.searchedAppraisals = null;
    }
}
