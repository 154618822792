import React, {useEffect, useRef} from 'react';
import {NeighbourhoodDetailsData} from '../../../appraising/models/object_summary';
import {Chart} from 'chart.js';
import 'chartjs-gauge-v3';
import ChartDataLabels from 'chartjs-plugin-datalabels';

interface OwnProps {
    neighbourhoodDetailsData: NeighbourhoodDetailsData | null;
}

export const NeighbourhoodBuildyearGauge: React.FC<OwnProps> = ({neighbourhoodDetailsData}: OwnProps) => {
    if (!neighbourhoodDetailsData?.neighbourhoodWonen) {
        return null;
    }

    const totalBouwjaar =
        neighbourhoodDetailsData.neighbourhoodWonen.bouwjaarVanaf2000.value +
        neighbourhoodDetailsData.neighbourhoodWonen.bouwjaarVoor2000.value;

    const bouwjaarVoor2000 = neighbourhoodDetailsData.neighbourhoodWonen.bouwjaarVoor2000.value;
    const bouwjaarNa2000 = neighbourhoodDetailsData.neighbourhoodWonen.bouwjaarVanaf2000.value;

    const ref = useRef<HTMLCanvasElement>(null);

    useEffect(() => {
        if (!ref.current) {
            return;
        }

        const chart = new Chart(ref.current, {
            type: 'gauge',
            plugins: [ChartDataLabels],
            data: {
                labels: ['% Bouwjaar voor 2000', '% Bouwjaar na 2000'],
                datasets: [
                    {
                        value: bouwjaarVoor2000,
                        data: [bouwjaarVoor2000, totalBouwjaar],
                        backgroundColor: [
                            'rgba(255, 205, 86, 1)', // yellow
                            'rgb(75,192,134)', // green
                        ],
                        datalabels: {
                            display: true,
                            formatter: function (value, context) {
                                if (context.chart.data.labels) {
                                    return context.chart.data.labels[context.dataIndex];
                                }
                            },
                            color: 'rgba(255, 255, 255, 1)',
                            backgroundColor: 'rgba(0,0,0,0.75)',
                            font: {
                                size: 8,
                                weight: 'bold',
                            },
                        },
                    },
                ],
            },
            options: {
                //@ts-expect-error - chartjs-gauge-v3 types are not up to date
                needle: {
                    radius: 2,
                    width: 3.2,
                    length: 80,
                    color: 'rgba(0, 0, 0, 1)',
                },
                valueLabel: {
                    display: true,
                    formatter: (value: number) => {
                        return Math.round(value) + '%';
                    },
                    color: 'rgba(255, 255, 255, 1)',
                    backgroundColor: 'rgba(0,0,0,1)',
                    borderRadius: 5,
                    padding: {
                        top: 10,
                        bottom: 10,
                    },
                },
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: function (context) {
                                if (context.dataIndex === 1) {
                                    return bouwjaarNa2000.toString(10) + '%';
                                }
                                return context.formattedValue + '%';
                            },
                        },
                        enabled: true,
                    },
                },
                responsive: true,
                layout: {
                    padding: {
                        left: 30,
                        right: 30,
                        bottom: 30,
                    },
                },
            },
        });

        return () => {
            chart.destroy();
        };
    }, []);

    return neighbourhoodDetailsData ? (
        <>
            <h2>Verdeling bouwjaar</h2>
            <div>
                <canvas key="buildyear_gauge" id="buildyear_gauge" ref={ref} />
            </div>
        </>
    ) : null;
};
