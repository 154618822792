import * as React from 'react';
import {createRoot} from 'react-dom/client';

import {Root} from '../root';
import {Widget} from './widget';
import {ValuationOverview} from './overview';

export function renderPrecheckAppraisalWozValuationEnvironmentInto(element: HTMLElement) {
    createRoot(element).render(
        <Root>
            <Widget />
        </Root>
    );
}

export function renderPrecheckAppraisalValuationReportInto(element: HTMLElement) {
    return new Promise<void>((resolve) => {
        createRoot(element).render(
            <Root>
                <ValuationOverview
                    forReport
                    setIsLoading={(loading) => {
                        if (!loading) {
                            resolve();
                        }
                    }}
                />
            </Root>
        );
    });
}
