import {action, computed, makeObservable, observable} from 'mobx';
import {CompositeSubscription} from '../support/composite_subscription';
import {Presenter} from '../support/presenter/presenter';
import {SearchAppraisal} from '../appraising/models/search_appraisal';
import {FlashMessageBroadcaster, Type} from '../appraising/business/flash_message_broadcaster';

export class AppraisalArchivesPresenter implements Presenter {
    private _subscriptions = new CompositeSubscription();

    public readonly MAX_BACKUP_SIZE = 50;

    @observable public addedAppraisals: SearchAppraisal[] = [];
    @observable public archiveName: string | null = null;

    constructor(private flashMessageBroadcaster: FlashMessageBroadcaster) {
        makeObservable(this);
    }

    public mount(): void {
        // Unused
    }

    public unmount(): void {
        this._subscriptions.clear();
    }

    @computed
    public get spaceLeft() {
        return this.MAX_BACKUP_SIZE - this.addedAppraisals.length;
    }

    @action
    public addAppraisals(appraisals: SearchAppraisal[]): void {
        if (appraisals.length > this.spaceLeft) {
            this.flashMessageBroadcaster.broadcast(
                'Er is niet genoeg ruimte om alle geselecteerde taxaties toe te voegen.',
                Type.Danger
            );
            return;
        }

        this.addedAppraisals.push(...appraisals);
    }

    @action
    public removeAppraisal(id: number) {
        this.addedAppraisals = this.addedAppraisals.filter((a) => a.id !== id);
    }

    @action
    public clearAppraisals(): void {
        this.addedAppraisals = [];
        this.archiveName = null;
    }

    @action
    public setArchiveName(name: string | null): void {
        this.archiveName = name;
    }

    public submit() {
        const form = document.createElement('form');
        form.setAttribute('method', 'post');
        form.setAttribute('action', '/account/me/appraisal-archive/create-stream');

        for (const appraisal of this.addedAppraisals) {
            const appraisalId = document.createElement('input');
            appraisalId.setAttribute('type', 'hidden');
            appraisalId.setAttribute('name', 'appraisal_ids[]');
            appraisalId.setAttribute('value', appraisal.id.toString());
            form.appendChild(appraisalId);
        }

        const archiveName = document.createElement('input');
        archiveName.setAttribute('type', 'hidden');
        archiveName.setAttribute('name', 'file_name');
        archiveName.setAttribute('value', `${this.archiveName ?? 'Taxatie-archief'}.zip`);
        form.appendChild(archiveName);

        const csrfToken = (document.head.querySelector('meta[name="csrf-token"]') as HTMLMetaElement).content;

        const csrfField = document.createElement('input');
        csrfField.setAttribute('type', 'hidden');
        csrfField.setAttribute('name', '_token');
        csrfField.setAttribute('value', csrfToken);
        form.appendChild(csrfField);

        document.body.appendChild(form);
        form.submit();
    }
}
