export enum CoreTaskType {
    // Contracteren
    APPRAISAL_ACQUIRE = 'appraisal-acquire',
    INDEPENDENCE_CHECK = 'independence-check',
    OBJECT_DETAILS_CHECK = 'object-details-check',
    DETERMINE_APPRAISAL_TYPE = 'determine-appraisal-type',
    DETERMINE_TERMS = 'determine-terms',
    SEND_TERMS = 'send-terms',
    HANDLE_TERMS_FINANCIAL = 'handle-terms-financial',
    TERMS_AFTER_SALES = 'terms-after-sales',

    // Rechercheren
    COLLECT_INFO = 'collect-info',
    CHECK_INFO = 'check-info',
    STORE_INFO = 'store-info',

    // Analyseren
    USE_INFO = 'use-info',
    ANALYSE_MARKET = 'analyse-market',
    ANALYSE_OBJECT = 'analyse-object',
    CONCLUDE_ANALYSIS = 'conclude-analysis',

    // Waarderen
    DETERMINE_FIGURES = 'determine-figures',
    CHOOSE_VALUATION_METHOD = 'choose-valuation-method',
    APPLY_VALUATION_METHOD = 'apply-valuation-method',
    DETERMINE_VALUATION = 'determine-valuation',

    // Rapporteren
    CREATE_REPORT = 'create-report',
    VALIDATE_REPORT = 'validate-report',
    SEND_REPORT = 'send-report',
    HANDLE_REPORT_FINANCIAL = 'handle-report-financial',
    REPORT_AFTER_SALES = 'report-after-sales',

    // Archiveren
    ARCHIVE = 'archive',
}
