import {action, computed, makeObservable, observable, runInAction} from 'mobx';
import {AppraisalProvider} from '../../appraising/business/appraisal_provider';
import {Appraisal} from '../../appraising/models/appraisal';
import {ApiAppraisal, apiAppraisalToAppraisal} from '../../appraising/network/models/api_appraisal';
import {Presenter} from '../../support/presenter/presenter';
import {
    ApiAppraisalCosts,
    apiAppraisalCostsToAppraisalCosts,
    AppraisalCostLine,
    AppraisalCosts,
    AppraisalCostTrackingCondition,
    AppraisalCostType,
    AutomatorCostDetails,
    AutomatorCostPackage,
    AutomatorDispatchGroup,
    AutomatorDispatchTime,
} from './appraisal_costs';

//This GLOBAL is different then the global used anywhere else in the app
declare let GLOBAL: {
    appraisal: ApiAppraisal;
};

declare let APPRAISAL_COSTS: {
    costs: ApiAppraisalCosts;
    activated_optional_costs: AppraisalCostType[] | null;
    automator_dispatch_preferences: Record<AutomatorDispatchGroup, AutomatorDispatchTime> | null;
    has_set_preferences: boolean;
};

export class PreferencesPresenter implements Presenter {
    @observable.ref public appraisal?: Appraisal;
    @observable public attachmentTeamFields: {[key: string]: string} = {};
    @observable public costs?: AppraisalCosts;
    @observable public disabled = false;
    @observable private enabledOptionalCosts = new Set<AppraisalCostType>();
    @observable private enabledDispatchGroups = new Set<AutomatorDispatchGroup>();

    @computed
    public get loading(): boolean {
        return !this.appraisal || !this.costs;
    }

    constructor(private appraisalProvider: AppraisalProvider) {
        makeObservable(this);
    }

    public mount(): void {
        runInAction(() => {
            this.appraisal = apiAppraisalToAppraisal(GLOBAL.appraisal);
            this.appraisalProvider.onChange(this.appraisal);

            this.attachmentTeamFields = (this.appraisal.attachmentTeamInfo?.fields ?? {}) as {[key: string]: string};

            this.costs = apiAppraisalCostsToAppraisalCosts(APPRAISAL_COSTS.costs);
            APPRAISAL_COSTS.activated_optional_costs?.forEach((costType) => this.enabledOptionalCosts.add(costType));
            Object.entries(APPRAISAL_COSTS.automator_dispatch_preferences ?? {}).forEach(([group, dispatchTime]) => {
                if (dispatchTime !== AutomatorDispatchTime.DISABLED) {
                    this.enabledDispatchGroups.add(group as AutomatorDispatchGroup);
                }
            });

            if (APPRAISAL_COSTS.has_set_preferences) {
                this.disabled = true; // Modifications no longer allowed if preferences have been set
            }
        });
    }

    public unmount(): void {
        /* Noop */
    }

    @computed
    public get forcedOptionalCosts() {
        const forcedCosts = new Map<
            AppraisalCostType,
            {
                costType?: AppraisalCostType;
                required?: true;
            }
        >();

        const enabledLines = this.getFlattenedLines(this.costs?.optionalLines ?? []).filter((line) =>
            this.enabledOptionalCosts.has(line.costType)
        );
        for (const line of enabledLines) {
            if (line.sublines) {
                for (const subline of line.sublines) {
                    forcedCosts.set(subline.costType, {
                        costType: line.costType,
                    });
                }
            }
        }

        for (const pkg of this.costs?.automatorCostPackages ?? []) {
            if (pkg.required) {
                forcedCosts.set(pkg.costType, {
                    required: true,
                });
            }
        }

        return forcedCosts;
    }

    @computed
    public get activeOptionalCosts() {
        return new Set([...this.enabledOptionalCosts, ...this.forcedOptionalCosts.keys()]);
    }

    @computed
    public get activeDispatchGroups() {
        const dispatchGroups = new Set(this.enabledDispatchGroups);

        for (const group of Object.values(AutomatorDispatchGroup)) {
            if (this.getDispatchGroupForcedPackages(group).length > 0) {
                dispatchGroups.add(group);
            }
        }

        return dispatchGroups;
    }

    @computed
    public get coveredCostTypes() {
        const coveredCostTypes = new Set<AppraisalCostType>();

        if (!this.costs) {
            return coveredCostTypes;
        }

        for (const pkg of this.costs.automatorCostPackages) {
            if (this.activeOptionalCosts.has(pkg.costType)) {
                for (const costType of pkg.coveredCosts) {
                    coveredCostTypes.add(costType.costType);
                }
            }
        }

        const allFlattenedLines = [
            ...this.getFlattenedLines(this.costs.lines),
            ...this.getFlattenedLines(this.costs.optionalLines),
        ];

        for (const line of allFlattenedLines) {
            if (
                this.activeOptionalCosts.has(line.costType) &&
                line.coveredBy !== undefined &&
                line.coveredBy.some((costType) => this.activeOptionalCosts.has(costType))
            ) {
                coveredCostTypes.add(line.costType);
            }
        }

        return coveredCostTypes;
    }

    @computed
    public get dispatchGroupsToShow() {
        return new Set(this.costs?.automatorCostDetails.map((costDetails) => costDetails.dispatchGroup) ?? []);
    }

    @computed
    public get lines() {
        const lines: AppraisalCostLine[] = [];

        if (!this.costs) {
            return lines;
        }

        lines.push(...this.getFlattenedLines(this.costs.lines).filter((line) => this.shouldShowCostLine(line)));

        for (const optionalLine of this.getFlattenedLines(this.costs.optionalLines)) {
            if (
                this.activeOptionalCosts.has(optionalLine.costType) &&
                !this.coveredCostTypes.has(optionalLine.costType) &&
                this.shouldShowCostLine(optionalLine)
            ) {
                if (
                    optionalLine.sublines &&
                    optionalLine.sublines.length > 0 &&
                    optionalLine.originalCostCents === undefined
                ) {
                    const ungroupedCosts = this.getFlattenedLines(optionalLine.sublines)
                        .filter(
                            (l) => this.coveredCostTypes.has(l.costType) && l.coveredBy?.includes(optionalLine.costType)
                        )
                        .reduce((acc, l) => acc + l.costCents, 0);
                    if (ungroupedCosts > optionalLine.costCents) {
                        lines.push({
                            ...optionalLine,
                            originalCostCents: ungroupedCosts,
                        });
                    }
                } else {
                    lines.push(optionalLine);
                }
            }
        }

        const costPackagesByCostType = new Map<AppraisalCostType, AppraisalCostLine[]>();

        for (const pkg of this.costs.automatorCostPackages) {
            if (this.activeOptionalCosts.has(pkg.costType) && !this.coveredCostTypes.has(pkg.costType)) {
                // Modified down below in automatorCostDetails loop
                const line: AppraisalCostLine = {...pkg};

                lines.push(pkg);

                pkg.coveredCosts.forEach((costType) => {
                    if (!costPackagesByCostType.has(costType.costType)) {
                        costPackagesByCostType.set(costType.costType, []);
                    }

                    costPackagesByCostType.get(costType.costType)?.push(line);
                });
            }
        }

        for (const automatorDetails of this.costs.automatorCostDetails) {
            if (this.activeDispatchGroups.has(automatorDetails.dispatchGroup)) {
                for (const line of this.getFlattenedLines(automatorDetails.lines)) {
                    if (!this.shouldShowCostLine(line)) {
                        continue;
                    }

                    if (!this.coveredCostTypes.has(line.costType)) {
                        lines.push(line);
                    } else if (costPackagesByCostType.has(line.costType)) {
                        costPackagesByCostType.get(line.costType)?.forEach((costPackage) => {
                            costPackage.originalCostCents ??= 0;
                            costPackage.originalCostCents += line.costCents;
                        });
                    }
                }
            }
        }

        return lines;
    }

    private shouldShowCostLine(line: AppraisalCostLine) {
        if (line.conditions) {
            for (const condition of line.conditions) {
                switch (condition) {
                    case AppraisalCostTrackingCondition.WITH_PRECHECK: {
                        if (!this.enabledOptionalCosts.has(AppraisalCostType.PRECHECK)) {
                            return false;
                        }
                        break;
                    }
                    case AppraisalCostTrackingCondition.WITHOUT_PRECHECK: {
                        if (this.enabledOptionalCosts.has(AppraisalCostType.PRECHECK)) {
                            return false;
                        }
                        break;
                    }
                }
            }
        }

        if (
            line.costCents === 0 &&
            line.sublines !== undefined &&
            line.sublines.length > 0 &&
            !this.getFlattenedLines(line.sublines).some((subline) => subline.coveredBy?.includes(line.costType))
        ) {
            // Hide line if it is purely used for grouping
            return false;
        }

        return true;
    }

    private getFlattenedLines(lines: AppraisalCostLine[]): AppraisalCostLine[] {
        const flattenedLines: AppraisalCostLine[] = [];

        for (const line of lines) {
            flattenedLines.push(line);

            if (line.sublines) {
                flattenedLines.push(...this.getFlattenedLines(line.sublines));
            }
        }

        return flattenedLines;
    }

    @action
    public toggleOptionalCosts(costType: AppraisalCostType): boolean {
        if (this.disabled) {
            return false;
        }

        if (this.forcedOptionalCosts.has(costType)) {
            const forcedReason = this.forcedOptionalCosts.get(costType);

            if (forcedReason?.costType) {
                this.toggleOptionalCosts(forcedReason.costType);
                this.enabledOptionalCosts.delete(costType);
            } else {
                return false;
            }
        } else if (this.enabledOptionalCosts.has(costType)) {
            this.enabledOptionalCosts.delete(costType);
        } else {
            this.enabledOptionalCosts.add(costType);
        }

        const pkg = this.costs?.automatorCostPackages.find((costPackage) => costPackage.costType === costType);
        if (pkg) {
            const included = this.getPackageIncludedDispatchGroups(pkg).filter((costDetails) =>
                this.dispatchGroupsToShow.has(costDetails.dispatchGroup)
            );
            for (const details of included) {
                this.enabledDispatchGroups.delete(details.dispatchGroup);
            }
        }

        return true;
    }

    @action
    public toggleDispatchGroup(dispatchGroup: AutomatorDispatchGroup): void {
        if (this.disabled) {
            return;
        }

        const forcedPackages = this.getDispatchGroupForcedPackages(dispatchGroup);
        if (forcedPackages.length > 0) {
            let wasDisabled = true;
            for (const forcedPackage of forcedPackages) {
                if (!this.toggleOptionalCosts(forcedPackage)) {
                    wasDisabled = false;
                    break;
                }

                const pkg = this.costs?.automatorCostPackages.find(
                    (costPackage) => costPackage.costType === forcedPackage
                );
                if (pkg) {
                    const included = this.getPackageIncludedDispatchGroups(pkg).filter((costDetails) =>
                        this.dispatchGroupsToShow.has(costDetails.dispatchGroup)
                    );
                    for (const details of included) {
                        if (details.dispatchGroup !== dispatchGroup) {
                            this.enabledDispatchGroups.add(details.dispatchGroup);
                        }
                    }
                }
            }

            if (wasDisabled) {
                this.enabledDispatchGroups.delete(dispatchGroup);
            }
        } else if (this.enabledDispatchGroups.has(dispatchGroup)) {
            this.enabledDispatchGroups.delete(dispatchGroup);
        } else {
            this.enabledDispatchGroups.add(dispatchGroup);
        }
    }

    @action
    public setAttachmentTeamField(field: string, value: string): void {
        this.attachmentTeamFields[field] = value;
    }

    public isManual(details: AutomatorCostDetails | AutomatorCostPackage) {
        if ('dispatchTimes' in details) {
            return details.dispatchTimes.length === 0 && details.dispatchTimes[0] === AutomatorDispatchTime.MANUAL;
        }

        return Object.values(details.dispatchPreferences).some((time) => time === AutomatorDispatchTime.MANUAL);
    }

    public getDispatchGroupForcedPackages(dispatchGroup: AutomatorDispatchGroup) {
        return Array.from(this.activeOptionalCosts).filter((costType) => {
            const automatorCostPackage = this.costs?.automatorCostPackages.find(
                (costPackage) => costPackage.costType === costType
            );

            return automatorCostPackage?.dispatchPreferences?.[dispatchGroup] !== undefined;
        });
    }

    public getPackageIncludedDispatchGroups(costPackage: AutomatorCostPackage) {
        const coveredCosts = new Set(costPackage.coveredCosts.map((costType) => costType.costType));

        return (
            this.costs?.automatorCostDetails.filter((details) =>
                details.lines.every((line) => coveredCosts.has(line.costType))
            ) ?? []
        );
    }
}
