import {makeObservable, observable, runInAction} from 'mobx';

import {Appraisal} from '../../../../models/appraisal';
import {Presenter} from '../../../../../support/presenter/presenter';
import {AppraisalApi} from '../../../../network/appraisal_api';
import {ValuationOverview} from '../../../../models/valuation_overview';

export class AppraisalValuationInfoWidgetPresenter implements Presenter {
    @observable public appraisal: Appraisal;
    @observable public valuationOverview?: ValuationOverview;
    @observable public loading = true;

    constructor(appraisal: Appraisal, private appraisalApi: AppraisalApi) {
        makeObservable(this);
        this.appraisal = appraisal;
    }

    public mount() {
        runInAction(() => {
            this.appraisalApi.getValuationOverview(this.appraisal.id).then((valuationOverview) => {
                runInAction(() => {
                    this.valuationOverview = valuationOverview;
                    this.loading = false;
                });
            });
        });
    }

    public unmount() {
        // noop
    }
}
