export enum AppraisalInvoiceFlow {
    CLIENT = 'client',
    APPRAISER = 'appraiser',
    NOTARY = 'notary',
    DEFERRED = 'deferred',
    THIRD_PARTY = 'third-party',
    ACCEPTANCE = 'acceptance',
    CANCELLED = 'cancelled',
    DAYS_AFTER_SENDING = 'days_after_sending',
}
