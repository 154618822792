import * as React from 'react';
import {classNames} from '../../../support/classnames';

interface OwnProps {
    lng: number;
    lat: number;
    className?: string;
    iconName?: string;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
    onClick?: () => void;
    isHovering: boolean;
    isBaseObject: boolean;
}

export class Pointer extends React.Component<OwnProps> {
    public render() {
        return (
            <div
                className={classNames('marker-container', {
                    hovering: this.props.isHovering,
                })}
                onMouseEnter={this.props.onMouseEnter}
                onMouseLeave={this.props.onMouseLeave}
                onClick={this.props.onClick}
            >
                <div
                    className={classNames(
                        this.props.className,
                        this.props.iconName,
                        {'pointer-object': this.props.isBaseObject},
                        'pointer'
                    )}
                />
            </div>
        );
    }
}
