//region Translations and units
interface NeighbourhoodProperty {
    unit: string;
    label: string;
}

interface NeighbourhoodData {
    [key: string]: NeighbourhoodProperty;
}

const algemeen: NeighbourhoodData = {
    codering: {
        unit: '',
        label: 'Codering',
    },
    gemeenteNaam: {
        unit: '',
        label: 'Gemeentenaam',
    },
    indelingswijzigingWijkenEnBuurten: {
        unit: '',
        label: 'Indelingswijziging wijken en buurten',
    },
    soortRegio: {
        unit: '',
        label: 'Soort regio',
    },
};

const neighbourhoodBedrijfsvestigingen: NeighbourhoodData = {
    ALandbouwBosbouwEnVisserij: {
        unit: 'aantal',
        label: 'Landbouw, bosbouw en visserij',
    },
    BFNijverheidEnEnergie: {
        unit: 'aantal',
        label: 'Nijverheid en energie',
    },
    BedrijfsvestigingenTotaal: {
        unit: 'aantal',
        label: 'Totaal',
    },
    GIHandelEnHoreca: {
        unit: 'aantal',
        label: 'Handel en horeca',
    },
    HJVervoerInformatieEnCommunicatie: {
        unit: 'aantal',
        label: 'Vervoer, informatie en communicatie',
    },
    KLFinancieleDienstenOnroerendGoed: {
        unit: 'aantal',
        label: 'Financiële diensten en onroerend goed',
    },
    MNZakelijkeDienstverlening: {
        unit: 'aantal',
        label: 'Zakelijke dienstverlening',
    },
    RUCultuurRecreatieOverigeDiensten: {
        unit: 'aantal',
        label: 'Cultuur, recreatie en overige diensten',
    },
};
const neighbourhoodBevolking: NeighbourhoodData = {
    AantalInwoners: {
        unit: 'aantal',
        label: 'Aantal inwoners',
    },
    Bevolkingsdichtheid: {
        unit: 'aantal per km²',
        label: 'Bevolkingsdichtheid',
    },
    Eenpersoonshuishoudens: {
        unit: 'aantal',
        label: 'Eenpersoonshuishoudens',
    },
    GeboorteRelatief: {
        unit: '‰ in een jaar per 1000 inwoners',
        label: 'Geboorte relatief',
    },
    GeboorteTotaal: {
        unit: 'aantal in een jaar',
        label: 'Geboorte totaal',
    },
    Gehuwd: {
        unit: 'aantal',
        label: 'Gehuwd',
    },
    GemiddeldeHuishoudensgrootte: {
        unit: 'aantal personen',
        label: 'Gemiddelde huishoudensgrootte',
    },
    Gescheiden: {
        unit: 'aantal',
        label: 'Gescheiden',
    },
    HuishoudensMetKinderen: {
        unit: 'aantal',
        label: 'Huishoudens met kinderen',
    },
    HuishoudensTotaal: {
        unit: 'aantal',
        label: 'Huishoudens totaal',
    },
    HuishoudensZonderKinderen: {
        unit: 'aantal',
        label: 'Huishoudens zonder kinderen',
    },
    Mannen: {
        unit: 'aantal',
        label: 'Mannen',
    },
    marokko: {
        unit: 'aantal',
        label: 'Inwoners uit Marokko',
    },
    NederlandseAntillenEnAruba: {
        unit: 'aantal',
        label: 'Inwoners uit Nederlandse Antillen en Aruba',
    },
    NietWestersTotaal: {
        unit: 'aantal',
        label: 'Niet-westers totaal',
    },
    Ongehuwd: {
        unit: 'aantal',
        label: 'Ongehuwd',
    },
    OverigNietWesters: {
        unit: 'aantal',
        label: 'Overig niet-westers',
    },
    SterfteRelatief: {
        unit: '‰ in een jaar per 1000 inwoners',
        label: 'Sterfte relatief',
    },
    SterfteTotaal: {
        unit: 'aantal in een jaar',
        label: 'Sterfte totaal',
    },
    Suriname: {
        unit: 'aantal',
        label: 'Inwoners uit Suriname',
    },
    Turkije: {
        unit: 'aantal',
        label: 'Inwoners uit Turkije',
    },
    Verweduwd: {
        unit: 'aantal',
        label: 'Verweduwd',
    },
    Vrouwen: {
        unit: 'aantal',
        label: 'Vrouwen',
    },
    WestersTotaal: {
        unit: 'aantal',
        label: 'Westers totaal',
    },
    k0Tot15Jaar: {
        unit: 'aantal',
        label: '0 tot 15 jaar',
    },
    k15Tot25Jaar: {
        unit: 'aantal',
        label: '15 tot 25 jaar',
    },
    k25Tot45Jaar: {
        unit: 'aantal',
        label: '25 tot 45 jaar',
    },
    k45Tot65Jaar: {
        unit: 'aantal',
        label: '45 tot 65 jaar',
    },
    k65JaarOfOuder: {
        unit: 'aantal',
        label: '65 jaar of ouder',
    },
};
const neighbourhoodDetailhandel: NeighbourhoodData = {
    AfstandTotGroteSupermarkt: {
        unit: 'km',
        label: 'Afstand tot grote supermarkt',
    },
    AfstandTotOvDagelLevensmiddelen: {
        unit: 'km',
        label: 'Afstand tot dagelijks levensmiddelen',
    },
    AfstandTotWarenhuis: {
        unit: 'km',
        label: 'Afstand tot warenhuis',
    },
    GroteSupermarktBinnen1Km: {
        unit: 'aantal',
        label: 'Grote supermarkt binnen 1 km',
    },
    GroteSupermarktBinnen3Km: {
        unit: 'aantal',
        label: 'Grote supermarkt binnen 3 km',
    },
    GroteSupermarktBinnen5Km: {
        unit: 'aantal',
        label: 'Grote supermarkt binnen 5 km',
    },
    OvDagelLevensmiddelenBinnen1Km: {
        unit: 'aantal',
        label: 'Dagelijks levensmiddelen binnen 1 km',
    },
    OvDagelLevensmiddelenBinnen3Km: {
        unit: 'aantal',
        label: 'Dagelijks levensmiddelen binnen 3 km',
    },
    OvDagelLevensmiddelenBinnen5Km: {
        unit: 'aantal',
        label: 'Dagelijks levensmiddelen binnen 5 km',
    },
    WarenhuisBinnen10Km: {
        unit: 'aantal',
        label: 'Warenhuis binnen 10 km',
    },
    WarenhuisBinnen20Km: {
        unit: 'aantal',
        label: 'Warenhuis binnen 20 km',
    },
    WarenhuisBinnen5Km: {
        unit: 'aantal',
        label: 'Warenhuis binnen 5 km',
    },
};
const neighbourhoodEnergie: NeighbourhoodData = {
    AppartementElektriciteit: {
        unit: 'kWh',
        label: 'Gemiddeld elektriciteitsverbruik appartement in 2018',
    },
    AppartementGas: {
        unit: 'm³',
        label: 'Gemiddeld gasverbruik appartement in 2018',
    },
    EigenWoningElektriciteit: {
        unit: 'kWh',
        label: 'Gemiddeld elektriciteitsverbruik eigen woning in 2018',
    },
    EigenWoningGas: {
        unit: 'm³',
        label: 'Gemiddeld gasverbruik eigen woning in 2018',
    },
    GemiddeldAardgasverbruikTotaal: {
        unit: 'm³',
        label: 'Gemiddeld aardgasverbruik totaal in 2018',
    },
    GemiddeldElektriciteitsverbruikTotaal: {
        unit: 'kWh',
        label: 'Gemiddeld elektriciteitsverbruik totaal in 2018',
    },
    HoekwoningElektriciteit: {
        unit: 'kWh',
        label: 'Gemiddeld elektriciteitsverbruik hoekwoning in 2018',
    },
    HoekwoningGas: {
        unit: 'm³',
        label: 'Gemiddeld gasverbruik hoekwoning in 2018',
    },
    HuurwoningElektriciteit: {
        unit: 'kWh',
        label: 'Gemiddeld elektriciteitsverbruik huurwoning in 2018',
    },
    HuurwoningGas: {
        unit: 'm³',
        label: 'Gemiddeld gasverbruik huurwoning in 2018',
    },
    TussenwoningElektriciteit: {
        unit: 'kWh',
        label: 'Gemiddeld elektriciteitsverbruik tussenwoning in 2018',
    },
    TussenwoningGas: {
        unit: 'm³',
        label: 'Gemiddeld gasverbruik tussenwoning in 2018',
    },
    TweeOnderEenKapWoningElektriciteit: {
        unit: 'kWh',
        label: 'Gemiddeld elektriciteitsverbruik twee-onder-een-kapwoning in 2018',
    },
    TweeOnderEenKapWoningGas: {
        unit: 'm³',
        label: 'Gemiddeld gasverbruik twee-onder-een-kapwoning in 2018',
    },
    VrijstaandeWoningElektriciteit: {
        unit: 'kWh',
        label: 'Gemiddeld elektriciteitsverbruik vrijstaande woning in 2018',
    },
    VrijstaandeWoningGas: {
        unit: 'm³',
        label: 'Gemiddeld gasverbruik vrijstaande woning in 2018',
    },
};
const neighbourhoodGezondheid: NeighbourhoodData = {
    AfstandTotApotheek: {
        unit: 'km',
        label: 'Afstand tot apotheek',
    },
    AfstandTotHuisartsenpraktijk: {
        unit: 'km',
        label: 'Afstand tot huisartsenpraktijk',
    },
    AfstandTotZiekenhuis: {
        unit: 'km',
        label: 'Afstand tot ziekenhuis',
    },
    HuisartsenpraktijkBinnen1Km: {
        unit: 'aantal',
        label: 'Huisartsenpraktijk binnen 1 km',
    },
    HuisartsenpraktijkBinnen3Km: {
        unit: 'aantal',
        label: 'Huisartsenpraktijk binnen 3 km',
    },
    HuisartsenpraktijkBinnen5Km: {
        unit: 'aantal',
        label: 'Huisartsenpraktijk binnen 5 km',
    },
    ZiekenhuisBinnen10Km: {
        unit: 'aantal',
        label: 'Ziekenhuis binnen 10 km',
    },
    ZiekenhuisBinnen20Km: {
        unit: 'aantal',
        label: 'Ziekenhuis binnen 20 km',
    },
    ZiekenhuisBinnen5Km: {
        unit: 'aantal',
        label: 'Ziekenhuis binnen 5 km',
    },
    AfstandTotBrandweerkazerne: {
        unit: 'km',
        label: 'Afstand tot brandweerkazerne',
    },
    AfstandTotHuisartsenpost: {
        unit: 'km',
        label: 'Afstand tot huisartsenpost',
    },
};
const neighbourhoodHoreca: NeighbourhoodData = {
    afstandTotCafeED: {
        unit: 'km',
        label: 'Afstand tot café',
    },
    afstandTotCafetariaED: {
        unit: 'km',
        label: 'Afstand tot cafetaria',
    },
    afstandTotHotelED: {
        unit: 'km',
        label: 'Afstand tot hotel',
    },
    afstandTotRestaurant: {
        unit: 'km',
        label: 'Afstand tot restaurant',
    },
    cafeEDBinnen1Km: {
        unit: 'aantal',
        label: 'Café binnen 1 km',
    },
    cafeEDBinnen3Km: {
        unit: 'aantal',
        label: 'Café binnen 3 km',
    },
    cafeEDBinnen5Km: {
        unit: 'aantal',
        label: 'Café binnen 5 km',
    },
    cafetariaEDBinnen1Km: {
        unit: 'aantal',
        label: 'Cafetaria binnen 1 km',
    },
    cafetariaEDBinnen3Km: {
        unit: 'aantal',
        label: 'Cafetaria binnen 3 km',
    },
    cafetariaEDBinnen5Km: {
        unit: 'aantal',
        label: 'Cafetaria binnen 5 km',
    },
    hotelBinnen10Km: {
        unit: 'aantal',
        label: 'Hotel binnen 10 km',
    },
    hotelBinnen20Km: {
        unit: 'aantal',
        label: 'Hotel binnen 20 km',
    },
    hotelBinnen5Km: {
        unit: 'aantal',
        label: 'Hotel binnen 5 km',
    },
    restaurantBinnen1Km: {
        unit: 'aantal',
        label: 'Restaurant binnen 1 km',
    },
    restaurantBinnen3Km: {
        unit: 'aantal',
        label: 'Restaurant binnen 3 km',
    },
    restaurantBinnen5Km: {
        unit: 'aantal',
        label: 'Restaurant binnen 5 km',
    },
};
const neighbourhoodInkomen: NeighbourhoodData = {
    aantalInkomensontvangers: {
        unit: 'aantal',
        label: 'Aantal inkomensontvangers in 2018',
    },
    gemiddeldInkomenPerInkomensontvanger: {
        unit: 'duizend €',
        label: 'Gemiddeld inkomen per inkomensontvanger in 2018',
    },
    gemiddeldInkomenPerInwoner: {
        unit: 'duizend €',
        label: 'Gemiddeld inkomen per inwoner in 2018',
    },
    huishOnderOfRondSociaalMinimum: {
        unit: 'aantal',
        label: 'Huishoudens onder of rond sociaal minimum in 2018',
    },
    huishoudensMetEenLaagInkomen: {
        unit: 'aantal',
        label: 'Huishoudens met een laag inkomen in 2018',
    },
    k20HuishoudensMetHoogsteInkomen: {
        unit: '%',
        label: '20% huishoudens met hoogste inkomen in 2018',
    },
    k20PersonenMetHoogsteInkomen: {
        unit: '%',
        label: '20% personen met hoogste inkomen in 2018',
    },
    k40HuishoudensMetLaagsteInkomen: {
        unit: '%',
        label: '40% huishoudens met laagste inkomen in 2018',
    },
    k40PersonenMetLaagsteInkomen: {
        unit: '%',
        label: '40% personen met laagste inkomen in 2018',
    },
};
const neighbourhoodKinderOpvang: NeighbourhoodData = {
    afstandTotBuitenschoolseOpvang: {
        unit: 'km',
        label: 'Afstand tot buitenschoolse opvang',
    },
    afstandTotKinderdagverblijf: {
        unit: 'km',
        label: 'Afstand tot kinderdagverblijf',
    },
    buitenschoolseOpvangBinnen1Km: {
        unit: 'aantal',
        label: 'Buitenschoolse opvang binnen 1 km',
    },
    buitenschoolseOpvangBinnen3Km: {
        unit: 'aantal',
        label: 'Buitenschoolse opvang binnen 3 km',
    },
    buitenschoolseOpvangBinnen5Km: {
        unit: 'aantal',
        label: 'Buitenschoolse opvang binnen 5 km',
    },
    kinderdagverblijfBinnen1Km: {
        unit: 'aantal',
        label: 'Kinderdagverblijf binnen 1 km',
    },
    kinderdagverblijfBinnen3Km: {
        unit: 'aantal',
        label: 'Kinderdagverblijf binnen 3 km',
    },
    kinderdagverblijfBinnen5Km: {
        unit: 'aantal',
        label: 'Kinderdagverblijf binnen 5 km',
    },
};
const neighbourhoodMotorvoertuigen: NeighbourhoodData = {
    motorfietsen: {
        unit: 'aantal',
        label: 'Motorfietsen',
    },
    personenautoSBrandstofBenzine: {
        unit: 'aantal',
        label: "Personenauto's op benzine",
    },
    personenautoSNaarOppervlakte: {
        unit: 'aantal per km²',
        label: "Personenauto's naar oppervlakte",
    },
    personenautoSOverigeBrandstof: {
        unit: 'aantal',
        label: "Personenauto's overige brandstof",
    },
    personenautoSPerHuishouden: {
        unit: 'aantal',
        label: "Personenauto's per huishouden",
    },
    personenautoSTotaal: {
        unit: 'aantal',
        label: "Personenauto's totaal",
    },
};
const neighbourhoodOppervlakte: NeighbourhoodData = {
    oppervlakteLand: {
        unit: 'ha',
        label: 'Oppervlakte land',
    },
    oppervlakteTotaal: {
        unit: 'ha',
        label: 'Oppervlakte totaal',
    },
    oppervlakteWater: {
        unit: 'ha',
        label: 'Oppervlakte water',
    },
};
const neighbourhoodPostcode: NeighbourhoodData = {
    meestVoorkomendePostcode: {
        unit: 'postcode',
        label: 'Postcode',
    },
    dekkingspercentage: {
        unit: '%',
        label: 'Dekkingspercentage meest voorkomende postcode',
    },
};
const neighbourhoodRecreatie: NeighbourhoodData = {
    afstandTotAttractie: {
        unit: 'km',
        label: 'Afstand tot attractie',
    },
    afstandTotBioscoop: {
        unit: 'km',
        label: 'Afstand tot bioscoop',
    },
    afstandTotKunstijsbaan: {
        unit: 'km',
        label: 'Afstand tot kunstijsbaan',
    },
    afstandTotSauna: {
        unit: 'km',
        label: 'Afstand tot sauna',
    },
    afstandTotZonnebank: {
        unit: 'km',
        label: 'Afstand tot zonnebank',
    },
    afstandTotZwembad: {
        unit: 'km',
        label: 'Afstand tot zwembad',
    },
    attractieBinnen10Km: {
        unit: 'aantal',
        label: 'Attractie binnen 10 km',
    },
    attractieBinnen20Km: {
        unit: 'aantal',
        label: 'Attractie binnen 20 km',
    },
    attractieBinnen50Km: {
        unit: 'aantal',
        label: 'Attractie binnen 50 km',
    },
    bioscoopBinnen10Km: {
        unit: 'aantal',
        label: 'Bioscoop binnen 10 km',
    },
    bioscoopBinnen20Km: {
        unit: 'aantal',
        label: 'Bioscoop binnen 20 km',
    },
    bioscoopBinnen5Km: {
        unit: 'aantal',
        label: 'Bioscoop binnen 5 km',
    },
};
const neighbourhoodSocialeZekerheid: NeighbourhoodData = {
    aOWUitkering: {
        unit: 'aantal',
        label: 'AOW-uitkering',
    },
    arbeidsongeschiktheidsuitkering: {
        unit: 'aantal',
        label: 'Arbeidsongeschiktheidsuitkering',
    },
    bijstandsuitkering: {
        unit: 'aantal',
        label: 'Bijstandsuitkering',
    },
    inwonersVanaf15Jaar: {
        unit: 'aantal',
        label: 'Inwoners vanaf 15 jaar',
    },
    inwonersVanaf15JrTotAOWLeeftijd: {
        unit: 'aantal',
        label: 'Inwoners vanaf 15 jaar tot AOW-leeftijd',
    },
    inwonersVanafDeAOWLeeftijd: {
        unit: 'aantal',
        label: 'Inwoners vanaf de AOW-leeftijd',
    },
    werkloosheidsuitkering: {
        unit: 'aantal',
        label: 'Werkloosheidsuitkering',
    },
};
const neighbourhoodStedelijkheid: NeighbourhoodData = {
    mateVanStedelijkheid: {
        unit: '%',
        label: 'Mate van stedelijkheid',
    },
    omgevingsadressendichtheid: {
        unit: 'aantal',
        label: 'Milieu-adresdichtheid per km²',
    },
};
const neighbourhoodVerkeerVervoer: NeighbourhoodData = {
    afstandTotBelangrijkOverstapstation: {
        unit: 'km',
        label: 'Gemiddelde afstand tot bushalte',
    },
    afstandTotOpritHoofdverkeersweg: {
        unit: 'km',
        label: 'Gemiddelde afstand tot metro',
    },
    afstandTotTreinstationsTotaal: {
        unit: 'km',
        label: 'Gemiddelde afstand tot treinstation',
    },
};
const neighbourhoodWonen: NeighbourhoodData = {
    bouwjaarVanaf2000: {
        unit: 'aantal',
        label: 'Bouwjaar vanaf 2000',
    },
    bouwjaarVoor2000: {
        unit: 'aantal',
        label: 'Bouwjaar voor 2000',
    },
    eigendomOnbekend: {
        unit: 'aantal',
        label: 'Eigendom onbekend',
    },
    gemiddeldeWoningwaarde: {
        unit: '€',
        label: 'Gemiddelde woningwaarde',
    },
    huurwoningenTotaal: {
        unit: 'aantal',
        label: 'Huurwoningen totaal',
    },
    inBezitOverigeVerhuurders: {
        unit: 'aantal',
        label: 'In bezit overige verhuurders',
    },
    inBezitWoningcorporatie: {
        unit: 'aantal',
        label: 'In bezit woningcorporatie',
    },
    koopwoningen: {
        unit: 'aantal',
        label: 'Koopwoningen',
    },
    percentageBewoond: {
        unit: '%',
        label: 'Percentage bewoond',
    },
    percentageEengezinswoning: {
        unit: '%',
        label: 'Percentage eengezinswoning',
    },
    percentageMeergezinswoning: {
        unit: '%',
        label: 'Percentage meergezinswoning',
    },
    percentageOnbewoond: {
        unit: '%',
        label: 'Percentage onbewoond',
    },
};
const neighbourhoodZonnestroom: NeighbourhoodData = {
    aantalInstallatiesBijWoningen: {
        unit: 'aantal',
        label: 'Aantal installaties bij woningen',
    },
    opgesteldVermogenVanZonnestroominstallaties: {
        unit: 'kWp',
        label: 'Opgesteld vermogen van zonnepanelen',
    },
};
const neighbourhoodCriminaliteit: NeighbourhoodData = {
    BedreigingEnStalking: {
        unit: 'aantal',
        label: 'Bedreiging en stalking',
    },
    DiefstalUitWoningSchuurED: {
        unit: 'aantal',
        label: 'Diefstal uit woning, schuur, e.d.',
    },
    DiefstalOverigeVervoermiddelen: {
        unit: 'aantal',
        label: 'Diefstal overige vervoermiddelen',
    },
    DiefstalUitNietResidentieleGebouwen: {
        unit: 'aantal',
        label: 'Diefstal uit niet-residentiële gebouwen',
    },
    DiefstalUitVanafVervoermiddelen: {
        unit: 'aantal',
        label: 'Diefstal uit / vanaf vervoermiddelen',
    },
    Fietsendiefstal: {
        unit: 'aantal',
        label: 'Fietsendiefstal',
    },
    GeweldsEnSeksueleMisdrijven: {
        unit: 'aantal',
        label: 'Gewelds- en seksuele misdrijven',
    },
    Mishandeling: {
        unit: 'aantal',
        label: 'Mishandeling',
    },
    OverigeDiefstalInbraak: {
        unit: 'aantal',
        label: 'Overige diefstal / inbraak',
    },
    OverigeGeweldsEnSeksueleMisdrijven: {
        unit: 'aantal',
        label: 'Overige gewelds- en seksuele misdrijven',
    },
    OverigeVermogensmisdrijven: {
        unit: 'aantal',
        label: 'Overige vermogensmisdrijven',
    },
    OverigeVernieling: {
        unit: 'aantal',
        label: 'Overige vernieling',
    },
    OverigeVernielingTegenOpenbareOrde: {
        unit: 'aantal',
        label: 'Overige vernieling tegen openbare orde',
    },
    TotaalDiefstal: {
        unit: 'aantal',
        label: 'Totaal diefstal',
    },
    TotaalDiefstalUitWoningSchuurED: {
        unit: 'aantal',
        label: 'Totaal diefstal uit woning, schuur, e.d.',
    },
    TotaalGeweldsEnSeksueleMisdrijven: {
        unit: 'aantal',
        label: 'Totaal gewelds- en seksuele misdrijven',
    },
    TotaalVermogenVernielingEnGeweld: {
        unit: 'aantal',
        label: 'Totaal vermogen, vernieling en geweld',
    },
    TotaalVermogensmisdrijven: {
        unit: 'aantal',
        label: 'Totaal vermogensmisdrijven',
    },
    TotaalVernieling: {
        unit: 'aantal',
        label: 'Totaal vernieling',
    },
    TotaalVernielingTegenOpenbareOrde: {
        unit: 'aantal',
        label: 'Totaal vernieling tegen openbare orde',
    },
    VernielingAanAuto: {
        unit: 'aantal',
        label: 'Vernieling aan auto',
    },
    VernielingMisdrijfTegenOpenbareOrde: {
        unit: 'aantal',
        label: 'Vernieling misdrijf tegen openbare orde',
    },
    ZakkenrollerijStraatroofEnBeroving: {
        unit: 'aantal',
        label: 'Zakkenrollerij, straatroof en beroving',
    },
};

//endregion

export function getUnitForNeighbourhoodDetail(type: string, key: string): string {
    switch (type) {
        case 'bedrijfsvestigingen':
            return neighbourhoodBedrijfsvestigingen[key].unit;
        case 'bevolking':
            return neighbourhoodBevolking[key].unit;
        case 'detailhandel':
            return neighbourhoodDetailhandel[key].unit;
        case 'energie':
            return neighbourhoodEnergie[key].unit;
        case 'gezondheid':
            return neighbourhoodGezondheid[key].unit;
        case 'horeca':
            return neighbourhoodHoreca[key].unit;
        case 'inkomen':
            return neighbourhoodInkomen[key].unit;
        case 'kinderopvang':
            return neighbourhoodKinderOpvang[key].unit;
        case 'motorvoertuigen':
            return neighbourhoodMotorvoertuigen[key].unit;
        case 'oppervlakte':
            return neighbourhoodOppervlakte[key].unit;
        case 'postcode':
            return neighbourhoodPostcode[key].unit;
        case 'recreatie':
            return neighbourhoodRecreatie[key].unit;
        case 'socialeZekerheid':
            return neighbourhoodSocialeZekerheid[key].unit;
        case 'stedelijkheid':
            return neighbourhoodStedelijkheid[key].unit;
        case 'verkeerVervoer':
            return neighbourhoodVerkeerVervoer[key].unit;
        case 'wonen':
            return neighbourhoodWonen[key].unit;
        case 'zonnestroom':
            return neighbourhoodZonnestroom[key].unit;
        case 'criminaliteit':
            return neighbourhoodCriminaliteit[key].unit;
        default:
            return '';
    }
}

export function getLabelForNeighbourhoodDetail(key: string): string {
    switch (key) {
        case 'postcode':
            return 'Postcode';
        case 'wijkenEnBuurten':
            return 'Wijken en buurten';
        case 'algemeen':
            return 'Algemeen';
        case 'neighbourhoodBedrijfsvestigingen':
            return 'Bedrijfsvestigingen';
        case 'neighbourhoodBevolking':
            return 'Bevolking';
        case 'neighbourhoodDetailhandel':
            return 'Detailhandel';
        case 'neighbourhoodEnergie':
            return 'Energie';
        case 'neighbourhoodGezondheid':
            return 'Gezondheid';
        case 'neighbourhoodHoreca':
            return 'Horeca';
        case 'neighbourhoodInkomen':
            return 'Inkomen';
        case 'neighbourhoodKinderopvang':
            return 'Kinderopvang';
        case 'neighbourhoodMotorvoertuigen':
            return 'Motorvoertuigen';
        case 'neighbourhoodOppervlakte':
            return 'Oppervlakte';
        case 'neighbourhoodPostcode':
            return 'Postcode';
        case 'neighbourhoodRecreatie':
            return 'Recreatie';
        case 'neighbourhoodSocialeZekerheid':
            return 'Sociale zekerheid';
        case 'neighbourhoodStedelijkheid':
            return 'Stedelijkheid';
        case 'neighbourhoodVerkeerVervoer':
            return 'Verkeer en vervoer';
        case 'neighbourhoodWonen':
            return 'Wonen';
        case 'neighbourhoodZonnestroom':
            return 'Zonnestroom';
        case 'neighbourhoodCriminaliteit':
            return 'Criminaliteit';
        default:
            return '';
    }
}

export function getLabelForSubNeighbourhoodDetail(type: string, key: string): string {
    switch (type) {
        case 'algemeen':
            return algemeen[key].label;
        case 'neighbourhoodBedrijfsvestigingen':
            return neighbourhoodBedrijfsvestigingen[key].label;
        case 'neighbourhoodBevolking':
            return neighbourhoodBevolking[key].label;
        case 'neighbourhoodDetailhandel':
            return neighbourhoodDetailhandel[key].label;
        case 'neighbourhoodEnergie':
            return neighbourhoodEnergie[key].label;
        case 'neighbourhoodGezondheid':
            return neighbourhoodGezondheid[key].label;
        case 'neighbourhoodHoreca':
            return neighbourhoodHoreca[key].label;
        case 'neighbourhoodInkomen':
            return neighbourhoodInkomen[key].label;
        case 'neighbourhoodKinderopvang':
            return neighbourhoodKinderOpvang[key].label;
        case 'neighbourhoodMotorvoertuigen':
            return neighbourhoodMotorvoertuigen[key].label;
        case 'neighbourhoodOppervlakte':
            return neighbourhoodOppervlakte[key].label;
        case 'neighbourhoodPostcode':
            return neighbourhoodPostcode[key].label;
        case 'neighbourhoodRecreatie':
            return neighbourhoodRecreatie[key].label;
        case 'neighbourhoodSocialeZekerheid':
            return neighbourhoodSocialeZekerheid[key].label;
        case 'neighbourhoodStedelijkheid':
            return neighbourhoodStedelijkheid[key].label;
        case 'neighbourhoodVerkeerVervoer':
            return neighbourhoodVerkeerVervoer[key].label;
        case 'neighbourhoodWonen':
            return neighbourhoodWonen[key].label;
        case 'neighbourhoodZonnestroom':
            return neighbourhoodZonnestroom[key].label;
        case 'neighbourhoodCriminaliteit':
            return neighbourhoodCriminaliteit[key].label;
        default:
            return '';
    }
}

export function getUnitForSubNeighbourhoodDetail(type: string, key: string): string {
    switch (type) {
        case 'algemeen':
            return algemeen[key].unit;
        case 'neighbourhoodBedrijfsvestigingen':
            return neighbourhoodBedrijfsvestigingen[key].unit;
        case 'neighbourhoodBevolking':
            return neighbourhoodBevolking[key].unit;
        case 'neighbourhoodDetailhandel':
            return neighbourhoodDetailhandel[key].unit;
        case 'neighbourhoodEnergie':
            return neighbourhoodEnergie[key].unit;
        case 'neighbourhoodGezondheid':
            return neighbourhoodGezondheid[key].unit;
        case 'neighbourhoodHoreca':
            return neighbourhoodHoreca[key].unit;
        case 'neighbourhoodInkomen':
            return neighbourhoodInkomen[key].unit;
        case 'neighbourhoodKinderopvang':
            return neighbourhoodKinderOpvang[key].unit;
        case 'neighbourhoodMotorvoertuigen':
            return neighbourhoodMotorvoertuigen[key].unit;
        case 'neighbourhoodOppervlakte':
            return neighbourhoodOppervlakte[key].unit;
        case 'neighbourhoodPostcode':
            return neighbourhoodPostcode[key].unit;
        case 'neighbourhoodRecreatie':
            return neighbourhoodRecreatie[key].unit;
        case 'neighbourhoodSocialeZekerheid':
            return neighbourhoodSocialeZekerheid[key].unit;
        case 'neighbourhoodStedelijkheid':
            return neighbourhoodStedelijkheid[key].unit;
        case 'neighbourhoodVerkeerVervoer':
            return neighbourhoodVerkeerVervoer[key].unit;
        case 'neighbourhoodWonen':
            return neighbourhoodWonen[key].unit;
        case 'neighbourhoodZonnestroom':
            return neighbourhoodZonnestroom[key].unit;
        case 'neighbourhoodCriminaliteit':
            return neighbourhoodCriminaliteit[key].unit;
        default:
            return '';
    }
}
