import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'moment';
import 'whatwg-fetch';
import './app/bootstrap';
import './app/uploads';
import './app/address';
import './app/add_to_homescreen';
import './app/fancy_inputs';
import './app/iframe-wizard';
import './app/layouts-options';
import './app/release-notes-dismiss';
import './app/modal-dismiss';
import './app/fireworks';
import './app/recaptcha';
import './create_appraisal/form';
import './create_appraisal/form_address';
import './create_appraisal/form_rights_client_details_postalcode';
import './create_appraisal/form_toggle_btn';
import './create_appraisal/form_rights_current_resident';
import './create_appraisal/form_prior_appraiser';
import './create_appraisal/form_rights_usage';
import './create_appraisal/form_tvi_contract';
import './create_appraisal/form_consider';
import './create_appraisal/address_geo_map';
import './create_appraisal/format_emails_safari';
import './edit_appraisal/form';
import './account/display_name';
import './account/form';
import './account/front_page';
import './account/appraisal_list';
import './account/message_template';
import './account/statistics_graph';
import './account/settings';
import './account/subscriptions';
import './app/input_copy';
import './app/list_ellipsis';
import './app/list_swipe';
import './app/pull_to_refresh';
import './app/copy';
import './app/tooltips';
import './notifications';
import './organisations';
import './macros';
import './controller_appraiser';

import $ from 'jquery';
import Localforage from 'localforage';
import {redirectOnTaskComplete} from './app/task';
import registerServiceWorker from './app/register_service_worker';

/**
 * Render show appraisal environment if needed
 */
(() => {
    const element = document.getElementById('show-appraisal');
    if (element) {
        const {renderAppraisalStartEnvironment} = require('./appraising/appraise/start');
        renderAppraisalStartEnvironment();
    }
})();

/**
 * Render appraisal environment if needed
 */
(() => {
    const element = document.getElementById('appraise');
    if (element) {
        const {renderEnvironmentInto} = require('./appraising/root_router');
        renderEnvironmentInto(element);
    }
})();

/**
 * Render model values environment if needed
 */
(() => {
    const element = document.getElementById('model-values');
    if (element) {
        const {renderAppraisalEnvironmentInto} = require('./appraising/model_values/index');
        renderAppraisalEnvironmentInto(element);
    }
})();

/**
 * Render geolocation permission part in account me screen
 */
(() => {
    const element = document.getElementById('account-me-geolocation');
    if (element) {
        const {renderAccountGeolocationPermission} = require('./account/account_geolocation');
        renderAccountGeolocationPermission(element);
    }
})();

/**
 * Render geolocation modal
 */
(() => {
    const element = document.getElementById('geolocation_modal');
    if (element) {
        const {renderGeolocationModal} = require('./nearby_appraisal');
        renderGeolocationModal(element);
    }
})();

/**
 * Render year overview UI
 */
(() => {
    const element = document.getElementById('year_overview');
    if (element) {
        const {renderYearOverviewSearchInto} = require('./year_overview/root');
        renderYearOverviewSearchInto(element);
    }
})();

/**
 * Submit forms
 */
(() => {
    $('form').on('submit', function () {
        $(this).find('[type=submit]').prop('disabled', true).append('<span class="loader"><span></span></span>');
    });
})();

/**
 * Render appraisal notes widget
 */
(() => {
    const element = document.getElementById('appraisal_notes');
    if (element) {
        const {renderAppraisalNotesWidgetIntoInto} = require('./appraising/components/notes_widget_root');
        renderAppraisalNotesWidgetIntoInto(element);
    }
})();

/**
 * Open Bootstrap modal on page load
 */
(() => {
    const storage = require('localforage').createInstance({
        name: 'taxapi-modal-showings',
    });
    $('.modal.onload:not(.onload-single)').modal('show');
    $('.modal.onload.onload-single').each(async function () {
        const id = $(this).attr('data-id');
        if (!id) {
            return;
        }
        const ref = id + '-modal-shown-at';
        const item = await storage.getItem(ref);
        const currentTime = new Date().getTime();
        if (item) {
            const diff = new Date().getTime() - item;
            const sevenDaysInMs = 604800000;
            if (diff >= sevenDaysInMs) {
                $(this).modal('show');
            }
        } else {
            $(this).modal('show');
        }
        storage.setItem(ref, currentTime);
    });
})();

/**
 * Bootstrap popover
 */
(() => {
    $('[data-toggle="popover"]').popover();
})();

/**
 * Bootstrap tooltip
 */
(() => {
    $('[data-toggle="tooltip"]').tooltip();
})();

/**
 * Get status from task
 */
(() => {
    redirectOnTaskComplete();
})();

/**
 * We'll always need this thing, both for caching as well as being recognized as progressive web-app
 * https://blog.betapage.co/how-to-add-add-to-homescreen-popup-in-web-app-99d5237fabff
 */
(() => {
    registerServiceWorker();
})();

/**
 * Change terms of service links in registration form
 */
(() => {
    $('[id="user-type"]').change(function (event) {
        var selectedOption = event.target.value;

        if (selectedOption === 'appraiser') {
            $('#terms_link').attr('href', LINKS.appraiser);
        } else {
            $('#terms_link').attr('href', LINKS.client);
        }
    });
})();

/**
 * Add loading spinner to appraise button
 */
(() => {
    $('#appraise-button').on('click', function () {
        $(this).addClass('disabled').append('<span class="loader"><span></span></span>');
    });
})();

/**
 * Hide validation message on type
 */
(() => {
    $('input, select').on('change', function () {
        const parent = $(this).parents('.form-group')[0];
        if (parent) {
            const invalid = $(parent).find('.invalid-feedback:not(.invalid-feedback-dynamic)');
            if (invalid) {
                invalid.hide();
            }
        }
    });
})();

/*
 * Load show appraisal chunk if needed
 */
(() => {
    const element = document.getElementById('show-appraisal');
    if (element) {
        require('./show_appraisal');
    }
})();

/**
 * Pause video on close modal
 */
(() => {
    const modals = $('.modal');
    if (modals) {
        for (const modal of modals) {
            const video = $(modal).find('video');
            if (video && video[0]) {
                $(modal).on('hide.bs.modal', function () {
                    video[0].pause();
                });
            }
        }
    }
})();

/**
 * Toggle appraise secondary floating container
 */
(() => {
    $('.card-floating-trigger').on('click', function () {
        $(this).parent('.card-floating').toggleClass('open');
    });
})();

/**
 * Render e-mail markdown editor
 */
(() => {
    const element = document.getElementById('email-markdown-editor');
    if (element) {
        const {renderEmailMarkdownEditor} = require('./account/email_editor');
        renderEmailMarkdownEditor(element);
    }
})();

/**
 * Render pre-check environment if needed
 */
(() => {
    const object = document.getElementById('pre-check-object');
    if (object) {
        const {renderPrecheckObjectEnvironmentInto} = require('./create_appraisal/pre_check/object');
        renderPrecheckObjectEnvironmentInto(object);
    }

    const decide = document.getElementById('pre-check-decide');
    if (decide) {
        const {renderPrecheckDecideEnvironmentInto} = require('./create_appraisal/pre_check/decide');
        renderPrecheckDecideEnvironmentInto(decide);
    }

    const attachments = document.getElementById('pre-check-attachments');
    if (attachments) {
        const {renderPrecheckAttachmentsEnvironmentInto} = require('./create_appraisal/pre_check/attachments');
        renderPrecheckAttachmentsEnvironmentInto(attachments);
    }

    const preferences = document.getElementById('appraisal-create-preferences');
    if (preferences) {
        const {renderAppraisalPreferencesEnvironmentInto} = require('./create_appraisal/preferences');
        renderAppraisalPreferencesEnvironmentInto(preferences);
    }

    const financial = document.getElementById('pre-check-financial');
    if (financial) {
        const {renderPrecheckAppraisalWozValuationEnvironmentInto} = require('./create_appraisal/pre_check/valuation');
        renderPrecheckAppraisalWozValuationEnvironmentInto(financial);
    }

    const rights = document.getElementById('pre-check-rights');
    if (rights) {
        const {renderPrecheckStatisticsEnvironmentInto} = require('./create_appraisal/pre_check/statistics');
        renderPrecheckStatisticsEnvironmentInto(rights);
    }

    const appraiser = document.getElementById('pre-check-appraiser');
    if (appraiser) {
        const {
            renderPrecheckAppraisalMatchStatisticsEnvironmentInto,
        } = require('./create_appraisal/pre_check/appraisal_match_statistics');
        renderPrecheckAppraisalMatchStatisticsEnvironmentInto(appraiser);
    }

    const references = document.getElementById('pre-check-references');
    if (references) {
        const {renderPrecheckReferencesEnvironmentInto} = require('./create_appraisal/pre_check/reference_objects');
        renderPrecheckReferencesEnvironmentInto(references);
    }

    const invoiceFlow = document.getElementById('pre-check-invoice-flow');
    if (invoiceFlow) {
        const {setupInvoiceFlowWidget} = require('./create_appraisal/pre_check/invoice_flow');
        setupInvoiceFlowWidget(invoiceFlow);
    }
})();

/**
 * Render reference objects environment if needed
 */
(() => {
    const referenceObjectsSearch = document.getElementById('reference-objects-search');
    if (referenceObjectsSearch) {
        const {renderReferenceObjectsSearchInto} = require('./reference_objects/search');
        renderReferenceObjectsSearchInto(referenceObjectsSearch);
    }
})();
