import React, {useState} from 'react';
import {PreferencesPresenter} from './preferences_presenter';
import {observer} from 'mobx-react';
import {usePresenter} from '../../support/presenter/use_presenter';
import {Loader} from '../../appraising/appraise/ui/components/loader';
import {PreferencesCheckbox} from './preferences_checkbox';
import {createPortal} from 'react-dom';
import {AutomatorDispatchGroup} from './appraisal_costs';
import {humanizeArray} from '../../appraising/appraise/ui/support/humanize_array';
import {formatMoney} from '../../appraising/appraise/ui/support/format_money';
import {Modal} from '../../appraising/appraise/ui/content/questions/components/modal';
import {CostsOverview} from './costs_overview';
import {SimpleTooltip} from '../../appraising/components/tooltip';
import {CostLineChoice} from './cost_line_choice';

const costsWidget = document.getElementById('appraisal-create-costs-widget');

export const Preferences: React.FC = observer(() => {
    const presenter = usePresenter((component) => new PreferencesPresenter(component.business.appraisalProvider));

    const [costModalOpen, setCostModalOpen] = useState(false);

    if (presenter.loading) {
        return (
            <div className="empty-message">
                <Loader />
                <h3>Aan het laden...</h3>
            </div>
        );
    }

    const hasManualCosts =
        presenter.costs?.automatorCostPackages.some((costPackage) => presenter.isManual(costPackage)) ||
        presenter.costs?.automatorCostDetails.some((costDetails) => presenter.isManual(costDetails));

    const hasActiveManualCosts =
        presenter.costs?.automatorCostPackages
            .filter((costPackage) => presenter.activeOptionalCosts.has(costPackage.costType))
            .some((costPackage) => presenter.isManual(costPackage)) ||
        presenter.costs?.automatorCostDetails
            .filter((costDetails) => presenter.activeDispatchGroups.has(costDetails.dispatchGroup))
            .some((costDetails) => presenter.isManual(costDetails));

    const hasFundering = presenter.activeDispatchGroups.has(AutomatorDispatchGroup.FUNDERINGSRISICO);

    const total = presenter.lines.reduce((total, line) => total + line.costCents, 0);

    const dispatchGroupsToShow = new Set(presenter.dispatchGroupsToShow);

    return (
        <>
            {presenter.costs?.optionalLines.map((costLine, i) => (
                <CostLineChoice
                    key={`${costLine.costType}-${i}`}
                    costLine={costLine}
                    activeOptionalCosts={presenter.activeOptionalCosts}
                    forcedOptionalCosts={presenter.forcedOptionalCosts}
                    disabled={presenter.disabled}
                    toggle={(costType) => presenter.toggleOptionalCosts(costType)}
                />
            ))}
            {presenter.costs?.automatorCostPackages.map((costPackage) => (
                <>
                    <PreferencesCheckbox
                        key={costPackage.costType}
                        id={costPackage.costType}
                        label={`${costPackage.shortName}${presenter.isManual(costPackage) ? '*' : ''}`}
                        checked={presenter.activeOptionalCosts.has(costPackage.costType)}
                        onChange={() => presenter.toggleOptionalCosts(costPackage.costType)}
                        disabled={presenter.disabled}
                        forceActivated={presenter.forcedOptionalCosts.has(costPackage.costType)}
                        extra={
                            <SimpleTooltip
                                content={`Dit pakket bevat: ${humanizeArray(
                                    costPackage.coveredCosts.map((c) => c.shortName.toLowerCase())
                                )}.`}
                                placement="top"
                            >
                                <a className="btn-text-link ion-ios-help-circle ml-2">&nbsp;</a>
                            </SimpleTooltip>
                        }
                    />
                    {presenter
                        .getPackageIncludedDispatchGroups(costPackage)
                        .filter((costDetails) => dispatchGroupsToShow.has(costDetails.dispatchGroup))
                        .map((costDetails) => {
                            dispatchGroupsToShow.delete(costDetails.dispatchGroup);

                            return (
                                <PreferencesCheckbox
                                    key={costDetails.dispatchGroup}
                                    id={costDetails.dispatchGroup}
                                    label={`${costDetails.displayName}${presenter.isManual(costDetails) ? '*' : ''}`}
                                    className="ml-4"
                                    checked={presenter.activeDispatchGroups.has(costDetails.dispatchGroup)}
                                    onChange={() => presenter.toggleDispatchGroup(costDetails.dispatchGroup)}
                                    disabled={presenter.disabled}
                                    forceActivated={
                                        presenter.getDispatchGroupForcedPackages(costDetails.dispatchGroup).length > 0
                                    }
                                />
                            );
                        })}
                </>
            ))}
            {(presenter.costs?.automatorCostDetails ?? [])
                .filter((costDetails) => dispatchGroupsToShow.has(costDetails.dispatchGroup))
                .map((costDetails) => (
                    <PreferencesCheckbox
                        key={costDetails.dispatchGroup}
                        id={costDetails.dispatchGroup}
                        label={`${costDetails.displayName}${presenter.isManual(costDetails) ? '*' : ''}`}
                        checked={presenter.activeDispatchGroups.has(costDetails.dispatchGroup)}
                        onChange={() => presenter.toggleDispatchGroup(costDetails.dispatchGroup)}
                        disabled={presenter.disabled}
                        forceActivated={presenter.getDispatchGroupForcedPackages(costDetails.dispatchGroup).length > 0}
                    />
                ))}
            {costsWidget &&
                createPortal(
                    <div className="widget pre-check-widget">
                        <div className="widget-title">
                            <h3>
                                <span className="ion-logo-euro" />
                                Kostenoverzicht
                            </h3>
                        </div>
                        <div className="widget-blocks">
                            <CostsOverview lines={presenter.lines ?? []} />
                        </div>
                    </div>,
                    costsWidget
                )}
            {hasManualCosts && (
                <p className="mt-3 mb-0">
                    * Deze bijlagen worden handmatig door taXapi toegevoegd.{' '}
                    {hasActiveManualCosts ? 'Voor het ophalen hiervan moeten nog extra gegevens worden opgegeven.' : ''}
                </p>
            )}
            {hasManualCosts && hasActiveManualCosts && (
                <div className="row">
                    <div className="col-12 col-md-6">
                        <div className="form-group">
                            <label className="form-label form-label-sm">
                                Gemeente<span className="form-label-highlight">*</span>
                            </label>
                            <input
                                className="form-control"
                                required
                                value={presenter.attachmentTeamFields['Gemeente']}
                                onChange={(ev) => presenter.setAttachmentTeamField('Gemeente', ev.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-6 col-md-3">
                        <div className="form-group">
                            <label className="form-label form-label-sm">
                                Sectie<span className="form-label-highlight">*</span>
                            </label>
                            <input
                                className="form-control"
                                required
                                value={presenter.attachmentTeamFields['Sectie']}
                                onChange={(ev) => presenter.setAttachmentTeamField('Sectie', ev.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-6 col-md-3">
                        <div className="form-group">
                            <label className="form-label form-label-sm">
                                Perceelnummer<span className="form-label-highlight">*</span>
                            </label>
                            <input
                                className="form-control"
                                required
                                inputMode="numeric"
                                value={presenter.attachmentTeamFields['Perceelnummer']}
                                onChange={(ev) => presenter.setAttachmentTeamField('Perceelnummer', ev.target.value)}
                            />
                        </div>
                    </div>
                </div>
            )}
            <input
                type="hidden"
                id="attachment_team_info"
                name="attachment_team_info"
                value={JSON.stringify(
                    Object.keys(presenter.attachmentTeamFields).length === 0
                        ? null
                        : {fields: presenter.attachmentTeamFields}
                )}
            />
            <div className="d-flex justify-content-between align-items-center mt-2 pre-check-mobile-view">
                <p className="mb-0">
                    De totale kosten voor deze taxatie zullen <strong>{formatMoney(total / 100, {decimals: 2})}</strong>{' '}
                    zijn.
                </p>
                <button
                    className="btn btn-sm btn-secondary"
                    onClick={(e) => {
                        e.preventDefault();
                        setCostModalOpen(true);
                    }}
                >
                    Kostenoverzicht
                </button>
            </div>
            <Modal visible={costModalOpen} onHide={() => setCostModalOpen(false)}>
                <div className="modal-header">
                    <h2>Kostenoverzicht</h2>
                </div>
                <div className="modal-body pre-check-modal">
                    <CostsOverview lines={presenter.lines ?? []} />
                </div>
                <div className="modal-footer">
                    <button
                        className="btn btn-secondary btn-full ion-md-close"
                        type="button"
                        onClick={() => setCostModalOpen(false)}
                    >
                        Sluiten
                    </button>
                </div>
            </Modal>
            {hasFundering && (
                <p className="mt-3 mb-0">
                    Door het activeren van de funderingsrisico-bijlage zal de taxatie direct worden aangemeld bij het
                    NWWI na aanmaken. Het is ook mogelijk om deze bijlage uit te schakelen en deze later op te halen op
                    de pagina Dossier. Hierdoor wordt de taxatie pas later aangemeld bij het NWWI, wat meer
                    mogelijkheden geeft om later nog wijzigingen aan te brengen in de opdrachtvoorwaarden of de taxatie
                    zelf.
                </p>
            )}
            <p className="mt-3 mb-0">
                Door op volgende te klikken ga je akkoord met de geselecteerde kosten. Deze kosten worden afgeschreven
                zodra je het aanmaakproces afrond. Als je dit proces niet afrond, dan worden de gemaakte kosten alsnog
                afgeschreven na 2 maanden.
            </p>
        </>
    );
});
