import {format, parse} from 'date-fns';
import {isEmpty} from '../../../../support/util';
import {nl} from 'date-fns/locale';

export enum DateFormat {
    TAXAPI = 'yyyy-MM-dd',
    PICKER = 'dd-MM-yyyy',
}

export function parseDateToFormat(contents: string, fromFormat: string, toFormat: string) {
    if (isEmpty(contents)) {
        return undefined;
    }
    const date = parseDate(contents ?? '', fromFormat);
    if (date) {
        return formatDate(date, toFormat);
    }
    return undefined;
}

export function parseDate(str: string, stringFormat: string) {
    const parsed = parse(str, stringFormat, new Date());
    if (!isNaN(parsed.valueOf())) {
        return parsed;
    }
    return undefined;
}

export function formatDate(date: Date, formatString: string) {
    return format(date, formatString, {
        locale: nl,
    });
}
