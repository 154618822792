import * as React from 'react';

import {CadastralPlotAddress, CadastralPlotAddressType} from '../../../../../../models/cadastral_plot_address';

import {AddCadastralPlotModalPresenter} from './add_cadastral_plot_modal_presenter';
import {Appraisal} from '../../../../../../models/appraisal';
import {IteratorGroup} from '../../simple/iterator_group';
import {Loader} from '../../../../components/loader';
import {Modal} from '../../components/modal';
import {PagePart} from '../../../../../../models/page_part';
import {PagePartsSet} from '../../../../../../models/page_parts_set';
import {Question} from '../../../../../../models/question';
import {QuestionSet} from '../../../../../../models/question_set';
import {QuestionType} from '../../../../../../enum/question_type';
import {RenderingContextType} from '../../../../../../enum/rendering_context_type';
import {ValidationError} from '../../../../../../models/validation_error';
import {ValidationMessageMap} from '../../../../../../business/validation/validation_message';
import {classNames} from '../../../../../../../support/classnames';
import {isEmpty} from '../../../../../../../support/util';
import {observer} from 'mobx-react';
import {usePresenter} from '../../../../../../../support/presenter/use_presenter';
import {QuestionRenderingData} from '../../../../../../models/question_rendering_data';
import {formatMoney} from '../../../../support/format_money';

interface OwnProps {
    question: Question;
    appraisal: Appraisal;
    questionSet: QuestionSet;
    validationErrors: ValidationError[];
    validationMessages: ValidationMessageMap;
    forceShowValidationMessages: boolean;
    iteration: string;
    parentAnswerUuid?: string;
    visible: boolean;
    onHide: () => void;
    onAdd: () => void;
    hiddenQuestionTypes: QuestionType[];
    pagePartsSet: PagePartsSet | null;
    activePagePart: PagePart | null;
    renderingContext: RenderingContextType;
    questionRenderingData: QuestionRenderingData | null;
}

export const AddCadastralPlotModal: React.FC<OwnProps> = observer(function AddCadastralPlotModal(props) {
    const presenter = usePresenter(
        (container) =>
            new AddCadastralPlotModalPresenter(
                props.appraisal,
                props.question,
                props.questionSet,
                props.parentAnswerUuid !== undefined ? props.parentAnswerUuid : null,
                props.iteration,
                props.onAdd,
                props.onHide,
                container.business.answerController(props.appraisal, props.questionSet),
                container.network.appraisalAutomationApi,
                container.business.networkStatusProvider,
                container.globalProvider
            )
    );

    function getIconClassNames(type: CadastralPlotAddressType | null) {
        switch (type) {
            case CadastralPlotAddressType.HOUSE:
                return 'grid-item-title-avatar-icon-house';
            case CadastralPlotAddressType.GARAGE:
                return 'grid-item-title-avatar-icon-garage';
            case CadastralPlotAddressType.OTHER:
            default:
                return 'grid-item-title-avatar-icon-other';
        }
    }

    return (
        <Modal onHide={() => presenter.onHideModal()} visible={props.visible}>
            <div className="modal-header">
                <h2>{props.question.contents} toevoegen</h2>
            </div>
            <div className="modal-body">
                {props.appraisal.cadastralPlotAddresses.length > 0 ? (
                    <div className="row">
                        <div className="col-12">
                            <p className="leader">
                                {presenter.deedOfDeliveryFile !== null
                                    ? 'Onderstaande percelen zijn gevonden bij het Kadaster. In de akte van levering kun je zien welke percelen er allemaal aanwezig zijn. Selecteer het perceel waarvan je de waarden wil overnemen, of vul zelf de gegevens in.'
                                    : 'Onderstaande percelen zijn gevonden bij het Kadaster. Selecteer het perceel waarvan je de waarden wil overnemen, of vul zelf de gegevens in.'}
                            </p>
                        </div>
                        {presenter.deedOfDeliveryFile !== null ? (
                            <div className="col-12">
                                <p className="text-right">
                                    <a
                                        href={presenter.deedOfDeliveryFile.url}
                                        className="btn btn-sm ion-md-download"
                                        target="_blank"
                                        download={
                                            presenter.deedOfDeliveryFile.compressedFilename ??
                                            presenter.deedOfDeliveryFile.originalFilename
                                        }
                                        rel="noreferrer"
                                    >
                                        Download akte van levering
                                    </a>
                                </p>
                            </div>
                        ) : null}
                    </div>
                ) : null}

                {presenter.formVisible ? (
                    <>
                        <div className="cadastral-iterator-questions">
                            <IteratorGroup
                                key={props.question.uuid + '[' + props.iteration + ']'}
                                questionSet={props.questionSet}
                                appraisal={props.appraisal}
                                iteration={props.iteration}
                                parentAnswerUuid={props.parentAnswerUuid}
                                validationErrors={props.validationErrors}
                                validationMessages={props.validationMessages}
                                forceShowValidationMessages={props.forceShowValidationMessages}
                                question={props.question}
                                adaptedDefaultValues={presenter.adaptedDefaultValuesMap}
                                showDeleteButton={false}
                                hiddenQuestionTypes={props.hiddenQuestionTypes}
                                pagePartsSet={props.pagePartsSet}
                                activePagePart={props.activePagePart}
                                renderingContext={props.renderingContext}
                                questionRenderingData={props.questionRenderingData}
                            />
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <input
                                        type="checkbox"
                                        id="cadastral-plot-run-automation"
                                        name="cadastral-plot-run-automation"
                                        checked={presenter.runAutomations}
                                        onChange={(e) => presenter.onCheckRunAutomations(e.target.checked)}
                                    />
                                    <label htmlFor="cadastral-plot-run-automation">
                                        Voeg uittreksel van dit perceel toe aan bijlagen
                                    </label>
                                </div>
                                <small>
                                    Voor het toevoegen van een extra uittreksel wordt{' '}
                                    {formatMoney(presenter.kadasterCostsCents / 100)} excl. BTW in rekening gebracht.
                                </small>
                            </div>
                        </div>
                    </>
                ) : (
                    <div className={classNames('row', {disabled: presenter.loading})}>
                        <div className="col-12">
                            <div className="row grid">
                                {props.appraisal.cadastralPlotAddresses.map(
                                    (cadastralPlotAddress: CadastralPlotAddress, key) => {
                                        return (
                                            <div
                                                key={key}
                                                className="col-12 col-sm-6 col-lg-4 form-grid-item"
                                                onClick={() => presenter.onClickOption(cadastralPlotAddress)}
                                            >
                                                <span className="form-grid-item-body">
                                                    <span className="row">
                                                        <span className="col-12">
                                                            <span className="grid-item-title grid-item-title-with-avatar">
                                                                <span
                                                                    className={classNames(
                                                                        'grid-item-title-avatar',
                                                                        getIconClassNames(cadastralPlotAddress.plotType)
                                                                    )}
                                                                >
                                                                    &nbsp;
                                                                </span>
                                                                <strong>
                                                                    {cadastralPlotAddress.address}{' '}
                                                                    {cadastralPlotAddress.number}{' '}
                                                                    {cadastralPlotAddress.numberAddition}
                                                                </strong>
                                                            </span>
                                                        </span>
                                                        <span className="col-12">
                                                            {!isEmpty(cadastralPlotAddress.identificationType) ? (
                                                                <dl className="full">
                                                                    <dt>Type</dt>
                                                                    <dd>{cadastralPlotAddress.identificationType}</dd>
                                                                </dl>
                                                            ) : null}
                                                            {!isEmpty(cadastralPlotAddress.plotTypeText) ? (
                                                                <dl className="full">
                                                                    <dt>Bebouwing</dt>
                                                                    <dd>{cadastralPlotAddress.plotTypeText}</dd>
                                                                </dl>
                                                            ) : null}
                                                            {!isEmpty(cadastralPlotAddress.municipal) ? (
                                                                <dl className="full">
                                                                    <dt>Gemeente</dt>
                                                                    <dd>{cadastralPlotAddress.municipal}</dd>
                                                                </dl>
                                                            ) : null}
                                                            {!isEmpty(cadastralPlotAddress.cadastralSection) ? (
                                                                <dl className="text-left">
                                                                    <dt>Sectie</dt>
                                                                    <dd>{cadastralPlotAddress.cadastralSection}</dd>
                                                                </dl>
                                                            ) : null}
                                                            {!isEmpty(cadastralPlotAddress.cadastralPlotNumber) ? (
                                                                <dl className="text-left">
                                                                    <dt>PerceelNr.</dt>
                                                                    <dd>{cadastralPlotAddress.cadastralPlotNumber}</dd>
                                                                </dl>
                                                            ) : null}
                                                            {!isEmpty(cadastralPlotAddress.apartmentRightsIndex) ? (
                                                                <dl className="text-left">
                                                                    <dt>Index</dt>
                                                                    <dd>{cadastralPlotAddress.apartmentRightsIndex}</dd>
                                                                </dl>
                                                            ) : null}
                                                        </span>
                                                    </span>
                                                </span>
                                            </div>
                                        );
                                    }
                                )}
                            </div>
                        </div>
                    </div>
                )}
                {presenter.error !== null ? (
                    <span className="invalid-feedback" role="alert">
                        {presenter.error}
                    </span>
                ) : null}
            </div>
            <div className="modal-footer">
                <button
                    className="btn btn-secondary ion-md-close"
                    type="button"
                    onClick={() => presenter.onHideModal()}
                >
                    Annuleren
                </button>
                {presenter.formVisible ? (
                    <button
                        className="btn btn-primary ion-md-arrow-forward"
                        type="button"
                        disabled={presenter.loading}
                        onClick={() => presenter.onCheckAdd()}
                    >
                        {presenter.loading ? <Loader /> : 'Toevoegen'}
                    </button>
                ) : (
                    <button
                        className="btn btn-primary ion-md-arrow-forward"
                        type="button"
                        onClick={() => presenter.onClickManual()}
                    >
                        {presenter.loading ? <Loader /> : 'Handmatig invoeren'}
                    </button>
                )}
            </div>
        </Modal>
    );
});
