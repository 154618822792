import React from 'react';
import {createRoot} from 'react-dom/client';

import {observer} from 'mobx-react';
import {usePresenter} from '../support/presenter/use_presenter';
import {AppraisalArchivesPresenter} from './appraisal_archives_presenter';
import {RangeSearchModal} from './range_modal';
import {AddressSearchModal} from './address_modal';
import {formatDate} from '../appraising/appraise/ui/support/format_date';
import {FlashMessage} from '../appraising/appraise/ui/container/flash_message';

const AppraisalArchives: React.FC = observer(() => {
    const presenter = usePresenter(
        (component) => new AppraisalArchivesPresenter(component.business.flashMessageBroadcaster)
    );
    const [isRangeModalVisible, setIsRangeModalVisible] = React.useState(false);
    const [addressModalVisible, setIsAddressModalVisible] = React.useState(false);

    return (
        <>
            <div className="card-body">
                <div className="messages-stack">
                    <FlashMessage />
                </div>
                <p>
                    Kies de taxaties waar je een back-up van wil maken. Zoek op adres of kies op basis van een periode.
                </p>
                <ul className="list-circles mt-2">
                    <li className="list-item ion-md-arrow-forward" onClick={() => setIsAddressModalVisible(true)}>
                        <span className="list-avatar ion-md-search" />
                        <span className="list-title">
                            <strong>Zoek taxaties op adres</strong>
                            <span className="thin">&nbsp;om toe te voegen aan de back-up.</span>
                        </span>
                    </li>
                    <li className="list-item ion-md-arrow-forward" onClick={() => setIsRangeModalVisible(true)}>
                        <span className="list-avatar ion-md-calendar" />
                        <span className="list-title">
                            <strong>Kies een periode</strong>
                            <span className="thin">&nbsp;om taxaties voor toe te voegen.</span>
                        </span>
                    </li>
                </ul>
                <div className="d-flex w-100 justify-content-between align-items-center mb-3">
                    <div className="d-flex justify-content-center align-items-center">
                        <strong className="btn-circle mr-2" style={{fontSize: 14}}>
                            {presenter.addedAppraisals.length}/{presenter.MAX_BACKUP_SIZE}
                        </strong>
                        <span>taxaties toegevoegd</span>
                    </div>
                    <div>
                        <button
                            className="btn btn-primary ion-md-trash btn-sm"
                            onClick={() => presenter.clearAppraisals()}
                            disabled={presenter.addedAppraisals.length === 0}
                        >
                            Taxaties wissen
                        </button>
                    </div>
                </div>
                <ul className="list-circles mt-2">
                    {presenter.addedAppraisals.map((appraisal) => (
                        <li className="list-item" key={appraisal.id}>
                            <span className="list-avatar">
                                <img src={appraisal.streetviewUrl} />
                            </span>
                            <span className="list-title">
                                <strong>
                                    {appraisal.address} {appraisal.houseNumber} {appraisal.letter}
                                </strong>
                                <span className="thin">
                                    - {appraisal.postalCode} {appraisal.city}
                                </span>
                            </span>
                            <small className="list-details">
                                <span>Aangemaakt op {formatDate(appraisal.createdAt, 'dd-MM-yyyy')}</span>
                            </small>
                            <span className="list-options">
                                <button
                                    className="ion-md-trash"
                                    onClick={() => presenter.removeAppraisal(appraisal.id)}
                                />
                            </span>
                        </li>
                    ))}
                </ul>
                {addressModalVisible && (
                    <AddressSearchModal
                        onClose={(appraisals) => {
                            presenter.addAppraisals(appraisals);
                            if (appraisals.length > 0) presenter.setArchiveName(null);
                            setIsAddressModalVisible(false);
                        }}
                        addedAppraisals={presenter.addedAppraisals}
                        spaceLeft={presenter.spaceLeft}
                    />
                )}
                {isRangeModalVisible && (
                    <RangeSearchModal
                        onClose={(appraisals, range) => {
                            if (appraisals.length > 0) {
                                if (presenter.addedAppraisals.length === 0 && range?.from && range?.to) {
                                    presenter.setArchiveName(
                                        `Archief ${formatDate(range.from, 'dd-MM-yyyy')} - ${formatDate(
                                            range.to,
                                            'dd-MM-yyyy'
                                        )}`
                                    );
                                } else {
                                    presenter.setArchiveName(null);
                                }
                            }

                            presenter.addAppraisals(appraisals);
                            setIsRangeModalVisible(false);
                        }}
                        addedAppraisals={presenter.addedAppraisals}
                        spaceLeft={presenter.spaceLeft}
                    />
                )}
            </div>
            <footer className="d-flex justify-content-end">
                <a className="btn btn-secondary" href="/">
                    Terug
                </a>
                <button
                    className="btn btn-primary"
                    onClick={() => presenter.submit()}
                    disabled={presenter.addedAppraisals.length === 0}
                >
                    Back-up maken
                </button>
            </footer>
        </>
    );
});

export function renderAppraisalArchivesInto(element: HTMLElement) {
    createRoot(element).render(<AppraisalArchives />);
}
