import React, {useEffect, useRef} from 'react';
import {NeighbourhoodDetailsData} from '../../../appraising/models/object_summary';
import {Chart, LegendItem} from 'chart.js';
import 'chartjs-gauge-v3';
import ChartDataLabels from 'chartjs-plugin-datalabels';

interface OwnProps {
    neighbourhoodDetailsData: NeighbourhoodDetailsData | null;
}

export const NeighbourhoodOwnershipGauge: React.FC<OwnProps> = ({neighbourhoodDetailsData}: OwnProps) => {
    if (!neighbourhoodDetailsData?.neighbourhoodWonen) {
        return null;
    }

    const koopwoningen = neighbourhoodDetailsData.neighbourhoodWonen.koopwoningen.value;
    const overigeVerhuurders = neighbourhoodDetailsData.neighbourhoodWonen.inBezitOverigeVerhuurders.value;
    const woningcorporatie = neighbourhoodDetailsData.neighbourhoodWonen.inBezitWoningcorporatie.value;
    const showLegend = koopwoningen > 15 && overigeVerhuurders > 15 && woningcorporatie > 15;

    const ref = useRef<HTMLCanvasElement>(null);

    useEffect(() => {
        if (!ref.current) {
            return;
        }

        const chart = new Chart(ref.current, {
            type: 'doughnut',
            plugins: [ChartDataLabels],
            data: {
                labels: ['% Koopwoningen', '% Woningcorporatie', '% Overige verhuurders'],
                datasets: [
                    {
                        data: [koopwoningen, woningcorporatie, overigeVerhuurders],
                        backgroundColor: [
                            'rgba(255, 99, 132, 1)', // red
                            'rgba(255, 205, 86, 1)', // yellow
                            'rgb(75,192,134)', // green
                        ],
                        datalabels: {
                            display: showLegend,
                            formatter: function (value, context) {
                                if (context.chart.data.labels) {
                                    return context.chart.data.labels[context.dataIndex];
                                }
                            },
                            color: 'rgba(255, 255, 255, 1)',
                            backgroundColor: 'rgba(0,0,0,0.75)',
                            font: {
                                size: 8,
                                weight: 'bold',
                            },
                        },
                    },
                ],
            },
            options: {
                //@ts-expect-error - chartjs-gauge-v3 types are not up to date
                cutout: '50%',
                rotation: -90,
                circumference: 180,
                plugins: {
                    legend: {
                        display: !showLegend,
                        position: 'bottom',
                        align: 'start',
                        labels: {
                            generateLabels(chart: Chart): LegendItem[] {
                                return chart.data.datasets[0].data.map((value, index) => {
                                    if (typeof value !== 'number') {
                                        return {
                                            text: '',
                                            hidden: true,
                                            index: index,
                                            datasetIndex: 0,
                                        };
                                    }

                                    if (!chart.data.labels) {
                                        return {
                                            text: '',
                                            hidden: true,
                                            index: index,
                                            datasetIndex: 0,
                                        };
                                    }
                                    return {
                                        text: `${value}${chart.data.labels[index]}`,
                                        hidden: isNaN(value),
                                        fillStyle: JSON.parse(JSON.stringify(chart.data.datasets[0].backgroundColor))[
                                            index
                                        ],
                                        index: index,
                                        lineWidth: 0,
                                        datasetIndex: 0,
                                    };
                                });
                            },
                        },
                    },
                    tooltip: {
                        callbacks: {
                            label: function (context) {
                                if (context.dataIndex === 1) {
                                    return woningcorporatie.toString(10) + '%';
                                } else if (context.dataIndex === 2) {
                                    return overigeVerhuurders.toString(10) + '%';
                                }
                                return context.formattedValue + '%';
                            },
                        },
                    },
                },
                responsive: true,
                layout: {
                    padding: {
                        left: 30,
                        right: 30,
                        bottom: 140,
                    },
                },
            },
        });

        return () => {
            chart.destroy();
        };
    }, []);

    return neighbourhoodDetailsData ? (
        <>
            <h2>Verdeling bezit</h2>
            <div>
                <canvas key="ownership_gauge" id="ownership_gauge" ref={ref} />
            </div>
        </>
    ) : null;
};
