import {NormalQuestionType, QuestionType} from '../../../enum/question_type';
import {
    ValidationMessage,
    ValidationMessageErrorType,
    ValidationMessageImportance,
    ValidationMessageType,
} from '../validation_message';

import {Answer} from '../../../models/answer';
import {AnswerController} from '../../answering/answer_controller';
import {AnswerValidator} from '../answer_validator';
import {IsRequiredCalculator} from '../internal/is_required_calculator';
import {Question} from '../../../models/question';
import {isEmpty} from '../../../../support/util';

export class RequiredQuestionValidator implements AnswerValidator {
    constructor(private isRequiredCalculator: IsRequiredCalculator, private answerController: AnswerController) {}

    public validate(question: Question, answer: Answer | null): ValidationMessage[] {
        if (!this.hasApplicableQuestionType(question.type)) {
            return [];
        }

        if (!this.isRequiredCalculator.isRequired(question, answer) || (answer !== null && this.hasAnswer(answer))) {
            return [];
        }

        // When an answer is not iterated, and there is no answer found, we don't validate
        if (answer !== null && answer.iteration === null) {
            const answerForQuestion = this.answerController.answersForQuestionUuidAndParentAnswerUuidInSameIteration(
                question.uuid,
                answer.parentUuid
            );
            if (answerForQuestion === null) {
                return [];
            }
        }

        return [
            {
                type: ValidationMessageType.TAXAPI,
                importance: ValidationMessageImportance.ERROR,
                question: question,
                answer: answer,
                messages: [],
                fallbackMessage: 'Het veld "' + question.contents + '" is verplicht om in te vullen.',
                floorInfo: null,
                errorType: ValidationMessageErrorType.MISSING_REQUIRED_ANSWER,
            },
        ];
    }

    private hasAnswer(answer: Answer) {
        return !isEmpty(answer.contents) || !isEmpty(answer.answerOptionId) || !isEmpty(answer.file);
    }

    private hasApplicableQuestionType(questionType: QuestionType) {
        return (
            [
                NormalQuestionType.ATTACHMENT,
                NormalQuestionType.PHOTO,
                NormalQuestionType.OPEN,
                NormalQuestionType.OPEN_COMPACT,
                NormalQuestionType.OPEN_MAX_CHARACTERS,
                NormalQuestionType.MC,
                NormalQuestionType.MC_SELECT,
                NormalQuestionType.MC_SELECT_OPTIONAL,
                NormalQuestionType.MC_SELECT_COMPARE,
                NormalQuestionType.MC_SELECT_DYNAMIC,
                NormalQuestionType.BOOLEAN,
                NormalQuestionType.DATE,
                NormalQuestionType.DATE_PICKER,
                NormalQuestionType.YEAR,
                NormalQuestionType.NUMBER,
                NormalQuestionType.INT_NUMBER,
                NormalQuestionType.INT_COMPARE,
                NormalQuestionType.INT_UNKNOWN,
                NormalQuestionType.CURRENCY,
                NormalQuestionType.CURRENCY_INDEXABLE,
                NormalQuestionType.CURRENCY_CENTS,
                NormalQuestionType.NEGATIVE_CURRENCY,
                NormalQuestionType.NEGATIVE_CURRENCY_CENTS,
                NormalQuestionType.REFERENCE_OBJECT_EXPLANATION,
                NormalQuestionType.BUILDING_DEFECTS,
                NormalQuestionType.BUILDING_DEFECTS_COSTS,
                NormalQuestionType.BUILDING_COSTS_CONDITION,
                NormalQuestionType.BUILDING_COSTS_PHOTO,
            ] as QuestionType[]
        ).includes(questionType);
    }
}
