import {ValidationMessage, ValidationMessageImportance, ValidationMessageType} from '../validation_message';

import {Answer} from '../../../models/answer';
import {AnswerController} from '../../answering/answer_controller';
import {AnswerValidator} from '../answer_validator';
import {IsRequiredCalculator} from '../internal/is_required_calculator';
import {NormalQuestionType} from '../../../enum/question_type';
import {Question} from '../../../models/question';
import {QuestionEffectInteractor} from '../../conditions/question_effects_interactor';
import {QuestionSet} from '../../../models/question_set';

export class MultipleBooleanGroupValidator implements AnswerValidator {
    constructor(
        private questionSet: QuestionSet,
        private answerController: AnswerController,
        private isRequiredCalculator: IsRequiredCalculator,
        private questionEffectInteractor: QuestionEffectInteractor
    ) {}

    public validate(question: Question, answer: Answer | null): ValidationMessage[] {
        if (question.type !== NormalQuestionType.MULTIPLE_BOOLEAN_GROUP || answer === null) {
            return [];
        }

        const isRequired = this.isRequiredCalculator.isRequired(question, answer);
        if (!isRequired) {
            return [];
        }

        if (this.questionEffectInteractor.isHidden(question.uuid, answer.parentUuid) || answer.isDeleted) {
            return [];
        }

        const childAnswers = this.answerController.answersByParentAnswerUuid(answer.uuid);

        if (!childAnswers.some((a) => a.contents === '1')) {
            return [
                {
                    type: ValidationMessageType.TAXAPI,
                    importance: ValidationMessageImportance.ERROR,
                    question: question,
                    answer: null,
                    messages: [],
                    fallbackMessage: 'Vink tenminste 1 optie aan bij "' + question.contents + '".',
                    floorInfo: null,
                    errorType: null,
                },
            ];
        }

        return [];
    }
}
