import * as React from 'react';

import {Appraisal} from '../../../../models/appraisal';
import {observer} from 'mobx-react';
import {usePresenter} from '../../../../../support/presenter/use_presenter';
import {Loader} from '../../components/loader';
import {AppraisalValuationInfoWidgetPresenter} from './appraisal_valuation_info_presenter';
import {useMemo} from 'react';
import {ValuationLine} from '../../../../../create_appraisal/pre_check/valuation/valuation_line';
import {LineChart} from '../../../../../create_appraisal/pre_check/valuation/line_chart';
import {format} from 'date-fns';
import {formatMoney} from '../../support/format_money';
import {classNames} from '../../../../../support/classnames';
import {ValuationObject, ValuationSource} from '../../../../../create_appraisal/pre_check/valuation/overview';
interface OwnProps {
    appraisal: Appraisal;
    onDiscard: () => void;
}

const daysToDuration = (days: number): string => {
    if (days < 7) {
        return `${days} ${days === 1 ? 'dag' : 'dagen'}`;
    } else if (days < 30) {
        return `${Math.floor(days / 7)} ${Math.floor(days / 7) === 1 ? 'week' : 'weken'}`;
    } else if (days < 365) {
        return `${Math.floor(days / 30)} ${Math.floor(days / 30) === 1 ? 'maand' : 'maanden'}`;
    } else {
        return `${Math.floor(days / 365)} ${Math.floor(days / 365) === 1 ? 'jaar' : 'jaren'} en ${Math.floor(
            (days % 365) / 30
        )} ${Math.floor((days % 365) / 30) === 1 ? 'maand' : 'maanden'}`;
    }
};

export const AppraisalValuationInfoWidget: React.FC<OwnProps> = observer(function AppraisalDetailsWidget(props) {
    const presenter = usePresenter(
        (container) => new AppraisalValuationInfoWidgetPresenter(props.appraisal, container.network.appraisalApi)
    );
    const [closing, setClosing] = React.useState(false);

    const values = useMemo(() => {
        const values: ValuationObject[] = [];

        if (presenter.valuationOverview?.wozValues && presenter.valuationOverview.wozValues.length > 0) {
            const sorted = presenter.valuationOverview.wozValues.slice().sort((a, b) => {
                return a.referenceDate < b.referenceDate ? 1 : -1;
            });
            values.push({source: ValuationSource.WOZ, value: sorted[0].value});
        }

        if (presenter.valuationOverview?.altumAvm) {
            values.push({source: ValuationSource.ALTUM, value: presenter.valuationOverview.altumAvm.priceEstimation});
        }
        if (presenter.valuationOverview?.matrixianAvm) {
            values.push({
                source: ValuationSource.MATRIXIAN,
                value: presenter.valuationOverview.matrixianAvm.priceEstimation,
            });
        }

        if (presenter.valuationOverview?.ortecTransaction) {
            values.push({
                source: ValuationSource.ORTEC,
                value: presenter.valuationOverview.ortecTransaction.indexedPurchasePrice,
            });
        }

        return values;
    }, [
        presenter.valuationOverview?.wozValues,
        presenter.valuationOverview?.altumAvm,
        presenter.valuationOverview?.ortecTransaction,
        presenter.valuationOverview?.matrixianAvm,
    ]);

    const hasEstimatedWoz = presenter.valuationOverview?.wozValues.find((wozWaarde) => wozWaarde.is_estimated);

    return (
        <div className={classNames('pdf-overlay', {closing})}>
            <div className="col-12">
                <div className="widget">
                    <div className="widget-title">
                        <h3>
                            <span className="ion-md-home" />
                            Waarderingen
                        </h3>
                        <button
                            className="widget-overlay-close"
                            onClick={() => {
                                setClosing(true);
                                setTimeout(() => {
                                    props.onDiscard();
                                }, 300);
                            }}
                        >
                            <span className="ion-md-close">&nbsp;</span>
                        </button>
                    </div>
                    <div className="widget-body">
                        {presenter.loading && (
                            <span className="list-avatar-loader">
                                <Loader addSpace={false} />
                            </span>
                        )}
                        {presenter.valuationOverview && (
                            <div className="widget-blocks">
                                <div className="row">
                                    {values.length > 1 ? (
                                        <div className="col-12 pt-1 pl-5 pr-5 pb-3">
                                            <ValuationLine values={values} />
                                        </div>
                                    ) : null}
                                    {presenter.valuationOverview.wozValues.length > 0 ||
                                    presenter.valuationOverview.altumTransactions.length > 0 ||
                                    presenter.valuationOverview.ortecTransaction ? (
                                        <div className="col-12 pt-1 pl-5 pr-5 pb-3">
                                            <h3>Historische gegevens</h3>
                                            <LineChart data={presenter.valuationOverview} />
                                        </div>
                                    ) : null}
                                    {presenter.valuationOverview.wozValues.length > 0 ? (
                                        <div className="col-12 pl-5 pr-5">
                                            <h3>WOZ-gegevens</h3>
                                            <table className="table table-sm table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>Peildatum</th>
                                                        <th>WOZ-waarde</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {presenter.valuationOverview.wozValues.map((value) => (
                                                        <tr key={value.referenceDate.toString()}>
                                                            <td>
                                                                {format(value.referenceDate, 'dd-MM-yyyy')}
                                                                {value.is_estimated ? '*' : null}
                                                            </td>
                                                            <td>{formatMoney(value.value)}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                            {hasEstimatedWoz ? <p>* Schatting op basis van indexatie</p> : null}
                                        </div>
                                    ) : null}
                                    {presenter.valuationOverview.altumTransactions.length > 0 ||
                                    presenter.valuationOverview.ortecTransaction !== null ? (
                                        <div className="col-12 pl-5 pr-5">
                                            <h3>Verkoopgegevens</h3>
                                            {presenter.valuationOverview.ortecTransaction !== null ? (
                                                <table className="table table-sm table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>Verkocht op</th>
                                                            <th>Originele verkoopprijs</th>
                                                            <th>Geïndexeerde verkoopprijs</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                {format(
                                                                    presenter.valuationOverview.ortecTransaction
                                                                        .saleDate,
                                                                    'dd-MM-yyyy'
                                                                )}
                                                            </td>
                                                            <td>
                                                                {formatMoney(
                                                                    presenter.valuationOverview.ortecTransaction
                                                                        .purchasePrice
                                                                )}
                                                            </td>
                                                            <td>
                                                                {formatMoney(
                                                                    presenter.valuationOverview.ortecTransaction
                                                                        .indexedPurchasePrice
                                                                )}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            ) : null}
                                            {presenter.valuationOverview.altumTransactions.length > 0 ? (
                                                <table className="table table-sm table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>Te koop van</th>
                                                            <th>Te koop tot</th>
                                                            <th>Laatst bekende vraagprijs</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {presenter.valuationOverview.altumTransactions.map((value) => (
                                                            <tr key={value.id}>
                                                                <td>{format(value.offeredSinceDate, 'dd-MM-yyyy')}</td>
                                                                <td>
                                                                    {format(value.saleDate, 'dd-MM-yyyy')} (
                                                                    {daysToDuration(value.nrOfDaysOffered)})
                                                                </td>
                                                                <td>{formatMoney(value.askingPrice)}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            ) : null}
                                        </div>
                                    ) : null}
                                    {values.length <= 1 &&
                                    presenter.valuationOverview.wozValues.length === 0 &&
                                    presenter.valuationOverview.altumTransactions.length === 0 &&
                                    presenter.valuationOverview.ortecTransaction === null ? (
                                        <div className="col-12 pl-5 pr-5 mt-3 mb-3">
                                            <div className="empty-message appraise mt-0">
                                                <h3>Er zijn geen gegevens gevonden.</h3>
                                                <span className="spacer-gradient">&nbsp;</span>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
});
