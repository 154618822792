import {
    BuildingInspectionAppointmentInteractor,
    BuildingReportRequiredPair,
} from '../../business/building_inspection_appointment_interactor';
import {CameraProgress, ProgressType} from './content/camera_presenter';
import {Global, GlobalProvider} from '../../../business/global_provider';
import {PhotoAnswerRetryInteractor, PhotoAnswerRetryStatus} from '../../business/photo_answer_retry_interactor';
import {action, computed, makeObservable, observable, runInAction} from 'mobx';
import {debounceTime, delay, filter, first, mergeAll, mergeMap, takeWhile, tap} from 'rxjs/operators';

import {AnswerController} from '../../business/answering/answer_controller';
import {AnswerInteractor} from '../../business/answering/answer_interactor';
import {AppraisalNavigator} from './appraisal_navigator';
import {AppraisalProvider} from '../../business/appraisal_provider';
import {AppraiseSecondaryConfigStackInteractor} from '../../business/appraise_secondary_config_stack_interactor';
import {AppraiseSecondaryType} from '../../models/appraise_secondary_config';
import {CompositeSubscription} from '../../../support/composite_subscription';
import {Presenter} from '../../../support/presenter/presenter';
import {QuestionSet} from '../../models/question_set';
import {ReferenceObjectsV1Provider} from './content/questions/advanced/reference_objects_question/v1/providers/reference_objects_provider';
import {SidebarItemForQuestionProvider} from '../../business/sidebar_item_for_question_provider';
import {SidebarTreeBuilder} from '../../business/sidebar_tree_builder';
import {UserInteractor} from '../../business/user_interactor';
import {getNewestAnswer} from '../../../support/get_newest_answer';
import {of} from 'rxjs';
import {Appraisal} from '../../models/appraisal';
import {ValuationDateProvider} from '../../business/valuation_date_provider';
import {AutosaveStatus} from '../../enum/autosave_status';
import {isAutosavingDetailName} from '../../../support/is_autosaving_detail_name';
import {NormalQuestionType} from '../../enum/question_type';
import {TechnicalReference} from '../../enum/technical_reference';
import {RenderingContextType} from '../../enum/rendering_context_type';

export class AppraisalPresenter implements Presenter {
    @observable public photoAnswerRetryStatus: PhotoAnswerRetryStatus | null = null;
    @observable public cameraProgress: null | CameraProgress = null;
    @observable public global: Global;
    @observable public autosaveStatus: AutosaveStatus = AutosaveStatus.NOT_SAVED;

    public shouldShowSidebar = true;
    public shouldShowTabbar = false;
    public shouldShowCamera = true;
    public shouldShowNotes = true;

    private _subscriptions = new CompositeSubscription();
    @observable private _buildingReportRequiredPair: BuildingReportRequiredPair | null = null;

    constructor(
        private appraisal: Appraisal,
        private questionSet: QuestionSet,
        private renderingContext: RenderingContextType,
        private globalProvider: GlobalProvider,
        private appraisalNavigator: AppraisalNavigator,
        private answerController: AnswerController,
        private answerInteractor: AnswerInteractor,
        private photoAnswerRetryInteractor: PhotoAnswerRetryInteractor,
        private sidebarTreeBuilder: SidebarTreeBuilder,
        private sidebarItemForQuestionProvider: SidebarItemForQuestionProvider,
        private userInteractor: UserInteractor,
        private appraiseSecondaryConfigStackInterator: AppraiseSecondaryConfigStackInteractor,
        private referenceObjectsV1Provider: ReferenceObjectsV1Provider,
        private appraisalProvider: AppraisalProvider,
        private buildingInspectionAppointmentInteractor: BuildingInspectionAppointmentInteractor,
        private valuationDateProvider: ValuationDateProvider
    ) {
        const isAppraise = this.renderingContext !== RenderingContextType.PLAUSIBILITY_CHECK;
        this.global = this.globalProvider.global;
        this.shouldShowTabbar = isAppraise === false;
        this.shouldShowSidebar =
            this.shouldShowTabbar === false && this.global.detailName === null && !this.global.inline;
        this.shouldShowCamera = this.global.detailName === null && isAppraise;
        this.shouldShowNotes = this.global.detailName === null && isAppraise;
        this.navigateBackUrl = this.navigateBackUrl.bind(this);

        makeObservable(this);
    }

    public async mount(): Promise<void> {
        this.appraisalNavigator.addNextListener(this.navigateBackUrl);

        if (window.location.hash === '#/') {
            this.redirectToInitialPage();
        }

        window.onbeforeunload = () => {
            if (
                (this.userInteractor.isAppraiser() ||
                    this.userInteractor.isEmployee() ||
                    this.userInteractor.isJuniorAppraiser()) &&
                (this.answerController.answers().some((answer) => answer.changed) ||
                    (this.cameraProgress && this.cameraProgress.type !== ProgressType.IDLE))
            ) {
                return 'Er zijn niet opgeslagen antwoorden, weet je zeker dat je weg wil navigeren?';
            }
        };

        if (this.renderingContext !== RenderingContextType.PLAUSIBILITY_CHECK) {
            this._subscriptions.add(
                this.photoAnswerRetryInteractor
                    .attempt()
                    .pipe(takeWhile((photoAnswerRetryStatus) => photoAnswerRetryStatus.queued > 0))
                    .subscribe(
                        (photoAnswerRetryStatus) => {
                            runInAction(() => {
                                this.photoAnswerRetryStatus = photoAnswerRetryStatus;
                            });
                        },
                        (err) => {
                            console.error(err);
                            runInAction(() => {
                                // We have no information, other than that something failed
                                return (this.photoAnswerRetryStatus = {
                                    done: 0,
                                    failed: 1,
                                    queued: 0,
                                    total: 0,
                                });
                            });
                        },
                        () => {
                            //This looks weird, however takeWhile is not inclusive of the last item, so if only one
                            //status update is passed it will never be propagated
                            return runInAction(() => {
                                return (this.photoAnswerRetryStatus = {
                                    done: 0,
                                    failed: 0,
                                    queued: 0,
                                    total: 0,
                                });
                            });
                        }
                    )
            );
        } else {
            runInAction(() => {
                return (this.photoAnswerRetryStatus = {
                    done: 0,
                    failed: 0,
                    queued: 0,
                    total: 0,
                });
            });
        }

        if (
            this.renderingContext !== RenderingContextType.PLAUSIBILITY_CHECK &&
            this.questionSet.reportDefintionConfig.referenceObjectsVersion === 1
        ) {
            this._subscriptions.add(
                of(null)
                    .pipe(
                        delay(3000),
                        mergeMap(() => this.referenceObjectsV1Provider.referenceObjectsStream)
                    )
                    .subscribe(() => {
                        // Keep this alive to keep referenceObjects cache hot
                    })
            );
            this._subscriptions.add(
                of(null)
                    .pipe(mergeMap(() => this.referenceObjectsV1Provider.preconditionsAreMetStream))
                    .subscribe(() => {
                        // Keep this alive to keep referenceObjects cache hot
                    })
            );
        }

        if (this.global.buildingInspectorSubscription) {
            this._subscriptions.add(
                this.buildingInspectionAppointmentInteractor.buildingReportRequiredStream().subscribe((pair) => {
                    this._buildingReportRequiredPair = pair;
                })
            );
            this._subscriptions.add(
                this.buildingInspectionAppointmentInteractor
                    .meetsRequiredConditionsStream()
                    .subscribe((meetsRequiredConditions) => {
                        // When conditions meet, trigger update the building report required question
                        if (meetsRequiredConditions) {
                            runInAction(() => {
                                if (
                                    this._buildingReportRequiredPair !== null &&
                                    this._buildingReportRequiredPair.answer !== null
                                ) {
                                    this.buildingInspectionAppointmentInteractor.updateBuildingReportRequiredQuestion(
                                        this._buildingReportRequiredPair.answer
                                    );
                                }
                            });
                        }
                    })
            );
        }

        runInAction(() => {
            if (this.renderingContext === RenderingContextType.PLAUSIBILITY_CHECK) {
                this.appraiseSecondaryConfigStackInterator.upsert({
                    id: 'appraisal-report-renderer',
                    type: AppraiseSecondaryType.APPRAISAL_REPORT_RENDERER,
                    appraisal: this.appraisalProvider.appraisal,
                    onClose: () => {
                        // Noop
                    },
                });
            } else {
                this.appraiseSecondaryConfigStackInterator.upsert({
                    id: 'widgets',
                    type: AppraiseSecondaryType.WIDGETS,
                    appraisal: this.appraisalProvider.appraisal,
                    questionSet: this.questionSet,
                    onClose: () => {
                        // Noop
                    },
                });
            }
        });

        if (this.shouldAutosave) {
            this.setupAutosave();
        }
    }

    public unmount(): void {
        this.appraisalNavigator.removeNextListener(this.navigateBackUrl);

        this._subscriptions.clear();
    }

    @computed
    public get shouldShowConstructionDefects() {
        if (this.global.detailName !== null || !this.questionSet.reportDefintionConfig.hasBuildingCosts) {
            return false;
        }

        return (
            this.questionSet.findQuestionsByType(NormalQuestionType.BUILDING_COSTS_PHOTO_GROUP).length > 0 ||
            this.questionSet.findQuestionByTechnicalReference(TechnicalReference.PHOTO_ITERATOR_CONSTRUCTION) !== null
        );
    }

    @computed
    public get shouldAutosave() {
        return this.global.detailName !== null && isAutosavingDetailName(this.global.detailName);
    }

    @action
    public onCameraProgressChange(progress: CameraProgress) {
        this.cameraProgress = progress;
    }

    private async redirectToInitialPage() {
        const tree = await this.sidebarTreeBuilder.build.pipe(first()).toPromise();

        if (tree && this.renderingContext === RenderingContextType.PLAUSIBILITY_CHECK) {
            // When the context is plausibility check, there are no child items in the sidebar.
            // The appraiser should always start in the first tab.
            window.location.href = '#' + tree[0].link;
            return;
        }

        const mostRecentAnswer = getNewestAnswer(
            this.answerController.answers().filter((a) => a.isAutomated !== undefined && !a.isAutomated)
        );

        const valuationDate = this.valuationDateProvider.date;

        // If there is a newest answer, we will go to that page
        // Unless the most recent answer is before the valuation date and the valuation date has passed.
        // In this case, we start from the beginning, because we are probably in the valuation
        if (
            mostRecentAnswer !== null &&
            (!valuationDate ||
                valuationDate.getTime() > Date.now() ||
                !mostRecentAnswer.updatedAt ||
                mostRecentAnswer.updatedAt.getTime() > valuationDate.getTime())
        ) {
            const question = this.questionSet.findQuestionByUuid(mostRecentAnswer.questionUuid);
            if (question !== undefined) {
                // If there is a question, then we fetch the sidebar item for this question and redirect to that
                const item = await this.sidebarItemForQuestionProvider.find(question).pipe(first()).toPromise();
                if (item) {
                    window.location.href = '#' + item.link;
                    return;
                }
            }
        }

        if (tree) {
            if (tree[0].children.length > 0) {
                window.location.href = '#' + tree[0].children[0].link;
            } else {
                window.location.href = '#' + tree[0].link;
            }
        }
    }

    private async navigateBackUrl() {
        if (this.global.conclusionUrl) {
            window.location.href = this.global.conclusionUrl;
        } else {
            window.location.href = this.global.backUrl;
        }

        return true;
    }

    @action
    private updateAutosaveState(status: AutosaveStatus) {
        this.autosaveStatus = status;
    }

    private setupAutosave() {
        this.updateAutosaveState(AutosaveStatus.SAVED);

        const AUTOSAVE_INTERVAL = 5000;
        this._subscriptions.add(
            this.answerController
                .answersStream()
                .pipe(
                    mergeAll(),
                    filter((a) => a.changed),
                    tap(() => {
                        this.updateAutosaveState(AutosaveStatus.NOT_SAVED);
                    }),
                    debounceTime(AUTOSAVE_INTERVAL)
                )
                .subscribe(() => {
                    runInAction(() => {
                        this.updateAutosaveState(AutosaveStatus.SAVING);
                    });
                    this.answerInteractor.submit().then(() => {
                        runInAction(() => {
                            this.updateAutosaveState(AutosaveStatus.SAVED);
                        });
                    });
                })
        );
    }
}
