import React, {useMemo} from 'react';
import {Modal} from '../appraising/appraise/ui/content/questions/components/modal';
import {DateRange, DayPicker} from 'react-day-picker';
import 'react-day-picker/style.css';
import {nl} from 'date-fns/locale';
import {usePresenter} from '../support/presenter/use_presenter';
import {RangeModalPresenter} from './range_modal_presenter';
import {observer} from 'mobx-react';
import {formatDate} from '../appraising/appraise/ui/support/format_date';
import {startOfWeek, endOfDay, subWeeks, endOfWeek, startOfMonth, subMonths, endOfMonth} from 'date-fns';
import {Loader} from '../appraising/appraise/ui/components/loader';
import {SearchAppraisal} from '../appraising/models/search_appraisal';

export const RangeSearchModal = observer(function RangeSearchModal({
    onClose,
    addedAppraisals,
    spaceLeft,
}: {
    onClose: (appraisalsToAdd: SearchAppraisal[], range: DateRange | null) => void;
    addedAppraisals: SearchAppraisal[];
    spaceLeft: number;
}) {
    const presenter = usePresenter(
        (component) =>
            new RangeModalPresenter(
                component.network.appraisalApi,
                component.business.flashMessageBroadcaster,
                addedAppraisals,
                spaceLeft
            ),
        [addedAppraisals, spaceLeft]
    );

    const [pickerMonth, setPickerMonth] = React.useState(new Date());

    const ranges = useMemo(() => {
        return {
            thisWeek: {
                from: startOfWeek(new Date(), {locale: nl}),
                to: endOfDay(new Date()),
            },
            lastWeek: {
                from: startOfWeek(subWeeks(new Date(), 1), {locale: nl}),
                to: endOfWeek(subWeeks(new Date(), 1), {locale: nl}),
            },
            thisMonth: {
                from: startOfMonth(new Date()),
                to: endOfDay(new Date()),
            },
            lastMonth: {
                from: startOfMonth(subMonths(new Date(), 1)),
                to: endOfMonth(subMonths(new Date(), 1)),
            },
        };
    }, []);

    const addLength = presenter.returnedTooManyAppraisals ? 0 : presenter.availableAppraisals?.length ?? 0;

    return (
        <Modal visible>
            <div className="modal-header">
                <h2>Periode kiezen</h2>
            </div>
            <div className="modal-body">
                <p>
                    Kies een periode om taxaties voor te downloaden. Taxaties worden toegevoegd op basis van{' '}
                    <strong>aanmaakdatum</strong>. Je kunt nog maximaal {spaceLeft} taxatie
                    {spaceLeft > 1 ? 's' : ''} toevoegen aan deze back-up.
                </p>
                <div className="d-flex justify-content-center">
                    <DayPicker
                        mode="range"
                        locale={nl}
                        max={365}
                        onSelect={(range) => presenter.updateRange(range)}
                        selected={presenter.range ?? undefined}
                        onMonthChange={(month) => setPickerMonth(month)}
                        month={pickerMonth}
                    />
                    <div className="d-flex ml-3 flex-column" style={{gap: 10}}>
                        <div className="form-group">
                            <input
                                type="checkbox"
                                id="only-approved"
                                name="only-approved"
                                checked={presenter.onlyApprovedAppraisals}
                                onChange={(e) => presenter.updateRange(presenter.range ?? undefined, e.target.checked)}
                            />
                            <label htmlFor="only-approved">Alleen afgeronde taxaties</label>
                        </div>
                        <button
                            className="btn btn-primary btn-sm ion-md-calendar"
                            onClick={() => presenter.updateRange(ranges.thisWeek)}
                        >
                            Deze week
                        </button>
                        <button
                            className="btn btn-primary btn-sm ion-md-calendar"
                            onClick={() => presenter.updateRange(ranges.lastWeek)}
                        >
                            Vorige week
                        </button>
                        <button
                            className="btn btn-primary btn-sm ion-md-calendar"
                            onClick={() => presenter.updateRange(ranges.thisMonth)}
                        >
                            Deze maand
                        </button>
                        <button
                            className="btn btn-primary btn-sm ion-md-calendar"
                            onClick={() => {
                                presenter.updateRange(ranges.lastMonth);
                                setPickerMonth(ranges.lastMonth.from);
                            }}
                        >
                            Vorige maand
                        </button>
                    </div>
                </div>
                <p className="text-center mt-3">
                    <strong>
                        {presenter.range?.from && presenter.range?.to
                            ? presenter.range.from.getTime() === presenter.range.to.getTime()
                                ? `${formatDate(presenter.range.from, 'EEEE dd MMM yyyy')}`
                                : `${formatDate(presenter.range.from, 'EEEE dd MMM yyyy')} t/m ${formatDate(
                                      presenter.range.to,
                                      'EEEE dd MMM yyyy'
                                  )}`
                            : 'Selecteer een periode'}
                    </strong>
                </p>
                {!presenter.isSearchingAppraisals && presenter.returnedTooManyAppraisals ? (
                    <div className="alert-inline alert-danger ion-md-close-circle mt-3">
                        <p>
                            Te veel taxaties in geselecteerde periode om toe te voegen aan de back-up. Er kunnen nog
                            maximaal {spaceLeft} taxatie{spaceLeft === 1 ? '' : 's'} worden toegevoegd.
                        </p>
                    </div>
                ) : null}
            </div>
            <div className="modal-footer">
                <button className="btn btn-secondary ion-md-close" type="button" onClick={() => onClose([], null)}>
                    Sluiten
                </button>
                <button
                    className="btn btn-primary"
                    onClick={() =>
                        presenter.returnedTooManyAppraisals
                            ? null
                            : onClose(presenter.availableAppraisals ?? [], presenter.range)
                    }
                    disabled={addLength === 0}
                >
                    {presenter.isSearchingAppraisals ? (
                        <Loader isSmall />
                    ) : (
                        <>
                            {addLength} taxatie
                            {addLength === 1 ? '' : 's'} toevoegen
                        </>
                    )}
                </button>
            </div>
        </Modal>
    );
});
