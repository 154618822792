export enum GeneratedReportType {
    CONCEPT_REPORT = 'concept-report',
    BOUWKUNDIGE_STAAT = 'bouwkundige-staat',
    ENERGETISCHE_STAAT = 'energetische-staat',
    CONSTRUCTION_SPECIFICATION = 'construction-specification',
    UNIFORM_MEASUREMENT = 'uniform-measurement',

    PLAUSIBILITY_CHECKLIST = 'plausibility-checklist',
    PLAUSIBILITY_STATEMENT = 'plausibility-statement',

    CORE_TASK_REFERENCES_REPORT = 'core-task-references-report',
    CORE_TASK_ANSWER_TRAIL_REPORT = 'core-task-answer-trail-report',
    CORE_TASK_ATTACHMENTS_SET = 'core-task-attachments-set',
    CORE_TASK_PHOTOS_SET = 'core-task-photos-set',
    CORE_TASK_EMAILS_SET = 'core-task-emails-set',
    CORE_TASK_NOTES = 'core-task-notes',
    CORE_TASK_ARCHIVE = 'core-task-archive',
}

export enum GeneratedReportFormat {
    HTML = 'html',
    PDF = 'pdf',
}
