import * as Uuid from 'uuid';

import {NetworkStatus, NetworkStatusProvider} from '../../../../../../business/network_status_provider';
import {action, computed, makeObservable, observable, runInAction} from 'mobx';
import {first, map} from 'rxjs/operators';

import {Answer} from '../../../../../../models/answer';
import {AnswerController} from '../../../../../../business/answering/answer_controller';
import {AnswerInteractor} from '../../../../../../business/answering/answer_interactor';
import {Appraisal} from '../../../../../../models/appraisal';
import {AppraisalAutomationApi} from '../../../../../../network/appraisal_automation_api';
import {AppraisalState} from '../../../../../../enum/appraisal_state';
import {AppraisalValidationType} from '../../../../../../enum/appraisal_validation_type';
import {CadastralDocument} from '../../../../../../models/cadastral_document';
import {CompositeSubscription} from '../../../../../../../support/composite_subscription';
import {NormalQuestionType} from '../../../../../../enum/question_type';
import {Presenter} from '../../../../../../../support/presenter/presenter';
import {Question} from '../../../../../../models/question';
import {QuestionEffectInteractor} from '../../../../../../business/conditions/question_effects_interactor';
import {QuestionSet} from '../../../../../../models/question_set';
import {getNewestAnswer} from '../../../../../../../support/get_newest_answer';
import {isEmpty} from '../../../../../../../support/util';
import {GlobalProvider} from '../../../../../../../business/global_provider';

interface Message {
    type: 'error' | 'success';
    message: string;
}

export class CadastralDocumentQuestionPresenter implements Presenter {
    @observable public answer?: Answer;
    @observable public iteratorQuestion?: Question;
    @observable public iteratorAnswer?: Answer;
    @observable public attachmentAnswer?: Answer;
    @observable public attachmentQuestion?: Question;

    @observable public loading = false;
    @observable public formOpen = false;
    @observable public openInputVisible = false;
    @observable public isHidden = false;

    @observable public message: Message | null = null;

    @observable public cadastralDocument?: CadastralDocument | null;
    @observable public cadastralDocumentString?: string | null;
    @observable public kadasterCostsCents: number;

    private timeout: NodeJS.Timeout | null = null;

    @computed
    public get isDisabled(): boolean {
        if (!this.appraisal.isEditableAppraisal) {
            return true;
        }
        if (this.appraisal.validationType === AppraisalValidationType.NOT_VALIDATED) {
            return (
                this.attachmentQuestion === undefined ||
                this.loading ||
                (this.appraisal.status !== AppraisalState.PROCESSING && this.question.freezes)
            );
        }
        return (
            this.attachmentQuestion === undefined ||
            this.loading ||
            (this.appraisal.status !== AppraisalState.PROCESSING && this.question.freezes) ||
            this.appraisal.status === AppraisalState.APPROVED ||
            this.appraisal.status === AppraisalState.CANCELED ||
            this.appraisal.status === AppraisalState.SUBMITTED_FOR_VALIDATION
        );
    }

    constructor(
        private appraisal: Appraisal,
        private question: Question,
        private questionSet: QuestionSet,
        private parentAnswerUuid: string | null,
        private answerController: AnswerController,
        private answerInteractor: AnswerInteractor,
        private questionEffectsInteractor: QuestionEffectInteractor,
        private appraisalAutomationApi: AppraisalAutomationApi,
        private networkStatusProvider: NetworkStatusProvider,
        private globalProvider: GlobalProvider,
        private addIteration?: (iteration: string) => void
    ) {
        this.isHidden = this.questionEffectsInteractor.isHidden(this.question.uuid, this.parentAnswerUuid ?? null);
        this.openInputVisible = this.appraisal.cadastralDocuments.length === 0;
        this.kadasterCostsCents = this.globalProvider.global.rateAdditionalKadasterExcerpt ?? -1;

        makeObservable(this);
    }

    private subscriptions = new CompositeSubscription();

    public mount(): void {
        this.cadastralDocument = this.appraisal.cadastralDocuments[0];

        this.subscriptions.add(
            this.answerController
                .answersForQuestionUuidAndParentAnswerUuidInSameIterationStream(
                    this.question.uuid,
                    this.parentAnswerUuid
                )
                .pipe(map((answers) => this.answerController.filterDeleted(answers)))
                .subscribe((answers) => (this.answer = getNewestAnswer(answers) ?? undefined))
        );

        this.subscriptions.add(
            this.questionEffectsInteractor
                .isHiddenStream(this.question.uuid, this.parentAnswerUuid ?? null)
                .subscribe((isHidden) => {
                    runInAction(() => {
                        this.isHidden = isHidden;
                    });
                })
        );

        // The parent question is the iterator
        this.iteratorQuestion = this.question.parentUuid
            ? this.questionSet.findQuestionByUuid(this.question.parentUuid)
            : undefined;

        // The parent question is the iterator, so search al, children to get the attachment question
        this.attachmentQuestion = this.question.parentUuid
            ? this.questionSet
                  .findChildQuestionsByParentUuid(this.question.parentUuid)
                  .find((q) => q.type === NormalQuestionType.ATTACHMENT)
            : undefined;
    }

    public unmount(): void {
        this.subscriptions.clear();
        if (this.timeout !== null) {
            clearTimeout(this.timeout);
        }
    }

    public isCloseButtonVisible() {
        return this.appraisal.cadastralDocuments.length > 0;
    }

    @action
    public onSelect(input: string) {
        this.message = null;
        this.cadastralDocument = null;
        this.cadastralDocumentString = null;

        this.openInputVisible = input === '-1';
        if (!this.openInputVisible) {
            this.cadastralDocumentString = input;
            this.cadastralDocument = this.appraisal.cadastralDocuments.find((d) => d.name === input);
        }
    }

    @action
    public onChangeInput(input: string) {
        this.cadastralDocumentString = input;
        this.message = null;
        if (this.validateInput(input)) {
            const register = input.toLowerCase().split('-')[0];
            const name = input.split('-')[1];
            const nameData = name?.split('/');
            this.cadastralDocument = {
                name: input,
                register: register,
                part: nameData[0] ?? null,
                number: nameData[1] ?? null,
                series: nameData[2] ?? null,
            };
        }
    }

    public async onSubmit() {
        try {
            runInAction(() => {
                this.message = null;
                this.loading = true;

                const iteration = Uuid.v4();
                if (this.iteratorQuestion === undefined || this.attachmentQuestion === undefined) {
                    this.loading = false;
                    this.setMessage(
                        'error',
                        'Fout bij het aanmaken van het antwoord. Neem a.u.b. contact op met de helpdesk.'
                    );
                    return;
                }

                this.iteratorAnswer = this.answerController.answerByIdentifiersOrStub(
                    this.iteratorQuestion.uuid,
                    this.parentAnswerUuid ?? null,
                    iteration
                );
                this.attachmentAnswer = this.answerController.answerByIdentifiersOrStub(
                    this.attachmentQuestion.uuid,
                    this.iteratorAnswer.uuid,
                    null
                );
                this.addIteration !== undefined && this.addIteration(iteration);
            });

            const status = await this.networkStatusProvider.status().pipe(first()).toPromise();
            if (status !== NetworkStatus.ONLINE) {
                const confirmed = confirm(
                    'Je bent offline, weet je zeker dat je verder wil? Het document kan dan niet automatisch bijgevoegd worden.'
                );
                if (!confirmed) {
                    return;
                }
            }

            if (this.cadastralDocumentString && !isEmpty(this.cadastralDocumentString)) {
                if (!this.validateInput(this.cadastralDocumentString)) {
                    runInAction(() => {
                        this.loading = false;
                        this.setMessage(
                            'error',
                            'Het formaat is incorrect ingevuld. Vul het in als "Hyp4-12356/13" of "Hyp4-1235/1/Eindhoven".'
                        );
                    });
                    return;
                }
            }

            if (this.attachmentAnswer === undefined) {
                runInAction(() => {
                    this.loading = false;
                    this.setMessage('error', 'Er is wat mis gegaan met het opvragen. Ververs a.u.b. de pagina.');
                });
                return;
            }

            if (this.cadastralDocument === undefined || this.cadastralDocument === null) {
                runInAction(() => {
                    this.loading = false;
                    this.setMessage(
                        'error',
                        'Er is wat mis gegaan met het opvragen. Selecteer a.u.b. opnieuw een document.'
                    );
                });
                return;
            }

            if (parseInt(this.cadastralDocument.part, 10) < 50000 && isEmpty(this.cadastralDocument.series)) {
                runInAction(() => {
                    this.loading = false;
                    this.setMessage(
                        'error',
                        'Wanneer het deel lager is dan 50000, dient er ook een reeks ingevuld te worden. Vul het in zoals: "Hyp4-1235/1/Eindhoven".'
                    );
                });
                return;
            }

            // Make sure the answer is submitted to the backend
            await this.answerInteractor.submit();

            const fileReference = await this.appraisalAutomationApi.getCadastralDocument(
                this.appraisal.id,
                this.cadastralDocument.register,
                this.cadastralDocument.part,
                this.cadastralDocument.number,
                this.cadastralDocument.series
            );

            if (fileReference === null) {
                runInAction(() => {
                    this.loading = false;
                    this.setMessage(
                        'error',
                        'Er is wat mis gegaan met het opvragen. Ververs a.u.b. de pagina en probeer opnieuw.'
                    );
                });
                return;
            }

            this.answerController.attachExistingFile(this.attachmentAnswer.uuid, null, fileReference);

            runInAction(() => {
                this.loading = false;
                this.formOpen = false;
                this.setMessage(
                    'success',
                    'Het document ' +
                        (fileReference.compressedFilename ?? fileReference.originalFilename) +
                        ' is toegevoegd als overige bijlage.'
                );
            });
        } catch (e) {
            runInAction(() => {
                this.message = null;
                this.loading = false;
            });
        } finally {
            runInAction(() => {
                this.timeout = setTimeout(() => {
                    this.message = null;
                }, 8000);
            });
        }
    }

    private validateInput(input: string): boolean {
        const validFormat = new RegExp('([a-z]+)([0-9]+)-(\\d+)\\/(\\d+)');
        return validFormat.test(input.toLowerCase());
    }

    @action
    public openForm() {
        this.formOpen = true;
        this.loading = false;
        this.message = null;
    }

    @action
    public closeForm() {
        this.formOpen = false;
        this.loading = false;
        this.message = null;
    }

    @action
    public hideOpenInput() {
        this.openInputVisible = false;
        this.cadastralDocumentString = null;
        this.loading = false;
        this.message = null;
    }

    private setMessage(type: 'success' | 'error', message: string) {
        this.message = {
            type: type,
            message: message,
        };
    }
}
