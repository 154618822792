import {GeneratedReportFormat, GeneratedReportType} from '../../enum/generated_report';
import {TechnicalReference} from '../../enum/technical_reference';
import {CoreTaskAttachment, CoreTaskAttachmentType, CoreTasksData} from '../../models/core_tasks';

export interface ApiCoreTasksData {
    attachments: ApiCoreTaskAttachment[];
    concept_report_url: string | null;
}

export interface ApiGeneratorCoreTaskAttachment {
    type: 'generator';
    technical_reference: TechnicalReference;
    generated_report_type: GeneratedReportType;
    generated_report_format: GeneratedReportFormat;
}

export interface ApiFileReferenceCoreTaskAttachment {
    type: 'file_reference';
    technical_reference: TechnicalReference;
    file_reference_id: number;
    url: string | null;
    file_name: string;
}

export interface ApiUrlCoreTaskAttachment {
    type: 'url';
    technical_reference: TechnicalReference;
    url: string;
    file_name: string;
}

export type ApiCoreTaskAttachment =
    | ApiGeneratorCoreTaskAttachment
    | ApiFileReferenceCoreTaskAttachment
    | ApiUrlCoreTaskAttachment;

export function apiCoreTasksToCoreTasks(apiCoreTasks: ApiCoreTasksData): CoreTasksData {
    return {
        attachments: apiCoreTasks.attachments.map(apiCoreTaskAttachmentToCoreTaskAttachment),
        conceptReportUrl: apiCoreTasks.concept_report_url,
    };
}

export function apiCoreTaskAttachmentToCoreTaskAttachment(
    apiCoreTaskAttachment: ApiCoreTaskAttachment
): CoreTaskAttachment {
    switch (apiCoreTaskAttachment.type) {
        case 'generator':
            return {
                type: CoreTaskAttachmentType.GENERATOR,
                technicalReference: apiCoreTaskAttachment.technical_reference,
                generatedReportType: apiCoreTaskAttachment.generated_report_type,
                generatedReportFormat: apiCoreTaskAttachment.generated_report_format,
            };
        case 'file_reference':
            return {
                type: CoreTaskAttachmentType.FILE_REFERENCE,
                technicalReference: apiCoreTaskAttachment.technical_reference,
                fileReferenceId: apiCoreTaskAttachment.file_reference_id,
                url: apiCoreTaskAttachment.url,
                fileName: apiCoreTaskAttachment.file_name,
            };
        case 'url':
            return {
                type: CoreTaskAttachmentType.URL,
                technicalReference: apiCoreTaskAttachment.technical_reference,
                url: apiCoreTaskAttachment.url,
                fileName: apiCoreTaskAttachment.file_name,
            };
    }
}
