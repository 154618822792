import React, {useEffect, useRef} from 'react';
import {NeighbourhoodDetailsData} from '../../../appraising/models/object_summary';
import {Chart, LegendItem} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

interface OwnProps {
    neighbourhoodDetailsData: NeighbourhoodDetailsData | null;
}

export const NeighbourhoodCompanyDetails: React.FC<OwnProps> = ({neighbourhoodDetailsData}: OwnProps) => {
    if (!neighbourhoodDetailsData?.neighbourhoodBedrijfsvestigingen || !neighbourhoodDetailsData?.wijkenEnBuurten) {
        return null;
    }

    const companyData = {
        ALandbouwBosbouwEnVisserij:
            neighbourhoodDetailsData.neighbourhoodBedrijfsvestigingen.ALandbouwBosbouwEnVisserij.value,
        BFNijverheidEnEnergie: neighbourhoodDetailsData.neighbourhoodBedrijfsvestigingen.BFNijverheidEnEnergie.value,
        GIHandelEnHoreca: neighbourhoodDetailsData.neighbourhoodBedrijfsvestigingen.GIHandelEnHoreca.value,
        RUCultuurRecreatieOverigeDiensten:
            neighbourhoodDetailsData.neighbourhoodBedrijfsvestigingen.RUCultuurRecreatieOverigeDiensten.value,
        HJVervoerInformatieEnCommunicatie:
            neighbourhoodDetailsData.neighbourhoodBedrijfsvestigingen.HJVervoerInformatieEnCommunicatie.value,
        KLFinancieleDienstenOnroerendGoed:
            neighbourhoodDetailsData.neighbourhoodBedrijfsvestigingen.KLFinancieleDienstenOnroerendGoed.value,
        MNZakelijkeDienstverlening:
            neighbourhoodDetailsData.neighbourhoodBedrijfsvestigingen.MNZakelijkeDienstverlening.value,
    };

    const shortendLabels = ['Landbouw', 'Nijverheid', 'Handel', 'Cultuur', 'Vervoer', 'Financieel', 'Zakelijk'];

    const ref = useRef<HTMLCanvasElement>(null);
    const data = {
        labels: [
            'Landbouw, bosbouw en visserij',
            'Nijverheid en energie',
            'Handel en horeca',
            'Cultuur, recreatie, overige diensten',
            'Vervoer, informatie en communicatie',
            'Financiële diensten, onroerend goed',
            'Zakelijke dienstverlening',
        ],
        datasets: [
            {
                label: neighbourhoodDetailsData.wijkenEnBuurten,
                data: [
                    companyData.ALandbouwBosbouwEnVisserij,
                    companyData.BFNijverheidEnEnergie,
                    companyData.GIHandelEnHoreca,
                    companyData.RUCultuurRecreatieOverigeDiensten,
                    companyData.HJVervoerInformatieEnCommunicatie,
                    companyData.KLFinancieleDienstenOnroerendGoed,
                    companyData.MNZakelijkeDienstverlening,
                ],
                backgroundColor: [
                    'rgba(54, 162, 235, 1)', // light blue
                    'rgba(75, 192, 192, 1)', // teal
                    'rgba(153, 102, 255, 1)', // purple
                    'rgba(255, 159, 64, 1)', // orange
                    'rgba(255, 99, 132, 1)', // red
                    'rgba(255, 205, 86, 1)', // yellow
                    'rgb(75,192,134)', // green
                ],
            },
        ],
    };

    useEffect(() => {
        if (!ref.current) {
            return;
        }

        const chart = new Chart(ref.current, {
            plugins: [ChartDataLabels],
            type: 'doughnut',
            data: data,
            options: {
                plugins: {
                    datalabels: {
                        display: false,
                    },
                    legend: {
                        position: 'right',
                        labels: {
                            generateLabels(chart: Chart): LegendItem[] {
                                return chart.data.datasets[0].data.map((value, index) => {
                                    if (typeof value !== 'number') {
                                        return {
                                            text: '',
                                            hidden: true,
                                            index: index,
                                            datasetIndex: 0,
                                        };
                                    }

                                    if (!chart.data.labels) {
                                        return {
                                            text: '',
                                            hidden: true,
                                            index: index,
                                            datasetIndex: 0,
                                        };
                                    }
                                    return {
                                        text: `${shortendLabels[index]}`,
                                        hidden: isNaN(value),
                                        fillStyle: JSON.parse(JSON.stringify(chart.data.datasets[0].backgroundColor))[
                                            index
                                        ],
                                        index: index,
                                        lineWidth: 0,
                                        datasetIndex: 0,
                                    };
                                });
                            },
                        },
                    },
                },
            },
        });

        return () => {
            chart.destroy();
        };
    }, [data]);

    return neighbourhoodDetailsData ? (
        <>
            <h2>Aantal bedrijven</h2>
            <canvas id="company_doughnut" key="company_doughnut" ref={ref} />
        </>
    ) : null;
};
