import React from 'react';
import {Modal} from '../../components/modal';
import {ReportDownloader, ReportDownloaderProps} from './report_downloader';

export interface ReportDownloaderModalProps extends ReportDownloaderProps {
    onClose: () => void;
}

export function ReportDownloaderModal({onClose, ...restProps}: ReportDownloaderModalProps) {
    return (
        <Modal visible forced>
            <div className="modal-header">
                <span className="widget-overlay-title-icon ion-md-cloud-download">&nbsp;</span>
                <h2>Bijlage downloaden</h2>
            </div>
            <div className="modal-body">
                <ReportDownloader {...restProps} />
            </div>
            <div className="modal-footer">
                <button
                    className="btn btn-primary btn-full ion-md-arrow-forward"
                    type="button"
                    onClick={() => onClose()}
                >
                    Sluiten
                </button>
            </div>
        </Modal>
    );
}
