import {ApiAppraisal, apiAppraisalToAppraisal} from './models/api_appraisal';

import {AjaxDriver} from '../../network/driver/ajax_driver';
import {Appraisal} from '../models/appraisal';
import {ObjectType} from '../enum/object_type';
import {TaskReference} from '../models/task_reference';
import {UniformMeasurementReport} from '../models/uniform_measurement_report';
import {ApiTaskReference, isApiTaskReference} from './models/api_task_reference';
import {AppraisalRecentAction} from '../models/appraisal_recent_action';
import {
    ApiAppraisalRecentAction,
    apiAppraisalRecentActionToAppraisalRecentAction,
} from './models/api_appraisal_recent_action';
import {ObjectImage} from '../models/object_image';
import {ApiObjectSummary, apiObjectSummaryToObjectSummary} from './models/api_object_summary';
import {ObjectSummary} from '../models/object_summary';
import {
    ApiAppraisalMatchStatistic,
    apiAppraisalMatchStatisticToAppraisalMatchStatistic,
    ApiAppraiserStatistic,
    apiAppraiserStatisticToAppraiserStatistic,
} from './models/api_appraiser_statistic';
import {AppraisalMatchStatistic, AppraiserStatistic} from '../models/appraiser_statistic';
import {ApiValuationOverview, apiValuationOverviewToValuationOverview} from './models/api_valuation_overview';
import {ValuationOverview} from '../models/valuation_overview';
import {AttachmentsDetails} from '../models/attachment_details';
import {ApiAttachmentsDetails, apiAttachmentsDetailsToAttachmentsDetails} from './models/api_attachment_details';
import {AutomatorDispatchGroup} from '../../create_appraisal/preferences/appraisal_costs';
import {SearchAppraisal} from '../models/search_appraisal';
import {ApiSearchAppraisal, apiSearchAppraisalToSearchAppraisal} from './models/api_search_appraisal';
import {GeneratedReportFormat, GeneratedReportType} from '../enum/generated_report';
import {Answer} from '../models/answer';
import {ApiAnswer, apiAnswerToAnswer} from './models/api_answer';
import {ApiCoreTasksData, apiCoreTasksToCoreTasks} from './models/api_core_tasks';
import {CoreTasksData} from '../models/core_tasks';
import {AppraisalState} from '../enum/appraisal_state';

type AppraisalSearchOptions = {
    page?: number;
    limit?: number;
    from?: Date;
    to?: Date;
    statusses?: AppraisalState[];
};
import {AppraisalImage, AppraisalObject} from '../../create_appraisal/pre_check/map/appraisal_object';
import {apiAppraisalObjectToAppraisalObject, AppraisalObjectApi} from './models/api_appraisal_object';

export interface AppraisalApi {
    get(id: number): Promise<Appraisal>;

    getAnswers(id: number): Promise<Answer[]>;

    search(query?: string, options?: AppraisalSearchOptions): Promise<SearchAppraisal[]>;

    recentUserActionsList(id: number): Promise<AppraisalRecentAction[]>;

    getUniformMeasurementReport(id: number): Promise<UniformMeasurementReport | TaskReference>;

    generateReport(
        id: number,
        type: GeneratedReportType,
        format: GeneratedReportFormat
    ): Promise<string | TaskReference>;

    updateNotes(id: number, notes: string): Promise<Appraisal>;

    updateObjectType(id: number, objectType: ObjectType): Promise<Appraisal>;

    forceAcceptTerms(appraisalId: number): Promise<TaskReference>;

    markAttachmentsChecked(appraisalId: number): Promise<TaskReference>;

    remindClientTerms(appraisalId: number): Promise<void>;

    getObjectImages(id: number): Promise<ObjectImage[]>;

    getObjectSummary(id: number): Promise<ObjectSummary>;

    getAppraiserStatistics(id: number): Promise<AppraiserStatistic[]>;

    getAppraisalMatchStatistics(id: number): Promise<AppraisalMatchStatistic>;

    getValuationOverview(id: number, withPaidAvms: boolean | null): Promise<ValuationOverview>;

    getAttachments(id: number): Promise<AttachmentsDetails>;

    markAttachmentVisited(appraisalId: number, answerUuid: string): Promise<void>;

    triggerAutomatorGroup(
        appraisalId: number,
        group: AutomatorDispatchGroup
    ): Promise<{
        tasks: ApiTaskReference[];
    }>;

    getAppraisalsForAppraiser(): Promise<AppraisalObject[]>;

    getAppraisalImages(appraisalId: number): Promise<AppraisalImage[]>;

    getCoreTaskData(appraisalId: number): Promise<CoreTasksData>;
}

export class DefaultAppraisalApi implements AppraisalApi {
    constructor(private ajaxDriver: AjaxDriver) {}

    public get(id: number): Promise<Appraisal> {
        return new Promise<Appraisal>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/ajax/appraisals/${id}`, {
                    method: 'GET',
                    credentials: 'same-origin',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then(async (result) => {
                    if (result.ok) {
                        const apiAppraisal: ApiAppraisal = await result.json();
                        resolve(apiAppraisalToAppraisal(apiAppraisal));
                    } else {
                        reject();
                    }
                })
                .catch((error) => reject(error));
        });
    }

    public getAnswers(id: number): Promise<Answer[]> {
        return new Promise<Answer[]>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/ajax/appraisals/${id}/answers`, {
                    method: 'GET',
                    credentials: 'same-origin',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then(async (result) => {
                    if (result.ok) {
                        const apiAnswers: ApiAnswer[] = await result.json();
                        const isoCache: Record<string, Date> = {};
                        resolve(apiAnswers.map((apiAnswer) => apiAnswerToAnswer(apiAnswer, isoCache)));
                    } else {
                        reject();
                    }
                })
                .catch((error) => reject(error));
        });
    }

    public search(query?: string, options?: AppraisalSearchOptions): Promise<SearchAppraisal[]> {
        const url = new URL('/ajax/appraisals/search', window.location.origin);
        if (query) {
            url.searchParams.set('q', query);
        }
        if (options?.page) {
            url.searchParams.set('page', options.page.toString());
        }
        if (options?.limit) {
            url.searchParams.set('limit', options.limit.toString());
        }
        if (options?.from) {
            url.searchParams.set('from', options.from.toISOString());
        }
        if (options?.to) {
            url.searchParams.set('to', options.to.toISOString());
        }
        if (options?.statusses) {
            for (const status of options.statusses) {
                url.searchParams.append('status[]', status);
            }
        }

        return new Promise<SearchAppraisal[]>((resolve, reject) => {
            this.ajaxDriver
                .fetch(url.toString(), {
                    method: 'GET',
                    credentials: 'same-origin',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then(async (result) => {
                    if (result.ok) {
                        const apiAppraisal: ApiSearchAppraisal[] = await result.json();
                        resolve(
                            apiAppraisal.map((apiSearchAppraisal) =>
                                apiSearchAppraisalToSearchAppraisal(apiSearchAppraisal)
                            )
                        );
                    } else {
                        reject();
                    }
                })
                .catch((error) => reject(error));
        });
    }

    public recentUserActionsList(id: number): Promise<AppraisalRecentAction[]> {
        return new Promise<AppraisalRecentAction[]>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/ajax/appraisals/${id}/recent-user-actions`, {
                    method: 'GET',
                    credentials: 'same-origin',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then(async (result) => {
                    if (result.ok) {
                        const apiAppraisalRecentActions: ApiAppraisalRecentAction[] = await result.json();
                        resolve(
                            apiAppraisalRecentActions.map((apiAppraisalRecentAction) =>
                                apiAppraisalRecentActionToAppraisalRecentAction(apiAppraisalRecentAction)
                            )
                        );
                    } else {
                        reject();
                    }
                })
                .catch((error) => reject(error));
        });
    }

    public updateNotes(id: number, notes: string): Promise<Appraisal> {
        return new Promise<Appraisal>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/ajax/appraisals/${id}/notes`, {
                    method: 'POST',
                    credentials: 'same-origin',
                    body: JSON.stringify({
                        notes: notes,
                    }),
                    headers: {
                        'X-Csrf-Token': (document.head.querySelector('meta[name="csrf-token"]') as HTMLMetaElement)
                            .content,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then(async (result) => {
                    if (result.ok) {
                        const apiAppraisal: ApiAppraisal = await result.json();
                        resolve(apiAppraisalToAppraisal(apiAppraisal));
                    } else {
                        reject();
                    }
                })
                .catch((error) => reject(error));
        });
    }

    public updateObjectType(id: number, objectType: ObjectType): Promise<Appraisal> {
        return new Promise<Appraisal>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/ajax/appraisals/${id}/object-type`, {
                    method: 'POST',
                    credentials: 'same-origin',
                    body: JSON.stringify({
                        object_type: objectType,
                    }),
                    headers: {
                        'X-Csrf-Token': (document.head.querySelector('meta[name="csrf-token"]') as HTMLMetaElement)
                            .content,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then(async (result) => {
                    if (result.ok) {
                        const apiAppraisal: ApiAppraisal = await result.json();
                        resolve(apiAppraisalToAppraisal(apiAppraisal));
                    } else {
                        reject();
                    }
                })
                .catch((error) => reject(error));
        });
    }

    public getUniformMeasurementReport(id: number): Promise<UniformMeasurementReport | TaskReference> {
        return new Promise<UniformMeasurementReport | TaskReference>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/ajax/appraisals/${id}/uniform-measurement-report`, {
                    method: 'GET',
                    credentials: 'same-origin',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then(async (result) => {
                    if (result.ok) {
                        const data = await result.json();
                        if (isApiTaskReference(data)) {
                            resolve({
                                taskId: data.task_id,
                            });
                        } else {
                            resolve(data);
                        }
                    } else {
                        reject();
                    }
                })
                .catch((error) => reject(error));
        });
    }

    public generateReport(
        id: number,
        type: GeneratedReportType,
        format: GeneratedReportFormat
    ): Promise<string | TaskReference> {
        return new Promise<string | TaskReference>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/ajax/appraisals/${id}/generate-report`, {
                    method: 'POST',
                    credentials: 'same-origin',
                    body: JSON.stringify({
                        type,
                        format,
                    }),
                    headers: {
                        'X-Csrf-Token': (document.head.querySelector('meta[name="csrf-token"]') as HTMLMetaElement)
                            .content,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then(async (result) => {
                    if (result.ok) {
                        const data = await result.json();
                        if (isApiTaskReference(data)) {
                            resolve({
                                taskId: data.task_id,
                            });
                        } else {
                            resolve(data);
                        }
                    } else {
                        reject();
                    }
                })
                .catch((error) => reject(error));
        });
    }

    public generateHtmlConceptReport(id: number): Promise<string | TaskReference> {
        return new Promise<string | TaskReference>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/ajax/appraisals/${id}/generate-html-concept-report`, {
                    method: 'GET',
                    credentials: 'same-origin',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then(async (result) => {
                    if (result.ok) {
                        const data = await result.json();
                        if (isApiTaskReference(data)) {
                            resolve({
                                taskId: data.task_id,
                            });
                        } else {
                            resolve(data);
                        }
                    } else {
                        reject();
                    }
                })
                .catch((error) => reject(error));
        });
    }

    public generateHtmlBouwkundigeStaat(id: number): Promise<string | TaskReference> {
        return new Promise<string | TaskReference>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/ajax/appraisals/${id}/generate-html-bouwkundige-staat`, {
                    method: 'GET',
                    credentials: 'same-origin',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then(async (result) => {
                    if (result.ok) {
                        const data = await result.json();
                        if (isApiTaskReference(data)) {
                            resolve({
                                taskId: data.task_id,
                            });
                        } else {
                            resolve(data);
                        }
                    } else {
                        reject();
                    }
                })
                .catch((error) => reject(error));
        });
    }

    public generateHtmlConstructionSpecification(id: number): Promise<string | TaskReference> {
        return new Promise<string | TaskReference>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/ajax/appraisals/${id}/generate-html-construction-specification`, {
                    method: 'GET',
                    credentials: 'same-origin',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then(async (result) => {
                    if (result.ok) {
                        const data = await result.json();
                        if (isApiTaskReference(data)) {
                            resolve({
                                taskId: data.task_id,
                            });
                        } else {
                            resolve(data);
                        }
                    } else {
                        reject();
                    }
                })
                .catch((error) => reject(error));
        });
    }

    public generatePdfConstructionSpecification(id: number): Promise<string | TaskReference> {
        return new Promise<string | TaskReference>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/ajax/appraisals/${id}/generate-pdf-construction-specification`, {
                    method: 'GET',
                    credentials: 'same-origin',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then(async (result) => {
                    if (result.ok) {
                        const data = await result.json();
                        if (isApiTaskReference(data)) {
                            resolve({
                                taskId: data.task_id,
                            });
                        } else {
                            resolve(data);
                        }
                    } else {
                        reject();
                    }
                })
                .catch((error) => reject(error));
        });
    }

    public generateHtmlUniformMeasurementReport(id: number): Promise<string | TaskReference> {
        return new Promise<string | TaskReference>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/ajax/appraisals/${id}/generate-html-uniform-measurement-report`, {
                    method: 'GET',
                    credentials: 'same-origin',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then(async (result) => {
                    if (result.ok) {
                        const data = await result.json();
                        if (isApiTaskReference(data)) {
                            resolve({
                                taskId: data.task_id,
                            });
                        } else {
                            resolve(data);
                        }
                    } else {
                        reject();
                    }
                })
                .catch((error) => reject(error));
        });
    }

    public generatePdfUniformMeasurementReport(id: number): Promise<string | TaskReference> {
        return new Promise<string | TaskReference>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/ajax/appraisals/${id}/generate-pdf-uniform-measurement-report`, {
                    method: 'GET',
                    credentials: 'same-origin',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then(async (result) => {
                    if (result.ok) {
                        const data = await result.json();
                        if (isApiTaskReference(data)) {
                            resolve({
                                taskId: data.task_id,
                            });
                        } else {
                            resolve(data);
                        }
                    } else {
                        reject();
                    }
                })
                .catch((error) => reject(error));
        });
    }

    public forceAcceptTerms(appraisalId: number): Promise<TaskReference> {
        return new Promise<TaskReference>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/ajax/appraisals/${appraisalId}/force-accept-terms`, {
                    method: 'POST',
                    credentials: 'same-origin',
                    headers: {
                        'X-Csrf-Token': (document.head.querySelector('meta[name="csrf-token"]') as HTMLMetaElement)
                            .content,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then(async (result) => {
                    if (result.ok) {
                        const data: ApiTaskReference = await result.json();
                        if (isApiTaskReference(data)) {
                            resolve({
                                taskId: data.task_id,
                            });
                        } else {
                            resolve(data);
                        }
                    } else {
                        reject();
                    }
                })
                .catch((error) => reject(error));
        });
    }

    public remindClientTerms(appraisalId: number): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/ajax/appraisals/${appraisalId}/remind-client-terms`, {
                    method: 'POST',
                    credentials: 'same-origin',
                    headers: {
                        'X-Csrf-Token': (document.head.querySelector('meta[name="csrf-token"]') as HTMLMetaElement)
                            .content,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then((result) => {
                    if (result.ok) {
                        resolve();
                    } else {
                        reject();
                    }
                })
                .catch((error) => reject(error));
        });
    }

    public markAttachmentsChecked(appraisalId: number): Promise<TaskReference> {
        return new Promise<TaskReference>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/ajax/appraisals/${appraisalId}/mark-attachments-checked-by-appraiser`, {
                    method: 'POST',
                    credentials: 'same-origin',
                    headers: {
                        'X-Csrf-Token': (document.head.querySelector('meta[name="csrf-token"]') as HTMLMetaElement)
                            .content,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then(async (result) => {
                    if (result.ok) {
                        const data: ApiTaskReference = await result.json();
                        if (isApiTaskReference(data)) {
                            resolve({
                                taskId: data.task_id,
                            });
                        } else {
                            resolve(data);
                        }
                    } else {
                        reject();
                    }
                })
                .catch((error) => reject(error));
        });
    }

    public getObjectImages(id: number): Promise<ObjectImage[]> {
        return new Promise<ObjectImage[]>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/ajax/appraisals/${id}/object-images`, {
                    credentials: 'same-origin',
                    headers: {
                        'X-Csrf-Token': (document.head.querySelector('meta[name="csrf-token"]') as HTMLMetaElement)
                            .content,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then(async (result) => {
                    if (result.ok) {
                        const images: ObjectImage[] = await result.json();
                        resolve(images);
                    } else {
                        reject();
                    }
                })
                .catch((error) => reject(error));
        });
    }

    public getObjectSummary(id: number): Promise<ObjectSummary> {
        return new Promise<ObjectSummary>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/ajax/appraisals/${id}/object-summary`, {
                    credentials: 'same-origin',
                    headers: {
                        'X-Csrf-Token': (document.head.querySelector('meta[name="csrf-token"]') as HTMLMetaElement)
                            .content,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then(async (result) => {
                    if (result.ok) {
                        const data: ApiObjectSummary = await result.json();
                        resolve(apiObjectSummaryToObjectSummary(data));
                    } else {
                        reject();
                    }
                })
                .catch((error) => reject(error));
        });
    }

    public getAppraiserStatistics(id: number): Promise<AppraiserStatistic[]> {
        return new Promise<AppraiserStatistic[]>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/ajax/appraisals/${id}/appraiser-statistics`, {
                    credentials: 'same-origin',
                    headers: {
                        'X-Csrf-Token': (document.head.querySelector('meta[name="csrf-token"]') as HTMLMetaElement)
                            .content,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then(async (result) => {
                    if (result.ok) {
                        const data: ApiAppraiserStatistic[] = await result.json();
                        resolve(data.map((statistic) => apiAppraiserStatisticToAppraiserStatistic(statistic)));
                    } else {
                        reject();
                    }
                })
                .catch((error) => reject(error));
        });
    }

    public getAppraisalMatchStatistics(id: number): Promise<AppraisalMatchStatistic> {
        return new Promise<AppraisalMatchStatistic>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/ajax/appraisals/${id}/appraisal-match-statistics`, {
                    credentials: 'same-origin',
                    headers: {
                        'X-Csrf-Token': (document.head.querySelector('meta[name="csrf-token"]') as HTMLMetaElement)
                            .content,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then(async (result) => {
                    if (result.ok) {
                        const data: ApiAppraisalMatchStatistic = await result.json();
                        resolve(apiAppraisalMatchStatisticToAppraisalMatchStatistic(data));
                    } else {
                        reject();
                    }
                })
                .catch((error) => reject(error));
        });
    }

    public getValuationOverview(id: number, withPaidAvms: boolean | null): Promise<ValuationOverview> {
        const searchParams = new URLSearchParams();
        if (withPaidAvms !== null) {
            searchParams.set('avms', withPaidAvms ? '1' : '0');
        }

        return new Promise<ValuationOverview>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/ajax/appraisals/${id}/valuation?${searchParams.toString()}`, {
                    credentials: 'same-origin',
                    headers: {
                        'X-Csrf-Token': (document.head.querySelector('meta[name="csrf-token"]') as HTMLMetaElement)
                            .content,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then(async (result) => {
                    if (result.ok) {
                        const data: ApiValuationOverview = await result.json();
                        resolve(apiValuationOverviewToValuationOverview(data));
                    } else {
                        reject();
                    }
                })
                .catch((error) => reject(error));
        });
    }

    public getAttachments(id: number): Promise<AttachmentsDetails> {
        return new Promise<AttachmentsDetails>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/ajax/appraisals/${id}/attachments`, {
                    credentials: 'same-origin',
                    headers: {
                        'X-Csrf-Token': (document.head.querySelector('meta[name="csrf-token"]') as HTMLMetaElement)
                            .content,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then(async (result) => {
                    if (result.ok) {
                        const data: ApiAttachmentsDetails = await result.json();
                        resolve(apiAttachmentsDetailsToAttachmentsDetails(data));
                    } else {
                        reject();
                    }
                })
                .catch((error) => reject(error));
        });
    }

    public markAttachmentVisited(appraisalId: number, answerUuid: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/ajax/appraisals/${appraisalId}/attachments/mark-visited`, {
                    credentials: 'same-origin',
                    headers: {
                        'X-Csrf-Token': (document.head.querySelector('meta[name="csrf-token"]') as HTMLMetaElement)
                            .content,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    method: 'POST',
                    body: JSON.stringify({answer_uuid: answerUuid}),
                })
                .then((result) => {
                    if (result.ok) {
                        resolve();
                    } else {
                        reject();
                    }
                })
                .catch((error) => reject(error));
        });
    }

    public triggerAutomatorGroup(appraisalId: number, automatorGroup: AutomatorDispatchGroup) {
        return new Promise<{
            tasks: ApiTaskReference[];
        }>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/ajax/appraisals/${appraisalId}/trigger-automator-group`, {
                    credentials: 'same-origin',
                    headers: {
                        'X-Csrf-Token': (document.head.querySelector('meta[name="csrf-token"]') as HTMLMetaElement)
                            .content,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    method: 'POST',
                    body: JSON.stringify({group: automatorGroup}),
                })
                .then(async (result) => {
                    if (result.ok) {
                        const data = await result.json();
                        resolve(data);
                    } else {
                        reject();
                    }
                })
                .catch((error) => reject(error));
        });
    }

    public getAppraisalsForAppraiser(): Promise<AppraisalObject[]> {
        return new Promise<AppraisalObject[]>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/ajax/appraisals/for-appraiser`, {
                    method: 'GET',
                    credentials: 'same-origin',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then(async (result) => {
                    if (result.ok) {
                        const data: AppraisalObjectApi[] = await result.json();
                        resolve(
                            data.map((appraisalObjectApi) => apiAppraisalObjectToAppraisalObject(appraisalObjectApi))
                        );
                    } else {
                        reject();
                    }
                })
                .catch((error) => reject(error));
        });
    }

    public getAppraisalImages(appraisalId: number): Promise<AppraisalImage[]> {
        return new Promise<AppraisalImage[]>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/ajax/appraisals/${appraisalId}/images`, {
                    method: 'GET',
                    credentials: 'same-origin',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then(async (result) => {
                    if (result.ok) {
                        const data: AppraisalImage[] = await result.json();
                        resolve(data);
                    } else {
                        reject();
                    }
                })
                .catch((error) => reject(error));
        });
    }

    public getCoreTaskData(appraisalId: number) {
        return new Promise<CoreTasksData>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/ajax/appraisals/${appraisalId}/core-tasks`, {
                    credentials: 'same-origin',
                    headers: {
                        'X-Csrf-Token': (document.head.querySelector('meta[name="csrf-token"]') as HTMLMetaElement)
                            .content,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    method: 'GET',
                })
                .then(async (result) => {
                    if (result.ok) {
                        const data: ApiCoreTasksData = await result.json();
                        resolve(apiCoreTasksToCoreTasks(data));
                    } else {
                        reject();
                    }
                })
                .catch((error) => reject(error));
        });
    }
}
