import * as React from 'react';

import {Appraisal} from '../../../../models/appraisal';
import {AppraisalCoreTasksWidgetPresenter} from './appraisal_core_tasks_widget_presenter';
import {Global} from '../../../../../business/global_provider';
import {QuestionSet} from '../../../../models/question_set';
import {observer} from 'mobx-react';
import {usePresenter} from '../../../../../support/presenter/use_presenter';
import {Collapsible} from '../../components/collapsible';
import {CoreTask} from './components/core_task';
import {CoreTaskItem} from './components/core_task_item';
import {CoreTaskType} from '../../../../enum/core_tasks';
import {CoreTaskFileDisplay} from './components/core_task_file_display';
import {AppraisalValidationType} from '../../../../enum/appraisal_validation_type';
import {TechnicalReference} from '../../../../enum/technical_reference';
import {AppraisalInvoiceFlow} from '../../../../enum/appraisal_invoice_flow';
import {PagePartsSet} from '../../../../models/page_parts_set';
import {ValidationError} from '../../../../models/validation_error';
import {RenderingContextType} from '../../../../enum/rendering_context_type';
import {CoreTaskAttachmentType} from '../../../../models/core_tasks';

interface OwnProps {
    global: Global;
    appraisal: Appraisal;
    questionSet: QuestionSet;
    pagePartsSet: PagePartsSet | null;
    validationErrors: ValidationError[];
    renderingContext: RenderingContextType;
}

export const AppraisalCoreTasksWidget: React.FC<OwnProps> = observer(function AppraisalDetailsWidget(props) {
    const presenter = usePresenter(
        (container) =>
            new AppraisalCoreTasksWidgetPresenter(
                props.questionSet,
                container.business.answerController(props.appraisal, props.questionSet),
                container.business.support.surfaceAreaProvider(props.questionSet, props.appraisal),
                container.business.plotAreaProvider(props.appraisal, props.questionSet),
                container.business.energyLabelProvider(props.appraisal, props.questionSet),
                container.business.appraisalProvider,
                container.network.appraisalApi,
                container.business.validationConclusionProvider(
                    props.appraisal,
                    props.questionSet,
                    props.pagePartsSet,
                    props.validationErrors,
                    props.renderingContext
                ),
                container.globalProvider
            )
    );

    if (!presenter.answer) {
        return null;
    }

    return (
        <div className="col-12">
            <div className="widget">
                <Collapsible
                    className="core-tasks-checklist"
                    defaultShown
                    title={
                        <div className="widget-title d-flex flex-row align-items-center pr-3">
                            <h3 className="w-auto flex-shrink-0 mr-3">
                                <span className="ion-md-checkmark-circle-outline" />
                                Kerntaken
                            </h3>
                            <div className="progress form-progress">
                                <div
                                    className="progress-bar"
                                    role="progressbar"
                                    style={{width: `${(presenter.completedMainTaskCount / 6) * 100}%`}}
                                ></div>
                            </div>
                            <span className="text-nowrap text-right mr-2" style={{minWidth: 50}}>
                                <strong>{presenter.completedMainTaskCount}</strong> / 6
                            </span>
                        </div>
                    }
                    light
                >
                    <div className="widget-body">
                        <CoreTask
                            index={1}
                            name="Contracteren"
                            taskCompletion={presenter.taskCompletionStatus}
                            subtasks={presenter.subtasks}
                        >
                            <CoreTaskItem
                                task={CoreTaskType.APPRAISAL_ACQUIRE}
                                taskIndex={1}
                                itemIndex={1}
                                name="Opdracht verwerven"
                                taskCompletion={presenter.taskCompletionStatus}
                                description="Het intakegesprek is uitgevoerd, het taxatiedoel vastgesteld en opgenomen in de opdrachtvoorwaarden."
                                checkboxTooltip="Deze taak wordt automatisch afgevinkt wanneer de opdracht is aangemaakt."
                            />
                            <CoreTaskItem
                                task={CoreTaskType.INDEPENDENCE_CHECK}
                                taskIndex={1}
                                itemIndex={2}
                                checkboxTooltip="Deze taak wordt automatisch afgevinkt wanneer het onafhankelijkheids- & deskundigheidsrapport is geupload."
                                name="Onafhankelijkheid & deskundigheid controleren"
                                description="Het belangenconflict en de deskundigheid van de taxateur zijn vastgesteld, en de handelingsbevoegdheid van de opdrachtgever is gecontroleerd."
                                taskCompletion={presenter.taskCompletionStatus}
                            >
                                <CoreTaskFileDisplay
                                    name="Rapport onafhankelijkheid & deskundigheid"
                                    description={
                                        'Dit rapport wordt automatich toegevoegd wanneer de kerntakenrapportage is ingeschakeld. Zo niet, dan kan er handmatig een rapport worden geupload.'
                                    }
                                    technicalReference={TechnicalReference.CORE_TASK_INDEPENDENCE_REPORT}
                                    data={presenter.coreTasksData}
                                    appraisal={props.appraisal}
                                    questionSet={props.questionSet}
                                    parentAnswerUuid={presenter.answer.uuid}
                                    pagePartsSet={props.pagePartsSet}
                                    renderingContext={props.renderingContext}
                                />
                            </CoreTaskItem>
                            <CoreTaskItem
                                task={CoreTaskType.OBJECT_DETAILS_CHECK}
                                taskIndex={1}
                                itemIndex={3}
                                taskCompletion={presenter.taskCompletionStatus}
                                name="Gegevens opvragen & vastleggen"
                                description="De gegevens van de opdrachtgever zijn verzameld, en de objectgegevens vastgelegd. Deze zijn opgenomen in de opdrachtvoorwaarden."
                                checkboxTooltip="Deze taak wordt automatisch afgevinkt wanneer de opdracht is aangemaakt."
                            />
                            <CoreTaskItem
                                task={CoreTaskType.DETERMINE_APPRAISAL_TYPE}
                                taskIndex={1}
                                itemIndex={4}
                                taskCompletion={presenter.taskCompletionStatus}
                                name="Uitvoering opdracht bepalen"
                                description="Het taxatiedoel en waardebegrip zijn vastgesteld in de opdrachtvoorwaarden. De planning voor de taxatie is vastgelegd."
                                checkboxTooltip="Deze taak wordt automatisch afgevinkt wanneer de mail met de inplanning van de opname beschikbaar is."
                            >
                                <CoreTaskFileDisplay
                                    type="mail"
                                    name="Bericht inplanning opname"
                                    description={
                                        'Zodra deze mail is verzonden, wordt deze hier getoond. Wanneer deze mail niet beschikbaar is, kan deze handmatig worden toegevoegd.'
                                    }
                                    technicalReference={TechnicalReference.CORE_TASK_APPOINTMENT_MAIL}
                                    data={presenter.coreTasksData}
                                    appraisal={props.appraisal}
                                    questionSet={props.questionSet}
                                    parentAnswerUuid={presenter.answer.uuid}
                                    pagePartsSet={props.pagePartsSet}
                                    renderingContext={props.renderingContext}
                                />
                                <CoreTaskFileDisplay
                                    name="Stappenplan uitvoering taxatie"
                                    description={
                                        'Dit stappenplan wordt automatich toegevoegd wanneer de kerntakenrapportage is ingeschakeld. Zo niet, dan kan dit optioneel handmatig worden toegevoegd.'
                                    }
                                    technicalReference={TechnicalReference.CORE_TASK_PLANNING}
                                    data={presenter.coreTasksData}
                                    appraisal={props.appraisal}
                                    questionSet={props.questionSet}
                                    parentAnswerUuid={presenter.answer.uuid}
                                    pagePartsSet={props.pagePartsSet}
                                    renderingContext={props.renderingContext}
                                />
                            </CoreTaskItem>
                            <CoreTaskItem
                                task={CoreTaskType.DETERMINE_TERMS}
                                taskIndex={1}
                                itemIndex={5}
                                taskCompletion={presenter.taskCompletionStatus}
                                checkboxTooltip="Deze taak wordt automatisch afgevinkt wanneer de definitieve opdrachtvoorwaarden beschikbaar zijn."
                                name="Opdrachtvoorwaarden/offerte opstellen"
                                description="De opdrachtvoorwaarden zijn opgesteld en toegelicht aan de opdrachtgever."
                            >
                                <CoreTaskFileDisplay
                                    name="Opdrachtvoorwaarden"
                                    description={
                                        'De definitieve versie van de opdrachtvoorwaarden zijn beschikbaar zodra de opdrachtgever deze heeft geaccepteerd.'
                                    }
                                    technicalReference={TechnicalReference.CORE_TASK_TERMS}
                                    data={presenter.coreTasksData}
                                    appraisal={props.appraisal}
                                    questionSet={props.questionSet}
                                    parentAnswerUuid={presenter.answer.uuid}
                                    pagePartsSet={props.pagePartsSet}
                                    renderingContext={props.renderingContext}
                                />
                            </CoreTaskItem>
                            <CoreTaskItem
                                task={CoreTaskType.SEND_TERMS}
                                taskIndex={1}
                                itemIndex={6}
                                taskCompletion={presenter.taskCompletionStatus}
                                checkboxTooltip="Deze taak wordt automatisch afgevinkt wanneer de bevestigingsmail beschikbaar is."
                                name="Opdrachtbrief opstellen en vastleggen"
                                description="De opdrachtbrief is verzonden naar de opdrachtgever en geaccepteerd."
                            >
                                <CoreTaskFileDisplay
                                    type="mail"
                                    name="Bevestiging acceptatie opdrachtvoorwaarden"
                                    description={
                                        'Zodra deze mail is verzonden, wordt deze hier getoond. Wanneer deze mail niet beschikbaar is, kan deze handmatig worden toegevoegd.'
                                    }
                                    technicalReference={TechnicalReference.CORE_TASK_TERMS_ACCEPTED_MAIL}
                                    data={presenter.coreTasksData}
                                    appraisal={props.appraisal}
                                    questionSet={props.questionSet}
                                    parentAnswerUuid={presenter.answer.uuid}
                                    pagePartsSet={props.pagePartsSet}
                                    renderingContext={props.renderingContext}
                                />
                            </CoreTaskItem>
                            <CoreTaskItem
                                task={CoreTaskType.HANDLE_TERMS_FINANCIAL}
                                taskIndex={1}
                                itemIndex={7}
                                taskCompletion={presenter.taskCompletionStatus}
                                checkboxTooltip={`Deze taak wordt automatisch afgevinkt wanneer de opdrachtbevestiging vastgelegd is ${
                                    props.appraisal.invoiceFlow === AppraisalInvoiceFlow.ACCEPTANCE
                                        ? 'en de opdrachtgever heeft betaald'
                                        : ''
                                }.`}
                                name="Administratie en financiën rondom de opdrachtverstrekking afhandelen"
                                description="De opdrachtbevestiging is vastgelegd, en de financiële zaken rondom de opdracht zijn afgehandeld"
                            />
                            <CoreTaskItem
                                task={CoreTaskType.TERMS_AFTER_SALES}
                                taskIndex={1}
                                itemIndex={8}
                                name="Aftersales verzorgen"
                                description={
                                    <>
                                        De tevredenheid van de opdrachtgever is gecontroleerd, en dit resultaat is
                                        vastgelegd.{' '}
                                        {presenter.coreTaskPreferences?.afterSalesTermsDisabled && (
                                            <>
                                                <br />
                                                Deze kerntaak is uitgeschakeld. Je kunt deze opnieuw inschakelen{' '}
                                                <a href="/account/me/message-templates" target="_blank" rel="noopener">
                                                    binnen je account.
                                                </a>
                                            </>
                                        )}
                                    </>
                                }
                                checkboxTooltip="Deze taak wordt automatisch afgevinkt wanneer de aftersales zijn gearchiveerd."
                                disabled={presenter.coreTaskPreferences?.afterSalesTermsDisabled}
                                taskCompletion={presenter.taskCompletionStatus}
                            >
                                {!presenter.coreTaskPreferences?.afterSalesTermsDisabled && (
                                    <CoreTaskFileDisplay
                                        type="mail"
                                        name="Aftersales na vastleggen opdrachtvoorwaarden"
                                        technicalReference={TechnicalReference.CORE_TASK_TERMS_AFTERSALES_MAIL}
                                        data={presenter.coreTasksData}
                                        description={
                                            <>
                                                Upload het resultaat van de aftersales.{' '}
                                                <a href="/account/me/message-templates" target="_blank" rel="noopener">
                                                    Binnen je account
                                                </a>{' '}
                                                is het mogelijk om een gepersonaliseerde e-mail te activeren voor het
                                                verzorgen van deze aftersales.
                                            </>
                                        }
                                        appraisal={props.appraisal}
                                        questionSet={props.questionSet}
                                        parentAnswerUuid={presenter.answer.uuid}
                                        pagePartsSet={props.pagePartsSet}
                                        renderingContext={props.renderingContext}
                                    />
                                )}
                            </CoreTaskItem>
                        </CoreTask>
                        <CoreTask
                            index={2}
                            name="Rechercheren"
                            taskCompletion={presenter.taskCompletionStatus}
                            subtasks={presenter.subtasks}
                        >
                            <CoreTaskItem
                                task={CoreTaskType.COLLECT_INFO}
                                taskIndex={2}
                                itemIndex={1}
                                name="Informatie verzamelen"
                                description="De opname is ingepland en uitgevoerd. De benodigde gegevens voor de taxatie zijn verzameld."
                                checkboxTooltip="Deze taak wordt automatisch afgevinkt wannneer alle verplichte vragen binnen de opname zijn ingevuld."
                                taskCompletion={presenter.taskCompletionStatus}
                            >
                                <CoreTaskFileDisplay
                                    name="Fotoset"
                                    description={"Gecombineerde set van alle foto's"}
                                    technicalReference={TechnicalReference.CORE_TASK_PHOTOS_SET}
                                    data={presenter.coreTasksData}
                                    appraisal={props.appraisal}
                                    questionSet={props.questionSet}
                                    parentAnswerUuid={presenter.answer.uuid}
                                    pagePartsSet={props.pagePartsSet}
                                    renderingContext={props.renderingContext}
                                />
                                <CoreTaskFileDisplay
                                    name="Notities"
                                    description={'Notities van de taxateur voor de taxatie'}
                                    technicalReference={TechnicalReference.CORE_TASK_NOTES}
                                    data={presenter.coreTasksData}
                                    appraisal={props.appraisal}
                                    questionSet={props.questionSet}
                                    parentAnswerUuid={presenter.answer.uuid}
                                    pagePartsSet={props.pagePartsSet}
                                    renderingContext={props.renderingContext}
                                />
                            </CoreTaskItem>
                            <CoreTaskItem
                                task={CoreTaskType.CHECK_INFO}
                                taskIndex={2}
                                itemIndex={2}
                                name="Informatie controleren"
                                description="De benodigde gegevens voor de taxatie zijn gecontroleerd"
                                checkboxTooltip="Deze taak wordt automatisch afgevinkt wannneer alle toegevoegde bijlagen zijn geopend."
                                taskCompletion={presenter.taskCompletionStatus}
                            />
                            <CoreTaskItem
                                task={CoreTaskType.STORE_INFO}
                                taskIndex={2}
                                itemIndex={3}
                                name="Onderzoekgegevens vastleggen"
                                description="Alle benodigde gegevens voor de taxatie zijn vastgelegd in het taxatiedossier."
                                checkboxTooltip="Deze taak wordt automatisch afgevinkt wanneer 2.1 en 2.2 zijn voltooid."
                                taskCompletion={presenter.taskCompletionStatus}
                            >
                                <CoreTaskFileDisplay
                                    name="Bijlagenset"
                                    description={'Gecombineerde set van alle bijlagen'}
                                    technicalReference={TechnicalReference.CORE_TASK_ATTACHMENTS_SET}
                                    data={presenter.coreTasksData}
                                    appraisal={props.appraisal}
                                    questionSet={props.questionSet}
                                    parentAnswerUuid={presenter.answer.uuid}
                                    pagePartsSet={props.pagePartsSet}
                                    renderingContext={props.renderingContext}
                                />
                                <CoreTaskFileDisplay
                                    name="Audit trail antwoorden"
                                    description={'Audit trail van alle antwoorden'}
                                    technicalReference={TechnicalReference.CORE_TASK_ANSWER_TRAIL_REPORT}
                                    data={presenter.coreTasksData}
                                    appraisal={props.appraisal}
                                    questionSet={props.questionSet}
                                    parentAnswerUuid={presenter.answer.uuid}
                                    pagePartsSet={props.pagePartsSet}
                                    renderingContext={props.renderingContext}
                                />
                                {/* This is only visible when there is actually a precheck report available (as this attachment is kindof redundant due to the seperate reports that are added to the other tasks)*/}
                                {presenter.hasAttachment(TechnicalReference.CORE_TASK_PRECHECK_REPORT) && (
                                    <CoreTaskFileDisplay
                                        name="Kerntakenrapport"
                                        description={
                                            'Het volledige rapport van de uitgevoerde vooronderzoek voor deze taxatie'
                                        }
                                        technicalReference={TechnicalReference.CORE_TASK_PRECHECK_REPORT}
                                        data={presenter.coreTasksData}
                                        appraisal={props.appraisal}
                                        questionSet={props.questionSet}
                                        parentAnswerUuid={presenter.answer.uuid}
                                        pagePartsSet={props.pagePartsSet}
                                        renderingContext={props.renderingContext}
                                    />
                                )}
                            </CoreTaskItem>
                        </CoreTask>
                        <CoreTask
                            index={3}
                            name="Analyseren"
                            taskCompletion={presenter.taskCompletionStatus}
                            subtasks={presenter.subtasks}
                        >
                            <CoreTaskItem
                                task={CoreTaskType.USE_INFO}
                                taskIndex={3}
                                itemIndex={1}
                                name="Onderzoekgegevens selecteren en onderbouwd koppelen aan het object"
                                description="Er is een selectie uit de onderzoekgegevens gemaakt, en gekoppeld aan dit object."
                                checkboxTooltip="Deze taak wordt automatisch afgevinkt wanneer de vragen m.b.t. geraadpleegde bronnen zijn ingevuld."
                                taskCompletion={presenter.taskCompletionStatus}
                            />
                            <CoreTaskItem
                                task={CoreTaskType.ANALYSE_MARKET}
                                taskIndex={3}
                                itemIndex={2}
                                name="Marktanalyse uitvoeren, vastleggen en onderbouwd koppelen aan het object"
                                description="De ontwikkelingen op de vastgoedmarkt, en de omstandigheden waar rekening mee gehouden moet worden bij deze taxatie zijn vastgelegd."
                                checkboxTooltip="Deze taak wordt automatisch afgevinkt wanneer de SWOT-analyse is ingevuld en het objectanalyze-rapport is toegevoegd."
                                taskCompletion={presenter.taskCompletionStatus}
                            >
                                <CoreTaskFileDisplay
                                    name="Objectanalyse"
                                    technicalReference={TechnicalReference.CORE_TASK_TRANSACTION_ANALYSIS_REPORT}
                                    description={
                                        'Dit rapport wordt automatich toegevoegd wanneer de kerntakenrapportage is ingeschakeld. Zo niet, dan kan er handmatig een rapport worden geupload.'
                                    }
                                    data={presenter.coreTasksData}
                                    appraisal={props.appraisal}
                                    questionSet={props.questionSet}
                                    parentAnswerUuid={presenter.answer.uuid}
                                    pagePartsSet={props.pagePartsSet}
                                    renderingContext={props.renderingContext}
                                />
                            </CoreTaskItem>
                            <CoreTaskItem
                                task={CoreTaskType.ANALYSE_OBJECT}
                                taskIndex={3}
                                itemIndex={3}
                                name="Objectanalyse uitvoeren, vastleggen en onderbouwd koppelen aan het object"
                                description="De objectkenmerken en de bouwkundige staat zijn vastgelegd. Vergelijkbare objecten zijn geanalyseerd."
                                checkboxTooltip="Deze taak wordt automatisch afgevinkt wanneer de woonlagen en de geactiveerde referentievergelijkers zijn ingevuld."
                                taskCompletion={presenter.taskCompletionStatus}
                            />
                            <CoreTaskItem
                                task={CoreTaskType.CONCLUDE_ANALYSIS}
                                taskIndex={3}
                                itemIndex={4}
                                name="Concluderen"
                                description="Er zijn conclusies getrokken, onderbouwd en vastgelegd m.b.t. de onderzoekgegevens, marktanalyse en objectanalyse."
                                checkboxTooltip="Deze taak wordt automatisch afgevinkt wanneer 3.1, 3.2 en 3.3 zijn afgerond."
                                taskCompletion={presenter.taskCompletionStatus}
                            >
                                <CoreTaskFileDisplay
                                    name="Ingevulde & afgekeurde referenties"
                                    technicalReference={TechnicalReference.CORE_TASK_REFERENCES_REPORT}
                                    data={presenter.coreTasksData}
                                    appraisal={props.appraisal}
                                    questionSet={props.questionSet}
                                    parentAnswerUuid={presenter.answer.uuid}
                                    pagePartsSet={props.pagePartsSet}
                                    renderingContext={props.renderingContext}
                                />
                            </CoreTaskItem>
                        </CoreTask>
                        <CoreTask
                            index={4}
                            name="Waarderen"
                            taskCompletion={presenter.taskCompletionStatus}
                            subtasks={presenter.subtasks}
                        >
                            <CoreTaskItem
                                task={CoreTaskType.DETERMINE_FIGURES}
                                taskIndex={4}
                                itemIndex={1}
                                name="Referentiekengetallen vaststellen"
                                description="De referentiekengetallen voor het object zijn vastgesteld."
                                checkboxTooltip="Deze taak wordt automatisch afgevinkt wanneer het bouwjaar, de GBO, de waardepeildatum en het objecttype zijn ingevuld."
                                taskCompletion={presenter.taskCompletionStatus}
                            />
                            <CoreTaskItem
                                task={CoreTaskType.CHOOSE_VALUATION_METHOD}
                                taskIndex={4}
                                itemIndex={2}
                                name="Waarderingsmethode kiezen"
                                description="De waarderingsmethoden die het best passen bij het object zijn gekozen."
                                checkboxTooltip='Deze taak wordt automatisch afgevinkt wanneer de vraag "De onderhavige waardering is mede gebaseerd op objectvergelijking" is ingevuld.'
                                taskCompletion={presenter.taskCompletionStatus}
                            />
                            <CoreTaskItem
                                task={CoreTaskType.APPLY_VALUATION_METHOD}
                                taskIndex={4}
                                itemIndex={3}
                                name="Waarderingsmethode toepassen"
                                description="De gekozen waarderingsmethoden zijn toegepast op het object."
                                checkboxTooltip="Deze taak wordt automatisch afgevinkt wanneer de gekozen referentievergelijkers en de waardering is ingevuld."
                                taskCompletion={presenter.taskCompletionStatus}
                            />
                            <CoreTaskItem
                                task={CoreTaskType.DETERMINE_VALUATION}
                                taskIndex={4}
                                itemIndex={4}
                                name="Waarde vaststellen"
                                description="De waarde van het object is vastgesteld en onderbouwd."
                                checkboxTooltip="Deze taak wordt automatisch afgevinkt wanneer de bijlage met de onderbouwing van de waardering hieronder is toegevoegd."
                                taskCompletion={presenter.taskCompletionStatus}
                            >
                                <CoreTaskFileDisplay
                                    type="file"
                                    name="Onderbouwing waardering"
                                    description={'Upload een onderbouwing van de ingevulde waardering.'}
                                    technicalReference={TechnicalReference.CORE_TASK_VALUATION_REPORT}
                                    data={presenter.coreTasksData}
                                    appraisal={props.appraisal}
                                    questionSet={props.questionSet}
                                    parentAnswerUuid={presenter.answer.uuid}
                                    pagePartsSet={props.pagePartsSet}
                                    renderingContext={props.renderingContext}
                                />
                            </CoreTaskItem>
                        </CoreTask>
                        <CoreTask
                            index={5}
                            name="Rapporteren"
                            taskCompletion={presenter.taskCompletionStatus}
                            subtasks={presenter.subtasks}
                        >
                            <CoreTaskItem
                                task={CoreTaskType.CREATE_REPORT}
                                taskIndex={5}
                                itemIndex={1}
                                name="Rapportage opstellen & onderbouwen"
                                description="De rapportage van de taxatie is compleet en onderbouwd."
                                checkboxTooltip={`Deze taak wordt automatisch afgevinkt wanneer de taxatie wordt ${
                                    props.appraisal.validationType === AppraisalValidationType.VALIDATED
                                        ? 'ingestuurd'
                                        : 'afgerond'
                                }.`}
                                taskCompletion={presenter.taskCompletionStatus}
                            >
                                {presenter.coreTasksData?.conceptReportUrl ? (
                                    <CoreTaskFileDisplay
                                        name="Conceptrapport"
                                        description={'Het concept-taxatierapport zoals is ingestuurd'}
                                        data={presenter.coreTasksData}
                                        attachment={{
                                            type: CoreTaskAttachmentType.URL,
                                            fileName: 'Conceptrapport',
                                            url: presenter.coreTasksData.conceptReportUrl,
                                            technicalReference: TechnicalReference.CORE_TASK_REPORT,
                                        }}
                                        appraisal={props.appraisal}
                                        questionSet={props.questionSet}
                                        parentAnswerUuid={presenter.answer.uuid}
                                        pagePartsSet={props.pagePartsSet}
                                        renderingContext={props.renderingContext}
                                    />
                                ) : (
                                    <>
                                        <CoreTaskFileDisplay
                                            name="Taxatierapport"
                                            description={
                                                'Het taxatierapport is beschikbaar zodra de taxatie is afgerond.'
                                            }
                                            technicalReference={TechnicalReference.CORE_TASK_REPORT}
                                            data={presenter.coreTasksData}
                                            appraisal={props.appraisal}
                                            questionSet={props.questionSet}
                                            parentAnswerUuid={presenter.answer.uuid}
                                            pagePartsSet={props.pagePartsSet}
                                            renderingContext={props.renderingContext}
                                        />
                                        {props.appraisal.validationType === AppraisalValidationType.VALIDATED && (
                                            <CoreTaskFileDisplay
                                                name="Taxatierapport met bijlagen"
                                                description={
                                                    'Het taxatierapport is beschikbaar zodra de taxatie is afgerond.'
                                                }
                                                technicalReference={TechnicalReference.CORE_TASK_REPORT_ATTACHMENTS}
                                                data={presenter.coreTasksData}
                                                appraisal={props.appraisal}
                                                questionSet={props.questionSet}
                                                parentAnswerUuid={presenter.answer.uuid}
                                                pagePartsSet={props.pagePartsSet}
                                                renderingContext={props.renderingContext}
                                            />
                                        )}
                                    </>
                                )}
                            </CoreTaskItem>
                            <CoreTaskItem
                                task={CoreTaskType.VALIDATE_REPORT}
                                taskIndex={5}
                                itemIndex={2}
                                name="Rapportage controleren & laten controleren"
                                description={`De rapportage is gecontroleerd door de taxateur en door ${
                                    props.appraisal.validationType === AppraisalValidationType.VALIDATED
                                        ? 'het validatie-instituut'
                                        : 'een controlerend taxateur d.m.v. een plausibiliteitsverklaring'
                                }.`}
                                checkboxTooltip="Deze taak wordt automatisch afgevinkt wanneer de taxatie afgerond is."
                                taskCompletion={presenter.taskCompletionStatus}
                            />
                            <CoreTaskItem
                                task={CoreTaskType.SEND_REPORT}
                                taskIndex={5}
                                itemIndex={3}
                                name="Rapportage vastleggen & presenteren"
                                description="Het taxatierapport is volledig, ondertekend, en verstuurd naar de opdrachtgever."
                                checkboxTooltip="Deze taak wordt automatisch afgevinkt wanneer de e-mail met het verzonden taxatierapport beschikbaar is."
                                taskCompletion={presenter.taskCompletionStatus}
                            >
                                <CoreTaskFileDisplay
                                    type="mail"
                                    name="Mail met taxatierapport voor opdrachtgever"
                                    description={
                                        'Zodra deze mail is verzonden, wordt deze hier getoond. Wanneer deze mail niet beschikbaar is, kan deze handmatig worden toegevoegd.'
                                    }
                                    technicalReference={TechnicalReference.CORE_TASK_REPORT_MAIL}
                                    data={presenter.coreTasksData}
                                    appraisal={props.appraisal}
                                    questionSet={props.questionSet}
                                    parentAnswerUuid={presenter.answer.uuid}
                                    pagePartsSet={props.pagePartsSet}
                                    renderingContext={props.renderingContext}
                                />
                            </CoreTaskItem>
                            <CoreTaskItem
                                task={CoreTaskType.HANDLE_REPORT_FINANCIAL}
                                taskIndex={5}
                                itemIndex={4}
                                name="Administratie en financiën rondom de rapportage afhandelen"
                                description="De financiële zaken rondom de taxatie zijn afgehandeld."
                                checkboxTooltip="Deze taak wordt automatisch afgevinkt wanneer de factuur beschikbaar is."
                                taskCompletion={presenter.taskCompletionStatus}
                            >
                                <CoreTaskFileDisplay
                                    name="Factuur opdrachtgever"
                                    description={
                                        'De factuur is beschikbaar zodra de taxatie is afgerond. Wanneer geen gebruik gemaakt wordt van facturatie via taXapi, dan kun je hier handmatig een factuur toevoegen.'
                                    }
                                    technicalReference={TechnicalReference.CORE_TASK_INVOICE}
                                    data={presenter.coreTasksData}
                                    appraisal={props.appraisal}
                                    questionSet={props.questionSet}
                                    parentAnswerUuid={presenter.answer.uuid}
                                    pagePartsSet={props.pagePartsSet}
                                    renderingContext={props.renderingContext}
                                />
                                <CoreTaskFileDisplay
                                    name="Communicatie"
                                    description={'Gecombineerde set van alle e-mails en communicatie voor deze taxatie'}
                                    technicalReference={TechnicalReference.CORE_TASK_EMAILS_SET}
                                    data={presenter.coreTasksData}
                                    appraisal={props.appraisal}
                                    questionSet={props.questionSet}
                                    parentAnswerUuid={presenter.answer.uuid}
                                    pagePartsSet={props.pagePartsSet}
                                    renderingContext={props.renderingContext}
                                />
                            </CoreTaskItem>
                            <CoreTaskItem
                                task={CoreTaskType.REPORT_AFTER_SALES}
                                taskIndex={5}
                                itemIndex={5}
                                name="Aftersales verzorgen"
                                description={
                                    <>
                                        De tevredenheid van de opdrachtgever is gecontroleerd, en dit resultaat is
                                        vastgelegd.{' '}
                                        {presenter.coreTaskPreferences?.afterSalesReportDisabled && (
                                            <>
                                                <br />
                                                Deze kerntaak is uitgeschakeld. Je kunt deze opnieuw inschakelen{' '}
                                                <a href="/account/me/message-templates" target="_blank" rel="noopener">
                                                    binnen je account.
                                                </a>
                                            </>
                                        )}
                                    </>
                                }
                                checkboxTooltip="Deze taak wordt automatisch afgevinkt wanneer de aftersales zijn gearchiveerd."
                                disabled={presenter.coreTaskPreferences?.afterSalesReportDisabled}
                                taskCompletion={presenter.taskCompletionStatus}
                            >
                                {!presenter.coreTaskPreferences?.afterSalesReportDisabled && (
                                    <CoreTaskFileDisplay
                                        name="Aftersales na afronden opdracht"
                                        description={
                                            <>
                                                Upload het resultaat van de aftersales.{' '}
                                                <a href="/account/me/message-templates" target="_blank" rel="noopener">
                                                    Binnen je account
                                                </a>{' '}
                                                is het mogelijk om een gepersonaliseerde e-mail te activeren voor het
                                                verzorgen van deze aftersales.
                                            </>
                                        }
                                        technicalReference={TechnicalReference.CORE_TASK_REPORT_AFTERSALES_MAIL}
                                        data={presenter.coreTasksData}
                                        appraisal={props.appraisal}
                                        questionSet={props.questionSet}
                                        parentAnswerUuid={presenter.answer.uuid}
                                        pagePartsSet={props.pagePartsSet}
                                        renderingContext={props.renderingContext}
                                    />
                                )}
                            </CoreTaskItem>
                        </CoreTask>
                        <CoreTask
                            index={6}
                            name="Archiveren"
                            taskCompletion={presenter.taskCompletionStatus}
                            subtasks={presenter.subtasks}
                        >
                            <CoreTaskItem
                                task={CoreTaskType.ARCHIVE}
                                taskIndex={6}
                                itemIndex={1}
                                name="Taxatieproces sluitend vastleggen"
                                description="Het taxatieproces en dossier zijn vastgelegd in het archief."
                                checkboxTooltip="Deze taak wordt automatisch afgevinkt wanneer het rapport is afgerond."
                                taskCompletion={presenter.taskCompletionStatus}
                            >
                                <CoreTaskFileDisplay
                                    name="Gearchiveerd taxatiedossier"
                                    description={
                                        'Het gearchiveerde taxatiedossier is beschikbaar zodra de taxatie is afgerond.'
                                    }
                                    data={presenter.coreTasksData}
                                    technicalReference={TechnicalReference.CORE_TASK_ARCHIVE}
                                    appraisal={props.appraisal}
                                    questionSet={props.questionSet}
                                    parentAnswerUuid={presenter.answer.uuid}
                                    pagePartsSet={props.pagePartsSet}
                                    renderingContext={props.renderingContext}
                                />
                            </CoreTaskItem>
                        </CoreTask>
                    </div>
                </Collapsible>
            </div>
        </div>
    );
});
