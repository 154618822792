import {GeneratedReportFormat, GeneratedReportType} from '../enum/generated_report';
import {TechnicalReference} from '../enum/technical_reference';

export interface CoreTasksData {
    attachments: CoreTaskAttachment[];
    conceptReportUrl: string | null;
}

export enum CoreTaskAttachmentType {
    GENERATOR = 'generator',
    FILE_REFERENCE = 'file_reference',
    URL = 'url',
}

export interface GeneratorCoreTaskAttachment {
    type: CoreTaskAttachmentType.GENERATOR;
    technicalReference: TechnicalReference;
    generatedReportType: GeneratedReportType;
    generatedReportFormat: GeneratedReportFormat;
}

export interface FileReferenceCoreTaskAttachment {
    type: CoreTaskAttachmentType.FILE_REFERENCE;
    technicalReference: TechnicalReference;
    fileReferenceId: number;
    url: string | null;
    fileName: string;
}

export interface UrlCoreTaskAttachment {
    type: CoreTaskAttachmentType.URL;
    technicalReference: TechnicalReference;
    url: string;
    fileName: string;
}

export type CoreTaskAttachment = GeneratorCoreTaskAttachment | FileReferenceCoreTaskAttachment | UrlCoreTaskAttachment;
