import {Appraisal} from '../models/appraisal';
import {AppraisalGoal} from '../enum/appraisal_goal';
import {AppraisalState} from '../enum/appraisal_state';
import {AppraisalValidationType} from '../enum/appraisal_validation_type';
import {AppraiseModel} from '../enum/appraise_model';
import {ObjectOwnershipType} from '../enum/object_ownership_type';
import {ObjectUsageType} from '../enum/object_usage_type';

export function createDummyAppraisal(): Appraisal {
    return {
        id: -1,
        questionSetId: -1,
        parentAppraisalId: null,
        appraiseModel: AppraiseModel.MODEL2021,
        isFrozen: false,
        createdAt: new Date(),
        valuationDate: null,
        status: AppraisalState.PROCESSING,
        appraisalGoal: AppraisalGoal.ACQUIRE_MORTAGE,
        validationType: AppraisalValidationType.VALIDATED,
        title: 'Dummy appraisal',
        address: '',
        postalCode: '',
        houseNumber: '',
        letter: '',
        city: '',
        country: '',
        latitude: null,
        longitude: null,
        dummy: true,
        hasController: true,
        controllerAppraiser: null,
        objectType: null,
        objectDisplayType: null,
        newConstruction: false,
        improvementsPlanned: true,
        recentlySold: false,
        appraiserIsPriorAppraiser: false,
        objectUsageType: {
            value: ObjectUsageType.IN_USE_WITH_OWNER,
            text: 'Alleen de eigenaar woont in de woning, eventueel samen met zijn gezin',
        },
        objectOwnershipType: {
            value: ObjectOwnershipType.BUYER,
            text: 'Een koper wil de woning kopen',
            other: null,
        },
        otherValueConcepts: false,
        otherValueConceptsNotes: null,
        isWoco: false,
        isAcceptedByClient: false,
        notes: '',
        cadastralPlotAddresses: [],
        cadastralDocuments: [],
        termsOfEngagement: {
            fileName: null,
            url: null,
        },
        precheckReport: null,
        isEditableAppraisal: true,
        recentlySoldAgreements: false,
        attachmentTeamInfo: null,
        reportNumber: null,
        invoiceFlow: null,
        activatedOptionalCosts: null,
    };
}
