import {downloadZip} from 'client-zip';
import * as Throttle from 'promise-parallel-throttle';
import {EMPTY, combineLatest} from 'rxjs';
import {map, mergeMap, switchMap, tap} from 'rxjs/operators';
import {action, computed, makeObservable, observable, runInAction} from 'mobx';

import {IteratorQuestionType, NormalQuestionType} from '../../../../../../enum/question_type';
import {TreeItem, findChildRecursiveByPredicate} from '../../../../../../../support/generic_tree';
import {UserSettings, UserSettingsInteractor} from '../../../../../../business/user_settings/user_settings_interactor';
import {sortByCreatedAt, sortByRank} from '../../../../../../../support/sort_answer';

import {Answer} from '../../../../../../models/answer';
import {AnswerController} from '../../../../../../business/answering/answer_controller';
import {Appraisal} from '../../../../../../models/appraisal';
import {AppraisalState} from '../../../../../../enum/appraisal_state';
import {CompositeSubscription} from '../../../../../../../support/composite_subscription';
import {PhotoSorting} from '../../../../../../../enums/photo_sorting';
import {Presenter} from '../../../../../../../support/presenter/presenter';
import {Question} from '../../../../../../models/question';
import {QuestionAnswerPair} from '../../../../../../../support/question_answer_tree';
import {QuestionEffectInteractor} from '../../../../../../business/conditions/question_effects_interactor';
import {QuestionSet} from '../../../../../../models/question_set';
import {UserInteractor} from '../../../../../../business/user_interactor';
import {groupPhotosByLabels} from '../../../../../../business/photo_sorting/photo_sorting_helper';
import {AppraisalValidationType} from '../../../../../../enum/appraisal_validation_type';
import {PagePartsSet} from '../../../../../../models/page_parts_set';
import {DEFAULT_SORT_ORDER, PhotoIteratorCustomizationType} from './photo_iterator_customizer_presenter';
import {getNewestAnswer} from '../../../../../../../support/get_newest_answer';
import {ImageType} from '../../../../../../components/image_viewer/image_viewer';
import {AttachmentUploadInteractor} from '../../../../../../business/attachments/attachment_upload_interactor';
import {BlobCacheInteractor} from '../../../../../../business/attachments/blob_cache_interactor';
import {FlashMessageBroadcaster, Type} from '../../../../../../business/flash_message_broadcaster';
import {getUniqueFileNames} from '../../../../../../../support/file_name';

export class PhotoIteratorPresenter implements Presenter {
    private otherLabel = 'overige';
    private labelSortingOrder?: string[];

    @observable public isPersistPending = false;
    @observable public isPersisting = false;

    @computed
    public get iterations(): string[] | undefined {
        if (this.photoIterations === undefined) {
            return undefined;
        }

        return this.photoIterations
            .filter((answer) => !answer.isDeleted)
            .map((answer) => answer.iteration)
            .filter((iteration: string | null) => iteration !== null)
            .reduce((p: string[], c: string | null) => {
                return c !== null && p.indexOf(c) === -1 ? [...p, c] : p;
            }, []);
    }

    @computed
    public get iteratorPhotoGroupQuestion() {
        //This doesnt really have a computed value, but this way the question will stay the same object
        //This prevent react from constantly rendering new grid items
        return {
            ...this.question,
            type: IteratorQuestionType.ITERATOR_PHOTO_GROUP,
        };
    }

    @computed
    public get deletedIterations(): string[] | undefined {
        if (this.photoIterations === undefined) {
            return undefined;
        }

        return this.photoIterations
            .filter((answer) => answer.isDeleted)
            .map((answer) => answer.iteration)
            .filter((iteration: string | null) => iteration !== null)
            .reduce((p: string[], c: string | null) => {
                return c !== null && p.indexOf(c) === -1 ? [...p, c] : p;
            }, []);
    }

    @computed
    public get viewerDetails(): ImageType[] {
        return (
            this.photos
                ?.filter(({iteration, photo}) => !iteration.isDeleted && photo?.file?.uncompressedUrl !== undefined)
                .map(({photo: answer}) => ({
                    url: answer.file?.url as string,
                    uncompressedUrl: answer.file?.uncompressedUrl as string,
                    answer: answer,
                })) ?? []
        );
    }

    @computed
    public get deletedViewerDetails(): ImageType[] {
        return (
            this.photos
                ?.filter(({iteration, photo}) => iteration.isDeleted && photo?.file?.uncompressedUrl !== undefined)
                .map(({photo: answer}) => ({
                    url: answer.file?.url as string,
                    uncompressedUrl: answer.file?.uncompressedUrl as string,
                    answer: answer,
                })) ?? []
        );
    }

    @computed
    public get hasAvailablePhotos() {
        return this.photos && this.photos.length > 0;
    }

    @observable.ref private photoIterations?: Answer[];
    @observable.ref private photos?: {iteration: Answer; photo: Answer}[];
    @observable private _isHidden = false;
    @observable.ref public settings: UserSettings | null = null;

    @computed
    public get loading() {
        return this.iterations === undefined;
    }

    @computed
    public get isHidden(): boolean {
        return (
            this._isHidden ||
            (this.question.isAppraiserOnly &&
                !this.userInteractor.isAppraiser() &&
                !this.userInteractor.isEmployee() &&
                !this.userInteractor.isJuniorAppraiser())
        );
    }

    @computed
    public get isDisabled(): boolean {
        if (!this.appraisal.isEditableAppraisal) {
            return true;
        }
        if (this.appraisal.validationType === AppraisalValidationType.NOT_VALIDATED) {
            return this.loading || (this.appraisal.status !== AppraisalState.PROCESSING && this.question.freezes);
        }
        return (
            this.loading ||
            (this.appraisal.status !== AppraisalState.PROCESSING && this.question.freezes) ||
            this.appraisal.status === AppraisalState.APPROVED ||
            this.appraisal.status === AppraisalState.CANCELED ||
            this.appraisal.status === AppraisalState.SUBMITTED_FOR_VALIDATION
        );
    }

    private subscriptions = new CompositeSubscription();

    private imageTimer: number | null = null;

    constructor(
        private fileTypes: string[],
        private question: Question,
        private appraisal: Appraisal,
        private parentAnswerUuid: string | undefined,
        private questionSet: QuestionSet,
        private pagePartsSet: PagePartsSet | null,
        private answerController: AnswerController,
        private questionEffectsInteractor: QuestionEffectInteractor,
        private userInteractor: UserInteractor,
        private userSettingsInteractor: UserSettingsInteractor,
        private attachmentUploadInteractor: AttachmentUploadInteractor,
        private blobCacheInteractor: BlobCacheInteractor,
        private flashMessageBroadcaster: FlashMessageBroadcaster
    ) {
        this._isHidden = this.questionEffectsInteractor.isHidden(this.question.uuid, this.parentAnswerUuid ?? null);
        makeObservable(this);
    }

    public mount(): void {
        this.subscriptions.add(
            this.answerController
                .answersForQuestionUuidAndParentAnswerUuidStream(this.question.uuid, this.parentAnswerUuid ?? null)
                .pipe(map((answers: Answer[]) => answers.sort(sortByCreatedAt).sort(sortByRank)))
                .subscribe((answers) => {
                    runInAction(() => {
                        this.photoIterations = answers;
                    });
                })
        );

        const photoQuestion = this.questionSet.findChildByPredicateRecursive(
            this.question,
            (question) => question.type === NormalQuestionType.PHOTO
        );

        this.subscriptions.add(
            this.answerController
                .answersForQuestionUuidAndParentAnswerUuidStream(this.question.uuid, this.parentAnswerUuid ?? null)
                .pipe(
                    map((answers: Answer[]) => answers.sort(sortByCreatedAt).sort(sortByRank)),
                    mergeMap((answers) => {
                        if (!photoQuestion) {
                            return EMPTY;
                        }

                        return combineLatest(
                            answers.map((a) =>
                                this.answerController
                                    .answersForQuestionUuidAndParentAnswerUuidInSameIterationOrNullStream(
                                        photoQuestion.uuid,
                                        a.uuid
                                    )
                                    .pipe(map((photoAnswers) => ({iteration: a, photoAnswers})))
                            )
                        );
                    }),
                    map((answers) =>
                        answers
                            .map(({iteration, photoAnswers}) => ({
                                iteration,
                                photo: getNewestAnswer(photoAnswers ?? []),
                            }))
                            .filter((data): data is {iteration: Answer; photo: Answer} => data.photo !== null)
                    )
                )
                .subscribe((answers) => {
                    runInAction(() => {
                        this.photos = answers;
                    });
                })
        );

        this.subscriptions.add(
            this.questionEffectsInteractor
                .isHiddenStream(this.question.uuid, this.parentAnswerUuid ?? null)
                .subscribe((isHidden) => {
                    runInAction(() => {
                        this._isHidden = isHidden;
                    });
                })
        );

        if (this.pagePartsSet) {
            this.subscriptions.add(
                this.pagePartsSet.customizations
                    .streamByQuestionUuid<PhotoIteratorCustomizationType>(this.question.uuid)
                    .subscribe((customization) => {
                        runInAction(() => {
                            this.labelSortingOrder = customization?.order ?? DEFAULT_SORT_ORDER;
                        });
                    })
            );
        }

        const labelQuestionsPath = this.questionSet.findChildrenPathByPredicateRecursive(
            this.question,
            (q) => q.type === NormalQuestionType.MC_SELECT
        );
        if (labelQuestionsPath) {
            const uuids = [this.question.uuid, ...labelQuestionsPath.map((q) => q.uuid)];
            this.subscriptions.add(
                this.userSettingsInteractor
                    .getSettingsStream()
                    .pipe(
                        tap((settings) => {
                            runInAction(() => {
                                this.settings = settings;
                            });
                        }),
                        switchMap((settings) => {
                            if (settings.photoSorting === PhotoSorting.OWN_SORTING) {
                                return EMPTY;
                            }

                            return this.answerController.answersForQuestionUuidsStream(uuids).pipe(
                                map((answers) => this.answerController.filterDeleted(answers)),
                                map((answers) => {
                                    const hiddenCache = {};
                                    return answers.filter(
                                        (answer) =>
                                            !this.questionEffectsInteractor.isHidden(
                                                answer.questionUuid,
                                                answer.parentUuid,
                                                hiddenCache
                                            )
                                    );
                                }),
                                map((answers) =>
                                    groupPhotosByLabels(
                                        this.labelSortingOrder ?? DEFAULT_SORT_ORDER,
                                        this.otherLabel,
                                        answers,
                                        this.questionSet,
                                        this.question
                                    )
                                )
                            );
                        })
                    )
                    .subscribe((labelsMap: Map<string, Array<TreeItem<QuestionAnswerPair>>>) => {
                        let rank = 1;
                        for (const [, photoTrees] of labelsMap.entries()) {
                            for (const photoTree of photoTrees) {
                                const photoIteratorItem = findChildRecursiveByPredicate(
                                    photoTree,
                                    (item) => item.question.type === IteratorQuestionType.PHOTO_ITERATOR
                                );
                                if (
                                    photoIteratorItem &&
                                    photoIteratorItem.item.answer &&
                                    photoIteratorItem.item.answer.rank !== rank
                                ) {
                                    this.answerController.onRankChange(photoIteratorItem.item.answer.uuid, rank);
                                    //We stop since this onRankChange will trigger a new emit of this whole subscription
                                    return;
                                }
                                rank++;
                            }
                        }
                    })
            );
        }
    }

    public unmount(): void {
        this.subscriptions.clear();
    }

    @action
    public onRanksOfIterationsChange(iterations: string[]) {
        const photos = this.photoIterations;
        if (photos === undefined) {
            return;
        }

        if (this.settings?.photoSorting !== PhotoSorting.OWN_SORTING) {
            this.userSettingsInteractor.setSettings({
                photoSorting: PhotoSorting.OWN_SORTING,
            });
        }

        iterations.forEach((iteration, index) => {
            const answer = photos.find((a) => a.iteration === iteration);
            const rank = index + 1;

            if (answer && answer.rank !== rank) {
                this.answerController.onRankChange(answer.uuid, rank);
            }
        });
    }

    public setSort = (sorting: PhotoSorting) => {
        this.userSettingsInteractor.setSettings({
            photoSorting: sorting,
        });
    };

    @action
    public debouncedImageChange(image: File, answer: Answer, cb: () => void) {
        if (this.imageTimer !== null) {
            clearTimeout(this.imageTimer);
        }

        this.isPersistPending = true;
        this.imageTimer = window.setTimeout(async () => {
            if (this.isPersisting === true) {
                return;
            }

            runInAction(() => {
                this.isPersisting = true;
            });

            try {
                await this.attachmentUploadInteractor.uploadForAnswer(answer.uuid, image, {
                    fileTypes: this.fileTypes,
                });
            } finally {
                runInAction(() => {
                    this.isPersisting = false;
                    this.isPersistPending = false;
                    cb();
                });
            }
        }, 2000);
    }

    public async downloadZip() {
        const photos = this.photos;
        if (photos) {
            const zipInputs = await Throttle.raw(
                photos.map(
                    (photo) =>
                        async (): Promise<{
                            input: Response | File;
                            name: string;
                        } | null> => {
                            if (photo.photo.file) {
                                const url = photo.photo.file?.uncompressedUrl;
                                if (url) {
                                    try {
                                        return {
                                            input: await fetch(url),
                                            name:
                                                photo.photo.file.compressedFilename ??
                                                photo.photo.file.originalFilename,
                                        };
                                    } catch (error) {
                                        console.warn(error);
                                        const compressedUrl = photo.photo.file?.url;
                                        if (compressedUrl) {
                                            return {
                                                input: await fetch(compressedUrl),
                                                name:
                                                    photo.photo.file.compressedFilename ??
                                                    photo.photo.file.originalFilename,
                                            };
                                        }
                                    }
                                }
                            } else {
                                const file = await this.blobCacheInteractor.find(photo.photo.uuid);
                                return file ? {input: file, name: file.name} : null;
                            }
                            return Promise.resolve(null);
                        }
                ),
                {
                    maxInProgress: 5,
                }
            );

            const filteredZipInputs = zipInputs.resolvedIndexes
                .map((index) => zipInputs.taskResults[index] ?? null)
                .filter(
                    (
                        resp
                    ): resp is {
                        input: Response | File;
                        name: string;
                    } => resp != null
                );

            if (zipInputs.amountRejected > 0 || filteredZipInputs.length < photos.length) {
                this.flashMessageBroadcaster.broadcast(
                    `Het is niet gelukt om ${
                        zipInputs.amountRejected + (photos.length - filteredZipInputs.length)
                    } fotos toe te voegen.`,
                    Type.Danger
                );
            }
            const zipBlob = await downloadZip(getUniqueFileNames(filteredZipInputs)).blob();

            let downloadName = `foto's ${this.appraisal.address} ${this.appraisal.houseNumber}`;
            if (this.appraisal.letter) {
                downloadName += ` ${this.appraisal.letter}`;
            }
            downloadName += `  ${this.appraisal.postalCode} ${this.appraisal.city} ${this.appraisal.valuationDate}.zip`;

            // make and click a temporary link to download the Blob
            const link = document.createElement('a');
            link.href = URL.createObjectURL(zipBlob);
            link.download = downloadName;
            link.click();
            link.remove();
            URL.revokeObjectURL(link.href);
        }
    }
}
