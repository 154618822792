import {buildQuestionAnswerTrees, QuestionAnswerPair} from '../../../../../../../support/question_answer_tree';
import {map, shareReplay} from 'rxjs/operators';

import {Answer} from '../../../../../../models/answer';
import {AnswerController} from '../../../../../../business/answering/answer_controller';
import {IteratorFilesAttachmentInteractor} from './iterator_files_attachment_interactor';
import {NormalQuestionType} from '../../../../../../enum/question_type';
import {Observable} from 'rxjs';
import {Question} from '../../../../../../models/question';
import {QuestionSet} from '../../../../../../models/question_set';
import {findChildrenAndSiblingsRecursiveByPredicate} from '../../../../../../../support/generic_tree';
import {findChildrenForQuestion} from '../../../../support/question_filtering';

export interface IteratorFilesAnswerData {
    iteration: Answer;
    fileTree: QuestionAnswerPair;
    mcTree?: QuestionAnswerPair;
    openTree: QuestionAnswerPair;
    checkTree?: QuestionAnswerPair;
}

export interface IteratorFilesAttachmentProvider {
    stream(): Observable<IteratorFilesAnswerData[]>;
    getIterationData(iteration: string): Observable<IteratorFilesAnswerData | null>;
}

export class DefaultIteratorFilesAttachmentProvider implements IteratorFilesAttachmentProvider {
    constructor(
        private question: Question,
        private questionSet: QuestionSet,
        private answerController: AnswerController,
        private iteratorFilesAttachmentInteractor: IteratorFilesAttachmentInteractor
    ) {}

    public getIterationData(iteration: string): Observable<IteratorFilesAnswerData | null> {
        return this.stream().pipe(
            map((items) => {
                return items.filter((item) => item.iteration.iteration === iteration)[0] ?? null;
            })
        );
    }

    private cachedStream: Observable<IteratorFilesAnswerData[]> | null = null;
    public stream(): Observable<IteratorFilesAnswerData[]> {
        if (this.cachedStream) {
            return this.cachedStream;
        }

        const questions = findChildrenForQuestion(this.question, this.questionSet);
        const questionUuids = [this.question.uuid, ...questions.map((q) => q.uuid)];

        this.cachedStream = this.answerController.answersForQuestionUuidsStream(questionUuids).pipe(
            map((answers) => this.answerController.filterDeleted(answers)),
            map((answers) => {
                const trees = buildQuestionAnswerTrees(this.questionSet, answers, this.question);

                const pairs: IteratorFilesAnswerData[] = [];
                for (const tree of trees) {
                    const filePair = findChildrenAndSiblingsRecursiveByPredicate(
                        tree,
                        (item) => item.question.type === NormalQuestionType.ATTACHMENT
                    )[0];
                    const mcPair = findChildrenAndSiblingsRecursiveByPredicate(
                        tree,
                        (item) =>
                            item.question.type === NormalQuestionType.MC_SELECT ||
                            item.question.type === NormalQuestionType.MC_SELECT_OPTIONAL
                    )[0];
                    const openPair = findChildrenAndSiblingsRecursiveByPredicate(
                        tree,
                        (item) => item.question.type === NormalQuestionType.OPEN_COMPACT
                    )[0];
                    const checkPair = findChildrenAndSiblingsRecursiveByPredicate(
                        tree,
                        (item) => item.question.type === NormalQuestionType.BOOLEAN
                    )[0];

                    if (tree.item.answer?.iteration) {
                        const pair = {
                            iteration: tree.item.answer,
                            fileTree: filePair.treeNode.item,
                            mcTree: mcPair?.treeNode.item as QuestionAnswerPair | undefined,
                            openTree: openPair.treeNode.item,
                            checkTree: checkPair?.treeNode.item as QuestionAnswerPair | undefined,
                        };

                        const updatedIteration = this.iteratorFilesAttachmentInteractor.updateStatus(pair);
                        if (updatedIteration) {
                            pair.iteration = updatedIteration;
                        }

                        pairs.push(pair);
                    }
                }
                return pairs;
            }),
            shareReplay(1)
        );

        return this.cachedStream;
    }
}
