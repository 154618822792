import * as React from 'react';
import {Appraisal} from '../../../appraising/models/appraisal';
import {observer} from 'mobx-react';
import {AppraisalImage, AppraisalObject} from './appraisal_object';
import {ImageViewer} from '../../../appraising/components/image_viewer/image_viewer';

interface OwnProps {
    isSelected: boolean;
    appraisalObject: AppraisalObject;

    appraisal: Appraisal;
    onClick: () => void;
    onClose: () => void;
    clickedAppraisalImageUrls: AppraisalImage[];
}

export const AppraisalObjectsMapDetails: React.FC<OwnProps> = observer(function AppraisalObjectsMapDetails(props) {
    return (
        <div className="container container-wide appraisal-object-map-details-container">
            <div className="row row-wide">
                <div className="col-6 offset-6 col-wide">
                    <div className="appraisal-object-map-details-container-inner">
                        <div className="appraisal-object-map-details">
                            <button
                                className="appraisal-object-map-details-close close ion-md-close"
                                onClick={() => props.onClose()}
                            />

                            <div className="appraisal-object-map-details-body">
                                {props.appraisalObject.frontUrl !== null &&
                                props.clickedAppraisalImageUrls.length === 0 ? (
                                    <div className="appraisal-object-map-details-img">
                                        <div className="appraisal-object-map-details-img-content">
                                            <img src={props.appraisalObject.frontUrl} />
                                        </div>
                                    </div>
                                ) : (
                                    <div className="appraisal-object-map-details-img">
                                        <div className="appraisal-object-map-details-img-content">
                                            <ImageViewer
                                                images={props.clickedAppraisalImageUrls.map((pair) => pair.imageUrl)}
                                            />
                                        </div>
                                    </div>
                                )}
                                <div className="row">
                                    <div className="col-12">
                                        <p>
                                            <strong>
                                                {props.appraisalObject.adres.straat}{' '}
                                                {props.appraisalObject.adres.huisnummer}
                                                {props.appraisalObject.adres.huisnummerToevoeging}
                                            </strong>
                                            <br />
                                            {props.appraisalObject.adres.postcode} {props.appraisalObject.adres.plaats}
                                        </p>
                                    </div>
                                    <div className="col-3">
                                        <dl>
                                            <dt>GBO wonen</dt>
                                            <dd>
                                                {props.appraisalObject.livingSurface
                                                    ? props.appraisalObject.livingSurface + 'm²'
                                                    : '-'}
                                            </dd>
                                        </dl>
                                    </div>

                                    <div className="col-3">
                                        <dl>
                                            <dt>Perceel</dt>
                                            <dd>
                                                {props.appraisalObject.parcelSurface
                                                    ? props.appraisalObject.parcelSurface + 'm²'
                                                    : '-'}
                                            </dd>
                                        </dl>
                                    </div>
                                    <div className="col-3">
                                        <dl>
                                            <dt>Objecttype</dt>
                                            <dd>{props.appraisalObject.objectType ?? '-'}</dd>
                                        </dl>
                                    </div>
                                    <div className="col-3">
                                        <dl>
                                            <dt>Bouwjaar</dt>
                                            <dd>{props.appraisalObject.buildYear ?? '-'}</dd>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});
