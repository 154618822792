import {ValidationMessage, ValidationMessageImportance, ValidationMessageType} from '../validation_message';

import {Answer} from '../../../models/answer';
import {AnswerController} from '../../answering/answer_controller';
import {AnswerValidator} from '../answer_validator';
import {Question} from '../../../models/question';
import {TechnicalReference} from '../../../enum/technical_reference';
import {QuestionSet} from '../../../models/question_set';
import {findChildRecursiveByPredicate} from '../../../../support/generic_tree';
import {buildQuestionAnswerTree} from '../../../../support/question_answer_tree';

export class GrondgebondenValidator implements AnswerValidator {
    constructor(private answerController: AnswerController, private questionSet: QuestionSet) {}

    public validate(question: Question, answer: Answer | null): ValidationMessage[] {
        if (question.technicalReference === TechnicalReference.CADASTRAL_GRONDGEBONDEN && answer !== null) {
            const checkedAnswer = answer;
            const answers = this.answerController.answersForQuestionUuid(question.uuid).filter((answer) => {
                return !answer.isDeleted;
            });

            if (answers.length === 0) {
                return [];
            }
            const answerMap: Map<number, object> = new Map();
            const duplicates = answers.map((answer, key) => {
                const answerTree = buildQuestionAnswerTree(this.questionSet, this.answerController.answers(), answer);
                const cadastralMunicipalAnswer = findChildRecursiveByPredicate(
                    answerTree,
                    (n) => n.question.technicalReference === TechnicalReference.CADASTRAL_MUNICIPAL
                )?.item.answer;
                const cadastralSectionAnswer = findChildRecursiveByPredicate(
                    answerTree,
                    (n) => n.question.technicalReference === TechnicalReference.CADASTRAL_SECTION
                )?.item.answer;

                const cadastralNumberAnswer = findChildRecursiveByPredicate(
                    answerTree,
                    (n) => n.question.technicalReference === TechnicalReference.CADASTRAL_NUMBER
                )?.item.answer;

                if (cadastralMunicipalAnswer && cadastralSectionAnswer && cadastralNumberAnswer) {
                    answerMap.set(key, {
                        cadastralMunicipal: cadastralMunicipalAnswer.contents,
                        cadastralSection: cadastralSectionAnswer.contents,
                        cadastralNumber: cadastralNumberAnswer.contents,
                    });
                }

                return [...new Set(this.findDuplicateKeys(answerMap))];
            });

            if (duplicates.length > 0) {
                return [
                    {
                        type: ValidationMessageType.TAXAPI,
                        importance: ValidationMessageImportance.ERROR,
                        question: question,
                        answer: checkedAnswer,
                        messages: [],
                        fallbackMessage: `Er zijn ${duplicates.length} dubbele grondgebonden percelen toegevoegd.`,
                        floorInfo: null,
                        errorType: null,
                    },
                ];
            } else {
                return [];
            }
        } else {
            return [];
        }
    }

    private findDuplicateKeys(map: Map<number, object>): number[] {
        const seenObjects = new Map();
        const duplicateKeys = [];

        for (const [key, obj] of map.entries()) {
            const jsonString = JSON.stringify(obj);

            if (seenObjects.has(jsonString)) {
                for (const [obj, key] of seenObjects.entries()) {
                    if (obj === jsonString) {
                        duplicateKeys.push(key);
                    }
                }
                duplicateKeys.push(key);
            } else {
                seenObjects.set(jsonString, key);
            }
        }

        return duplicateKeys;
    }
}
