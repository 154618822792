import {observer} from 'mobx-react';
import React from 'react';
import 'react-day-picker/style.css';
import {Loader} from '../appraising/appraise/ui/components/loader';
import {Modal} from '../appraising/appraise/ui/content/questions/components/modal';
import {formatDate} from '../appraising/appraise/ui/support/format_date';
import {usePresenter} from '../support/presenter/use_presenter';
import {AddresSearchModalPresenter} from './address_modal_presenter';
import {SearchAppraisal} from '../appraising/models/search_appraisal';
import {SimpleTooltip} from '../appraising/components/tooltip';
import {AppraisalState} from '../appraising/enum/appraisal_state';

export const AddressSearchModal = observer(function AddressSearchModal({
    onClose,
    addedAppraisals,
    spaceLeft,
}: {
    onClose: (appraisalsToAdd: SearchAppraisal[]) => void;
    addedAppraisals: SearchAppraisal[];
    spaceLeft: number;
}) {
    const presenter = usePresenter(
        (component) =>
            new AddresSearchModalPresenter(
                component.network.appraisalApi,
                component.business.flashMessageBroadcaster,
                spaceLeft,
                addedAppraisals
            ),
        [spaceLeft, addedAppraisals]
    );

    return (
        <Modal visible>
            <div className="modal-header">
                <h2>Taxaties zoeken</h2>
            </div>
            <div className="modal-body">
                <p>
                    Zoek de taxaties die je wilt toevoegen aan de back-up. Je kunt maximaal {presenter.spaceLeft}{' '}
                    taxaties toevoegen.
                </p>
                <div className="card-search">
                    <div className="form-group">
                        <input
                            type="text"
                            placeholder="Zoek op adres, postcode of plaats"
                            className="form-control"
                            onChange={(ev) => presenter.onSearchAppraisal(ev.target.value)}
                            onKeyDown={(ev) =>
                                ev.key === 'Enter' ? presenter.onSearchAppraisal(undefined, true) : null
                            }
                        />
                    </div>
                    <button className="btn-search" onClick={() => presenter.onSearchAppraisal(undefined, true)}>
                        <span className="ion-md-search"></span>
                    </button>
                </div>
                <ul
                    className="list-circles mt-2"
                    style={presenter.isAppraisalSearchPending ? {opacity: 0.6} : undefined}
                >
                    {presenter.isSearchingAppraisals ? (
                        <div className="empty-message mt-5">
                            <Loader />
                            <h3>Zoeken naar taxaties...</h3>
                        </div>
                    ) : presenter.searchAppraisals !== null && presenter.searchAppraisals.length > 0 ? (
                        presenter.searchAppraisals.map((appraisal) => (
                            <li className="list-item" key={appraisal.id}>
                                <span className="list-avatar">
                                    <img src={appraisal.streetviewUrl} />
                                </span>
                                <span className="list-title">
                                    <strong>
                                        {appraisal.address} {appraisal.houseNumber} {appraisal.letter}
                                    </strong>
                                    <span className="thin">
                                        - {appraisal.postalCode} {appraisal.city}
                                    </span>
                                    <span className="list-form-element">
                                        {presenter.isAlreadyAdded(appraisal.id) ? (
                                            <SimpleTooltip
                                                content="Deze taxatie is al toegevoegd aan de back-up."
                                                contentStyle={{
                                                    zIndex: 1200,
                                                }}
                                            >
                                                <input
                                                    type="checkbox"
                                                    name={`appraisal-value-${appraisal.id}`}
                                                    id={`appraisal-value-${appraisal.id}`}
                                                    checked
                                                    disabled
                                                />
                                                <label htmlFor={`appraisal-value-${appraisal.id}`}></label>
                                            </SimpleTooltip>
                                        ) : presenter.isFull &&
                                          !presenter.selectedAppraisals.some((a) => a.id === appraisal.id) ? (
                                            <SimpleTooltip
                                                content="Deze taxatie kan niet meer worden toegevoegd aan deze back-up."
                                                contentStyle={{
                                                    zIndex: 1200,
                                                }}
                                            >
                                                <input
                                                    type="checkbox"
                                                    name={`appraisal-value-${appraisal.id}`}
                                                    id={`appraisal-value-${appraisal.id}`}
                                                    checked={presenter.selectedAppraisals.some(
                                                        (a) => a.id === appraisal.id
                                                    )}
                                                    disabled
                                                />
                                                <label htmlFor={`appraisal-value-${appraisal.id}`}></label>
                                            </SimpleTooltip>
                                        ) : (
                                            <button className="tooltip-trigger">
                                                <input
                                                    type="checkbox"
                                                    name={`appraisal-value-${appraisal.id}`}
                                                    id={`appraisal-value-${appraisal.id}`}
                                                    checked={presenter.selectedAppraisals.some(
                                                        (a) => a.id === appraisal.id
                                                    )}
                                                    onClick={() => presenter.toggleAppraisal(appraisal)}
                                                />
                                                <label htmlFor={`appraisal-value-${appraisal.id}`}></label>
                                            </button>
                                        )}
                                    </span>
                                </span>
                                <small className="list-details">
                                    {appraisal.status === AppraisalState.APPROVED ? (
                                        <span className="status-label d-inline-block animated-label">Afgerond</span>
                                    ) : (
                                        <span className="status-label secondary-label d-inline-block">
                                            Niet afgerond
                                        </span>
                                    )}
                                    <span>Aangemaakt op {formatDate(appraisal.createdAt, 'dd-MM-yyyy')}</span>
                                </small>
                            </li>
                        ))
                    ) : (
                        <div className="empty-message mt-5">
                            <h3>
                                {presenter.searchAppraisals === null
                                    ? 'Voer een adres in om taxaties te zoeken'
                                    : 'Geen taxaties gevonden'}
                            </h3>
                            <span className="spacer-gradient"></span>
                        </div>
                    )}
                </ul>
            </div>
            <div className="modal-footer">
                <button className="btn btn-secondary ion-md-close" type="button" onClick={() => onClose([])}>
                    Sluiten
                </button>
                <button
                    className="btn btn-primary"
                    onClick={() => onClose(presenter.selectedAppraisals)}
                    disabled={presenter.selectedAppraisals.length === 0}
                >
                    {presenter.selectedAppraisals.length} taxatie{presenter.selectedAppraisals.length === 1 ? '' : 's'}{' '}
                    toevoegen
                </button>
            </div>
        </Modal>
    );
});
