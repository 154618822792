import * as React from 'react';

import {ActiveSetDefinition, SuggestedReferenceObjects} from '../reference_objects_question_presenter';

import {Appraisal} from '../../../../../../../../models/appraisal';
import {PagePart} from '../../../../../../../../models/page_part';
import {PagePartsSet} from '../../../../../../../../models/page_parts_set';
import {QuestionContainer} from '../../../../question_container';
import {QuestionSet} from '../../../../../../../../models/question_set';
import {QuestionType} from '../../../../../../../../enum/question_type';
import {ReferenceObjectData} from '../internal/create_reference_object_data';
import {RenderingContextType} from '../../../../../../../../enum/rendering_context_type';
import {SetType} from '../../../../../../../../models/reference_set/set_type';
import {TechnicalReference} from '../../../../../../../../enum/technical_reference';
import {ValidationError} from '../../../../../../../../models/validation_error';
import {ValidationMessageMap} from '../../../../../../../../business/validation/validation_message';
import {ValuationAnswersContainerPresenter} from './valuation_answers_container_presenter';
import {classNames} from '../../../../../../../../../support/classnames';
import {observer} from 'mobx-react';
import {
    ReferenceSubscriptions,
    ReferenceSubscriptionType,
} from '../../../../../../../../models/reference_subscriptions';
import {SubscriptionButtons} from './subscription_buttons';
import {usePresenter} from '../../../../../../../../../support/presenter/use_presenter';
import {SimpleTooltip} from '../../../../../../../../components/tooltip';
import {QuestionRenderingData} from '../../../../../../../../models/question_rendering_data';
import {ReferenceFilters, ReferenceFiltersData} from './reference_filters';

interface OwnProps {
    appraisal: Appraisal;
    questionSet: QuestionSet;
    validationErrors: ValidationError[];
    validationMessages: ValidationMessageMap;
    forceShowValidationMessages: boolean;
    disabled?: boolean;
    suggestedReferenceObjects: SuggestedReferenceObjects[];
    canAdd: boolean;
    onAdd: (objectData: ReferenceObjectData) => void;
    onSearch: () => void;
    hiddenQuestionTypes: QuestionType[];
    activeSetDefinition: ActiveSetDefinition;
    referenceSubscriptions: ReferenceSubscriptions | null;
    forceRequest: (type: ReferenceSubscriptionType) => void;
    onAddCustomReferenceSaleButtonClick: () => void;
    canAddSales: boolean;
    filters: ReferenceFiltersData;
    setFilters: (filters: ReferenceFiltersData) => void;

    pagePartsSet: PagePartsSet | null;
    activePagePart: PagePart | null;
    renderingContext: RenderingContextType;
    questionRenderingData: QuestionRenderingData | null;
}

export const ValuationAnswersContainer: React.FC<OwnProps> = observer(function ValuationAnswersContainer(props) {
    const presenter = usePresenter(
        (container) =>
            new ValuationAnswersContainerPresenter(
                props.questionSet,
                container.business.adaptedValueProvider(props.appraisal, props.questionSet)
            )
    );

    const isMarketValueSet =
        props.activeSetDefinition.setDefinition.groupTree.item.question.technicalReference ===
            TechnicalReference.VALUATION_MARKET_VALUE_GROUP ||
        props.activeSetDefinition.setDefinition.groupTree.item.question.technicalReference ===
            TechnicalReference.VALUATION_MARKET_VALUE_FORCED_SALE_GROUP;

    const isForcedSaleSet =
        props.activeSetDefinition.setDefinition.groupTree.item.question.technicalReference ===
            TechnicalReference.VALUATION_MARKET_VALUE_FORCED_SALE_GROUP ||
        props.activeSetDefinition.setDefinition.groupTree.item.question.technicalReference ===
            TechnicalReference.VALUATION_SPECIAL_VALUE_FORCED_SALE_GROUP;

    const isExecutorialSaleSet =
        props.activeSetDefinition.setDefinition.groupTree.item.question.technicalReference ===
        TechnicalReference.VALUATION_FORCED_SALE_GROUP;

    function renderForcedSaleFilters(activeSetDefinition: ActiveSetDefinition) {
        const specialValueData = presenter.specialValueData(activeSetDefinition);
        if (specialValueData === null) {
            return null;
        }
        const adaptedValuesMap = presenter.defaultAdaptedAnswers(props.activeSetDefinition);

        const {questions, iteration, parentAnswerUuid} = specialValueData;
        return (
            <>
                <div className="col-12">
                    {questions.map((question) => {
                        return (
                            <QuestionContainer
                                key={question.uuid + '[' + iteration + ']' + 'iteration'}
                                iteration={iteration !== null ? iteration : undefined}
                                appraisal={props.appraisal}
                                question={question}
                                questionSet={props.questionSet}
                                validationErrors={props.validationErrors}
                                validationMessages={props.validationMessages}
                                parentAnswerUuid={parentAnswerUuid}
                                disabled={props.disabled}
                                adaptedDefaultValues={adaptedValuesMap}
                                disableAdaptedValueContainer={true}
                                hiddenQuestionTypes={props.hiddenQuestionTypes}
                                pagePartsSet={props.pagePartsSet}
                                activePagePart={props.activePagePart}
                                renderingContext={props.renderingContext}
                                questionRenderingData={props.questionRenderingData}
                                forceShowValidationMessages={props.forceShowValidationMessages}
                            />
                        );
                    })}
                </div>
                <div className="col-12 pt-4">
                    <button className="btn btn-sm" onClick={() => props.onSearch()}>
                        Zoek referenties
                    </button>
                </div>
            </>
        );
    }

    function renderSet(activeSetDefinition: ActiveSetDefinition) {
        const setValueData = isMarketValueSet
            ? presenter.marketValueData(activeSetDefinition)
            : presenter.specialValueData(activeSetDefinition);

        if (setValueData === null) {
            return null;
        }

        const adaptedValuesMap = presenter.defaultAdaptedAnswers(props.activeSetDefinition);

        const {questions, iteration, parentAnswerUuid} = setValueData;
        return (
            <>
                <div className="col-12">
                    {questions.map((question) => {
                        return (
                            <QuestionContainer
                                key={
                                    question.uuid +
                                    '[' +
                                    iteration +
                                    ']' +
                                    'iteration' +
                                    '[' +
                                    parentAnswerUuid +
                                    ']' +
                                    'parent'
                                }
                                iteration={iteration !== null ? iteration : undefined}
                                appraisal={props.appraisal}
                                question={question}
                                questionSet={props.questionSet}
                                validationErrors={props.validationErrors}
                                validationMessages={props.validationMessages}
                                forceShowValidationMessages={props.forceShowValidationMessages}
                                parentAnswerUuid={parentAnswerUuid}
                                disabled={props.disabled}
                                adaptedDefaultValues={adaptedValuesMap}
                                disableAdaptedValueContainer={true}
                                hiddenQuestionTypes={props.hiddenQuestionTypes}
                                pagePartsSet={props.pagePartsSet}
                                activePagePart={props.activePagePart}
                                renderingContext={props.renderingContext}
                                questionRenderingData={props.questionRenderingData}
                            />
                        );
                    })}
                </div>
            </>
        );
    }

    function renderMutedFieldsForActiveSet(activeSetDefinition: ActiveSetDefinition) {
        return (
            <div className="col-12">
                <div>
                    <h3>Objectkenmerken</h3>
                </div>
                <div className="form-columns">
                    <div className="row">
                        {presenter.marketValuationQuestions(activeSetDefinition).map((q) => {
                            return (
                                <div key={q.question.uuid} className="col-12 col-md-6 ">
                                    <div className="form-group">
                                        <div className="label-container">
                                            <label>{q.question.contents}</label>
                                        </div>
                                        <SimpleTooltip
                                            content={q.question.contents + ' kun je hier niet aanpassen.'}
                                            placement="top"
                                        >
                                            <div className="input-group input-group-no-prepend" data-type="error">
                                                <input
                                                    type="text"
                                                    disabled
                                                    className="form-control disabled"
                                                    value={q.value ?? '-'}
                                                />
                                            </div>
                                        </SimpleTooltip>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }

    function handleOnObjectCopy(groups: Record<string, ReferenceObjectData[]>) {
        if (presenter.loading) {
            return;
        }

        if (presenter.selectedreferenceObjectDataIndexes) {
            presenter.setLoading(true);
            const [group, objectDataIndex] = presenter.selectedreferenceObjectDataIndexes.split('-');
            if (group && objectDataIndex) {
                const suggestedReferenceObjects = groups[group];

                if (!suggestedReferenceObjects) {
                    return;
                }

                if (objectDataIndex === 'all') {
                    for (const objectData of suggestedReferenceObjects) {
                        if (objectData) {
                            props.onAdd(objectData);
                        }
                    }
                } else {
                    const objectData = suggestedReferenceObjects[parseInt(objectDataIndex, 10)];
                    if (objectData) {
                        props.onAdd(objectData);
                    }
                }
            }
        }
        presenter.selectedreferenceObjectDataIndexes = null;
    }

    const renderMutedFields =
        !props.activeSetDefinition.setDefinition.isDemolishedBuilding &&
        (isMarketValueSet ||
            props.activeSetDefinition.setDefinition.groupTree.item.question.technicalReference ===
                TechnicalReference.VALUATION_SPECIAL_VALUE_FORCED_SALE_GROUP);

    const groups = presenter.filterGroups(props.suggestedReferenceObjects, props.activeSetDefinition);
    const shouldShowCopyButton = props.canAdd && Object.values(groups).some((a) => a.length > 0);
    return (
        <div
            className={classNames('row group-collapsible group-collapsible-light', {
                'group-collapsible-visible': !presenter.filtersCollapsed,
            })}
        >
            <div
                className={classNames('col-12', {
                    'col-md-5': shouldShowCopyButton,
                    'col-md-6': !shouldShowCopyButton,
                })}
            >
                <h2 className="reference-objects-nav-title">
                    <span className="badge badge-secondary float-left">
                        {props.activeSetDefinition.setDefinition.type === SetType.RENT
                            ? 'Huur'
                            : props.activeSetDefinition.setDefinition.type === SetType.SALE
                            ? 'Koop'
                            : 'Verkocht'}
                    </span>
                    <span>&nbsp;</span>
                    {isMarketValueSet
                        ? 'Marktwaarde'
                        : isForcedSaleSet
                        ? 'Gedwongen verkoop'
                        : isExecutorialSaleSet
                        ? 'Executiewaarde'
                        : 'Bijzonder uitgangspunt'}
                </h2>
                {!isMarketValueSet && !isForcedSaleSet && !isExecutorialSaleSet && (
                    <h3 className="reference-objects-nav-subtitle">
                        {props.activeSetDefinition.setDefinition.description}
                    </h3>
                )}
                {isMarketValueSet ? (
                    <h3 className="reference-objects-nav-subtitle">
                        {isForcedSaleSet ? 'Marktwaarde gedwongen verkoop' : 'Marktwaarde in huidige staat'}
                    </h3>
                ) : isForcedSaleSet ? (
                    <h3 className="reference-objects-nav-subtitle">Bijzonder uitgangspunt gedwongen verkoop</h3>
                ) : null}

                {!isExecutorialSaleSet && (
                    <a
                        className={classNames('group-collapsible-header', {
                            'ion-ios-arrow-down': presenter.filtersCollapsed,
                            'ion-ios-arrow-up': !presenter.filtersCollapsed,
                        })}
                        onClick={() => presenter.setFiltersCollapsed(!presenter.filtersCollapsed)}
                    >
                        Filters
                    </a>
                )}
            </div>
            <div
                className={classNames('col-12', {
                    'col-md-7': shouldShowCopyButton,
                    'col-md-6': !shouldShowCopyButton,
                })}
            >
                <div className="reference-objects-nav-btn-container">
                    {shouldShowCopyButton ? (
                        <div className="select-with-copy">
                            <select
                                name="set-select"
                                id="set-select"
                                className="form-control custom-select custom-select-sm"
                                disabled={props.disabled}
                                value={presenter.selectedreferenceObjectDataIndexes ?? 'disabled'}
                                onChange={(e) => {
                                    presenter.setSelectedreferenceObjectDataIndexes(e.target.value);
                                }}
                            >
                                <option value="disabled" disabled>
                                    Selecteer object
                                </option>
                                {Object.entries(groups).map(([group, referenceObjects], optGroupIndex) => {
                                    return (
                                        <optgroup key={group + '-' + optGroupIndex} label={group}>
                                            <option key={`${group}-all`} value={`${group}-all`}>
                                                Alle objecten
                                            </option>
                                            {referenceObjects.map((objectData, objectDataIndex) => {
                                                const adres = objectData.referenceObjectAnswer.referenceObject.adres;
                                                return (
                                                    <option
                                                        key={`${group}-${adres.huisnummerToevoeging}-${objectDataIndex}`}
                                                        value={`${group}-${objectDataIndex}`}
                                                    >
                                                        {adres.straat} {adres.huisnummer} {adres.huisnummerToevoeging}
                                                    </option>
                                                );
                                            })}
                                        </optgroup>
                                    );
                                })}
                            </select>
                            <button
                                className="btn btn-sm btn-append btn-secondary ion-md-copy"
                                disabled={props.disabled || presenter.selectedreferenceObjectDataIndexes === null}
                                onClick={() => handleOnObjectCopy(groups)}
                            >
                                Kopieër
                            </button>
                        </div>
                    ) : null}
                    <SubscriptionButtons
                        activeSetDefinition={props.activeSetDefinition}
                        canAddSales={props.canAddSales}
                        forceRequest={props.forceRequest}
                        onAddCustomReferenceSaleButtonClick={props.onAddCustomReferenceSaleButtonClick}
                        referenceSubscriptions={props.referenceSubscriptions}
                        appraisal={props.appraisal}
                        questionSet={props.questionSet}
                    />
                </div>
            </div>
            {!isExecutorialSaleSet && (
                <div className="col-12 reference-set-valuation-filters">
                    <div className="group-collapsible-children">
                        <div className="group-collapsible-inner">
                            <ReferenceFilters
                                filters={props.filters}
                                setFilters={props.setFilters}
                                activeSetDefinition={props.activeSetDefinition}
                            />
                        </div>
                    </div>
                </div>
            )}

            <div className="reference-set-valuation-questions-container">
                {renderMutedFields
                    ? renderMutedFieldsForActiveSet(props.activeSetDefinition)
                    : isExecutorialSaleSet
                    ? null
                    : renderSet(props.activeSetDefinition)}
            </div>
            <div className="reference-set-valuation-filters-container">
                {isExecutorialSaleSet && renderForcedSaleFilters(props.activeSetDefinition)}
            </div>
        </div>
    );
});
