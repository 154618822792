import {FileReference} from '../../models/file_reference';

export interface ApiFile {
    id: number;
    content_type: string;
    original_filename: string;
    compressed_filename?: string;
    compressed_content_type?: string;
    url: string;
    uncompressed_url: string;
    path?: string;
}

export function apiFileToFile(apiFile: ApiFile): FileReference {
    return {
        id: apiFile.id,
        contentType: apiFile.content_type,
        originalFilename: apiFile.original_filename,
        url: apiFile.url,
        uncompressedUrl: apiFile.uncompressed_url,
        compressedFilename: apiFile.compressed_filename,
        compressedContentType: apiFile.compressed_content_type,
        path: apiFile.path,
    };
}

export function fileToApiFile(file: FileReference): ApiFile {
    return {
        id: file.id,
        content_type: file.contentType,
        original_filename: file.originalFilename,
        compressed_content_type: file.compressedContentType,
        compressed_filename: file.compressedFilename,
        url: file.url,
        uncompressed_url: file.uncompressedUrl,
        path: file.path,
    };
}
