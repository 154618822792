import {computed, makeObservable, observable, runInAction} from 'mobx';

import {AnswerController} from '../../../../business/answering/answer_controller';
import {Appraisal} from '../../../../models/appraisal';
import {AppraisalProvider} from '../../../../business/appraisal_provider';
import {CompositeSubscription} from '../../../../../support/composite_subscription';
import {PlotAreaProvider} from '../../../../business/plot_area_provider';
import {Presenter} from '../../../../../support/presenter/presenter';
import {QuestionSet} from '../../../../models/question_set';
import {SurfaceAreaProvider} from '../../../../business/support/surface_area_provider';
import {TechnicalReference} from '../../../../enum/technical_reference';
import {getNewestAnswer} from '../../../../../support/get_newest_answer';
import {EnergyLabelProvider} from '../../../../business/energy_label_provider';
import {CoreTaskType} from '../../../../enum/core_tasks';
import {AppraisalState} from '../../../../enum/appraisal_state';
import {AppraisalApi} from '../../../../network/appraisal_api';
import {CoreTasksData} from '../../../../models/core_tasks';
import {Question} from '../../../../models/question';
import {Answer} from '../../../../models/answer';
import {switchMap} from 'rxjs';
import {
    ValidationConclusion,
    ValidationConclusionProvider,
} from '../../../../business/validation/validation_conclusion_provider';
import {AppraisalInvoiceFlow} from '../../../../enum/appraisal_invoice_flow';
import {
    ValidationMessageErrorType,
    ValidationMessageImportance,
} from '../../../../business/validation/validation_message';
import {isEmpty} from '../../../../../support/util';
import {NormalQuestionType} from '../../../../enum/question_type';
import {GlobalProvider} from '../../../../../business/global_provider';

export type TaskCompletionInfo = Map<CoreTaskType, boolean | {label: string; completed: boolean}[]>;

export function hasCompletedTask(info: TaskCompletionInfo, type: CoreTaskType): boolean {
    const taskInfo = info.get(type);
    if (taskInfo === undefined) {
        return false;
    }

    if (typeof taskInfo === 'boolean') {
        return taskInfo;
    }

    return taskInfo.every((task) => task.completed);
}
export class AppraisalCoreTasksWidgetPresenter implements Presenter {
    @observable.ref public buildYear: string | null = null;
    @observable.ref public energyLabel: string | null = null;
    @observable.ref public volume: string | null = null;
    @observable.ref public surfaceArea: number | null = null;
    @observable.ref public plotArea: number | null = null;
    @observable.ref public appraisal: Appraisal;
    @observable.ref public coreTasksData: CoreTasksData | null = null;
    @observable.ref public answer: Answer | null = null;

    @observable.ref private dependentAnwers: Map<TechnicalReference, Answer[]> = new Map();
    @observable.ref private coreTaskAnswersByTechnicalReference: Map<TechnicalReference, Answer> = new Map();
    @observable.ref private validationConclusion: ValidationConclusion | null = null;

    private ignoredRequiredCollectInfoQuestions: Set<string>;
    private subscriptions = new CompositeSubscription();

    private question: Question | null;

    public readonly subtasks = new Map<number, CoreTaskType[]>([
        [
            1,
            [
                CoreTaskType.APPRAISAL_ACQUIRE,
                CoreTaskType.INDEPENDENCE_CHECK,
                CoreTaskType.OBJECT_DETAILS_CHECK,
                CoreTaskType.DETERMINE_APPRAISAL_TYPE,
                CoreTaskType.DETERMINE_TERMS,
                CoreTaskType.SEND_TERMS,
                CoreTaskType.HANDLE_TERMS_FINANCIAL,
                CoreTaskType.TERMS_AFTER_SALES,
            ],
        ],
        [2, [CoreTaskType.COLLECT_INFO, CoreTaskType.CHECK_INFO, CoreTaskType.STORE_INFO]],
        [
            3,
            [
                CoreTaskType.USE_INFO,
                CoreTaskType.ANALYSE_MARKET,
                CoreTaskType.ANALYSE_OBJECT,
                CoreTaskType.CONCLUDE_ANALYSIS,
            ],
        ],
        [
            4,
            [
                CoreTaskType.DETERMINE_FIGURES,
                CoreTaskType.CHOOSE_VALUATION_METHOD,
                CoreTaskType.APPLY_VALUATION_METHOD,
                CoreTaskType.DETERMINE_VALUATION,
            ],
        ],
        [
            5,
            [
                CoreTaskType.CREATE_REPORT,
                CoreTaskType.VALIDATE_REPORT,
                CoreTaskType.SEND_REPORT,
                CoreTaskType.HANDLE_REPORT_FINANCIAL,
                CoreTaskType.REPORT_AFTER_SALES,
            ],
        ],
        [6, [CoreTaskType.ARCHIVE]],
    ]);

    constructor(
        private questionSet: QuestionSet,
        private answerController: AnswerController,
        private surfaceAreaProvider: SurfaceAreaProvider,
        private plotAreaProvider: PlotAreaProvider,
        private energyLabelProvider: EnergyLabelProvider,
        private appraisalProvider: AppraisalProvider,
        private appraisalApi: AppraisalApi,
        private validationConclusionProvider: ValidationConclusionProvider,
        private globalProvider: GlobalProvider
    ) {
        makeObservable(this);
        this.appraisal = appraisalProvider.appraisal;

        this.question = this.questionSet.findQuestionByTechnicalReference(TechnicalReference.CORE_TASK_ROOT_GROUP);
        this.ignoredRequiredCollectInfoQuestions = this.getIgnoredRequiredCollectInfoQuestions();

        if (this.globalProvider.global.coreTasksPreferences?.afterSalesTermsDisabled) {
            this.subtasks.set(1, this.subtasks.get(1)?.filter((task) => task !== CoreTaskType.TERMS_AFTER_SALES) ?? []);
        }

        if (this.globalProvider.global.coreTasksPreferences?.afterSalesReportDisabled) {
            this.subtasks.set(
                5,
                this.subtasks.get(5)?.filter((task) => task !== CoreTaskType.REPORT_AFTER_SALES) ?? []
            );
        }
    }

    public mount() {
        this.subscriptions.add(
            this.appraisalProvider.stream.subscribe((appraisal) => {
                runInAction(() => {
                    this.appraisal = appraisal;
                });
            })
        );

        this.subscriptions.add(
            this.validationConclusionProvider.stream.subscribe((validationConclusion) => {
                runInAction(() => {
                    this.validationConclusion = validationConclusion;
                });
            })
        );

        if (this.question) {
            this.subscriptions.add(
                this.answerController.answerByIdentifiersStream(this.question.uuid, null, null).subscribe((answer) => {
                    runInAction(() => {
                        this.answer = answer;
                    });
                })
            );

            this.subscriptions.add(
                this.answerController
                    .answerByIdentifiersStream(this.question.uuid, null, null)
                    .pipe(
                        switchMap((answer) => this.answerController.childrenAnswersForAnswerRecursivelyStream(answer))
                    )
                    .subscribe((answers) => {
                        runInAction(() => {
                            this.coreTaskAnswersByTechnicalReference = new Map(
                                answers
                                    .map((answer) => {
                                        const question = this.questionSet.findQuestionByUuid(answer.questionUuid);
                                        if (!question) {
                                            return [null, answer] as const;
                                        }
                                        return [question.technicalReference, answer] as const;
                                    })
                                    .filter((data): data is [TechnicalReference, Answer] => data[0] !== null)
                            );
                        });
                    })
            );
        }

        const dependentQuestions = this.getDependentQuestions();
        const dependentQuestionUuidsByTechnicalReference = new Map(
            Array.from(dependentQuestions.entries()).map(([technicalReference, questions]) => {
                return [technicalReference, new Set(questions.map((question) => question.uuid))] as const;
            })
        );
        this.subscriptions.add(
            this.answerController
                .answersForQuestionUuidsStream(
                    Array.from(dependentQuestions.values())
                        .flat()
                        .map((question) => question.uuid)
                )
                .subscribe((answers) => {
                    runInAction(() => {
                        this.dependentAnwers = new Map(
                            Array.from(dependentQuestionUuidsByTechnicalReference.entries()).map(
                                ([technicalReference, questionUuids]) => {
                                    return [
                                        technicalReference,
                                        answers.filter((answer) => questionUuids.has(answer.questionUuid)),
                                    ] as const;
                                }
                            )
                        );
                    });
                })
        );

        this.appraisalApi
            .getCoreTaskData(this.appraisal.id)
            .then((coreTasksData) => {
                runInAction(() => {
                    this.coreTasksData = coreTasksData;
                });
            })
            .catch((error) => {
                console.error(error);
            });

        const buildYearQuestion = this.questionSet.findQuestionByTechnicalReference(
            TechnicalReference.OBJECT_BUILD_YEAR
        );
        if (buildYearQuestion) {
            this.subscriptions.add(
                this.answerController.answersForQuestionUuidStream(buildYearQuestion.uuid).subscribe((answers) => {
                    runInAction(() => {
                        this.buildYear = getNewestAnswer(answers)?.contents ?? null;
                    });
                })
            );
        }

        this.subscriptions.add(
            this.energyLabelProvider.stream().subscribe((energyLabel) => {
                runInAction(() => {
                    this.energyLabel = energyLabel;
                });
            })
        );

        const volumeQuestion = this.questionSet.findQuestionByTechnicalReference(TechnicalReference.OBJECT_VOLUME);
        if (volumeQuestion) {
            this.subscriptions.add(
                this.answerController.answersForQuestionUuidStream(volumeQuestion.uuid).subscribe((answers) => {
                    runInAction(() => {
                        this.volume = getNewestAnswer(answers)?.contents ?? null;
                    });
                })
            );
        }

        this.subscriptions.add(
            this.surfaceAreaProvider.surfaceArea().subscribe((sum) => {
                runInAction(() => {
                    this.surfaceArea = sum;
                });
            })
        );

        this.subscriptions.add(
            this.plotAreaProvider.plotArea().subscribe((plotArea) => {
                runInAction(() => {
                    this.plotArea = plotArea;
                });
            })
        );

        const timeout = setTimeout(() => {
            if (this.validationConclusion === null) {
                this.validationConclusionProvider.refreshShallow().catch((err) => console.warn(err));
            }
        }, 50);
        this.subscriptions.add(() => clearTimeout(timeout));
    }

    public unmount() {
        this.subscriptions.clear();
    }

    @computed
    public get coreTaskPreferences() {
        return this.globalProvider.global.coreTasksPreferences;
    }

    @computed
    public get completedMainTaskCount(): number {
        return Array.from(this.subtasks.values()).filter((subtasks) =>
            subtasks.every((task) => hasCompletedTask(this.taskCompletionStatus, task))
        ).length;
    }

    @computed
    public get taskCompletionStatus(): TaskCompletionInfo {
        const completionInfo: TaskCompletionInfo = new Map();

        // 1. Contracteren

        // When we are in the appraising environment, anything that would be filled in in terms of engagement is already complete
        completionInfo.set(CoreTaskType.APPRAISAL_ACQUIRE, true);
        completionInfo.set(CoreTaskType.OBJECT_DETAILS_CHECK, true);

        completionInfo.set(
            CoreTaskType.INDEPENDENCE_CHECK,
            this.hasAttachment(TechnicalReference.CORE_TASK_INDEPENDENCE_REPORT)
        );

        completionInfo.set(
            CoreTaskType.DETERMINE_APPRAISAL_TYPE,
            this.hasAttachment(TechnicalReference.CORE_TASK_APPOINTMENT_MAIL)
        );

        completionInfo.set(CoreTaskType.DETERMINE_TERMS, this.hasAttachment(TechnicalReference.CORE_TASK_TERMS));

        completionInfo.set(
            CoreTaskType.SEND_TERMS,
            this.hasAttachment(TechnicalReference.CORE_TASK_TERMS_ACCEPTED_MAIL)
        );

        let hasHandledTermsFinancial = this.hasAttachment(TechnicalReference.CORE_TASK_TERMS);
        if (this.appraisal.invoiceFlow === AppraisalInvoiceFlow.ACCEPTANCE) {
            hasHandledTermsFinancial =
                hasHandledTermsFinancial && this.hasAttachment(TechnicalReference.CORE_TASK_INVOICE);
        }
        completionInfo.set(CoreTaskType.HANDLE_TERMS_FINANCIAL, hasHandledTermsFinancial);

        completionInfo.set(
            CoreTaskType.TERMS_AFTER_SALES,
            this.hasAttachment(TechnicalReference.CORE_TASK_TERMS_AFTERSALES_MAIL)
        );

        // 2. Rechercheren

        const hasCollectedInfo = !this.validationMessages.some((message) => {
            if (message.errorType !== ValidationMessageErrorType.MISSING_REQUIRED_ANSWER) {
                return false;
            }

            const questionUuid = message.question?.uuid ?? message.answer?.questionUuid;
            if (questionUuid === undefined) {
                return true;
            }

            return !this.ignoredRequiredCollectInfoQuestions.has(questionUuid);
        });
        completionInfo.set(CoreTaskType.COLLECT_INFO, hasCollectedInfo);

        const checkedInfoErrors = this.validationMessages.filter(
            (message) => message.errorType === ValidationMessageErrorType.NOT_VISITED
        );
        if (checkedInfoErrors.length === 0) {
            completionInfo.set(CoreTaskType.CHECK_INFO, true);
        } else {
            completionInfo.set(
                CoreTaskType.CHECK_INFO,
                checkedInfoErrors.map((error) => ({label: error.fallbackMessage ?? '', completed: false}))
            );
        }

        completionInfo.set(CoreTaskType.STORE_INFO, [
            {
                label: 'Taak 2.1 is afgerond',
                completed: hasCompletedTask(completionInfo, CoreTaskType.COLLECT_INFO),
            },
            {
                label: 'Taak 2.2 is afgerond',
                completed: hasCompletedTask(completionInfo, CoreTaskType.CHECK_INFO),
            },
        ]);

        // 3. Analyseren
        completionInfo.set(CoreTaskType.USE_INFO, [
            {
                label: 'Bronnen voor verontreining zijn ingevuld',
                completed: this.hasFilledAtLeastOneDependentAnswer(TechnicalReference.POLLUTION_SOURCE),
            },
            {
                label: 'Bronnen voor fundering zijn ingevuld',
                completed: this.hasFilledAtLeastOneDependentAnswer(TechnicalReference.FOUNDATION_SOURCE),
            },
            {
                label: 'Bronnen voor bewoning zijn ingevuld',
                completed: this.hasFilledAtLeastOneDependentAnswer(TechnicalReference.OCCUPANCY_SOURCE),
            },
        ]);

        completionInfo.set(CoreTaskType.ANALYSE_MARKET, [
            {
                label: 'SWOT: Marktanalyse is ingevuld',
                completed: this.hasFilledAtLeastOneDependentAnswer(TechnicalReference.SWOT_MARKET),
            },
            {
                label: 'SWOT: Sterkte is ingevuld',
                completed: this.hasFilledAtLeastOneDependentAnswer(TechnicalReference.SWOT_STRENGTH),
            },
            {
                label: 'SWOT: Zwakte is ingevuld',
                completed: this.hasFilledAtLeastOneDependentAnswer(TechnicalReference.SWOT_WEAKNESS),
            },
            {
                label: 'SWOT: Kansen zijn ingevuld',
                completed: this.hasFilledAtLeastOneDependentAnswer(TechnicalReference.SWOT_OPPORTUNITY),
            },
            {
                label: 'SWOT: Bedreigingen is ingevuld',
                completed: this.hasFilledAtLeastOneDependentAnswer(TechnicalReference.SWOT_THREAT),
            },
            {
                label: 'Objectanalyse-rapport is toegevoegd',
                completed: this.hasAttachment(TechnicalReference.CORE_TASK_TRANSACTION_ANALYSIS_REPORT),
            },
        ]);

        const hasFilledFloors = !this.validationMessages.some(
            (message) => message.errorType === ValidationMessageErrorType.MISSING_FLOOR
        );
        const hasSelectedReferences = !this.validationMessages.some(
            (message) => message.errorType === ValidationMessageErrorType.REFERENCES_INVALID
        );

        completionInfo.set(CoreTaskType.ANALYSE_OBJECT, [
            {
                label: 'Woonlagen zijn toegevoegd',
                completed: hasFilledFloors,
            },
            {
                label: 'Referenties zijn geselecteerd',
                completed: hasSelectedReferences,
            },
        ]);

        completionInfo.set(CoreTaskType.CONCLUDE_ANALYSIS, [
            {
                label: 'Taak 3.1 is afgerond',
                completed: hasCompletedTask(completionInfo, CoreTaskType.USE_INFO),
            },
            {
                label: 'Taak 3.2 is afgerond',
                completed: hasCompletedTask(completionInfo, CoreTaskType.ANALYSE_MARKET),
            },
            {
                label: 'Taak 3.3 is afgerond',
                completed: hasCompletedTask(completionInfo, CoreTaskType.ANALYSE_OBJECT),
            },
        ]);

        // 4. Waarderen
        completionInfo.set(CoreTaskType.DETERMINE_FIGURES, [
            {
                label: 'Bouwjaar is ingevuld',
                completed: this.hasFilledAtLeastOneDependentAnswer(TechnicalReference.OBJECT_BUILD_YEAR),
            },
            {
                label: 'Gebruiksoppervlakte is ingevuld',
                completed: this.surfaceArea !== null,
            },
            {
                label: 'Waardepeildatum is ingevuld',
                completed: this.appraisal.valuationDate !== null,
            },
            {
                label: 'Objecttype is ingevuld',
                completed: this.appraisal.objectType !== null,
            },
        ]);

        completionInfo.set(
            CoreTaskType.CHOOSE_VALUATION_METHOD,
            this.hasFilledAtLeastOneDependentAnswer(TechnicalReference.VALUATION_METHOD_COMPARE)
        );

        completionInfo.set(
            CoreTaskType.APPLY_VALUATION_METHOD,
            this.hasFilledAtLeastOneDependentAnswer(TechnicalReference.VALUATION) && hasSelectedReferences
        );

        completionInfo.set(
            CoreTaskType.DETERMINE_VALUATION,
            this.hasAttachment(TechnicalReference.CORE_TASK_VALUATION_REPORT)
        );

        // 5. Rapporteren

        completionInfo.set(
            CoreTaskType.CREATE_REPORT,
            [AppraisalState.SUBMITTED_FOR_VALIDATION, AppraisalState.APPROVED].includes(this.appraisal.status)
        );

        completionInfo.set(CoreTaskType.VALIDATE_REPORT, this.appraisal.status === AppraisalState.APPROVED);

        completionInfo.set(CoreTaskType.SEND_REPORT, this.hasAttachment(TechnicalReference.CORE_TASK_REPORT_MAIL));

        completionInfo.set(
            CoreTaskType.HANDLE_REPORT_FINANCIAL,
            this.hasAttachment(TechnicalReference.CORE_TASK_INVOICE)
        );

        completionInfo.set(
            CoreTaskType.REPORT_AFTER_SALES,
            this.hasAttachment(TechnicalReference.CORE_TASK_REPORT_AFTERSALES_MAIL)
        );

        // 6. Archiveren

        completionInfo.set(
            CoreTaskType.ARCHIVE,
            [AppraisalState.SUBMITTED_FOR_VALIDATION, AppraisalState.APPROVED].includes(this.appraisal.status) &&
                this.hasAttachment(TechnicalReference.CORE_TASK_ARCHIVE)
        );

        return completionInfo;
    }

    @computed
    private get validationMessages() {
        return (
            this.validationConclusion?.validationMap.flatMap((validationMap) => {
                return [
                    ...validationMap[ValidationMessageImportance.ERROR],
                    ...validationMap[ValidationMessageImportance.WARNING],
                    ...validationMap[ValidationMessageImportance.INFO],
                ];
            }) ?? []
        );
    }

    public hasAttachment(technicalReference: TechnicalReference): boolean {
        const answer = this.coreTaskAnswersByTechnicalReference.get(technicalReference);
        if (answer !== undefined && answer.file !== null) {
            return true;
        }

        return this.coreTasksData?.attachments.some((a) => a.technicalReference === technicalReference) ?? false;
    }

    private hasFilledAtLeastOneDependentAnswer(technicalReference: TechnicalReference): boolean {
        const answers = this.dependentAnwers.get(technicalReference);
        if (answers === undefined) {
            return false;
        }

        return answers.some((answer) => !isEmpty(answer.contents) || answer.answerOptionId !== null);
    }

    private hasFilledAllDependentAnswers(technicalReference: TechnicalReference): boolean {
        const answers = this.dependentAnwers.get(technicalReference);
        if (answers === undefined) {
            return false;
        }

        return answers.every((answer) => !isEmpty(answer.contents) || answer.answerOptionId !== null);
    }

    private getDependentQuestions(): Map<TechnicalReference, Question[]> {
        const technicalReferences = [
            TechnicalReference.VALUATION,
            TechnicalReference.VALUATION_METHOD_COMPARE,
            TechnicalReference.POLLUTION_SOURCE,
            TechnicalReference.FOUNDATION_SOURCE,
            TechnicalReference.OCCUPANCY_SOURCE,
            TechnicalReference.SWOT_MARKET,
            TechnicalReference.SWOT_STRENGTH,
            TechnicalReference.SWOT_WEAKNESS,
            TechnicalReference.SWOT_OPPORTUNITY,
            TechnicalReference.SWOT_THREAT,
            TechnicalReference.OBJECT_BUILD_YEAR,
        ];

        return new Map(
            technicalReferences.map((technicalReference) => {
                let questions = this.questionSet.findQuestionsByTechnicalReference(technicalReference);
                for (const question of questions) {
                    if (
                        question.type === NormalQuestionType.MULTIPLE_BOOLEAN_GROUP ||
                        question.type === NormalQuestionType.CHECKLIST
                    ) {
                        questions = questions.concat(this.questionSet.findChildQuestionsByParentUuid(question.uuid));
                    }
                }
                return [technicalReference, questions] as const;
            })
        );
    }

    private getIgnoredRequiredCollectInfoQuestions() {
        const technicalReferences = [TechnicalReference.VALUATION_GROUP, TechnicalReference.VALUATION_METHOD_COMPARE];

        return new Set(
            technicalReferences
                .flatMap((technicalReference) => this.questionSet.findQuestionsByTechnicalReference(technicalReference))
                .flatMap((question) => this.questionSet.flattenChildrenRecursively(question.uuid, () => true))
                .map((q) => q.uuid)
        );
    }
}
