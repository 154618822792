import React from 'react';
import {AppraisalCostLine, AppraisalCostType} from './appraisal_costs';
import {PreferencesCheckbox} from './preferences_checkbox';
import {SimpleTooltip} from '../../appraising/components/tooltip';
import {observer} from 'mobx-react';

export const CostLineChoice = observer(function CostLineChoice({
    costLine,
    activeOptionalCosts,
    forcedOptionalCosts,
    disabled,
    toggle,
    indent,
}: {
    costLine: AppraisalCostLine;
    activeOptionalCosts: Set<AppraisalCostType>;
    forcedOptionalCosts: Map<AppraisalCostType, unknown>;
    disabled: boolean;
    toggle: (costType: AppraisalCostType) => void;
    indent?: number;
}) {
    return (
        <>
            <PreferencesCheckbox
                key={costLine.costType}
                id={costLine.costType}
                label={costLine.shortName}
                checked={activeOptionalCosts.has(costLine.costType)}
                onChange={() => toggle(costLine.costType)}
                disabled={disabled}
                style={{marginLeft: (indent ?? 0) * 25}}
                forceActivated={forcedOptionalCosts.has(costLine.costType)}
                extra={
                    costLine.tooltip ? (
                        <SimpleTooltip content={costLine.tooltip} placement="top">
                            <a className="btn-text-link ion-ios-help-circle ml-2">&nbsp;</a>
                        </SimpleTooltip>
                    ) : null
                }
            />
            {costLine.sublines?.map((subline, i) => (
                <CostLineChoice
                    key={`${costLine.costType}-${i}`}
                    costLine={subline}
                    activeOptionalCosts={activeOptionalCosts}
                    forcedOptionalCosts={forcedOptionalCosts}
                    disabled={disabled}
                    toggle={toggle}
                    indent={(indent ?? 0) + 1}
                />
            ))}
        </>
    );
});
