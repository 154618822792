import {buildQuestionAnswerTree, QuestionAnswerPair} from '../../../../../../../../../../support/question_answer_tree';
import {
    findChildrenRecursiveByPredicate,
    findParentByPredicateRecursive,
    TreeItem,
} from '../../../../../../../../../../support/generic_tree';
import {debounceTime, map, shareReplay} from 'rxjs/operators';

import {AnswerController} from '../../../../../../../../../business/answering/answer_controller';
import {NormalQuestionType} from '../../../../../../../../../enum/question_type';
import {EMPTY, Observable} from 'rxjs';
import {Question} from '../../../../../../../../../models/question';
import {QuestionEffectInteractor} from '../../../../../../../../../business/conditions/question_effects_interactor';
import {QuestionSet} from '../../../../../../../../../models/question_set';
import {SetType} from '../../../../../../../../../models/reference_set/set_type';
import {TechnicalReference} from '../../../../../../../../../enum/technical_reference';
import {formatMoney} from '../../../../../../../support/format_money';
import {Appraisal} from '../../../../../../../../../models/appraisal';
import {AppraiseModel, isAppraiseModel} from '../../../../../../../../../enum/appraise_model';

export interface V3ValuationSeedData {
    technicalReference: TechnicalReference;
    iteration: string | null;
}

export interface V3SetDefinition<T = TreeItem<QuestionAnswerPair>> {
    valuationType: string;
    valuationSeedData: V3ValuationSeedData;
    title: string;
    valuation: number;
    type: SetType;
    groupTree: T;
    name: string | null;
    description: string | null;
    isDemolishedBuilding: boolean;
}

export interface SetDefinitionsData {
    tree: TreeItem<QuestionAnswerPair>;
    setDefinitions: Array<V3SetDefinition<TreeItem<QuestionAnswerPair>>>;
}

export interface V3SetDefinitionsProvider {
    setDefinitions(): Observable<Array<V3SetDefinition<TreeItem<QuestionAnswerPair>>> | null>;
    getSetDefinitions(valuationGroupQuestion: Question, ignoreValuationTypeNone: boolean): SetDefinitionsData | null;
}

export const v3ValuationGroupTechnicalReferences = [
    TechnicalReference.VALUATION_MARKET_VALUE_GROUP,
    TechnicalReference.VALUATION_MARKET_VALUE_AFTER_CONSTRUCTION_GROUP,
    TechnicalReference.VALUATION_MARKET_VALUE_AFTER_REALISATION_GROUP,
    TechnicalReference.VALUATION_MARKET_VALUE_FORCED_SALE_GROUP,
    TechnicalReference.VALUATION_SPECIAL_VALUE_GROUP,
    TechnicalReference.VALUATION_SPECIAL_VALUE_AFTER_CONSTRUCTION_GROUP,
    TechnicalReference.VALUATION_SPECIAL_VALUE_AFTER_REALISATION_GROUP,
    TechnicalReference.VALUATION_SPECIAL_VALUE_FORCED_SALE_GROUP,
    TechnicalReference.VALUATION_FORCED_SALE_GROUP,
];

export class DefaultV3SetDefinitionsProvider implements V3SetDefinitionsProvider {
    constructor(
        private questionSet: QuestionSet,
        private answerController: AnswerController,
        private questionEffectInteractor: QuestionEffectInteractor,
        private appraisal: Appraisal
    ) {}

    private collectQuestionUuids(valuationGroupQuestion: Question): string[] {
        const allChildren = this.questionSet.flattenChildrenRecursively(valuationGroupQuestion.uuid);
        const symlinks = this.questionSet.findQuestionsByTechnicalReference(TechnicalReference.SYMLINK_REFERENTIES);
        const symlinkChildren = symlinks
            .filter((q) => q.type !== NormalQuestionType.SYMLINK_LINK)
            .map((q) => this.questionSet.flattenChildrenRecursively(q.uuid))
            .flat();

        return [
            valuationGroupQuestion.uuid,
            ...allChildren.map((child) => child.uuid),
            ...symlinks.map((child) => child.uuid),
            ...symlinkChildren.map((child) => child.uuid),
        ];
    }

    private collectValuationSeedData(valuationGroup: TreeItem<QuestionAnswerPair>): V3ValuationSeedData | null {
        if (valuationGroup.item.question.technicalReference === null) {
            return null;
        }

        const data: V3ValuationSeedData = {
            technicalReference: valuationGroup.item.question.technicalReference,
            iteration: null,
        };

        const specialValuationIteratorParent = findParentByPredicateRecursive(
            valuationGroup,
            (item) => item.question.technicalReference === TechnicalReference.SPECIAL_VALUE_ARGUMENT_ITERATOR
        );
        if (specialValuationIteratorParent) {
            data.iteration = specialValuationIteratorParent.item.answer?.iteration ?? null;
        }

        const isSpecialValuation =
            data.technicalReference === TechnicalReference.VALUATION_SPECIAL_VALUE_GROUP ||
            data.technicalReference === TechnicalReference.VALUATION_SPECIAL_VALUE_AFTER_CONSTRUCTION_GROUP ||
            data.technicalReference === TechnicalReference.VALUATION_SPECIAL_VALUE_AFTER_REALISATION_GROUP ||
            data.technicalReference === TechnicalReference.VALUATION_SPECIAL_VALUE_FORCED_SALE_GROUP;

        const isForcedSale =
            data.technicalReference === TechnicalReference.VALUATION_SPECIAL_VALUE_FORCED_SALE_GROUP ||
            data.technicalReference === TechnicalReference.VALUATION_FORCED_SALE_GROUP;

        // Forced sale valuation has only one special valuation
        if (isSpecialValuation && data.iteration === null && isForcedSale === false) {
            if (process.env.NODE_ENV === 'development') {
                console.error('Valuation inside an special value should have an iteration set.');
            }
            return null;
        }

        return data;
    }

    private createValuationType(valuationSeedData: V3ValuationSeedData): string {
        let seed = String(valuationSeedData.technicalReference);

        if (valuationSeedData.iteration !== null) {
            seed += '|' + valuationSeedData.iteration;
        }

        return window.btoa(seed);
    }

    public findValidSetDefinitions(
        tree: TreeItem<QuestionAnswerPair>,
        ignoreValuationTypeNone: boolean
    ): V3SetDefinition[] {
        const valuationGroups = findChildrenRecursiveByPredicate(
            tree,
            (i) => v3ValuationGroupTechnicalReferences.some((tr) => tr === i.question.technicalReference),
            true
        ).filter((group) => group.item.answer !== null);

        const setDefinitions: V3SetDefinition[] = [];
        for (const valuationGroup of valuationGroups) {
            const valuationSeedData = this.collectValuationSeedData(valuationGroup);
            if (valuationSeedData !== null) {
                const valuationType = this.createValuationType(valuationSeedData);

                const valuation = valuationGroup.children.find(
                    (child) =>
                        child.item.question.technicalReference === TechnicalReference.VALUATION ||
                        child.item.question.technicalReference === TechnicalReference.SPECIAL_VALUE_ARGUMENT_PRICE
                );

                const dropdown = valuationGroup.children.find(
                    (child) =>
                        child.item.question.type === NormalQuestionType.MC_SELECT &&
                        child.item.question.technicalReference &&
                        [
                            TechnicalReference.HAS_REFERENCE_OBJECTS_VALUATION,
                            TechnicalReference.HAS_REFERENCE_OBJECTS_AFTER_CONSTRUCTION,
                            TechnicalReference.HAS_REFERENCE_OBJECTS_AFTER_REALISATION,
                            TechnicalReference.HAS_REFERENCE_OBJECTS_SPECIAL_VALUE_ARGUMENT,
                        ].includes(child.item.question.technicalReference)
                );

                const valuationValue = valuation?.item.answer?.contents ?? null;
                const selectedDropdownAnswerOption =
                    dropdown?.item.question.answerOptions.find(
                        (ao) => ao.id === dropdown.item.answer?.answerOptionId
                    ) ?? null;

                if (
                    selectedDropdownAnswerOption &&
                    selectedDropdownAnswerOption.contents.toLowerCase() !== 'geen' &&
                    // Make sure reference set is actually visible
                    dropdown?.item.answer &&
                    this.answerController.filterDeleted([dropdown.item.answer]).length > 0 &&
                    !this.questionEffectInteractor.isHidden(dropdown.item.question.uuid, dropdown.item.answer.uuid)
                ) {
                    let type = SetType.SOLD;
                    switch (selectedDropdownAnswerOption.contents.toLowerCase()) {
                        case 'verhuurd':
                        case 'huur':
                            type = SetType.RENT;
                            break;
                        case 'te koop':
                        case 'koop':
                            type = SetType.SALE;
                            break;
                        case 'geen':
                            type = SetType.NONE;
                            break;
                        default:
                            type = SetType.SOLD;
                            break;
                    }

                    const shouldAdd = !ignoreValuationTypeNone || (ignoreValuationTypeNone && type !== SetType.NONE);

                    const existingSameDefinition = setDefinitions.find((sd) => {
                        return (
                            sd.valuationSeedData.iteration === valuationSeedData.iteration &&
                            sd.valuationSeedData.technicalReference === valuationSeedData.technicalReference
                        );
                    });

                    const isMarkedValue =
                        valuationSeedData.technicalReference === TechnicalReference.VALUATION_MARKET_VALUE_GROUP ||
                        valuationSeedData.technicalReference ===
                            TechnicalReference.VALUATION_MARKET_VALUE_FORCED_SALE_GROUP;

                    if (!existingSameDefinition && shouldAdd) {
                        setDefinitions.push({
                            valuationType: valuationType,
                            valuationSeedData: valuationSeedData,
                            title: valuationValue
                                ? formatMoney(parseInt(valuationValue, 10) ?? 0)
                                : this.getValuationName(valuationSeedData, true),
                            valuation: valuationValue ? parseInt(valuationValue, 10) ?? 0 : 0,
                            type: type,
                            groupTree: valuationGroup,
                            name: this.getValuationName(valuationSeedData),
                            description: this.getValuationDescription(
                                valuationSeedData,
                                this.valuationDescription(valuationGroup)
                            ),
                            isDemolishedBuilding: isMarkedValue && this.isDemolishedBuilding(),
                        });
                    }
                }
            }
        }
        return setDefinitions;
    }

    private isDemolishedBuilding(): boolean {
        const isDemolishedQuestion = this.questionSet.findQuestionByTechnicalReference(
            TechnicalReference.HAS_TO_BE_DESTRUCTED_OBJECT
        );
        if (isDemolishedQuestion) {
            for (const isDemolishedAnswer of this.answerController.answersForQuestionUuid(isDemolishedQuestion.uuid)) {
                if (isDemolishedAnswer) {
                    if (
                        isDemolishedAnswer.contents === '1' ||
                        isDemolishedQuestion.answerOptions.find(
                            (ao) => ao.contents.toLowerCase() === 'ja' && ao.id === isDemolishedAnswer.answerOptionId
                        ) !== undefined
                    ) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    private valuationDescription(valuationGroup: TreeItem<QuestionAnswerPair>): string | null {
        const iteratorGroup: TreeItem<QuestionAnswerPair> | null = findParentByPredicateRecursive(
            valuationGroup,
            (q) => q.question.technicalReference === TechnicalReference.SPECIAL_VALUE_ARGUMENT_ITERATOR
        );

        const dropdown =
            iteratorGroup?.children.find(
                (child) =>
                    child.item.question.technicalReference === TechnicalReference.VALUATION_SPECIAL_EXPLANATION_MC
            ) ?? null;

        const answerOption =
            dropdown?.item.question.answerOptions.find((ao) => ao.id === dropdown.item.answer?.answerOptionId) ?? null;

        if (answerOption?.contents.toLowerCase() !== 'anders') {
            return answerOption?.contents ?? null;
        }

        const open =
            iteratorGroup?.children.find(
                (child) =>
                    child.item.question.technicalReference === TechnicalReference.VALUATION_SPECIAL_EXPLANATION_OPEN
            ) ?? null;

        return open?.item.answer?.contents ?? null;
    }

    private getValuationName(valuationSeedData: V3ValuationSeedData, useShort?: boolean): string {
        switch (valuationSeedData.technicalReference) {
            case TechnicalReference.VALUATION_MARKET_VALUE_GROUP:
            case TechnicalReference.VALUATION_MARKET_VALUE_AFTER_CONSTRUCTION_GROUP:
            case TechnicalReference.VALUATION_MARKET_VALUE_AFTER_REALISATION_GROUP:
            case TechnicalReference.VALUATION_MARKET_VALUE_FORCED_SALE_GROUP:
                return useShort ? 'Marktw.' : 'Marktwaarde';
            case TechnicalReference.VALUATION_SPECIAL_VALUE_GROUP:
            case TechnicalReference.VALUATION_SPECIAL_VALUE_AFTER_CONSTRUCTION_GROUP:
            case TechnicalReference.VALUATION_SPECIAL_VALUE_AFTER_REALISATION_GROUP:
            case TechnicalReference.VALUATION_SPECIAL_VALUE_FORCED_SALE_GROUP:
                return useShort ? 'Bijz. uit.' : 'Bijzonder uitgangspunt';
            case TechnicalReference.VALUATION_FORCED_SALE_GROUP:
                return useShort ? 'Gedw. verkoop' : 'Gedwongen verkoop';
            default:
                return '';
        }
    }

    private getValuationDescription(
        valuationSeedData: V3ValuationSeedData,
        specialValuationDescription: string | null
    ): string | null {
        switch (valuationSeedData.technicalReference) {
            case TechnicalReference.VALUATION_MARKET_VALUE_GROUP:
                return 'Marktwaarde in huidige staat';
            case TechnicalReference.VALUATION_MARKET_VALUE_FORCED_SALE_GROUP:
                return 'Marktwaarde gedwongen verkoop';
            case TechnicalReference.VALUATION_MARKET_VALUE_AFTER_CONSTRUCTION_GROUP:
            case TechnicalReference.VALUATION_SPECIAL_VALUE_AFTER_CONSTRUCTION_GROUP:
                return 'Waarde na verbouwing' + (specialValuationDescription ? ' :' + specialValuationDescription : '');
            case TechnicalReference.VALUATION_MARKET_VALUE_AFTER_REALISATION_GROUP:
            case TechnicalReference.VALUATION_SPECIAL_VALUE_AFTER_REALISATION_GROUP:
                return 'Waarde na realisatie' + (specialValuationDescription ? ' :' + specialValuationDescription : '');
            case TechnicalReference.VALUATION_SPECIAL_VALUE_GROUP:
            case TechnicalReference.VALUATION_SPECIAL_VALUE_FORCED_SALE_GROUP:
            case TechnicalReference.VALUATION_FORCED_SALE_GROUP:
                return 'Waarde' + (specialValuationDescription ? ' :' + specialValuationDescription : '');
            default:
                return null;
        }
    }

    public getSetDefinitions(
        valuationGroupQuestion: Question,
        ignoreValuationTypeNone: boolean
    ): SetDefinitionsData | null {
        const questionUuids = this.collectQuestionUuids(valuationGroupQuestion);

        const answersWithDeleted = questionUuids.flatMap((uuid) => this.answerController.answersForQuestionUuid(uuid));
        const answers = this.answerController
            .filterDeleted(answersWithDeleted)
            .filter((a) => !this.questionEffectInteractor.isHidden(a.questionUuid, a.uuid));

        const rootAnswer = answers.find((a) => a.questionUuid === valuationGroupQuestion.uuid);
        if (!rootAnswer) {
            return null;
        }

        const tree = buildQuestionAnswerTree(this.questionSet, answers, rootAnswer);
        const setDefinitions = this.findValidSetDefinitions(tree, ignoreValuationTypeNone);

        return {
            tree,
            setDefinitions,
        };
    }

    //Keep an cached version of the observable, this observable is quite performance intensive, caching the observable itself
    //Will result in it only executing once for every subscription (thanks to the shareReplay)
    //If we dont do this, a new observable is created every time the `setDefinitions` method is called
    private observableCache: Observable<Array<V3SetDefinition<TreeItem<QuestionAnswerPair>>> | null> | null = null;
    public setDefinitions(): Observable<Array<V3SetDefinition<TreeItem<QuestionAnswerPair>>> | null> {
        if (isAppraiseModel(this.appraisal, AppraiseModel.MODEL2024PLAUSIBILITY)) {
            // This set has no reference objects
            return EMPTY;
        }

        if (this.observableCache !== null) {
            return this.observableCache;
        }

        const valuationGroupQuestion = this.questionSet.findQuestionByTechnicalReference(
            TechnicalReference.VALUATION_GROUP
        );

        if (!valuationGroupQuestion) {
            console.log(
                `No question with TechnicalReference "${TechnicalReference.VALUATION_GROUP}" found! possible v2 report`
            );
            return EMPTY;
        }
        const questionUuids = this.collectQuestionUuids(valuationGroupQuestion);

        this.observableCache = this.answerController.answersForQuestionUuidsStream(questionUuids).pipe(
            debounceTime(200),
            map((answers) => {
                const rootAnswer = answers.find((a) => a.questionUuid === valuationGroupQuestion.uuid);
                if (rootAnswer) {
                    return buildQuestionAnswerTree(this.questionSet, answers, rootAnswer);
                }

                return null;
            }),
            map((tree) => {
                if (!tree) {
                    return null;
                }

                return this.findValidSetDefinitions(tree, false);
            }),
            shareReplay(1)
        );
        return this.observableCache;
    }
}
