import {makeObservable, observable, runInAction} from 'mobx';

import {Appraisal} from '../../../../models/appraisal';
import {Presenter} from '../../../../../support/presenter/presenter';
import {AppraisalApi} from '../../../../network/appraisal_api';
import {ObjectSummary} from '../../../../models/object_summary';

export class AppraisalMatrixianBuurtinfoWidgetPresenter implements Presenter {
    @observable public appraisal: Appraisal;
    @observable public objectSummary?: ObjectSummary;
    @observable public loading = true;

    constructor(appraisal: Appraisal, private appraisalApi: AppraisalApi) {
        makeObservable(this);
        this.appraisal = appraisal;
    }

    public mount() {
        runInAction(() => {
            this.appraisalApi.getObjectSummary(this.appraisal.id).then((objectSummary) => {
                objectSummary.keywords =
                    objectSummary.keywords?.filter((summary) => {
                        return !(
                            summary === 'Onbekend' ||
                            summary === 'Goed' ||
                            summary === 'Redelijk' ||
                            summary === 'Slecht' ||
                            summary === 'Matig'
                        );
                    }) ?? [];

                runInAction(() => {
                    this.objectSummary = objectSummary;
                    this.loading = false;
                });
            });
        });
    }

    public unmount() {
        // noop
    }
}
