import {Observable, map, of} from 'rxjs';
import {AnswerController} from '../appraising/business/answering/answer_controller';
import {QuestionEffectInteractor} from '../appraising/business/conditions/question_effects_interactor';
import {TechnicalReference} from '../appraising/enum/technical_reference';
import {QuestionSet} from '../appraising/models/question_set';
import {getNewestAnswer} from './get_newest_answer';
import {Answer} from '../appraising/models/answer';
import {Question} from '../appraising/models/question';

export function getQuestionsAndAnswerStreamByTechnicalReference(
    questionSet: QuestionSet,
    answerController: AnswerController,
    questionEffectInteractor: QuestionEffectInteractor,
    technicalReference: TechnicalReference
): Observable<{questions: Question[]; answer: Answer | null} | null> {
    const questions = questionSet.findQuestionsByTechnicalReference(technicalReference);

    if (questions.length === 0) {
        return of(null);
    }

    return answerController.answersForQuestionUuidsStream(questions.map((q) => q.uuid)).pipe(
        map((a) => answerController.filterDeleted(a)),
        map((a) => a.filter((answer) => !questionEffectInteractor.isHidden(answer.questionUuid, answer.uuid))),
        map((a) => ({
            questions,
            answer: getNewestAnswer(a) ?? null,
        }))
    );
}

export function getAnswerContentsStreamByTechnicalReference(
    questionSet: QuestionSet,
    answerController: AnswerController,
    questionEffectInteractor: QuestionEffectInteractor,
    technicalReference: TechnicalReference
): Observable<string | null> {
    return getQuestionsAndAnswerStreamByTechnicalReference(
        questionSet,
        answerController,
        questionEffectInteractor,
        technicalReference
    ).pipe(map((result) => result?.answer?.contents ?? null));
}

export function getMultipleChoiceAnswerStreamByTechnicalReference(
    questionSet: QuestionSet,
    answerController: AnswerController,
    questionEffectInteractor: QuestionEffectInteractor,
    technicalReference: TechnicalReference
): Observable<string | null> {
    return getQuestionsAndAnswerStreamByTechnicalReference(
        questionSet,
        answerController,
        questionEffectInteractor,
        technicalReference
    ).pipe(
        map((result) => {
            if (!result) {
                return null;
            }

            const answerOptions = result.questions.flatMap((q) => q.answerOptions);
            if (result.answer?.answerOptionId != null) {
                return answerOptions.find((option) => option.id === result.answer?.answerOptionId)?.contents ?? null;
            } else {
                return result.answer?.contents ?? null;
            }
        })
    );
}
